<div class="card mb-0">
   
    <div class="card-body">

        <div class="card item-form">

            <div class="card-body">

                <div class="container rates-container" *ngIf="rates">
                    <div class="mb-3 row" *ngFor="let rate of rates; let i = index">
                        <div class="custom-label col-5">
                            <i class="uil uil-file-alt" *ngIf="!rate.restriction"></i>
                            <i class="uil uil-file-block-alt" *ngIf="rate.restriction"></i>
                            <label class="">{{rate.name}}</label>
                        </div>

                        <div class="form-floating col-6">
                            <ng-select [clearable]="false" placeholder="{{'AVAILABILITY.channel_room' | translate}}" [(ngModel)]="rateIndex[rate.id].channelRateCode" class="room-mapping-select">
                                <ng-option *ngFor="let item of channelRates" [value]="item.strId">
                                    {{item.name}}
                                    <small>[{{item.strId}}]</small>
                                </ng-option>
                            </ng-select>
                        </div>
                        

                    </div><!-- end row -->

                    <div class="row text-center">
                        <button class="btn btn-primary" (click)="savePlan()">
                            {{"GENERIC.save_btn" | translate}}
                        </button>
                    </div>
                </div>
            </div>

        </div>

    </div><!-- end card body -->
</div>