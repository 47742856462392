import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';
import { StructureService } from 'src/app/core/services/structure.service';
import * as moment from 'moment';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexPlotOptions, ApexYAxis, ApexXAxis, ApexFill, ApexTooltip, ApexStroke, ApexLegend, ChartComponent } from 'ng-apexcharts';
import { TranslateService } from '@ngx-translate/core';
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};

@Component({
  selector: 'app-report-view-month',
  templateUrl: './report-view-month.component.html',
  styleUrls: ['./report-view-month.component.scss']
})
export class ReportViewMonthComponent {
  loaded: boolean = false;
  showTable: boolean = false;
  year: any = {
    from: '',
    to: ''
  };
  years: any = [];
  startYear: any = [];
  compareYear: any = [];

  @ViewChild("chart_occ") chart_occ: ChartComponent | any;
  @ViewChild("chart_prod") chart_prod: ChartComponent | any;
  @ViewChild("chart_rmc") chart_rmc: ChartComponent | any;
  @ViewChild("chart_revpare") chart_revpare: ChartComponent | any;
  public chartOptions_occ: Partial<ChartOptions> | any;
  public chartOptions_prod: Partial<ChartOptions> | any;
  public chartOptions_rmc: Partial<ChartOptions> | any;
  public chartOptions_revpare: Partial<ChartOptions> | any;
  rowSelectedIndex: any;
  fullChart: boolean = false;
  selectedColumn: any;

  constructor(public structureService: StructureService,private restService: RestService,private router: Router, public guiService: GuiService,public translate: TranslateService){
    this.structureService.isStructureChanged.subscribe( value => {
      if(value == true){
        this.ngOnInit()
      }
  });
  }

  ngOnInit(): void {
    this.generateYearsArray()
  }

  generateYearsArray(){
    console.debug()
    let nextYear = moment().add('1','year').format('YYYY')
    this.years.push(nextYear)
    for(let i = 1; i < 10; i++){
      this.years.push(moment(nextYear).subtract(i,'years').format('YYYY'))
    }
    console.debug(this.years)
  }

  getTableDatas(){
    this.restService.getService('calendar', 'v4/'+this.structureService.selectedStructure.id+'/monthly-revenue-indicators?from='+this.year.from+'-01'+'&months='+12).subscribe((ris)=>{
      let tmpTotalStartYear = {
        month: 0,
        monthName: 'Totale',
        occ:0,
        prod: "0,00",
        revpar: "0,00",
        rmc:"0,00"
      }
      this.startYear = []
      for(let item in ris.indicators){
        this.startYear.push(ris.indicators[item].current)
      }
      this.startYear.push(tmpTotalStartYear)
      console.debug(this.startYear)
      this.loaded = true;
      this.showTable = true;
      if(this.year.to){
      console.debug(this.year.to)
      this.loaded = false;
      this.showTable = false;
      this.restService.getService('calendar', 'v4/'+this.structureService.selectedStructure.id+'/monthly-revenue-indicators?from='+this.year.to+'-01'+'&months='+12).subscribe((res)=>{
        this.compareYear = []
        let tmpTotalCompareYear = {
          month: 0,
          monthName: 'Totale',
          occ:0,
          prod: "0,00",
          revpar: "0,00",
          rmc:"0,00"
        }
        for(let item in res.indicators){
          this.compareYear.push(res.indicators[item].current)
        }
        this.compareYear.push(tmpTotalCompareYear)
        console.debug(this.compareYear)
        for(let i = 0; i < this.compareYear.length; i++){
          console.debug(i)
          /* SI FA QUESTA ASSEGNAZIONE PER ADDATTARE IL FUNZIONAMENTO CON LE CHIAVI DEL NUOVO SERVIZIO IMPLEMENTATO */
          if(i < 12){
            this.startYear[i].occ = this.startYear[i].occupancy
            this.startYear[i].prod = this.startYear[i].revenue
            this.startYear[i].revpar = this.startYear[i].revpar
            this.startYear[i].rmc = this.startYear[i].rmc
            this.startYear[i].month = i+1

            this.compareYear[i].occ = this.compareYear[i].occupancy
            this.compareYear[i].prod = this.compareYear[i].revenue
            this.compareYear[i].revpar = this.compareYear[i].revpar
            this.compareYear[i].rmc = this.compareYear[i].rmc
            this.compareYear[i].month = i+1

            /* FACCIO IL CALCOLO DEL TOTALE ESSENDO NON NELLA RISPOSTA */

            this.startYear[12].occ = this.tmpCalculateSum(this.startYear[12].occ,this.startYear[i].occ)
            this.startYear[12].prod  = this.tmpCalculateSum(this.startYear[12].prod,this.startYear[i].prod)
            this.startYear[12].revpar = this.tmpCalculateSum(this.startYear[12].revpar,this.startYear[i].revpar)
            this.startYear[12].rmc = this.tmpCalculateSum(this.startYear[12].rmc,this.startYear[i].rmc)

            this.compareYear[12].occ = this.tmpCalculateSum(this.compareYear[12].occ,this.compareYear[i].occ)
            this.compareYear[12].prod = this.tmpCalculateSum(this.compareYear[12].prod,this.compareYear[i].prod)
            this.compareYear[12].revpar = this.tmpCalculateSum(this.compareYear[12].revpar,this.compareYear[i].revpar)
            this.compareYear[12].rmc = this.tmpCalculateSum(this.compareYear[12].rmc,this.compareYear[i].rmc)

            this.compareYear[i].occ_calc = this.tmpCalculateDiffrence(this.startYear[i].occ,this.compareYear[i].occ)
            this.compareYear[i].prod_calc = this.tmpCalculateDiffrence(this.startYear[i].prod,this.compareYear[i].prod)
            this.compareYear[i].revpar_calc = this.tmpCalculateDiffrence(this.startYear[i].revpar,this.compareYear[i].revpar)
            this.compareYear[i].rmc_calc = this.tmpCalculateDiffrence(this.startYear[i].rmc,this.compareYear[i].rmc)
          }

        }

        this.compareYear[12].revpar = (Math.ceil(this.compareYear[12].revpar) / 12).toFixed(2);
        this.compareYear[12].rmc = (Math.ceil(this.compareYear[12].rmc) / 12).toFixed(2);

        this.startYear[12].revpar = (Math.ceil(this.startYear[12].revpar) / 12).toFixed(2);
        this.startYear[12].rmc = (Math.ceil(this.startYear[12].rmc) / 12).toFixed(2);

        this.initAllCharts(this.year.from, this.year.to, this.startYear[12].occ, this.compareYear[12].occ,this.startYear[12].prod, this.compareYear[12].prod,this.startYear[12].revpar, this.compareYear[12].revpar,this.startYear[12].rmc, this.compareYear[12].rmc)
        this.loaded = true;
        this.showTable = true;
      })
    }
    })


  }

  tmpCalculateDiffrence(input1: any, input2:any){
    return Number(parseFloat(input1)) - Number(parseFloat(input2))
   }

   tmpCalculateSum(input1: any, input2:any){
    return Number(parseFloat(input1)) + Number(parseFloat(input2))
   }

   initAllCharts(yearFrom: any, yearTo: any,yearFrom_occ: any, yearTo_occ: any,yearFrom_prod: any, yearTo_prod: any,yearFrom_revpar: any, yearTo_revpar: any,yearFrom_rmc: any, yearTo_rmc: any,indexSelected?:any){
    this.initChartOcc(yearFrom, yearTo, yearFrom_occ, yearTo_occ)
    this.initChartProd(this.year.from, this.year.to, yearFrom_prod, yearTo_prod)
    this.initChartRevpar(this.year.from, this.year.to, yearFrom_revpar, yearTo_revpar)
    this.initChartRmc(this.year.from, this.year.to, yearFrom_rmc, yearTo_rmc)
    this.fullChart = false;
    this.rowSelectedIndex = indexSelected
    this.selectedColumn = null
   }

   initChartOcc(fromYear: any,toYear: any, yearFromOcc: any, yearToOcc: any,column?: boolean,type?:string){
      /* CHART OCC */
      type ? (this.selectedColumn = type,this.rowSelectedIndex = null) :null

      if(column){
        setTimeout(() => {
          this.chartOptions_prod = null
          this.chartOptions_revpare = null
          this.chartOptions_rmc = null
        }, 100);
        yearFromOcc = []
        yearToOcc = []
        for(let i = 0; i < this.startYear.length; i++){
          if(i < 12){
            let item = this.startYear[i]
            yearFromOcc.push(item.occ)
          }
        }
        for(let i = 0; i < this.compareYear.length; i++){
          if(i < 12){
            let item = this.compareYear[i]
            yearToOcc.push(item.occ)
          }
        }
      }

      this.chartOptions_occ = {
      colors:['#000000','#9998ff'],
      series: [
        {
          name: column ? this.year.from : fromYear,
          data: column ? yearFromOcc : [yearFromOcc]
        },
        {
          name: column ? this.year.to : toYear,
          data: column ? yearToOcc : [yearToOcc]
        }
      ],
      chart: {
        type: "bar",
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: column ? [
          this.translate.instant("MONTHS.SHORT.1"),
          this.translate.instant("MONTHS.SHORT.2"),
          this.translate.instant("MONTHS.SHORT.3"),
          this.translate.instant("MONTHS.SHORT.4"),
          this.translate.instant("MONTHS.SHORT.5"),
          this.translate.instant("MONTHS.SHORT.6"),
          this.translate.instant("MONTHS.SHORT.7"),
          this.translate.instant("MONTHS.SHORT.8"),
          this.translate.instant("MONTHS.SHORT.9"),
          this.translate.instant("MONTHS.SHORT.10"),
          this.translate.instant("MONTHS.SHORT.11"),
          this.translate.instant("MONTHS.SHORT.12")
        ] : ['',''],
        labels: {
          show: column ? true : false
        }
      },
      yaxis: {
        title: {
          text: "OCC"
        }
      },
      fill: {
        colors:['#000000','#9998ff'],
        opacity: 1
      },
      tooltip: {
        y: {
        }
      }
    };
    column ? this.fullChart = true : null
  }

  initChartProd(fromYear: any,toYear: any, yearFromProd: any, yearToProd: any,column?: boolean,type?:string){
  /* CHART PROD */
  type ? (this.selectedColumn = type,this.rowSelectedIndex = null) :null

    if(column){
      setTimeout(() => {
        this.chartOptions_occ = null
        this.chartOptions_revpare = null
        this.chartOptions_rmc = null
      }, 100);
      yearFromProd = []
      yearToProd = []
      for(let i = 0; i < this.startYear.length; i++){
        if(i < 12){
          let item = this.startYear[i]
          yearFromProd.push(item.prod)
        }
      }
      for(let i = 0; i < this.compareYear.length; i++){
        if(i < 12){
          let item = this.compareYear[i]
          yearToProd.push(item.prod)
        }
      }
    }

      this.chartOptions_prod = {
        colors:['#000000','#9998ff'],
        series: [
          {
            name: column ? this.year.from : fromYear,
            data: column ? yearFromProd : [yearFromProd]
          },
          {
            name: column ? this.year.to : toYear,
            data: column ? yearToProd : [yearToProd]
          }
        ],
        chart: {
          type: "bar",
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded"
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: column ? [
            this.translate.instant("MONTHS.SHORT.1"),
            this.translate.instant("MONTHS.SHORT.2"),
            this.translate.instant("MONTHS.SHORT.3"),
            this.translate.instant("MONTHS.SHORT.4"),
            this.translate.instant("MONTHS.SHORT.5"),
            this.translate.instant("MONTHS.SHORT.6"),
            this.translate.instant("MONTHS.SHORT.7"),
            this.translate.instant("MONTHS.SHORT.8"),
            this.translate.instant("MONTHS.SHORT.9"),
            this.translate.instant("MONTHS.SHORT.10"),
            this.translate.instant("MONTHS.SHORT.11"),
            this.translate.instant("MONTHS.SHORT.12")
          ] : ['',''],
          labels: {
            show: column ? true : false
          }
        },
        yaxis: {
          title: {
            text: "PROD"
          }
        },
        fill: {
          colors:['#000000','#9998ff'],
          opacity: 1
        },
        tooltip: {
          y: {
          }
        }
      };
      column ? this.fullChart = true : null
  }

  initChartRevpar(fromYear: any,toYear: any,yearFromRevpar: any, yearToRevpar: any,column?: boolean,type?:string){
  /* CHART REVPAR */
  type ? (this.selectedColumn = type,this.rowSelectedIndex = null) :null

  if(column){
    setTimeout(() => {
      this.chartOptions_occ = null
      this.chartOptions_prod = null
      this.chartOptions_rmc = null
    }, 100);
    yearFromRevpar = []
    yearToRevpar = []
    for(let i = 0; i < this.startYear.length; i++){
      if(i < 12){
        let item = this.startYear[i]
        yearFromRevpar.push(item.revpar)
      }
    }
    for(let i = 0; i < this.compareYear.length; i++){
      if(i < 12){
        let item = this.compareYear[i]
        yearToRevpar.push(item.revpar)
      }
    }
  }

      this.chartOptions_revpare = {
        colors:['#000000','#9998ff'],
        series: [
          {
            name: column ? this.year.from : fromYear,
            data: column ? yearFromRevpar : [yearFromRevpar]
          },
          {
            name: column ? this.year.to : toYear,
            data: column ? yearToRevpar : [yearToRevpar]
          }
        ],
        chart: {
          type: "bar",
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded"
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: column ? [
            this.translate.instant("MONTHS.SHORT.1"),
            this.translate.instant("MONTHS.SHORT.2"),
            this.translate.instant("MONTHS.SHORT.3"),
            this.translate.instant("MONTHS.SHORT.4"),
            this.translate.instant("MONTHS.SHORT.5"),
            this.translate.instant("MONTHS.SHORT.6"),
            this.translate.instant("MONTHS.SHORT.7"),
            this.translate.instant("MONTHS.SHORT.8"),
            this.translate.instant("MONTHS.SHORT.9"),
            this.translate.instant("MONTHS.SHORT.10"),
            this.translate.instant("MONTHS.SHORT.11"),
            this.translate.instant("MONTHS.SHORT.12")
          ] : ['',''],
          labels: {
            show: column ? true : false
          }
        },
        yaxis: {
          title: {
            text: "RATEPAR"
          }
        },
        fill: {
          colors:['#000000','#9998ff'],
          opacity: 1
        },
        tooltip: {
          y: {
          }
        }
      };
      column ? this.fullChart = true : null
  }

  initChartRmc(fromYear: any,toYear: any,yearFromRmc: any, yearToRmc: any,column?: boolean,type?:string){
  /* REV RMC */
  type ? (this.selectedColumn = type,this.rowSelectedIndex = null) :null

  if(column){
    setTimeout(() => {
      this.chartOptions_occ = null
      this.chartOptions_revpare = null
      this.chartOptions_prod = null
    }, 100);
    yearFromRmc = []
    yearToRmc = []
    for(let i = 0; i < this.startYear.length; i++){
      if(i < 12){
        let item = this.startYear[i]
        yearFromRmc.push(item.rmc)
      }
    }
    for(let i = 0; i < this.compareYear.length; i++){
      if(i < 12){
        let item = this.compareYear[i]
        yearToRmc.push(item.rmc)
      }
    }
  }

      this.chartOptions_rmc = {
        colors:['#000000','#9998ff'],
        series: [
          {
            name: column ? this.year.from : fromYear,
            data: column ? yearFromRmc : [yearFromRmc]
          },
          {
            name: column ? this.year.to : toYear,
            data: column ? yearToRmc : [yearToRmc]
          }
        ],
        chart: {
          type: "bar",
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded"
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: column ? [
            this.translate.instant("MONTHS.SHORT.1"),
            this.translate.instant("MONTHS.SHORT.2"),
            this.translate.instant("MONTHS.SHORT.3"),
            this.translate.instant("MONTHS.SHORT.4"),
            this.translate.instant("MONTHS.SHORT.5"),
            this.translate.instant("MONTHS.SHORT.6"),
            this.translate.instant("MONTHS.SHORT.7"),
            this.translate.instant("MONTHS.SHORT.8"),
            this.translate.instant("MONTHS.SHORT.9"),
            this.translate.instant("MONTHS.SHORT.10"),
            this.translate.instant("MONTHS.SHORT.11"),
            this.translate.instant("MONTHS.SHORT.12")
          ] : ['',''],
          labels: {
            show: column ? true : false
          }
        },
        yaxis: {
          title: {
            text: "RMC"
          }
        },
        fill: {
          colors:['#000000','#9998ff'],
          opacity: 1
        },
        tooltip: {
          y: {
          }
        }
      };
      column ? this.fullChart = true : null
  }

}
