import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Lightbox} from "ngx-lightbox";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-video-guide',
  templateUrl: './video-guide.component.html',
  styleUrls: ['./video-guide.component.scss']
})
export class VideoGuideComponent implements OnInit {
  loaded: boolean = false;
  video_items: Array<any> = [];

  constructor(public translate: TranslateService, private lightbox: Lightbox, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    setTimeout(()=>{

      this.video_items = [
        {
          title: this.translate.instant("VIDEOGUIDE.MOD_MANUAL.title"),
          url: this.translate.instant("VIDEOGUIDE.MOD_MANUAL.url")
        },
        {
          title: this.translate.instant("VIDEOGUIDE.CALC.title"),
          url: this.translate.instant("VIDEOGUIDE.CALC.url")
        },
        {
          title: this.translate.instant("VIDEOGUIDE.DATAENTRY.title"),
          url: this.translate.instant("VIDEOGUIDE.DATAENTRY.url")
        },
        {
          title: this.translate.instant("VIDEOGUIDE.AVAILABILITY.title"),
          url: this.translate.instant("VIDEOGUIDE.AVAILABILITY.url")
        },
        {
          title: this.translate.instant("VIDEOGUIDE.EVENTS.title"),
          url: this.translate.instant("VIDEOGUIDE.EVENTS.url")
        }
      ];
      this.loaded = true;
    }, 1000)
  }

  /**
   * Open modal
   * @param content modal content
   */
  openYoutubeModal(content: any) {
    this.modalService.open(content, { size: 'lg', windowClass: 'modal-holder', centered: true });
  }
}
