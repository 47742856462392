import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';
import { StructureService } from 'src/app/core/services/structure.service';
import Swal from 'sweetalert2';
import {RateModel} from "../../../core/model/rate-model";

@Component({
  selector: 'app-price-list',
  templateUrl: './price-list.component.html',
  styleUrls: ['./price-list.component.scss']
})
export class PriceListComponent {
  priceView: boolean = false;
  priceSelected: any;
  priceToDuplicate: any;
  newPrice: boolean = false;
  searchCtrl: any;
  page = 1;
  size = 15;
  prices: any;
  showDetail: boolean = false;
  loaded: boolean = false;

  constructor(public guiService: GuiService, public structureService: StructureService,private router: Router,private restService: RestService){
    this.structureService.isStructureChanged.subscribe( value => {
      if(value == true){
        this.ngOnInit()
        this.priceSelected = null
        this.showDetail = false;
      }
  });

  this.guiService.closeDetail.subscribe( value => {
    if(value == true){
      this.priceSelected = null
      this.showDetail = false;
    }
});

this.guiService.refreshList.subscribe( value => {
  if(value == true){
    this.page = 0
    this.getPrices()
  }
 });
  }

  ngOnInit(): void {
    console.debug(this.structureService.selectedStructure)

    this.structureService.selectedStructure ? null : Swal.fire({
       title: 'Nessuna struttura selezionata',
       icon: 'warning'
     }).then((result)=>{
       if(result.isConfirmed){
         this.router.navigate(['admin/dashboard']);
       }
     });

    this.getPrices()
  }

  getPrices(){
    if(this.structureService.selectedStructure){
      console.debug("PAGE",this.page);
    this.restService.getService('price_list','/'+this.structureService.selectedStructure.id).subscribe((ris)=>{
      this.prices = ris;
      this.loaded = true;
    })
    }
  }

  loadPage(event:any){
    this.page = event;
    this.getPrices();
   }

  editPrice(item: RateModel ){
    this.priceSelected = item;
    this.newPrice = false;
    this.priceToDuplicate = false;
    this.showDetail = true;
   }

   duplicatePrice(item: RateModel ){
    console.debug("Duplicate price",item);
     this.priceSelected = item;
     this.newPrice = false;
     this.priceToDuplicate = true;
     this.showDetail = true;
   }

   newPriceEnable() {
    this.newPrice = true;
    this.priceSelected = null;
    this.showDetail = true;
    this.priceToDuplicate = false;
  }

}
