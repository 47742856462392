import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appOtpAutotab]'
})
export class OtpAutotabDirective {
  @Input() appOtpAutotab: any;
  constructor() {}
  @HostListener('input', ['$event.target']) onInput(input: any) {
    const length = input.value.length;
    const maxLength = input.attributes.maxlength.value;
    console.debug("-->",length,maxLength);

    if (length >= maxLength && this.appOtpAutotab) {
      const field = document.getElementById(this.appOtpAutotab);
      if (field) {
        field.focus();
      }
    }
  }

}
