<div class="contenitore" *ngIf="calService.calendarDataDays!=null">
  <div class="box-day" *ngFor="let item of days_data; let d = index" id="{{d}}"
    [class.selected-day]="calService.selectedDay && (item.y+''+item.m+''+item.d) == calService.selectedDay"
    [class.pre-post-month]="calService.selectedDay && item.m != calService.monthSelectedForCalendar"
    [class.past-days]="calService.selectedDay && !calService.nowForCalendar.isSameOrBefore(item.day,'day')"
    [class.edited]="calService.editedDays.indexOf(item.day) >= 0">

    <div class="row"
      *ngIf="calService.calendarDataDays[item.day] && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY']">

      <div class="col-6">


        <!-- Start ROOMS code -->

        <div class="days-col col-12" *ngIf="guiService.calendarTabSelected =='rooms'">


          <!-- HEADER TABLE -->
          <div class="row">
            <div class="col-day-label">
              &nbsp;
            </div>

            <div class="col-day-data header-text" id="ref_{{d}}" (mouseup)="onXScroll(d)">
              <div class="main-data-wrapper" [style.width.px]="(((rooms.length + 1) + channels.length)*70)">
                <div class="main-data" *ngIf="rooms">
                  <span class="day-cell" [class.inv-room-block]="structureService.selectedStructureConfig['occ_inv'].value === 'true'">{{"GENERIC.inv" | translate | uppercase}}</span>
                  <span *ngFor="let room of rooms; let i = index">
                    <span class="day-cell" [class.inv-room-block]="structureService.selectedStructureConfig['occ_inv'].value === 'true'">{{room.title}}</span>
                  </span>
                </div>

                <!-- CHANNELS -->
                <div class="main-data" *ngIf="channels">
                  <span *ngFor="let channel of channels; let i = index">
                    <span class="day-cell">{{channel.code}}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- CURRENT YEAR -->
          <div class="row current-year">
            <div class="col-day-label black">
              <span *ngIf="calService.calendarDataDays[item.day]">
                <span class="event-area" *ngFor="let ev of calService.calendarDataDays[item.day].events.current">
                  <i class="bx" [ngClass]="{'bxs-star': !ev['preOrPostDay'], 'bx-star' : ev['preOrPostDay'] }"
                    ngbTooltip="{{ev.name}}" placement="top"
                    *ngIf="calService.calendarDataDays[item.day].events.current"></i>
                </span>
                {{'DAYS.SHORT.' + item.DoW | translate}} {{item.d}}-{{item.m}}-{{item.y}}
              </span>
            </div>
            <div class="col-day-data">
              <div class="main-data-wrapper" [style.width.px]="(((rooms.length + 1) + channels.length)*70)">
                <div class="main-data" *ngIf="rooms">
                  <span class="day-cell">
                    <span class="inv-block" *ngIf="calService.calendarDataDays != null &&
                        calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] &&
                        calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current">
                      <span
                        [class.overbooking]="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.inv<0">
                        {{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.inv}}
                      </span>
                    </span>
                  </span>
                  <span class="day-cell" *ngFor="let room of rooms; let i = index">
                    <span class="day-cell-occ">
                      <i *ngIf="structureService.selectedStructureConfig['occ_inv'].value !== 'true'">
                        <a
                          (click)="guiService.showSegmentInfo(item.day,room,calService.calendarDataDays[item.day].occupancies[room.title].current)">{{calService.calendarDataDays[item.day].occupancies[room.title].current
                          ? calService.calendarDataDays[item.day].occupancies[room.title].current.occupancy : ''}}</a>
                      </i>
                      <i *ngIf="structureService.selectedStructureConfig['occ_inv'].value === 'true'">
                        <a (click)="guiService.showSegmentInfo(item.day,room,calService.calendarDataDays[item.day].occupancies[room.title].current)">
                          {{calService.calendarDataDays[item.day].occupancies[room.title] ? calService.calendarDataDays[item.day].occupancies[room.title].inv_occ_current : ''}}
                        </a>
                      </i>
                      <i class="pk">

                        <span class="pk"
                          *ngIf="calService.calendarDataDays != null && calService.calendarDataDays[item.day].occupancies[room.title] && calService.calendarDataDays[item.day].occupancies[room.title].current && calService.calendarDataDays[item.day].occupancies[room.title].current.occupancyPickup != null">
                          <span [ngClass]="{
                        'pick-up-more': calService.calendarDataDays[item.day].occupancies[room.title].current.occupancyPickup>0,
                        'pick-up-less': calService.calendarDataDays[item.day].occupancies[room.title].current.occupancyPickup<0
                        }">
                            {{calService.calendarDataDays[item.day].occupancies[room.title].current.occupancyPickup==0 ?
                            '' : calService.calendarDataDays[item.day].occupancies[room.title].current.occupancyPickup}}
                          </span>
                        </span>

                      </i>
                    </span>
                  </span>

                </div>

                <!-- CHANNELS -->
                <div class="main-data" *ngIf="channels">
                  <span class="day-cell" *ngFor="let channel of channels; let i = index">
                    <span class="day-cell-occ">
                      <i>{{calService.calendarDataDays[item.day].occupancies[channel.code] &&
                        calService.calendarDataDays[item.day].occupancies[channel.code].current ?
                        calService.calendarDataDays[item.day].occupancies[channel.code].current.occupancy : ''}}</i>
                      <i class="pk">

                        <span class="pk"
                          *ngIf="calService.calendarDataDays != null && calService.calendarDataDays[item.day].occupancies[channel.code] && calService.calendarDataDays[item.day].occupancies[channel.code].current && calService.calendarDataDays[item.day].occupancies[channel.code].current.occupancyPickup != null">
                          <span [ngClass]="{
                        'pick-up-more': calService.calendarDataDays[item.day].occupancies[channel.code].current.occupancyPickup>0,
                        'pick-up-less': calService.calendarDataDays[item.day].occupancies[channel.code].current.occupancyPickup<0
                        }">
                            {{calService.calendarDataDays[item.day].occupancies[channel.code].current.occupancyPickup==0
                            ? '' :
                            calService.calendarDataDays[item.day].occupancies[channel.code].current.occupancyPickup}}
                          </span>
                        </span>

                      </i>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- DYNAMIC HISTORY -->
          <div class="row dynamic-h-year" *ngIf="calService.showDynamicHistory">
            <div class="col-day-label pink second-date">
              <span *ngIf="calService.calendarDataDays[item.day]">
                <span class="event-area" *ngFor="let ev of calService.calendarDataDays[item.day].events.dynHistoryc">
                  <i class="bx" [ngClass]="{'bxs-star': !ev['preOrPostDay'], 'bx-star' : ev['preOrPostDay'] }"
                    ngbTooltip="{{ev.name}}" placement="top"
                    *ngIf="calService.calendarDataDays[item.day].events.current"></i>
                </span>
                {{'DAYS.SHORT.' + item.DoW | translate}} {{item.dynday}}</span>
            </div>

            <div class="col-day-data">
              <div class="main-data-wrapper" [style.width.px]="(((rooms.length + 1) + channels.length)*70)">
                <div class="main-data" *ngIf="rooms">
                  <span class="day-cell" style="display: inline-block;">
                    <span class="inv-block" *ngIf="calService.calendarDataDays != null &&
                        calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] &&
                        calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric">
                      <span
                        [class.overbooking]="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric.inv<0">
                        {{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric.inv}}
                      </span>
                    </span>
                    <span class="inv-block" *ngIf="calService.calendarDataDays != null &&
                        calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] &&
                        !calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric">
                      <span>
                      /
                      </span>
                    </span>
                  </span>
                  <span *ngFor="let room of rooms; let i = index" class="dynamic-h-data">
                    <span class="day-cell" *ngIf="structureService.selectedStructureConfig['occ_inv'].value !== 'true'">{{calService.calendarDataDays[item.day].occupancies[room.title] &&
                      calService.calendarDataDays[item.day].occupancies[room.title].dynHistoric ?
                      calService.calendarDataDays[item.day].occupancies[room.title].dynHistoric.occupancy : ''}}</span>
                      <span class="day-cell" *ngIf="structureService.selectedStructureConfig['occ_inv'].value === 'true'">
                        <span>{{calService.calendarDataDays[item.day].occupancies[room.title] ? calService.calendarDataDays[item.day].occupancies[room.title].inv_occ_dynHistoric : ''}}</span>
                      </span>
                  </span>
                </div>

                <!-- CHANNELS -->
                <div class="main-data" *ngIf="channels">
                  <span *ngFor="let channel of channels; let i = index">
                    <span class="day-cell">{{calService.calendarDataDays[item.day].occupancies[channel.title] &&
                      calService.calendarDataDays[item.day].occupancies[channel.title].dynHistoric ?
                      calService.calendarDataDays[item.day].occupancies[channel.title].dynHistoric.occupancy :
                      ''}}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- LAST YEAR -->
          <div class="row last-year">
            <div class="col-day-label blue">
              <span *ngIf="calService.calendarDataDays[item.day]">
                <span class="event-area" *ngFor="let ev of calService.calendarDataDays[item.day].events.statHistoric">
                  <i class="bx" [ngClass]="{'bxs-star': !ev['preOrPostDay'], 'bx-star' : ev['preOrPostDay'] }"
                    ngbTooltip="{{ev.name}}" placement="top"
                    *ngIf="calService.calendarDataDays[item.day].events.current"></i>
                </span>
                {{'DAYS.SHORT.' + item.DoW | translate}} {{item.staday}}
              </span>
            </div>

            <div class="col-day-data">
              <div class="main-data-wrapper" [style.width.px]="(((rooms.length + 1) + channels.length)*70)">
                <div class="main-data" *ngIf="rooms">
                  <span class="day-cell">
                    <span class="inv-block" *ngIf="calService.calendarDataDays != null &&
                        calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] &&
                        calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric">
                      <span
                        [class.overbooking]="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric.inv<0">
                        {{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric.inv}}
                      </span>
                    </span>
                    <span class="inv-block" *ngIf="calService.calendarDataDays != null &&
                        calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] &&
                        !calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric">
                      <span>
                      /
                      </span>
                    </span>
                  </span>
                  <span *ngFor="let room of rooms; let i = index" class="last-y-data">
                    <span class="day-cell" *ngIf="structureService.selectedStructureConfig['occ_inv'].value !== 'true'">{{calService.calendarDataDays[item.day].occupancies[room.title] &&
                      calService.calendarDataDays[item.day].occupancies[room.title].statHistoric ?
                      calService.calendarDataDays[item.day].occupancies[room.title].statHistoric.occupancy : ''}}</span>
                      <span class="day-cell" *ngIf="structureService.selectedStructureConfig['occ_inv'].value === 'true'">
                        <span>{{calService.calendarDataDays[item.day].occupancies[room.title] ? calService.calendarDataDays[item.day].occupancies[room.title].inv_occ_statHistoric : ''}}</span>
                      </span>
                  </span>
                </div>

                <!-- CHANNELS -->
                <div class="main-data" *ngIf="channels">
                  <span *ngFor="let channel of channels; let i = index">
                    <span class="day-cell">{{calService.calendarDataDays[item.day].occupancies[channel.title] &&
                      calService.calendarDataDays[item.day].occupancies[channel.title].statHistoric ?
                      calService.calendarDataDays[item.day].occupancies[channel.title].statHistoric.occupancy :
                      ''}}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>



        </div>


      </div>
      <div class="col-2 mid-box-container">
        <div class="col-4 d-inline-block text-center mid-box" [class.mid-box-no-dynamic-history]="!calService.showDynamicHistory">
          <h1>OCC</h1>
          <h3>{{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] &&
            calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current ?
            calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.occupancy : '/'}}
            <h4 *ngIf="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.occupancyPickup !== 0">
              <span [ngClass]="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.occupancyPickup>0 ? 'pick-up-more' : 'pick-up-less'">
                {{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.occupancyPickup}}
              </span>
            </h4>
          </h3>
          <h3 class="pink" *ngIf="calService.showDynamicHistory">
            <span *ngIf="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric">
              {{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] ?
              calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.occupancy : '/'}}
            </span>
          </h3>
          <h3 class="blue">
            <span *ngIf="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric">
              {{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] ?
              calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric.occupancy : '/'}}</span>
          </h3>

        </div>
        <div class="col-4 d-inline-block text-center mid-box" [class.mid-box-no-dynamic-history]="!calService.showDynamicHistory">
          <h1><a
              (click)="guiService.showDetailDayInfo(item.day,'WHOLE_OCCUPANCY',calService.calendarDataDays[item.day].occupancies)">RMC</a>
          </h1>
          <h3
            *ngIf="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.occupancy>0">
            {{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.revenue /
            calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.occupancy | currency
            :'EUR':'symbol':'1.2-2'}}
          </h3>
          <h3
            *ngIf="!calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] || calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.occupancy<=0">
            0</h3>
          <h3 class="pink" *ngIf="calService.showDynamicHistory">
            <span
              *ngIf="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric.occupancy>0">
              {{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric.revenue /
              calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric.occupancy | currency
              :'EUR':'symbol':'1.2-2'}}
            </span>
          </h3>
          <h3 class="blue">
            <span
              *ngIf="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric.occupancy>0">
              {{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric.revenue /
              calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric.occupancy | currency
              :'EUR':'symbol':'1.2-2'}}
            </span>
          </h3>
        </div>
        <div class="col-4 d-inline-block text-center mid-box" [class.mid-box-no-dynamic-history]="!calService.showDynamicHistory" [class.over-revpar]="checkOverRevPar(item)">
          <h1>RPar</h1>
          <h3>{{(capacity>0 ?
            calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current &&
            calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.revenue / capacity : '0') |
            currency :'EUR':'symbol':'1.2-2'}}</h3>
          <h3 class="pink" *ngIf="calService.showDynamicHistory">
            <span *ngIf="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric">
              {{(capacity>0 ?
              calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric.revenue / capacity : '0')
              | currency :
              'EUR':'symbol':'1.2-2'}}
            </span>
          </h3>
          <h3 class="blue">
            <span *ngIf="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric">
              {{(capacity>0 ?
              calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric.revenue / capacity :
              '0') | currency :
              'EUR':'symbol':'1.2-2'}}
            </span>
          </h3>
        </div>
      </div>

      <div class="input-box" [class.input-box-no-dynamic-history]="!calService.showDynamicHistory" [class.expand]="calService.expandArea == d">
        <div class="row">
          <div class="col-2"></div>
          <div class="col-1 black">VR(+/-)</div>
          <div class="col-3 black">Price</div>
          <div class="col-2 black"
            *ngIf="structureService.selectedStructureConfig.length && structureService.selectedStructureConfig['WUBOOK_SYNC_ACTIVE'].value=='1'">
            Avail</div>
          <div class="col-2 black"
            *ngIf="structureService.selectedStructureConfig.length && structureService.selectedStructureConfig['WUBOOK_SYNC_ACTIVE'].value=='1'">
            MS</div>
          <div class="col-2 black"
            *ngIf="structureService.selectedStructureConfig.length && structureService.selectedStructureConfig['WUBOOK_SYNC_ACTIVE'].value=='1'">
            Open</div>
        </div>
        <div class="row mb-1" *ngFor="let room of rooms; let i = index">
          <div class="col-2">
            <h1 [title]="room.title">{{room.title}}</h1>
            <h2 *ngIf="calService.calendarDataDays[item.day].rates[room.title].currentFormula">
              {{calService.calendarDataDays[item.day].rates[room.title].currentFormula}}</h2>
            <button class="btn btn-link btn-link-ai" [disabled]="isAiBtnPress===d"
              *ngIf="rateResult[item.day][room.title].current.showAi"
              (click)="loadAiRate(d, item.day,room.title)">
              <i class="bx bx-right-arrow-alt"></i> AI
            </button>
          </div>
          <div class="col-1">
            <h2 class="pick-up-more"
              *ngIf="calService.calendarDataDays[item.day].occupancies[room.title] && calService.calendarDataDays[item.day].occupancies[room.title].current">
              {{calService.calendarDataDays[item.day].occupancies[room.title].current.vrplus}}</h2>
            <h2 class="pick-up-less"
              *ngIf="calService.calendarDataDays[item.day].occupancies[room.title] && calService.calendarDataDays[item.day].occupancies[room.title].current">
              {{calService.calendarDataDays[item.day].occupancies[room.title].current.vrminus}}</h2>
          </div>
          <div class="col-3 position-relative">
            <input class="form-control" type="text"
              [disabled]="inputStatus(item.day)"
              [(ngModel)]="rateResult[item.day][room.title].current.rate"
              (change)="checkChanges(d,room.title,item.day)">
            <i class="alert-icon" [ngClass]="{
                'uil-exclamation-triangle error': !calService.calendarDataDays[item.day].rates[room.title],
                'uil-info-circle info': calService.calendarDataDays[item.day].rates[room.title].current && calService.calendarDataDays[item.day].rates[room.title].current.forced
              }"></i>
          </div>
          <div class="col-2">
            <input
              *ngIf="structureService.selectedStructureConfig.length && structureService.selectedStructureConfig['WUBOOK_SYNC_ACTIVE'].value=='1'"
              class="form-control" type="text"
              [disabled]="inputStatus(item.day)">
          </div>
          <div class="col-2">
            <input
              *ngIf="structureService.selectedStructureConfig.length && structureService.selectedStructureConfig['WUBOOK_SYNC_ACTIVE'].value=='1'"
              class="form-control" type="text"
              [disabled]="inputStatus(item.day)">
          </div>
          <div class="col-2">
            <ui-switch defaultBoColor="#dfdfdf" color="#51d28c" class="ui-switch-custom"
              *ngIf="structureService.selectedStructureConfig.length && structureService.selectedStructureConfig['WUBOOK_SYNC_ACTIVE'].value=='1'"
              [disabled]="inputStatus(item.day)">
            </ui-switch>
          </div>
        </div>

      </div>

      <div ngbDropdown class="btn btn-action-day">
        <span class="table-icon" ngbDropdownToggle>
          <i *ngIf="daysprocessing.indexOf(item.day) < 0" class="uil-ellipsis-v"></i>
          <i *ngIf="daysprocessing.indexOf(item.day) >= 0" class="fas fa-spinner fa-spin"></i>
        </span>
        <div ngbDropdownMenu>
          <button ngbDropdownItem (click)="rateSave(item.day)"
            *ngIf="!(inputStatus(item.day))">
            {{ "GENERIC.save_btn" | translate }}
          </button>
          <button ngbDropdownItem (click)="calendarRateHistory(item.day)">{{ "PRICING.pricing_history" | translate
            }}</button>
          <button ngbDropdownItem
            (click)="guiService.showDetailDayRateInfo(item.day,calService.calendarDataDays[item.day].rates)">{{
            "PRICING.pricing_compare" | translate }}</button>
        </div>
      </div>

      <a class="btn-expand-collapse" (click)="calService.expandCollapse(d)">
        {{ calService.expandArea == d ? 'RIDUCI' : 'ESPANDI'}}
        <i *ngIf="calService.expandArea == d" class="uil-minus-square"></i>
        <i *ngIf="calService.expandArea != d" class="uil-plus-square"></i>
      </a>
    </div>


    <!-- NO DATA -->
    <div class="row"
      *ngIf="!calService.calendarDataDays[item.day] || !calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY']">
      <div class="col-6">
        <div class="graphics">
          <div class="row current-year mt-2">
            <div class="col-3 black">
              <span *ngIf="calService.calendarDataDays[item.day]">
                <span class="event-area" *ngFor="let ev of calService.calendarDataDays[item.day].events.current">
                  <i class="bx" [ngClass]="{'bxs-star': !ev['preOrPostDay'], 'bx-star' : ev['preOrPostDay'] }"
                    ngbTooltip="{{ev.name}}" placement="top"
                    *ngIf="calService.calendarDataDays[item.day].events.current"></i>
                </span>
                {{'DAYS.SHORT.' + item.DoW | translate}} {{item.d}}-{{item.m}}-{{item.y}}
              </span>
            </div>
          </div>
          <div class="row dynamic-h-year mt-1" *ngIf="calService.showDynamicHistory">
            <div class="col-3 pink second-date">
              <span *ngIf="calService.calendarDataDays[item.day]">
                <span class="event-area" *ngFor="let ev of calService.calendarDataDays[item.day].events.dynHistoryc">
                  <i class="bx" [ngClass]="{'bxs-star': !ev['preOrPostDay'], 'bx-star' : ev['preOrPostDay'] }"
                    ngbTooltip="{{ev.name}}" placement="top"
                    *ngIf="calService.calendarDataDays[item.day].events.current"></i>
                </span>
                {{'DAYS.SHORT.' + item.DoW | translate}} {{item.d}}-{{item.m}}-{{item.y}}
              </span>
            </div>
          </div>
          <div class="row last-year mt-1">
            <div class="col-3 blue">
              <span *ngIf="calService.calendarDataDays[item.day]">
                <span class="event-area" *ngFor="let ev of calService.calendarDataDays[item.day].events.statHistoric">
                  <i class="bx" [ngClass]="{'bxs-star': !ev['preOrPostDay'], 'bx-star' : ev['preOrPostDay'] }"
                    ngbTooltip="{{ev.name}}" placement="top"
                    *ngIf="calService.calendarDataDays[item.day].events.current"></i>
                </span>
                {{'DAYS.SHORT.' + item.DoW | translate}} {{item.d}}-{{item.m}}-{{item.y}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="cal-data-empty">
          <span>{{ "GENERIC.no_data" | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!calService.calendarDataDays" class="main-page-loading">
  <div class="loading-bars-box">
    <div class="loading-bars"></div>
  </div>
</div>
