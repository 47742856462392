import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CalendarService } from 'src/app/core/services/calendar.service';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';
import { StructureService } from 'src/app/core/services/structure.service';

@Component({
  selector: 'app-right-calendar',
  templateUrl: './right-calendar.component.html',
  styleUrls: ['./right-calendar.component.scss']
})
export class RightCalendarComponent implements OnInit {
  @Input('monthlyIndicator') dataReceived: any;

  constructor(public structureService: StructureService, private translate: TranslateService, private guiService: GuiService, 
    private restService: RestService, public calService: CalendarService) { }

  ngOnInit(): void {
    this.structureService.loadConfigStructure();
    console.debug("CFG", this.structureService.selectedStructureConfig);
  }
  

}
