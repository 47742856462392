<div class="row align-items-start">
  <div class="col-11 box-main-form">

    <div *ngIf="structureService.selectedStructureConfig && structureService.selectedStructureConfig.setup_phase">

      <!-- FORM MAIN SETTINGS -->

      <!-- MAIN FIELDSET -->
      <fieldset class="row">
        <legend>
          <h3>{{ "CFG.main_legend" | translate }}</h3>
        </legend>
        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.com_lang" | translate }}</h3>
            <ng-select [clearable]="true" placeholder="Nome struttura" [(ngModel)]="structureService.selectedStructureConfig.COMMUNICATION_LANG.value">
              <ng-option *ngFor="let item of com_lang" [value]="item['value']">{{item['label']}}</ng-option>
            </ng-select>
            <small ngbTooltip="{{language.getHelpTranslate('COMUNICATION','PRINCIPAL','MSG_LANG')}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
              <i-feather name="help-circle"></i-feather>
            </small>
          </label>
        </div>

        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.rev_mail_enabled" | translate }}</h3>
            <div dir="ltr" class="form-check form-switch" *ngIf="structureService.selectedStructureConfig && structureService.selectedStructureConfig.REVOLUTION_EMAIL_ENABLED">
              <input type="checkbox" id="customSwitchsizesm" [checked]="structureService.selectedStructureConfig.REVOLUTION_EMAIL_ENABLED.value == '1'" class="form-check-input" (change)="configKeyChange('REVOLUTION_EMAIL_ENABLED')">
            </div>
            <small ngbTooltip="{{language.getHelpTranslate('COMUNICATION','PRINCIPAL','EMAIL')}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
              <i-feather name="help-circle"></i-feather>
            </small>
          </label>
        </div>

        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.mail_from" | translate }}</h3>
            <input class="form-control input-camere" type="text" [(ngModel)]="structureService.selectedStructureConfig.mail_from.value" />
            <small ngbTooltip="{{language.getHelpTranslate('COMUNICATION','PRINCIPAL','SENDER')}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
              <i-feather name="help-circle"></i-feather>
            </small>
          </label>
        </div>

        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.mail_to" | translate }}</h3>
            <input class="form-control input-camere" type="text" [(ngModel)]="structureService.selectedStructureConfig.mail_to.value" />
            <small ngbTooltip="{{language.getHelpTranslate('COMUNICATION','PRINCIPAL','RECEIVER')}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
              <i-feather name="help-circle"></i-feather>
            </small>
          </label>
        </div>

        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.mail_bcc" | translate }}</h3>
            <input class="form-control input-camere" type="text" [(ngModel)]="structureService.selectedStructureConfig.mail_bcc.value" />
            <small ngbTooltip="{{language.getHelpTranslate('COMUNICATION','PRINCIPAL','HIDE_COPY')}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
              <i-feather name="help-circle"></i-feather>
            </small>
          </label>
        </div>

        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.mail_excel_to" | translate }}</h3>
            <input class="form-control input-camere" type="text" [(ngModel)]="structureService.selectedStructureConfig.mail_excel_to.value" />
            <small ngbTooltip="{{language.getHelpTranslate('COMUNICATION','PRINCIPAL','EMAIL_REPORT')}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
              <i-feather name="help-circle"></i-feather>
            </small>
          </label>
        </div>
      </fieldset>

      <!-- FORECAST EMAIL FIELDSET -->
      <fieldset class="row">
        <legend>
          <h3>{{ "CFG.forecast_mail_cfg" | translate }}</h3>
        </legend>

        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.mail_subject" | translate }}</h3>
            <input class="form-control input-camere" type="text" [(ngModel)]="structureService.selectedStructureConfig.mail_subject.value" />
            <small ngbTooltip="{{language.getHelpTranslate('COMUNICATION','EMAIL_FORECAST','OBJECT')}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
              <i-feather name="help-circle"></i-feather>
            </small>
          </label>
        </div>

        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.mail_header" | translate }}</h3>
            <textarea class="form-control input-camere" [(ngModel)]="structureService.selectedStructureConfig.mail_header.value"></textarea>
            <small ngbTooltip="{{language.getHelpTranslate('COMUNICATION','EMAIL_FORECAST','MESSAGE')}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
              <i-feather name="help-circle"></i-feather>
            </small>
          </label>
        </div>

        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.mail_footer" | translate }}</h3>
            <textarea class="form-control input-camere" [(ngModel)]="structureService.selectedStructureConfig.mail_footer.value"></textarea>
            <small ngbTooltip="{{language.getHelpTranslate('COMUNICATION','EMAIL_FORECAST','SIGN')}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
              <i-feather name="help-circle"></i-feather>
            </small>
          </label>
        </div>
      </fieldset>

    </div>

  </div>
  <div class="col-1 btn-group-vertical right-sidebar" role="group">
    <button class="btn-send" (click)="save()">
      <span class="uil-folder-check"></span>
      <h1>save</h1>
    </button>
    <button class="btn-delete" (click)="reset()">
      <span class="uil-trash"></span>
      <h1>{{ "GENERIC.reset_btn" | translate }}</h1>
    </button>
  </div>

</div>
