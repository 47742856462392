<div class="contenitore" *ngIf="calService.monthlyIndicator!=null">
  <div class="row">
    <div class="col-xxl-6 col-lg-6 first-box">
      <span *ngIf="this.dataReceived.current != null">
        <div class="datas-container">
          <h1 class="col-5 int">
            OCC
            <small>Room Night</small>
          </h1>
          <h3 class="col-7 current">{{dataReceived.current.occupancy}} / {{dataReceived.current.capacity}} <small>({{((dataReceived.current.occupancy * 100) / dataReceived.current.capacity).toFixed(2) }}%)</small></h3>
        </div>
        <!-- DYN HISTORY -->
        <div class="datas-container" *ngIf="calService.showDynamicHistory">
          <h3 class="col-12 dynhistory">
            <div class="badge" [ngClass]="{'badge-success-subtle': (dataReceived.current.occupancy - dataReceived.dynHistoric.occupancy)>0, 'badge-danger-subtle': (dataReceived.current.occupancy - dataReceived.dynHistoric.occupancy)<0}">
              {{dataReceived.current.occDynDiff}}
              <i class="uil uil-arrow-up-right text-success ms-1" *ngIf="(dataReceived.current.occupancy - dataReceived.dynHistoric.occupancy)>0"></i>
              <i class="uil uil-arrow-down-right text-danger ms-1" *ngIf="(dataReceived.current.occupancy - dataReceived.dynHistoric.occupancy)<0"></i>
            </div>
            {{dataReceived.dynHistoric.occupancy}} / {{dataReceived.dynHistoric.capacity}}
            <small>({{((dataReceived.dynHistoric.occupancy * 100) / dataReceived.dynHistoric.capacity).toFixed(2)}}%)</small>
          </h3>
        </div>
        <!-- STATIC HISTORY -->
        <div class="datas-container">
          <h3 class="col-12 statichistory">
            <div class="badge" [ngClass]="{'badge-success-subtle': (dataReceived.current.occupancy - dataReceived.statHistoric.occupancy)>0, 'badge-danger-subtle': (dataReceived.current.occupancy - dataReceived.statHistoric.occupancy)<0}"
              *ngIf="(dataReceived.current.occupancy - dataReceived.statHistoric.occupancy)!=0">
              {{dataReceived.current.occStaDiff}}
              <i class="uil uil-arrow-up-right text-success ms-1" *ngIf="(dataReceived.current.occupancy - dataReceived.statHistoric.occupancy)>0"></i>
              <i class="uil uil-arrow-down-right text-danger ms-1" *ngIf="(dataReceived.current.occupancy - dataReceived.statHistoric.occupancy)<0"></i>
            </div>
            {{dataReceived.statHistoric.occupancy}} / {{dataReceived.statHistoric.capacity}}
            <small>({{((dataReceived.statHistoric.occupancy * 100) / dataReceived.statHistoric.capacity).toFixed(2)}}%)</small>
          </h3>
        </div>
      </span>
      <div class="loader" *ngIf="!dataReceived"></div>
    </div>
    <div class="col-xxl-6 col-lg-6 second-box" >
      <span *ngIf="dataReceived">
        <div class="datas-container">
          <h1 class="col-5 int">
            PROD
            <small>{{structureService.selectedStructureConfig ? structureService.selectedStructureConfig.currency.value : ''}}</small>
          </h1>
          <h3 class="col-7 current">{{dataReceived.current.revenue ? dataReceived.current.revenue.toFixed(2) : ''}}</h3>
        </div>
        <!-- DYN HISTORY -->
        <div class="datas-container" *ngIf="calService.showDynamicHistory">
          <h3 class="col-12 dynhistory">
            <div class="badge" [ngClass]="{'badge-success-subtle': (dataReceived.current.revenueDynDiff)>0, 'badge-danger-subtle': (dataReceived.current.revenueDynDiff)<0}">
              {{dataReceived.current.revenueDynDiff}}
              <i class="uil uil-arrow-up-right text-success ms-1" *ngIf="(dataReceived.current.revenueDynDiff)>0"></i>
              <i class="uil uil-arrow-down-right text-danger ms-1" *ngIf="(dataReceived.current.revenueDynDiff)<0"></i>
            </div>
            {{dataReceived.dynHistoric.revenue ? dataReceived.dynHistoric.revenue.toFixed(2) : ''}}
          </h3>
        </div>
        <!-- STATIC HISTORY -->
        <div class="datas-container">
          <h3 class="col-12 statichistory">
            <div class="badge" [ngClass]="{'badge-success-subtle': (dataReceived.current.revenueStaDiff)>0, 'badge-danger-subtle': (dataReceived.current.revenueStaDiff)<0}">
              {{dataReceived.current.revenueStaDiff}}
              <i class="uil uil-arrow-up-right text-success ms-1" *ngIf="(dataReceived.current.revenueStaDiff)>0"></i>
              <i class="uil uil-arrow-down-right text-danger ms-1" *ngIf="(dataReceived.current.revenueStaDiff)<0"></i>
            </div>
            {{dataReceived.statHistoric.revenue ? dataReceived.statHistoric.revenue.toFixed(2) : ''}}
          </h3>
        </div>
      </span>
      <div class="loader" *ngIf="!dataReceived"></div>
    </div>
  </div>
  <div class="row">
    <div class="col-xxl-6 col-lg-6 third-box" >
      <span *ngIf="dataReceived">
        <div class="datas-container">
          <h1 class="col-5 int">
            RMC
            <small>{{structureService.selectedStructureConfig ? structureService.selectedStructureConfig.currency.value : ''}}</small>
          </h1>
          <h3 class="col-7 current">{{dataReceived.current.rmc ? dataReceived.current.rmc.toFixed(2) : ''}}</h3>
        </div>
        <!-- DYN HISTORY -->
        <div class="datas-container" *ngIf="calService.showDynamicHistory">
          <h3 class="col-12 dynhistory">
            <div class="badge" [ngClass]="{'badge-success-subtle': (dataReceived.current.rmcDynDiff)>0, 'badge-danger-subtle': (dataReceived.current.rmcDynDiff)<0}">
              {{dataReceived.current.rmcDynDiff}}
              <i class="uil uil-arrow-up-right text-success ms-1" *ngIf="(dataReceived.current.rmcDynDiff)>0"></i>
              <i class="uil uil-arrow-down-right text-danger ms-1" *ngIf="(dataReceived.current.rmcDynDiff)<0"></i>
            </div>
            {{dataReceived.dynHistoric.rmc ? dataReceived.dynHistoric.rmc.toFixed(2) : ''}}
          </h3>
        </div>
        <!-- STATIC HISTORY -->
        <div class="datas-container">
          <h3 class="col-12 statichistory">
            <div class="badge" [ngClass]="{'badge-success-subtle': (dataReceived.current.rmcStaDiff)>0, 'badge-danger-subtle': (dataReceived.current.rmcStaDiff)<0}">
              {{dataReceived.current.rmcStaDiff}}
              <i class="uil uil-arrow-up-right text-success ms-1" *ngIf="(dataReceived.current.rmcStaDiff)>0"></i>
              <i class="uil uil-arrow-down-right text-danger ms-1" *ngIf="(dataReceived.current.rmcStaDiff)<0"></i>
            </div>
            {{dataReceived.statHistoric.rmc ? dataReceived.statHistoric.rmc.toFixed(2) : ''}}
          </h3>
        </div>
      </span>
      <div class="loader" *ngIf="!dataReceived"></div>
    </div>
    <div class="col-xxl-6 col-lg-6 fourth-box" >
      <span *ngIf="dataReceived">
        <div class="datas-container">
          <h1 class="col-5 int">
            RevPAR
            <small>{{structureService.selectedStructureConfig ? structureService.selectedStructureConfig.currency.value : ''}}</small>
          </h1>
          <h3 class="col-7 current">{{dataReceived.current.revpar ? dataReceived.current.revpar.toFixed(2) : ''}}</h3>
        </div>
        <!-- DYN HISTORY -->
        <div class="datas-container" *ngIf="calService.showDynamicHistory">
          <h3 class="col-12 dynhistory">
            <div class="badge" [ngClass]="{'badge-success-subtle': (dataReceived.current.revparDynDiff)>0, 'badge-danger-subtle': (dataReceived.current.revparDynDiff)<0}">
              {{dataReceived.current.revparDynDiff}}
              <i class="uil uil-arrow-up-right text-success ms-1" *ngIf="(dataReceived.current.revparDynDiff)>0"></i>
              <i class="uil uil-arrow-down-right text-danger ms-1" *ngIf="(dataReceived.current.revparDynDiff)<0"></i>
            </div>
            {{dataReceived.dynHistoric.revpar ? dataReceived.dynHistoric.revpar.toFixed(2) : ''}}
          </h3>
        </div>
        <!-- STATIC HISTORY -->
        <div class="datas-container">
          <h3 class="col-12 statichistory">
            <div class="badge" [ngClass]="{'badge-success-subtle': (dataReceived.current.revparStaDiff)>0, 'badge-danger-subtle': (dataReceived.current.revparStaDiff)<0}">
              {{dataReceived.current.revparStaDiff}}
              <i class="uil uil-arrow-up-right text-success ms-1" *ngIf="(dataReceived.current.revparStaDiff)>0"></i>
              <i class="uil uil-arrow-down-right text-danger ms-1" *ngIf="(dataReceived.current.revparStaDiff)<0"></i>
            </div>
            {{dataReceived.statHistoric.revpar ? dataReceived.statHistoric.revpar.toFixed(2) : ''}}
          </h3>
        </div>
      </span>
      <div class="loader" *ngIf="!dataReceived"></div>
    </div>
  </div>
</div>
