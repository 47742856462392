import { filter } from 'rxjs/operators';
import { take } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';
import { TranslateService } from '@ngx-translate/core';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexLegend, ApexPlotOptions, ApexStroke, ApexTooltip, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};

@Component({
  selector: 'app-report-view-cluster',
  templateUrl: './report-view-cluster.component.html',
  styleUrls: ['./report-view-cluster.component.scss']
})
export class ReportViewClusterComponent implements OnInit {
  loaded: boolean = false;
  expandFlags: any = {
    filters: true,
    table: false,
    charts: false
  };
  filter: any = {
    year_from: moment().year(),
    year_to: '',
    geo_tag: '',
    str_tag: '',
    type_tag: '',
    region: '',
    license: '0',
    status: '0'
  };
  selectyears: any = [];
  geoTags: any = [];
  strTags: any = [];
  typeTags: any = [];
  regions: any = [];
  licenses: any = [
    {value: '0',title: 'Tutte'},
    {value: '1',title: 'In Consulenza'}
  ];
  status: any = [
    {value: '0',title: 'Tutte'},
    {value: '1',title: 'Abilitata'}
  ];
  reportRes: any;
  searchCtrl: any;
  reportResCopy: any;
  isFlaged: boolean = true;

  /* CHART ZONE */

  @ViewChild("chart_occ") chart_occ: ChartComponent | any;
  @ViewChild("chart_prod") chart_prod: ChartComponent | any;
  @ViewChild("chart_rmc") chart_rmc: ChartComponent | any;
  @ViewChild("chart_revpare") chart_revpare: ChartComponent | any;
  public chartOptions_occ: Partial<ChartOptions> | any;
  public chartOptions_prod: Partial<ChartOptions> | any;
  public chartOptions_rmc: Partial<ChartOptions> | any;
  public chartOptions_revpare: Partial<ChartOptions> | any;
  rowSelectedIndex: any;
  fullChart: boolean = false;
  selectedColumn: any;
  startYear: any = [];
  compareYear: any = [];

  constructor(private restService: RestService, public guiService: GuiService, public translate: TranslateService){

  }

  ngOnInit(): void {
    this.populateSelects()
  }

  populateSelects(){
    let tmp: any = moment();
    this.selectyears = [];

    for (let i=0;i<10;i++) {
      this.selectyears.push(moment(tmp).subtract(i,"year").year());
    }

    this.restService.getService('tag','/list/0').subscribe((ris)=>{
      for(let tag of ris){
        if(tag.type === 'STRUCTURE'){
          this.strTags.push(tag)
        }else if(tag.type === 'TYPE'){
          this.typeTags.push(tag)
        }else if(tag.type === 'GEO'){
          this.geoTags.push(tag)
        }
      }
    })

    this.restService.getService('provinces','').subscribe((ris)=>{
      console.debug('prov___',ris)
      for(let province of ris){
       this.regions.push(province.region)
      }
      console.debug(this.regions)
    })
  }
  getReport(){

    let params: any = "";

      params += "year="+this.filter.year_from;
      this.filter.year_to && this.filter.year_to!= "" ? params += "&oldYear="+this.filter.year_to : params += "&oldYear="+this.filter.year_from ;
      this.filter.geo_tag && this.filter.geo_tag!= "" ? params += "&geoType="+this.filter.geo_tag : params += "&geoType=";
      this.filter.str_tag && this.filter.str_tag!= "" ? params += "&strutt="+this.filter.str_tag : params += "&strutt=";
      this.filter.type_tag && this.filter.type_tag!= "" ? params += "&locType="+this.filter.type_tag : params += "&locType=";
      this.filter.region && this.filter.region!= "" ? params += "&region="+this.filter.region : params += "&region=";
      this.filter.license && this.filter.license!= 0 ? params += "&consulting="+this.filter.license : null;
      this.filter.status && this.filter.status!= 0 ? params += "&enabled="+this.filter.status : null;
      
      console.debug("PARAMS", params);

    this.restService.getService('report','/cluster_structures?'+params).subscribe((ris)=>{
      console.debug('REPORT____RES',ris)
      this.reportRes = ris
      for(let str of this.reportRes.data){
        str.selected = true
      }
      this.reportResCopy = JSON.parse(JSON.stringify(ris))
      this.expandFlags.filters = false;
      this.expandFlags.table = true;
      this.loaded = true;
    })
  }

  searchStr(){
    if(this.searchCtrl && this.searchCtrl.length > 3){
      console.debug('SEARCH_CTRL = ',this.searchCtrl)
      let tmpArr = []
      for(let str of this.reportRes.data){
        str.structureName.toLowerCase().includes(this.searchCtrl.toLowerCase()) ? tmpArr.push(str) : null
      }
      this.reportRes.data = tmpArr
    }else{
      this.reportRes.data = this.reportResCopy.data
    }
  }

  createReport(){
    let param:any = {
      structureIds: [],
      from: this.filter.year_from+'-01',
      months: 12
    }
    let paramTwo:any = {
      structureIds: [],
      from: this.filter.year_to+'-01',
      months: 12
    }
    for(let str of this.reportRes.data){
      str.selected === true ? (param.structureIds.push(str.structureId),paramTwo.structureIds.push(str.structureId)) : null
    }
    console.debug(param)
    this.restService.putService('calendar','v4/monthly-revenue-indicators-for-group',param).subscribe((ris)=>{
      this.startYear = []
      for(let item in ris.indicators){
        this.startYear.push(ris.indicators[item])
      }
      console.debug(this.startYear)
        this.restService.putService('calendar','v4/monthly-revenue-indicators-for-group',param).subscribe((res)=>{
          this.compareYear = []

          for(let item in res.indicators){
            this.compareYear.push(res.indicators[item])
          }

          for(let i = 0; i < this.compareYear.length; i++){
            console.debug(this.startYear[i])
            this.startYear[i].occ = this.startYear[i].occupancy
            this.startYear[i].prod = this.startYear[i].revenue
            this.startYear[i].revpar = this.startYear[i].revpar
            this.startYear[i].rmc = this.startYear[i].rmc
            this.startYear[i].month = i+1

            this.compareYear[i].occ = this.compareYear[i].occupancy
            this.compareYear[i].prod = this.compareYear[i].revenue
            this.compareYear[i].revpar = this.compareYear[i].revpar
            this.compareYear[i].rmc = this.compareYear[i].rmc
            this.compareYear[i].month = i+1

            this.compareYear[i].occ_calc = this.tmpCalculateDiffrence(this.startYear[i].occupancy,this.compareYear[i].occupancy)
            this.compareYear[i].prod_calc = this.tmpCalculateDiffrence(this.startYear[i].revenue,this.compareYear[i].revenue)
            this.compareYear[i].revpar_calc = this.tmpCalculateDiffrence(this.startYear[i].revpar,this.compareYear[i].revpar)
            this.compareYear[i].rmc_calc = this.tmpCalculateDiffrence(this.startYear[i].rmc,this.compareYear[i].rmc)
          }

          ris.total['month'] = 0
          ris.total['monthName'] = 'Totale'
          res.total['month'] = 0
          res.total['monthName'] = 'Totale'
          this.startYear.push(ris.total)
          this.compareYear.push(res.total)
          this.initAllCharts(this.filter.year_from, this.filter.year_to, this.startYear[12].occupancy, this.compareYear[12].occupancy,this.startYear[12].revenue, this.compareYear[12].revenue,this.startYear[12].revpar, this.compareYear[12].revpar,this.startYear[12].rmc, this.compareYear[12].rmc)
          this.expandFlags.table = false;
          this.expandFlags.chart = true;
        })
    })
  }

  toggleSelected(){
    for(let str of this.reportRes.data){
      str.selected = this.isFlaged ? false : true
    }
  }

  tmpCalculateDiffrence(input1: any, input2:any){
    return Number(parseFloat(input1)) - Number(parseFloat(input2))
   }

  initAllCharts(yearFrom: any, yearTo: any,yearFrom_occ: any, yearTo_occ: any,yearFrom_prod: any, yearTo_prod: any,yearFrom_revpar: any, yearTo_revpar: any,yearFrom_rmc: any, yearTo_rmc: any,indexSelected?:any){
    this.initChartOcc(yearFrom, yearTo, yearFrom_occ, yearTo_occ)
    this.initChartProd(this.filter.year_from, this.filter.year_to, yearFrom_prod, yearTo_prod)
    this.initChartRevpar(this.filter.year_from, this.filter.year_to, yearFrom_revpar, yearTo_revpar)
    this.initChartRmc(this.filter.year_from, this.filter.year_to, yearFrom_rmc, yearTo_rmc)
    this.fullChart = false;
    this.rowSelectedIndex = indexSelected
    this.selectedColumn = null
   }

  initChartOcc(fromYear: any,toYear: any, yearFromOcc: any, yearToOcc: any,column?: boolean,type?:string){
    /* CHART OCC */
    type ? (this.selectedColumn = type,this.rowSelectedIndex = null) :null

    if(column){
      setTimeout(() => {
        this.chartOptions_prod = null
        this.chartOptions_revpare = null
        this.chartOptions_rmc = null
      }, 100);
      yearFromOcc = []
      yearToOcc = []
      for(let i = 0; i < this.startYear.length; i++){
        if(i < 12){
          let item = this.startYear[i]
          yearFromOcc.push(item.occupancy)
        }
      }
      for(let i = 0; i < this.compareYear.length; i++){
        if(i < 12){
          let item = this.compareYear[i]
          yearToOcc.push(item.occupancy)
        }
      }
    }

    this.chartOptions_occ = {
    colors:['#000000','#9998ff'],
    series: [
      {
        name: column ? this.filter.year_from : fromYear,
        data: column ? yearFromOcc : [yearFromOcc]
      },
      {
        name: column ? this.filter.year_to : toYear,
        data: column ? yearToOcc : [yearToOcc]
      }
    ],
    chart: {
      type: "bar",
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"]
    },
    xaxis: {
      categories: column ? [
        this.translate.instant("MONTHS.SHORT.1"),
        this.translate.instant("MONTHS.SHORT.2"),
        this.translate.instant("MONTHS.SHORT.3"),
        this.translate.instant("MONTHS.SHORT.4"),
        this.translate.instant("MONTHS.SHORT.5"),
        this.translate.instant("MONTHS.SHORT.6"),
        this.translate.instant("MONTHS.SHORT.7"),
        this.translate.instant("MONTHS.SHORT.8"),
        this.translate.instant("MONTHS.SHORT.9"),
        this.translate.instant("MONTHS.SHORT.10"),
        this.translate.instant("MONTHS.SHORT.11"),
        this.translate.instant("MONTHS.SHORT.12")
      ] : ['',''],
      labels: {
        show: column ? true : false
      }
    },
    yaxis: {
      title: {
        text: "OCC"
      }
    },
    fill: {
      colors:['#000000','#9998ff'],
      opacity: 1
    },
    tooltip: {
      y: {
      }
    }
  };
  column ? this.fullChart = true : null
}

initChartProd(fromYear: any,toYear: any, yearFromProd: any, yearToProd: any,column?: boolean,type?:string){
/* CHART PROD */
type ? (this.selectedColumn = type,this.rowSelectedIndex = null) :null
  console.debug(fromYear,toYear, yearFromProd, yearToProd,column,type)
  if(column){
    setTimeout(() => {
      this.chartOptions_occ = null
      this.chartOptions_revpare = null
      this.chartOptions_rmc = null
    }, 100);
    yearFromProd = []
    yearToProd = []
    for(let i = 0; i < this.startYear.length; i++){
      if(i < 12){
        let item = this.startYear[i]
        yearFromProd.push(item.revenue)
      }
    }
    for(let i = 0; i < this.compareYear.length; i++){
      if(i < 12){
        let item = this.compareYear[i]
        yearToProd.push(item.revenue)
      }
    }
  }

    this.chartOptions_prod = {
      colors:['#000000','#9998ff'],
      series: [
        {
          name: column ? this.filter.year_from : fromYear,
          data: column ? yearFromProd : [yearFromProd]
        },
        {
          name: column ? this.filter.year_to : toYear,
          data: column ? yearToProd : [yearToProd]
        }
      ],
      chart: {
        type: "bar",
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: column ? [
          this.translate.instant("MONTHS.SHORT.1"),
          this.translate.instant("MONTHS.SHORT.2"),
          this.translate.instant("MONTHS.SHORT.3"),
          this.translate.instant("MONTHS.SHORT.4"),
          this.translate.instant("MONTHS.SHORT.5"),
          this.translate.instant("MONTHS.SHORT.6"),
          this.translate.instant("MONTHS.SHORT.7"),
          this.translate.instant("MONTHS.SHORT.8"),
          this.translate.instant("MONTHS.SHORT.9"),
          this.translate.instant("MONTHS.SHORT.10"),
          this.translate.instant("MONTHS.SHORT.11"),
          this.translate.instant("MONTHS.SHORT.12")
        ] : ['',''],
        labels: {
          show: column ? true : false
        }
      },
      yaxis: {
        title: {
          text: "PROD"
        }
      },
      fill: {
        colors:['#000000','#9998ff'],
        opacity: 1
      },
      tooltip: {
        y: {
        }
      }
    };
    column ? this.fullChart = true : null
}

initChartRevpar(fromYear: any,toYear: any,yearFromRevpar: any, yearToRevpar: any,column?: boolean,type?:string){
/* CHART REVPAR */
type ? (this.selectedColumn = type,this.rowSelectedIndex = null) :null

if(column){
  setTimeout(() => {
    this.chartOptions_occ = null
    this.chartOptions_prod = null
    this.chartOptions_rmc = null
  }, 100);
  yearFromRevpar = []
  yearToRevpar = []
  for(let i = 0; i < this.startYear.length; i++){
    if(i < 12){
      let item = this.startYear[i]
      yearFromRevpar.push(item.revpar)
    }
  }
  for(let i = 0; i < this.compareYear.length; i++){
    if(i < 12){
      let item = this.compareYear[i]
      yearToRevpar.push(item.revpar)
    }
  }
}

    this.chartOptions_revpare = {
      colors:['#000000','#9998ff'],
      series: [
        {
          name: column ? this.filter.year_from : fromYear,
          data: column ? yearFromRevpar : [yearFromRevpar]
        },
        {
          name: column ? this.filter.year_to : toYear,
          data: column ? yearToRevpar : [yearToRevpar]
        }
      ],
      chart: {
        type: "bar",
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: column ? [
          this.translate.instant("MONTHS.SHORT.1"),
          this.translate.instant("MONTHS.SHORT.2"),
          this.translate.instant("MONTHS.SHORT.3"),
          this.translate.instant("MONTHS.SHORT.4"),
          this.translate.instant("MONTHS.SHORT.5"),
          this.translate.instant("MONTHS.SHORT.6"),
          this.translate.instant("MONTHS.SHORT.7"),
          this.translate.instant("MONTHS.SHORT.8"),
          this.translate.instant("MONTHS.SHORT.9"),
          this.translate.instant("MONTHS.SHORT.10"),
          this.translate.instant("MONTHS.SHORT.11"),
          this.translate.instant("MONTHS.SHORT.12")
        ] : ['',''],
        labels: {
          show: column ? true : false
        }
      },
      yaxis: {
        title: {
          text: "RATEPAR"
        }
      },
      fill: {
        colors:['#000000','#9998ff'],
        opacity: 1
      },
      tooltip: {
        y: {
        }
      }
    };
    column ? this.fullChart = true : null
}

initChartRmc(fromYear: any,toYear: any,yearFromRmc: any, yearToRmc: any,column?: boolean,type?:string){
/* REV RMC */
type ? (this.selectedColumn = type,this.rowSelectedIndex = null) :null

if(column){
  setTimeout(() => {
    this.chartOptions_occ = null
    this.chartOptions_revpare = null
    this.chartOptions_prod = null
  }, 100);
  yearFromRmc = []
  yearToRmc = []
  for(let i = 0; i < this.startYear.length; i++){
    if(i < 12){
      let item = this.startYear[i]
      yearFromRmc.push(item.rmc)
    }
  }
  for(let i = 0; i < this.compareYear.length; i++){
    if(i < 12){
      let item = this.compareYear[i]
      yearToRmc.push(item.rmc)
    }
  }
}

    this.chartOptions_rmc = {
      colors:['#000000','#9998ff'],
      series: [
        {
          name: column ? this.filter.year_from : fromYear,
          data: column ? yearFromRmc : [yearFromRmc]
        },
        {
          name: column ? this.filter.year_to : toYear,
          data: column ? yearToRmc : [yearToRmc]
        }
      ],
      chart: {
        type: "bar",
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: column ? [
          this.translate.instant("MONTHS.SHORT.1"),
          this.translate.instant("MONTHS.SHORT.2"),
          this.translate.instant("MONTHS.SHORT.3"),
          this.translate.instant("MONTHS.SHORT.4"),
          this.translate.instant("MONTHS.SHORT.5"),
          this.translate.instant("MONTHS.SHORT.6"),
          this.translate.instant("MONTHS.SHORT.7"),
          this.translate.instant("MONTHS.SHORT.8"),
          this.translate.instant("MONTHS.SHORT.9"),
          this.translate.instant("MONTHS.SHORT.10"),
          this.translate.instant("MONTHS.SHORT.11"),
          this.translate.instant("MONTHS.SHORT.12")
        ] : ['',''],
        labels: {
          show: column ? true : false
        }
      },
      yaxis: {
        title: {
          text: "RMC"
        }
      },
      fill: {
        colors:['#000000','#9998ff'],
        opacity: 1
      },
      tooltip: {
        y: {
        }
      }
    };
    column ? this.fullChart = true : null
}
}
