<div class="contenitore">
  <div class="page-title-box d-flex align-items-center justify-content-between">
    <h4 class="mb-0">{{"FX.fileexport" | translate}}</h4>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="row">
        <div class="form-floating">
          <input class="form-control" type="date" id="init" [(ngModel)]="date.from">
          <label for="init">DA</label>
        </div>
        <div class="form-floating">
          <input class="form-control" type="date" id="expire" [(ngModel)]="date.to">
          <label for="expire">A</label>
        </div>
        <button class="btn icona cerca" [disabled]="!loaded">
          <i class="uil-search" (click)="exportFile()"></i>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="!loaded" class="main-page-loading">
    <div class="loading-bars-box">
      <div class="loading-bars"></div>
    </div>
  </div>
</div>
