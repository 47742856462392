import { Component, ViewChild } from '@angular/core';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';
import { StructureService } from 'src/app/core/services/structure.service';
import Swal from 'sweetalert2';
import {TranslateService} from "@ngx-translate/core";
import { reset } from 'numeral';

@Component({
  selector: 'app-structure-configuration-pms',
  templateUrl: './structure-configuration-pms.component.html',
  styleUrls: ['./structure-configuration-pms.component.scss']
})
export class StructureConfigurationPmsComponent {
  modeSelected: any = {
    name: "Somma tipologie configurate",
    value: "1"
  };
  pmsSelected: any = {
    name: "5 Stelle",
    value: "hotel_5_stelle"
  };
  activeTab: any = 1;
  @ViewChild("customNav")
  customNav:any
  rooms: any;
  channels: any;
  occ_resources_full: Array<string> = [];
  timeToAdd: any = null;
  timeSlots: any = [];
  stringWrote: any = {};
  stringWrote2: any = {};
 

  PMS_CREDENTIAL: any = {
    username: null,
    password: null
  };
 
 

  pms: any = [
    {
      name: "5 Stelle (trasmissione)",
      value: "hotel_5_stelle"
    },
    {
      name: "5 Stelle (file)",
      value: "hotel_5_stelle"
    },
    {
      name: "5 Stelle (file, versione 1)",
      value: "hotel_5_stelle_1"
    },
    {
      name: "Asa (trasmissione)",
      value: "Asa"
    },
    {
      name: "AmicHotel (trasmissione)",
      value: "Amichotel"
    },
    {
      name: "BBPlanner (trasmissione)",
      value: "BBPlanner"
    },
    {
      name: "Beddy (trasmissione)",
      value: "beddy"
    },
    {
      name: "Bedzzle (trasmissione)",
      value: "bedzzle"
    },
    {
      name: "BookingDesign (trasmissione)",
      value: "BookingDesign"
    },
    {
      name: "Direct Holiday (trasmissione)",
      value: "Direct Holiday"
    },
    {
      name: "DNR (trasmissione)",
      value: "DNR"
    },
    {
      name: "dylog (file)",
      value: "dylog"
    },
    {
      name: "Ericsoft (file)",
      value: "ericsoft_t"
    },
    {
      name: "Ericsoft (file, v1)",
      value: "ericsoft_t1"
    },
    {
      name: "Ericsoft (file, v2)",
      value: "ericsoft_t2"
    },
    {
      name: "Fullboard (trasmissione)",
      value: "fullboard"
    },
    {
      name: "Hotel in Cloud (trasmissione)",
      value: "Hotel_In_Cloud"
    },
    {
      name: "ISTES (trasmissione)",
      value: "ISTES"
    },
    {
      name: "Krossbooking (trasmissione)",
      value: "KROSSBOOKING"
    },
    {
      name: "Leonardo (file)",
      value: "leonardo"
    },
    {
      name: "Octorate (trasmissione)",
      value: "octorate"
    },
    {
      name: "Passpartout (file)",
      value: "passpartout"
    },
    {
      name: "Passpartout (trasmissione)",
      value: "passpartout"
    },
    {
      name: "Protel (file)",
      value: "PROTEL"
    },
    {
      name: "Protel (file, v2)",
      value: "PROTEL"
    },
    {
      name: "Protel (trasmissione)",
      value: "PROTEL"
    },
    {
      name: "Scidoo (trasmissione)",
      value: "scidoo"
    },
    {
      name: "Slope (trasmissione)",
      value: "slope"
    },
    {
      name: "Sysdat (trasmissione)",
      value: "sysdat"
    },
    {
      name: "Revolution (file)",
      value: "passpartout_1"
    },
    {
      name: "ZAK (trasmissione)",
      value: "ZAK"
    }
  ];
  calc_mode: any = [
    {
      name: "Somma tipologie configurate",
      value: "PER_TIPOLOGIE_CAMERE"
    },
    {
      name: "Somma canali configurati",
      value: "PER_CANALI_VENDITA"
    },
    {
      name: "Personalizzata",
      value: "CUSTOMIZZATA"
    }
  ];

  origin_mapping: any = {};

  constructor(public structureService: StructureService, private restService: RestService, public guiService: GuiService, public translate: TranslateService){

  }

  ngOnInit(): void {
    this.getPMS();
    this.loadPMSCredential();
    this.getRooms();
    this.getChannels();
  }

  getPMS () {

    console.debug("PMS",this.structureService.selectedStructureConfig.pms);
    this.pmsSelected = this.structureService.selectedStructureConfig.pms ? this.structureService.selectedStructureConfig.pms.value : null;

  }

  getRooms(){
    if(this.structureService.selectedStructure){
      console.debug(this.structureService.selectedStructure)
      let params = {
        page: 0,
        searchCriteria: [{
          "key": "ENABLED",
          "operation" : "EQUALITY",
          "value" : true,
          "orPredicate" : false
        }],
        size: 50,
        structureId: this.structureService.selectedStructure.id
      }
      this.restService.postService('room_type','/v2/search',params).subscribe((ris =>{
      for(let k in ris.items){
        let item = ris.items[k];
        item.resources_full = [];
        this.stringWrote["_"+item.id] = "";
      }
      this.rooms = ris.items;
      this.getStructureMapping();
    }))
    }

  }

  getChannels(){
    let params = {
      page: 0,
      searchCriteria: [],
      size: 20,
      structureId: this.structureService.selectedStructure.id
    }
    this.restService.postService('sales_channel','/v2/search',params).subscribe((ris)=>{
      for(let k in ris.items){
        let item = ris.items[k]
        item.resources_full = []
        this.stringWrote2["_"+item.id] = ""
      }
      this.channels = ris.items
    })
   }

  sendPms(){
    this.guiService.isConfigEdited = false;
    this.savePMSSettings();
  }

  clear(){
    Swal.fire({
      title: 'Sei sicuro?',
      text: "Ripulirai tutti i dati presenti",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, pulisci!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.guiService.isConfigEdited = true
        Swal.fire(
          'Fatto!',
          '',
          'success'
        )
        this.modeSelected = this.calc_mode[0].value;
        this.pmsSelected = this.pms[0];
      for(let item of this.rooms){
        item.resources_full = []
      }
      for(let item of this.channels){
        item.resources_full = []
      }

      }
    })

  }

  addChipRoom(idx:any,roomIndex:any){
    if(idx && this.stringWrote["_"+idx].includes(",") == false){
      console.debug('stringa',this.stringWrote["_"+idx])
      this.rooms[roomIndex].resources_full.push(this.stringWrote["_"+idx])
      console.debug(this.rooms)
    }else if(idx && this.stringWrote["_"+idx].includes(",")){
      let tmpValue = this.stringWrote["_"+idx].split(",")

      for(let item of tmpValue){
        this.rooms[roomIndex].resources_full.push(item)
      }
    }
    this.stringWrote["_"+idx] = ""
    console.debug("STRING ROOMS",this.rooms)
    this.guiService.isConfigEdited = true
  }

  addChipRoomInOccTot(idx:any){
    if(idx && this.stringWrote["_"+idx].includes(",") == false){
      this.occ_resources_full.push(this.stringWrote["_"+idx]);
    }else if(idx && this.stringWrote["_"+idx].includes(",")){
      let tmpValue = this.stringWrote["_"+idx].split(",")

      for(let item of tmpValue){
        this.occ_resources_full.push(item)
      }
    }
    this.stringWrote["_"+idx] = ""
    this.guiService.isConfigEdited = true
  }

  removeChipRoom(chipIndex:any,roomIndex:any){
    this.rooms[roomIndex].resources_full.splice(chipIndex,1)
    console.debug(this.rooms)
    this.guiService.isConfigEdited = true
  }

  removeChipRoomInOccTot(chipIndex:any){
    this.occ_resources_full.splice(chipIndex,1);
    this.guiService.isConfigEdited = true;
  }

  addChipChannel(idx:any,channelIndex:any){
    if(idx && this.stringWrote2["_"+idx].includes(",") == false){
      this.channels[channelIndex].resources_full.push(this.stringWrote2["_"+idx])
      console.debug(this.channels)
    }else if(idx && this.stringWrote2["_"+idx].includes(",")){
      let tmpValue = this.stringWrote2["_"+idx].split(",")

      for(let item of tmpValue){
        this.channels[channelIndex].resources_full.push(item)
      }
    }
    this.stringWrote2["_"+idx] = ""
    this.guiService.isConfigEdited = true
  }

  removeChipChannel(chipIndex:any,channelIndex:any){
    this.channels[channelIndex].resources_full.splice(chipIndex,1)
    console.debug(this.channels)
    this.guiService.isConfigEdited = true
  }

  getStructureMapping() {

    this.restService.getService('structure_configuration','/mapping-to-pms/'+this.structureService.selectedStructure.id).subscribe((ris)=>{

      for(let item of this.pms) {
        ris.pms && ris.pms==item.value ? this.pmsSelected = item : null;
      }
      for(let item of this.calc_mode) {
        if(ris.occupancyEvaluationMode && ris.occupancyEvaluationMode==item.value) {
          this.modeSelected = item.value;
          item.value == "CUSTOMIZZATA" ? this.occ_resources_full = ris.roomTypologiesForWholeOccupancy : null;
        }

      }

      for(let item of this.rooms) {
        ris.roomTypologiesMapping[item.title] ? item.resources_full = ris.roomTypologiesMapping[item.title] : null;
      }
      for(let item of this.channels) {
        ris.saleChannelsMapping[item.code] ? item.resources_full = ris.saleChannelsMapping[item.code] : null;
      }
    });
  }

  resetMapping() {

    this.modeSelected = this.calc_mode[0].value;

    for(let item of this.rooms) {
      item.resources_full = [];
    }
    for(let item of this.channels) {
      item.resources_full = [];
    }



    this.updateStructureMapping();

  }

  savePMSSettings() {
    this.guiService.loaded = false;
    this.structureService.selectedStructureConfig.pms.value = this.pmsSelected;
    this.structureService.selectedStructureConfig.PMS_TIME_SLOT.value = this.timeSlots.join("::");

    let param = [];
    for(let c in this.structureService.selectedStructureConfig){
      param.push(this.structureService.selectedStructureConfig[c]);
    }

    this.restService.putService('structure_configuration', '/v2/update/'+this.structureService.selectedStructure.id+'/PMS',param).subscribe((ris)=>{
      this.guiService.isConfigEdited = false;
      this.pmsSelected != "" ? this.updateStructureMapping() : this.resetMapping();
    })
  }

  updateStructureMapping() {
    let param : any = {
      "occupancyEvaluationMode": this.modeSelected,
      "roomTypologiesForWholeOccupancy": [],
      "saleChannelsMapping": {},
      "roomTypologiesMapping": {}
    };

    // PREPARO LA MAPPATURA PER LE TIPOLOGIE DI CAMERA
    let rt_labelList: Array<string> = [];
    for(let item of this.rooms) {
      if(item.resources_full && item.resources_full.length>0) {
        param.roomTypologiesMapping[item.title] = [];

        for(let label of item.resources_full){
          if(rt_labelList.indexOf(label)<0) {
            param.roomTypologiesMapping[item.title].push(label);
            rt_labelList.push(label);
          } else {
            Swal.fire({
              title: this.translate.instant("ALERTS.pms_mapping_fail"),
              icon: 'warning'
            }).then((result)=>{
              this.guiService.loaded = true;
              // nothing
            });
            return false;
          }
        }
      };
    }
    this.modeSelected == "CUSTOMIZZATA" ? param.roomTypologiesForWholeOccupancy = this.occ_resources_full : delete param.roomTypologiesForWholeOccupancy;

    // PREPARO LA MAPPATURA PER I CANALI DI VENDITA
    let sc_labelList: Array<string> = [];
    for(let item of this.channels) {
      if(item.resources_full && item.resources_full.length>0) {
        param.saleChannelsMapping[item.code] = [];

        for(let label of item.resources_full){
          if(sc_labelList.indexOf(label)<0) {
            param.saleChannelsMapping[item.code].push(label);
            sc_labelList.push(label);
          } else {
            Swal.fire({
              title: this.translate.instant("ALERTS.pms_mapping_fail"),
              icon: 'warning'
            }).then((result)=>{
              this.guiService.loaded = true;
              // nothing
            });
            return false;
          }
        }
      };
    }

    console.debug("PARAM", param);

    this.restService.putService('structure_configuration','/mapping-to-pms/'+this.structureService.selectedStructure.id,param).subscribe((ris)=>{
      Swal.fire({
        title: 'Success!',
        text: this.translate.instant("ALERTS.pms_mapping_success"),
        icon: 'success'
      }).then(()=>{
        this.guiService.loaded = true;
      });

    });
  }

  loadPMSCredential() {

    this.restService.getService('structure_configuration','/pms-account/'+this.structureService.selectedStructure.id).subscribe((ris)=>{
      ris.pmsUsername ? this.PMS_CREDENTIAL.username = ris.pmsUsername : null;
      ris.pmsPassword ? this.PMS_CREDENTIAL.password = ris.pmsPassword : null;
    });
    
  }

  deletePMSCredential() {
    this.guiService.deleteConfirm('structure_configuration','/pms-account',this.structureService.selectedStructure.id);
  }

  savePMSCredential() {
    this.guiService.loaded = false;

    this.PMS_CREDENTIAL.username == "" ? this.PMS_CREDENTIAL.username = null : null;
    this.PMS_CREDENTIAL.password == "" ? this.PMS_CREDENTIAL.password = null : null;

    if(this.PMS_CREDENTIAL.username != null && this.PMS_CREDENTIAL.password != null) {
      let param: any = {
        pmsUsername: this.PMS_CREDENTIAL.username,
        pmsPassword: this.PMS_CREDENTIAL.password
      }
  
      this.restService.putService('structure_configuration','/pms-account/'+this.structureService.selectedStructure.id,param).subscribe((ris)=>{
        Swal.fire({
          title: this.translate.instant("ALERTS.success"),
          text: this.translate.instant("ALERTS.generic_success"),
          icon: 'success'
        }).then(()=>{
          this.guiService.loaded = true;
        });
  
      });

    } else {

      Swal.fire({
        title: this.translate.instant("ALERTS.error"),
        text: this.translate.instant("ALERTS.generic_fail"),
        icon: 'error'
      }).then(()=>{
        this.guiService.loaded = true;
      });
    }
  }



  addChipTimeSlot(){
    if(this.timeToAdd && this.timeToAdd != "") {
      if(this.timeSlots.indexOf(this.timeToAdd)<0) {
        this.timeSlots.push(this.timeToAdd);
        this.timeToAdd = null;
      }
    }
    this.guiService.isConfigEdited = true
  }

  removeChipTimeSlot(chipIndex:any){
    this.timeSlots.splice(chipIndex,1);
    this.guiService.isConfigEdited = true
  }


}
