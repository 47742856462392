import { Component, TemplateRef } from '@angular/core';
import {RestService} from "../../../../../../core/services/rest.service";
import {ActivatedRoute, Router} from "@angular/router";
import {StructureService} from "../../../../../../core/services/structure.service";
import {GuiService} from "../../../../../../core/services/gui.service";
import {TranslateService} from "@ngx-translate/core";
import { ChannelParam } from 'src/app/core/enum/channel-param';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bedzzle',
  templateUrl: './bedzzle.component.html',
  styleUrls: ['./bedzzle.component.scss']
})
export class BedzzleComponent {
  helptxt: string = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam blandit aliquet nisl at tristique. Nunc tincidunt tempor consequat. Orci varius natoque penatibus et magnis dis.";
  rooms: Array<any> = [];
  mealPlan: any

  constructor(private restService: RestService,private route: ActivatedRoute, public structureService: StructureService,private router: Router,public guiService: GuiService,
              public translate: TranslateService,private modalService: NgbModal) {

  }

  ngOnInit() {
    this.structureService.selectedStructureConfig.BEDZZLE_API_KEY.value = ChannelParam.BEDZZLE_KEY;
    this.getRooms();
  }

  getRooms(){
    let params = {
      page: 0,
      searchCriteria:[],
      size: 100,
      structureId: this.structureService.selectedStructure.id
    }
    this.restService.postService('room_type','/v2/search',params).subscribe((ris =>{
      this.rooms = ris.items;
      console.debug(this.rooms)
      this.prepareParams();
    }))
  }

  openMealPlansSettings(content: TemplateRef<any>) {
		this.modalService.open(content, { size: 'xl', scrollable: true });
	}

  prepareParams() {
    console.debug("Prepare Params BEDZZLE",this.structureService.selectedStructureConfig);
    if(this.structureService.selectedStructureConfig.BEDZZLE_RATES_MAPPING.value && this.structureService.selectedStructureConfig.BEDZZLE_RATES_MAPPING.value!="") {
      let tmp = JSON.parse(this.structureService.selectedStructureConfig.BEDZZLE_RATES_MAPPING.value).items;
      console.debug(tmp)
      this.mealPlan = []
      for(let mealP in tmp){
        console.debug(tmp[mealP])
        this.mealPlan.push(
          {
            id: mealP,
            items: tmp[mealP].items,
            total: tmp[mealP].total,
            accordionShow: false
          })
      }
      console.debug(this.mealPlan)
    }
  }

  setMealPlans(){
    let finalMealPlan: any = []
    for(let mealP of this.mealPlan){
      console.debug(mealP)
      finalMealPlan[mealP.id] = []
      for(let priceL of mealP.items){
        console.debug(priceL)
        if(priceL.enabled){
          finalMealPlan[mealP.id].push(priceL)
        }
      }
    }
    console.debug(finalMealPlan)
  }
}
