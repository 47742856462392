<div class="main-container">
  <div class="head">
    <img src="assets/images/Logotipo-negativo.svg"/>
  </div>
  <div class="bg-over">
    <div class="background">
      <div class="out-box">
        <div class="box">
          <form [formGroup]="form">
            <div class="p-3">
              <div class="mb-3">
                <label for="floatingInput">Username</label>
                <input type="text" (keyup.enter)="send()"  class="form-control" formControlName="username" id="floatingInput" placeholder="Enter your username">
              </div>
              <div class="mb-3 psw-zone">
                <label for="floatingPassword">Password</label>
                <input [type]="isVisibile ? 'text' : 'password'" (keyup.enter)="send()" class="form-control" formControlName="password" id="floatingPassword" placeholder="Enter your password">
                <i class="uil-eye" *ngIf="!isVisibile" (click)="passwordToggle()"></i>
                <i class="uil-eye-slash" *ngIf="isVisibile" (click)="passwordToggle()"></i>
              </div>
              <div class="mb-3">
                <input type="checkbox" formControlName="remember" class="me-2" />
                <label>{{"AUTH.REMEMBERME" | translate}}</label>
              </div>
              <button class="w-100 btn"(click)="send()" *ngIf="!isLoading">{{"AUTH.LOGIN" | translate}}</button>
              <div class="loading-bars-box" *ngIf="isLoading">
                <div class="loading-bars"></div>
              </div>
              <h3 (click)="resetPsw()">{{ "AUTH.RESET_PSW" | translate }}</h3>
            </div>
          </form>
        </div>
      </div>

      <div class="footer-box">
        <a>Privacy Policy</a>
      </div>
    </div>

  </div>
</div>

<div class="tablet">
  <div class="contenitore">
    <div class="img-zone">
      <div class="head">
        <img src="assets/images/Logotipo-negativo.svg"/>
      </div>
    </div>
    <div class="login-zone">
      <form [formGroup]="form">
        <div class="p-3">
          <div class="mb-3">
            <label for="floatingInput">Username</label>
            <input type="text" (keyup.enter)="send()"  class="form-control" formControlName="username" id="floatingInput" placeholder="Enter your username">
          </div>
          <div class="mb-3 psw-zone">
            <label for="floatingPassword">Password</label>
            <input [type]="isVisibile ? 'text' : 'password'" (keyup.enter)="send()" class="form-control" formControlName="password" id="floatingPassword" placeholder="Enter your password">
            <i class="uil-eye" *ngIf="!isVisibile" (click)="passwordToggle()"></i>
            <i class="uil-eye-slash" *ngIf="isVisibile" (click)="passwordToggle()"></i>
          </div>
          <button class="w-100 btn"(click)="send()" *ngIf="!isLoading">Log in</button>
          <div class="loading-bars-box" *ngIf="isLoading">
            <div class="loading-bars"></div>
          </div>
          <h3 (click)="resetPsw()">Forgot your password?</h3>
        </div>
      </form>
      <div class="footer-zone">
        <a>Privacy Policy</a>
      </div>
    </div>
  </div>
</div>

<div class="mobile">
  <div class="head">
    <img src="assets/images/Simbolo-negativo.svg"/>
  </div>
    <div class="login-zone">
      <form [formGroup]="form">
        <div class="p-3">
          <div class="mb-3">
            <label for="floatingInput">Username</label>
            <input type="text" (keyup.enter)="send()"  class="form-control" formControlName="username" id="floatingInput" placeholder="Enter your username">
          </div>
          <div class="mb-3 psw-zone">
            <label for="floatingPassword">Password</label>
            <input [type]="isVisibile ? 'text' : 'password'" (keyup.enter)="send()" class="form-control" formControlName="password" id="floatingPassword" placeholder="Enter your password">
            <i class="uil-eye" *ngIf="!isVisibile" (click)="passwordToggle()"></i>
            <i class="uil-eye-slash" *ngIf="isVisibile" (click)="passwordToggle()"></i>
          </div>
          <button class="w-100 btn"(click)="send()" *ngIf="!isLoading">Log in</button>
          <div class="loading-bars-box" *ngIf="isLoading">
            <div class="loading-bars"></div>
          </div>
          <h3 (click)="resetPsw()">Forgot your password?</h3>
        </div>
      </form>
    </div>
</div>
