import { Component, OnInit } from '@angular/core';
import { CalendarService } from 'src/app/core/services/calendar.service';
import { RestService } from 'src/app/core/services/rest.service';
import * as moment from 'moment';
import { StructureService } from 'src/app/core/services/structure.service';
import { CalculatorService } from 'src/app/core/services/calculator.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-rightsidebar',
  templateUrl: './rightsidebar.component.html',
  styleUrls: ['./rightsidebar.component.scss']
})

/**
 * Right-sidebar component
 */
export class RightsidebarComponent implements OnInit {
  defaultDate: any = null;
  dateformat: any = "d-m-Y";
  locale: any = {
    weekdays: {
      shorthand: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
      longhand: [
        "Domenica",
        "Lunedì",
        "Martedì",
        "Mercoledì",
        "Giovedì",
        "Venerdì",
        "Sabato",
      ],
    },
    months: {
      shorthand: [
        "Gen",
        "Feb",
        "Mar",
        "Apr",
        "Mag",
        "Giu",
        "Lug",
        "Ago",
        "Set",
        "Ott",
        "Nov",
        "Dic",
      ],
      longhand: [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre",
      ],
    },
    firstDayOfWeek: 1,
    ordinal: () => "°",
    rangeSeparator: " al ",
    weekAbbreviation: "Se",
    scrollTitle: "Scrolla per aumentare",
    toggleTitle: "Clicca per cambiare",
    time_24hr: true,
    defaultDate: [moment() , moment().add(30,"days")]
  };
  date: any = [];
  dayPreventive: any;
  rooms: any = [];
  cart:any = [];
  showRooms: boolean = false;
  total: number = 0;

  constructor(public restService: RestService,public calService: CalendarService, public structureService: StructureService, private calculatorService: CalculatorService, public translate: TranslateService) { }

  ngOnInit(): void {
    if(this.structureService.selectedStructure && this.structureService.selectedStructure.id){
      !this.calculatorService.allowRestore || this.rooms.length <= 0 && !this.calculatorService.rooms ? this.getRooms() : null
      this.calculatorService.allowRestore ? this.restore() : null
    }
  }

  ngOnDestroy(){
    this.rooms.length <= 0 ? null : this.hide()
  }

  getCalcInfo(){
    let dtRange = this.defaultDate.split(this.locale.rangeSeparator);
    this.date.from = moment(dtRange[0],"DD-MM-YYYY").format('yyyy-MM-DD');
    this.date.to = moment(dtRange[1],"DD-MM-YYYY").format('yyyy-MM-DD');
    this.showRooms = false;
    this.calService.getCalendarData(this.date.from, this.date.to, '', '',true).subscribe((ris)=>{
      this.cart = [];
      this.total = 0;
      this.dayPreventive = moment(this.date.to).diff(moment(this.date.from),'days')
      for(let room of this.rooms){
        room.rate = 0
        room.selected = 0
        for(let item in ris){
          room.rate += ris[item].rates[room.title].current.rate
        }
      }
      this.showRooms = true
    })
  }

  getRooms(){
    let params = {
      page: 0,
      searchCriteria:[{
        "key": "ENABLED",
        "operation" : "EQUALITY",
        "value" : true,
        "orPredicate" : false
      }],
      size: 50,
      structureId: this.structureService.selectedStructure.id
    }
    this.restService.postService('room_type','/v2/search',params).subscribe((ris =>{
      this.rooms = ris.items
      this.calculatorService.allowRestore = true
    }))
  }

  close(){
    document.body.classList.remove('right-bar-enabled');
  }

  public hide() {
    this.calculatorService.allowRestore = true
    this.calculatorService.defaultDate = this.defaultDate;
    this.calculatorService.dayPreventive = this.dayPreventive;
    this.calculatorService.rooms = this.rooms;
    this.calculatorService.cart = this.cart;
    this.calculatorService.total = this.total;

    document.body.classList.remove('right-bar-enabled');
  }

  addToCart(room:any){
    if(this.cart.find((x: { title: any; }) => x.title === room.title)){
      this.cart.find((x: { title: any; }) => x.title === room.title).selected += 1
    }else{
      room.selected = 1
      this.cart.push(room)
    }
    this.total = 0
    for(let item of this.cart){
      this.total += item.rate*item.selected
    }
  }

  cartAddOrRemove(mark: string,room:any){
    if(mark === 'add'){
      room.selected += 1
    }else if(mark === 'remove'){
      if(room.selected > 1){
        room.selected -= 1
      }else{
        this.cart = this.cart.filter((obj: { title: string; }) => obj.title !== room.title);
      }
    }
    this.total = 0
    for(let item of this.cart){
      this.total += item.rate*item.selected
    }
  }

  restore(){
    this.calculatorService.defaultDate ? this.defaultDate = JSON.parse(JSON.stringify(this.calculatorService.defaultDate)) : null;
    this.calculatorService.dayPreventive ? this.dayPreventive = JSON.parse(JSON.stringify(this.calculatorService.dayPreventive)) : null;
    this.calculatorService.rooms ? this.rooms = JSON.parse(JSON.stringify(this.calculatorService.rooms)) : null;
    this.calculatorService.cart ? this.cart = JSON.parse(JSON.stringify(this.calculatorService.cart)) : null;
    this.calculatorService.total ? this.total = JSON.parse(JSON.stringify(this.calculatorService.total)) : null;
    this.showRooms = true
  }

  confirmClose(){
    let ref = this
    Swal.fire({
      template:'#my-template',
      showCloseButton: false,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: this.translate.instant('GENERIC.close_btn'),
      didOpen(popup) {
        console.debug(popup)
        const downsell = document.querySelector('.thumbs-up')
        const rpt = document.querySelector('.thumbs-down')
        const rpo = document.querySelector('.frown')

        downsell?.addEventListener('click', () => {
          console.log('Downsell was Cliked.')
          ref.sendDownSell()
        })

        rpt?.addEventListener('click', () => {
          console.log('Rpt was Cliked.')
          ref.sendRpt()
        })

        rpo?.addEventListener('click', () => {
          console.log('Rpo was Cliked.')
          ref.sendRpo()
        })
      },
    }).then((result)=>{
      if(result.dismiss === Swal.DismissReason.cancel){
        this.calculatorService.reset();
        this.defaultDate = null;
        this.dayPreventive = undefined;
        this.rooms = [];
        this.cart = [];
        this.total = 0;
        this.showRooms = false;
        this.close();
        this.getRooms()
      }
    })
  }

  sendRpt(){
    let dtRange = this.defaultDate.split(this.locale.rangeSeparator);
    let param = {
      "fromDay": moment(dtRange[0],"DD-MM-YYYY").format('yyyy-MM-DD'),
      "toDay": moment(dtRange[1],"DD-MM-YYYY").format('yyyy-MM-DD')
    }
    this.restService.postService('calendar','v4/'+this.structureService.selectedStructure.id+'/days/resistanceindex-increase',param).subscribe((ris)=>{
      Swal.close();
      Swal.fire("Rpt inviato!");
    })
  }

  sendDownSell(){
    let dtRange = this.defaultDate.split(this.locale.rangeSeparator);
    let param = {
      "fromDay": moment(dtRange[0],"DD-MM-YYYY").format('yyyy-MM-DD'),
      "toDay": moment(dtRange[1],"DD-MM-YYYY").format('yyyy-MM-DD')
    }
    this.restService.postService('calendar','v4/'+this.structureService.selectedStructure.id+'/days/downsell-increase',param).subscribe((ris)=>{
      Swal.close();
      Swal.fire("Downsell inviato!");
    })
  }

  sendRpo(){
    let dtRange = this.defaultDate.split(this.locale.rangeSeparator);
    let param = {
      "fromDay": moment(dtRange[0],"DD-MM-YYYY").format('YYYY-MM-DD'),
      "toDay": moment(dtRange[1],"DD-MM-YYYY").format('YYYY-MM-DD')
    }
    this.restService.postService('calendar','v4/'+this.structureService.selectedStructure.id+'/days/rpo-increase',param).subscribe((ris)=>{
      Swal.close();
      Swal.fire("Rpo inviato!");
    })
  }

}
