import { Component } from '@angular/core';
import { RestService } from "../../../../core/services/rest.service";
import { ActivatedRoute, Router } from "@angular/router";
import { StructureService } from "../../../../core/services/structure.service";
import { GuiService } from "../../../../core/services/gui.service";
import Swal from 'sweetalert2';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-structure-configuration-calendar',
  templateUrl: './structure-configuration-calendar.component.html',
  styleUrls: ['./structure-configuration-calendar.component.scss']
})
export class StructureConfigurationCalendarComponent {
  rooms: any = [];
  sales_channels: any = [];
  roomSelected: any;
  channelSelected: any;
  calendar: any = [];
  calendarIndex: any = [];
  str_config: any;
  str_config_index: any;

  resourceIndex: any = {}
  channelIndex: any = {}
  selectedCalendarIndex: any;
  invSetting!: boolean;

  constructor(private restService: RestService, private route: ActivatedRoute, public structureService: StructureService, private router: Router, public guiService: GuiService, public translate: TranslateService) {
    this.structureService.isStructureChanged.subscribe(value => {
      if (value == true) {
        this.ngOnInit()
      }
    });
  }

  ngOnInit(): void {
    this.getRooms();
    this.getSalesChannel();
  }

  getConfig() {
    this.restService.getService('structure_configuration', '/list_base/'+this.structureService.selectedStructure.id).subscribe((ris) => {
      this.str_config = ris.data;
      this.str_config_index = this.str_config.findIndex((c: { key: string; }) => c.key === 'calendar_config')
      let tmpCalendar = this.str_config[this.str_config_index].value && this.str_config[this.str_config_index].value !== '' ? JSON.parse(this.str_config[this.str_config_index].value) : []
      for (let k in tmpCalendar) {
        let item = tmpCalendar[k].resources
        let item2 = tmpCalendar[k].channels
        tmpCalendar[k].resources_full = []
        tmpCalendar[k].channels_full = []
        for (let f in item) {
          tmpCalendar[k].resources_full[f] = this.resourceIndex[item[f]]
        }
        for (let f in item2) {
          tmpCalendar[k].channels_full[f] = this.channelIndex[item2[f]]
        }
      }
      this.calendar = tmpCalendar

      /* CONTROLLO SE DA I SETTINGS NELL OBJ 'occ_inv' nel valore c'è true */
      const invSetting = this.str_config.find((c: { key: string; }) => c.key === 'occ_inv')
      invSetting && invSetting.value.includes('true') ? this.invSetting = true : this.invSetting = false;
    })
  }

  getRooms() {
      let params = {
        page: 0,
        size: 200,
        searchCriteria: [{
          key: "ENABLED",
          operation: "EQUALITY",
          value: true,
          orPredicate: false
        }],
        structureId: this.structureService.selectedStructure.id
      }
      this.restService.postService('room_type', '/v2/search', params).subscribe((ris) => {
        this.rooms = ris.items;
        for (let item of ris.items) {
          this.resourceIndex[item.id] = item
        }
        console.debug(this.resourceIndex)
      });
  }

  getSalesChannel() {

    let params = {
      searchCriteria: [
        {
          "key": 'ENABLED',
          "operation": "EQUALITY",
          "value": true,
          "orPredicate": false
        }
      ],
      structureId: this.structureService.selectedStructure.id
    }

    this.restService.postService('sales_channel', '/v2/search', params).subscribe((ris) => {
      this.getConfig()
      this.sales_channels = ris.items
      for (let item of ris.items) {
        this.channelIndex[item.id] = item
      }
    });
  }

  addChipRoom(room: any, calendarIndex: any) {
    if (room) {
      console.debug('room', room)
      this.calendar[calendarIndex].resources_full.push(room)
      this.calendar[calendarIndex].resources.push(room.id)
    }
    this.roomSelected = null
    this.guiService.isConfigEdited = true
  }
  removeChipRoom(chipIndex: any, calendarIndex: any) {
    this.calendar[calendarIndex].resources_full.splice(chipIndex, 1)
    this.calendar[calendarIndex].resources.splice(chipIndex, 1)
    this.guiService.isConfigEdited = true
  }

  addChipSales(sales: any, calendarIndex: any) {
    if (sales) {
      this.calendar[calendarIndex].channels_full.push(sales)
      this.calendar[calendarIndex].channels.push(sales.id)
    }
    this.channelSelected = null
    this.guiService.isConfigEdited = true
  }
  removeChipSales(chipIndex: any, calendarIndex: any) {
    this.calendar[calendarIndex].channels_full.splice(chipIndex, 1)
    this.calendar[calendarIndex].channels.splice(chipIndex, 1)
    this.guiService.isConfigEdited = true
  }

  addCalendar(type: any, item: any) {
    this.guiService.isConfigEdited = true
    if(this.calendarIndex.indexOf(item.title)<0 && this.calendarIndex.indexOf(item.code)<0) {
      if (type == 'resource') {
        this.calendar.push({
          type: "resource",
          label: item.name,
          code: item.title,
          quantity: 0,
          sold: true,
          sum_occ: false,
          sum_prod: false,
          resources_full: [],
          resources: [],
          channels_full: [],
          channels: []
        });
        this.calendarIndex.push(item.title);
      }
      if (type == 'channel') {
        this.calendar.push({
          type: "channel",
          label: item.description,
          code: item.code,
          quantity: 0,
          sold: true,
          sum_occ: false,
          sum_prod: false,
          resources_full: [],
          resources: [],
          channels_full: [],
          channels: []
        });
        this.calendarIndex.push(item.code);
      }
      if (type == 'opz') {
        this.calendar.push({
          type: "opz",
          label: "opz",
          code: "OPZ",
          quantity: 0,
          sold: true,
          sum_occ: false,
          sum_prod: false,
          resources_full: [],
          resources: [],
          channels_full: [],
          channels: []
        });
        this.calendarIndex.push("opz");
      }
      if (type == 'grp') {
        if (this.calendarIndex.indexOf("grp") < 0) {
          this.calendar.push({
            type: "grp",
            label: "grp",
            code: "GRP",
            quantity: 0,
            sold: true,
            sum_occ: false,
            sum_prod: false,
            resources_full: [],
            resources: [],
            channels_full: [],
            channels: []
          });
          this.calendarIndex.push("grp");
        }
      }
      if (type == 'ind') {
        this.calendar.push({
          type: "ind",
          label: "ind",
          code: "IND",
          quantity: 0,
          sold: true,
          sum_occ: false,
          sum_prod: false,
          resources_full: [],
          resources: [],
          channels_full: [],
          channels: []
        });
        this.calendarIndex.push("ind");
      }
    } else {
      Swal.fire(
        this.translate.instant("ALERTS.warning"),
        this.translate.instant("ALERTS.isexist"),
        'warning'
      );
    }
  }

  moveCalendar(from: any, to: any) {
    console.debug('from', from, 'to', to)
    let item = this.calendar[from]
    this.calendar.splice(from, 1);
    this.calendar.splice(to, 0, item)
    this.guiService.isConfigEdited = true
    console.debug(this.calendar)
    return this.calendar
  }

  sendCalendar() {
    for (let c in this.calendar) {
      let cal = this.calendar[c];
      delete cal.channels_full;
      delete cal.resources_full;
    }

    this.str_config[this.str_config_index].value = this.str_config[this.str_config_index] && this.str_config[this.str_config_index].value!=null ? JSON.stringify(this.calendar) : "";

    const invSettingIndex = this.str_config.findIndex((c: { key: string; }) => c.key === 'occ_inv')
    this.str_config[invSettingIndex].value = String(this.invSetting)

    this.guiService.loaded = false;

    this.restService.putService('structure_configuration', '/v2/update/' + this.structureService.selectedStructure.id + '/CALENDAR', this.str_config).subscribe((ris) => {
      console.debug("RIS", ris);
      this.getConfig();
      this.guiService.isConfigEdited = false
      this.guiService.editedSuccesfully()
      this.guiService.loaded = true;
    })
  }

  toUpperCaseValue(idx: number, _key: string) {
    this.calendar[idx][_key] = this.calendar[idx][_key].toUpperCase();
  }

  selectCalendar(index: any) {
    this.selectedCalendarIndex = index
  }
  deleteCalendar() {
    if (this.selectedCalendarIndex != null) {
      Swal.fire({
        title: 'Sei sicuro?',
        text: "Vuoi eliminare questa configurazione?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, elimina!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.calendar.splice(this.selectedCalendarIndex, 1);
          this.calendarIndex.splice(this.selectedCalendarIndex, 1);
          this.guiService.isConfigEdited = true
          Swal.fire(
            'Eliminata!',
            'La tua configurazione è stata eliminata.',
            'success'
          )
        }
      })
    } else {
      Swal.fire(
        'Nessuna configurazione da eliminare selezionata',
        'Seleziona una configurazione!'
      )
    }

  }

  getInvConfigValue(evt: boolean){
    this.invSetting = evt
  }
}
