<div class="modal-header">
  <h4 class="modal-title">Settings</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
  <p>Menu fisso</p>
  <ui-switch defaultBoColor="#dfdfdf" color="#51d28c" [checked]="fixedMenuFlag" checkedLabel="On"uncheckedLabel="Off" (change)="menuFixed(fixedMenuFlag == true ? false : true)"></ui-switch>
  <span *ngIf="fixedMenuFlag == true">
    <p>Layout Menu</p>
    <ui-switch defaultBoColor="#dfdfdf" color="#51d28c" [checked]="menuLayout" checkedLabel="Large"uncheckedLabel="Small" (change)="menuSize(menuLayout == true ? false : true)"></ui-switch>
  </span>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="save()">Save</button>
</div>
