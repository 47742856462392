import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';
import { StructureService } from 'src/app/core/services/structure.service';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexGrid, ApexLegend, ApexPlotOptions, ApexStroke, ApexTooltip, ApexXAxis, ApexYAxis } from 'ng-apexcharts';
import moment from 'moment';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  tooltip?: ApexTooltip;
  stroke?: ApexStroke;
  legend?: ApexLegend;
  colors?: any;
};

@Component({
  selector: 'app-calendar-rate-history',
  templateUrl: './calendar-rate-history.component.html',
  styleUrls: ['./calendar-rate-history.component.scss']
})
export class CalendarRateHistoryComponent {
  @Input() public day: any;
  @Input() public rooms: any;
  loaded: boolean = true;
  ratesChanges: Array<any> = [];

  
/**
 * Scatter - Datetime Chart
 */
chartOptions: ChartOptions = {
  series: [],
  chart: {
    height: 200,
    type: 'scatter',
    zoom: {
      enabled: true,
      type: 'xy'
    }
  },
  grid: {
    show: true,
    borderColor: undefined,
    strokeDashArray: 0,
    position: 'back',
    xaxis: {
        lines: {
            show: true
        }
    },   
    yaxis: {
        lines: {
            show: true
        }
    },  
    row: {
        colors: ["#ffffff"],
        opacity: 0.75
    },  
    column: {
        colors: ["#ffffff"],
        opacity: 0.75
    },  
    padding: {
        top: 0,
        right: 15,
        bottom: 15,
        left: 15
    }
  },
  xaxis: {
    type: "datetime",
    tickAmount: 10
  },
  yaxis: {
    tickAmount: 7
  }
};

  constructor(public gui: GuiService, private rest: RestService, public translate: TranslateService,
              public structureService: StructureService, public modalService: NgbModal) {

  }

  ngOnInit() {
    this.chartOptions.colors = this.gui.chart_colors;
    this.loadRateChanges();
  }

  loadRateChanges() {
    this.loaded = false;

    this.rest.getService('calendar','v4/'+this.structureService.selectedStructure.id+'/rates-history/'+this.day).subscribe((ris)=>{
      let items = ris.ratesChanges;
      let resultingArr: any = {};
      Object.keys(items).sort().forEach(e => { resultingArr[e] = items[e] })

      this.ratesChanges = [];
      let cnt: number = 0;

      for(let k in resultingArr) {
        console.debug("resultingArr",resultingArr[k]);

        let item: any = {
          dur: resultingArr[k].durationInDaysTillNextChange,
          occ: resultingArr[k].wholeOccupancy,
          changes: resultingArr[k].changes
        };

        let itemR: any = {
          dt: k,
          dur: resultingArr[k].durationInDaysTillNextChange,
          occ: resultingArr[k].wholeOccupancy,
          changes: resultingArr[k].changes
        };

        if(cnt>0) {
          this.ratesChanges.push(item);
        }
        this.ratesChanges.push(itemR);
        cnt++;
      }
      
      console.debug("ratesChanges",this.ratesChanges);
      this.initChart();
      this.loaded = true;
    });
  }

  initChart() {
    let items = this.ratesChanges;
    let roomIdx: Array<string> = [];
    this.chartOptions.series = [];

    for(let item of items) {
      let series = item.changes;

      for(let k in series) {

        if(roomIdx.indexOf(k) >= 0) {
          let idx = roomIdx.indexOf(k);
          let element: any = [moment(item.dt).utc().toDate().getTime(),series[k].newRateValue];
          this.chartOptions.series[idx].data.push(element);
        } else {
          let element: any = [moment(item.dt).utc().toDate().getTime(),series[k].newRateValue];
          this.chartOptions.series.push({
            name: k,
            data: [element]
          })
          roomIdx.push(k);
        }
      }
    }
  }

  generateDayWiseTimeSeries(baseval: any, count: any, yrange: any) {
    var i = 0;
    var series = [];
    while (i < count) {
      var y =
        Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

      series.push([baseval, y]);
      baseval += 86400000;
      i++;
    }
    return series;
  }

}
