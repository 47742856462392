import { Component, Input, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';

@Component({
  selector: 'app-conteggi-detail',
  templateUrl: './conteggi-detail.component.html',
  styleUrls: ['./conteggi-detail.component.scss']
})
export class ConteggiDetailComponent {
  @Input() conteggio: any;
  form!: UntypedFormGroup;
  showBtnNew: boolean = false;

  constructor(private fb: UntypedFormBuilder, public guiService: GuiService, private restService: RestService){

  }

  ngOnInit(): void {
    this.initForm()
    console.debug(this.conteggio)
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initForm()
  }

  initForm(){
    this.form = this.fb.group({
      importo: [null],
      commissione: [null],
      totale: [null],
      scadenza: [null]
    })

    this.conteggio && this.conteggio.monthToChange ? (this.populateForm(),this.showBtnNew= false) : this.showBtnNew= true
   }

  populateForm(){
    this.form.controls['importo'].setValue(this.conteggio.data[this.conteggio.monthToChange])
    this.form.controls['commissione'].setValue(0)
    setTimeout(() => {
      this.calculateCommissions()
    }, 500 );
  }

  save(){
    this.conteggio.data[this.conteggio.monthToChange] = this.form.value.totale
    let objToSend = {
      data: JSON.stringify(this.conteggio.data),
      portal: this.conteggio.portal,
      structureId: this.conteggio.structureId,
      year: this.conteggio.year
    }

    this.restService.postService('crm_base_new','/inv_counters/save',objToSend).subscribe((ris)=>{
      this.guiService.editedSuccesfully()
      this.guiService.refreshList.next(true);
      this.close()
    })
  }

  calculateCommissions(){
    let v = this.form.value.importo
		v= parseFloat(v);
    let perc = this.form.value.commissione
    console.debug('v',v,'perc',perc)
    if(perc>0) {
      v = v/(perc/100);
      this.form.controls['totale'].setValue(v);
    } else if (perc<0) {
      v = v*(perc/100)*-1;
      this.form.controls['totale'].setValue(v);
    } else {
      this.form.controls['totale'].setValue(v);
    }
  }

  close(){
    this.guiService.closeDetail.next(true);
  }

  generateInvoice(){
    let strId = this.conteggio.structureId
    let yearFrom = this.conteggio.yearFromCtrl
    let yearTo = this.conteggio.yearToCtrl
    let expireDays = this.form.value.scadenza ? this.form.value.scadenza : '30'
    this.restService.postService('crm_base_new','/inv_invoice/generate_ext_full/'+strId+'/'+yearFrom+'/'+yearTo+'/'+expireDays,{}).subscribe((ris)=>{
      this.guiService.createdSuccessfully()
      this.close()
    })
  }
}
