import { Component } from '@angular/core';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';
import * as moment from 'moment';

@Component({
  selector: 'app-provvigioni',
  templateUrl: './provvigioni.component.html',
  styleUrls: ['./provvigioni.component.scss']
})
export class ProvvigioniComponent {
  socialCtrl: any;
  dateFromCtrl:any;
  dateToCtrl: any;
  loaded: boolean = false;

  commissions: any;
  commissionSelected: any = {};
  newCommission: boolean = false;
  page = 0
  size = 10;
  showDetail: boolean = false;

  constructor(private restService: RestService, public guiService: GuiService){
    this.guiService.closeDetail.subscribe(value => {
      if(value == true){
        this.commissionSelected = null;
        this.showDetail = false;
      }
    });

    this.guiService.refreshList.subscribe( value => {
      if(value == true){
        this.page = 0
        this.getCommissions()
        this.showDetail = false
      }
     });
  }

  ngOnInit(): void {
    this.getCommissions();
  }

  loadPage(event:any){
    this.page = event
    this.getCommissions()
  }

  getCommissions(){
    let tmpJson: any = {
      "draw":this.page,
      "start": this.page*10,
      "length": 10,
      "order[0][column]": "0",
      "order[0][dir]":"asc",
      "ragioneSociale": this.socialCtrl ? this.socialCtrl : '',
      "from": this.dateFromCtrl ? this.dateFromCtrl : '',
      "to": this.dateToCtrl ? this.dateToCtrl : ''
    };

    let json = new URLSearchParams(tmpJson).toString();
    this.loaded = false;
    this.restService.postService('crm_base_new','/inv_agent/report_compact?'+json,{}).subscribe((ris)=>{
      console.debug(ris)
      this.commissions = [];
      this.commissions = ris;
      this.loaded = true;
    });
  }

  commissionDetail(id:any){
    let from = this.dateFromCtrl ? this.dateFromCtrl : '&'
    let to = this.dateToCtrl ? this.dateToCtrl : '&'
    this.restService.getService('crm_base_new','/inv_agent/report_compact/detail/'+id+'?from='+from+'to='+to).subscribe((ris)=>{
      this.commissionSelected = ris.model
      this.commissionSelected['agentId'] = id
      this.newCommission = false;
      this.showDetail = true;
    })
  }

}
