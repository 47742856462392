import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RestService } from './rest.service';
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class StructureService {
  structures: any;
  selectedStructure: any;
  selectedStructureConfig: any;
  structureCalendarConfig: any = null;
  public isStructureChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /* LOADING TOP SEARCH */
  loadingTopSearch: boolean = false;

  constructor(private restService: RestService, private translate: TranslateService) {
    this.getStructures()
    if (sessionStorage.getItem('selected-structure')) {
      let tmpSelectedStructure = JSON.parse(sessionStorage.getItem('selected-structure') + "");
      this.selectedStructure = tmpSelectedStructure;

      this.getStructurebyId(tmpSelectedStructure.id);
    } else {
      this.checkAssignedStructures()
    }
  }

  getStructures(valore?: any) {
    if (valore && valore.length > 2) {
      this.restService.getService('structure', `/likeList/?query=${valore}`).subscribe(ris => {
        this.structures = ris
        console.debug("STRUCTURES SEARCH FIELD", this.structures);
      })
    }

  }

  getStructurebyId(idStructure: number) {
    this.restService.postService('structure_detail', '', { id: idStructure }).subscribe((ris) => {
      sessionStorage.setItem('selected-structure', JSON.stringify(ris.structure));

      this.loadConfigStructure();
    })
  }

  loadConfigStructure() {
    this.restService.getService('structure_configuration', '/list_base/' + this.selectedStructure.id).subscribe((ris) => {
      this.selectedStructureConfig = {};

      for (let k in ris.data) {
        let item = ris.data[k];
        this.selectedStructureConfig[item.key] = item;
      }
      console.debug('BASE SELECTED STRUCTURE CONFIG', this.selectedStructureConfig);

    })
  }

  updateStructureConfig(typ: any, str_config: any) {
    this.selectedStructureConfig.setup_phase.value == "0" ? this.selectedStructureConfig.setup_phase.value = "1" : this.selectedStructureConfig.setup_phase.value = "0";
    this.restService.putService('structure_configuration', '/v2/update/' + this.selectedStructure.id + '/' + typ, str_config).subscribe((ris) => {
      if (this.selectedStructureConfig.setup_phase.value == "0") {
        Swal.fire({
          title: this.translate.instant("ALERTS.setup_mode_enable"),
          icon: 'info'
        });
      } else {
        Swal.fire({
          title: this.translate.instant("ALERTS.setup_mode_disable"),
          icon: 'info'
        });
      }
    })
  }

  queryStructure(param: any) {
    if (param.length > 2) {
      this.restService.getService('structure', '/likeList/?query=' + param).subscribe((ris) => {
        return ris;
      })
    }
  }

  checkAssignedStructures() {

    let param: any = {
      page: 0,
      size: 2
    }

    this.restService.postService('structure', 'v4/search', param).subscribe({
      next: (ris) => {
        if(ris.totalItems === 1){
          console.debug("E' PRESENTE SOLO UNA STRUTTURA E LA IMPOSTO COME SELEZIONATA!")
          this.selectedStructure = ris.items[0];
          sessionStorage.setItem('selected-structure', JSON.stringify(ris.items[0]));
          this.loadConfigStructure();
        }
      }
    });
  }
}
