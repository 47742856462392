import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';
import { StructureService } from 'src/app/core/services/structure.service';
import Swal from 'sweetalert2';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-structure-configuration',
  templateUrl: './structure-configuration.component.html',
  styleUrls: ['./structure-configuration.component.scss']
})
export class StructureConfigurationComponent {
  structureId: any;
  navItems: any[] = [{
    value: "MAIN",
    label: "CFG.main_legend"
  },{
    value: "USERS",
    label: "CFG.user_legend"
  },{
    value: "ROOMS",
    label: "FX.rooms"
  },{
    value: "SALES_CHANNEL",
    label: "FX.saleschannels"
  },{
    value: "CALENDAR",
    label: "FX.calendar"
  },{
    value: "PMS",
    label: "CFG.pms_legend"
  },{
    value: "CHANNEL_MAN",
    label: "CFG.channel_legend"
  },{
    value: "COMMUNICATION",
    label: "CFG.email_legend"
  },
  {
    value: "AI",
    label:"AI.ai_main"
  }
  ];
  navItemSelected: any = null;


  constructor(private restService: RestService,private route: ActivatedRoute, public structureService: StructureService,private router: Router,public guiService: GuiService, public translate: TranslateService){


  }

  ngOnInit(): void {

    this.structureId = this.structureService.selectedStructure.id;
    this.structureService.selectedStructure ? this.structureService.getStructurebyId(this.structureId) : Swal.fire({
      title: 'Nessuna struttura selezionata',
      icon: 'warning'
    }).then((result)=>{
      if(result.isConfirmed){
        this.router.navigate(['admin/dashboard']);
      }
    });

    //this.navItemSelected = this.navItems[0];
    this.structureService.loadConfigStructure();
  }

  changeNavItem(item:any){
    if(this.guiService.isConfigEdited){
      Swal.fire({
      title: 'Hai salvato le tue modifiche?',
      text: 'Se non hai salvato perderai le tue modifiche',
      showCancelButton: true,
      confirmButtonText: 'Non voglio salvare',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.navItemSelected = item;
        this.guiService.isConfigEdited = false;
      }
    })
    }else{
      this.navItemSelected = item;
    }
  }

}
