<div class="row align-items-start">
  <div class="col-12 box-main-form">
    <fieldset>
      <div *ngIf="structureService.selectedStructureConfig && structureService.selectedStructureConfig.setup_phase">

        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.CMX.SIMPLE_BOOKING_HOTEL_ID" | translate }}</h3>
            <input class="form-control input-camere" type="text" [(ngModel)]="structureService.selectedStructureConfig.SIMPLE_BOOKING_HOTEL_ID.value" />
            <small ngbTooltip="{{helptxt}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
              <i-feather name="help-circle"></i-feather>
            </small>
          </label>
        </div>

        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.CMX.SIMPLE_BOOKING_ACCOUNT" | translate }}</h3>
            <input class="form-control input-camere" type="text" [(ngModel)]="structureService.selectedStructureConfig.SIMPLE_BOOKING_ACCOUNT.value" />
            <small ngbTooltip="{{helptxt}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
              <i-feather name="help-circle"></i-feather>
            </small>
          </label>
        </div>

        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.CMX.SIMPLE_BOOKING_PWD" | translate }}</h3>
            <input class="form-control input-camere" type="password" [(ngModel)]="structureService.selectedStructureConfig.SIMPLE_BOOKING_PWD.value" />
            <small ngbTooltip="{{helptxt}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
              <i-feather name="help-circle"></i-feather>
            </small>
          </label>
        </div>

        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.CMX.SIMPLE_BOOKING_PKEY" | translate }}</h3>
            <input class="form-control input-camere" type="text" [(ngModel)]="structureService.selectedStructureConfig.SIMPLE_BOOKING_PKEY.value" />
            <small ngbTooltip="{{helptxt}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
              <i-feather name="help-circle"></i-feather>
            </small>
          </label>
        </div>

        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.CMX.SIMPLE_BOOKING_PROVIDER_NAME" | translate }}</h3>
            <input class="form-control input-camere" type="text" [(ngModel)]="structureService.selectedStructureConfig.SIMPLE_BOOKING_PROVIDER_NAME.value" />
            <small ngbTooltip="{{helptxt}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
              <i-feather name="help-circle"></i-feather>
            </small>
          </label>
        </div>

        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.CMX.SIMPLE_BOOKING_PROVIDER_KEY" | translate }}</h3>
            <input class="form-control input-camere" type="text" [(ngModel)]="structureService.selectedStructureConfig.SIMPLE_BOOKING_PROVIDER_KEY.value" />
            <small ngbTooltip="{{helptxt}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
              <i-feather name="help-circle"></i-feather>
            </small>
          </label>
        </div>

        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.CMX.SIMPLE_BOOKING_REF_MEAL_PLAN" | translate }}</h3>
            <ng-select [items]="REF_MEAL_PLAN" bindLabel="label" bindValue="val" [(ngModel)]="structureService.selectedStructureConfig.SIMPLE_BOOKING_REF_MEAL_PLAN.value"></ng-select>
            <small ngbTooltip="{{helptxt}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
              <i-feather name="help-circle"></i-feather>
            </small>
          </label>
        </div>

        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.CMX.SIMPLE_BOOKING_MEAL_PLANS" | translate }}</h3>
            <button (click)="openMealPlansSettings(content)" class="btn btn-primary mx-2">{{"CFG.CMX.SIMPLE_BOOKING_SETTINGS_MEAL_PLANS" | translate}}</button>
            <button (click)="copyMealPlansSettings()" class="btn btn-outline-primary">{{"CFG.CMX.SIMPLE_BOOKING_COPY_MEAL_PLANS" | translate}}</button>
            <!--textarea class="form-control input-camere" type="text" [(ngModel)]="structureService.selectedStructureConfig.SIMPLE_BOOKING_MEAL_PLANS.value"></textarea-->
          </label>
        </div>

      </div>
    </fieldset>
  </div>

  <!-- Modal per generare il JSON per Meal Plan -->
  <ng-template #content let-modal>
    <div class="modal-header">
      <h1 class="modal-title fs-5" id="exampleModalLabel">{{"CFG.CMX.SIMPLE_BOOKING_SETTINGS_MEAL_PLANS" | translate}}</h1>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <div class="accordion" id="mealplans">
        <div class="accordion-item" *ngFor="let mealp of mealPlanBase.mealPlans; let i = index">
          <h2 class="accordion-header header-custom">
            <input type="checkbox" [(ngModel)]="mealp.enabled" class="mx-2" (change)="checkPlanEnabled(mealp.enabled,i)"/>
            <button class="accordion-button" type="button" (click)="this.mealAccordionShow[i] = !this.mealAccordionShow[i]">
              {{mealp.mealPlanName}}
            </button>
          </h2>
          <div id="#collapse_{{i}}" class="accordion-collapse collapse" [class.show]="mealAccordionShow[i]" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <div class="row header">
                <div class="col-3">{{"GENERIC.name" | translate}} {{"GENERIC.type" | translate}}</div>
                <div class="col-2">ID</div>
                <div class="col-3">{{"PRICING.formula" | translate}}</div>
                <div class="col-2">{{"PRICING.min" | translate}}</div>
                <div class="col-2">{{"PRICING.max" | translate}}</div>
              </div>
              <div *ngFor="let room of rooms">
                <div class="row" *ngIf="mealp.mealPlanRooms[room.id]">
                  <div class="col-3 title">
                    <input type="checkbox" [(ngModel)]="mealp.mealPlanRooms[room.id].enabled" (change)="checkRoomEnabled(mealp.mealPlanRooms[room.id].enabled,i)"/>
                    {{room.title}}-{{room.name}}
                  </div>
                  <div class="col-2">{{room.id}}</div>
                  <div class="col-3">
                    <input type="text" class="form-control" name="mealPlanFormula" [(ngModel)]="mealp.mealPlanRooms[room.id].mealPlanFormula">
                  </div>
                  <div class="col-2">
                    <input type="text" class="form-control" name="bottom" [(ngModel)]="mealp.mealPlanRooms[room.id].bottom">
                  </div>
                  <div class="col-2">
                    <input type="text" class="form-control" name="rack" [(ngModel)]="mealp.mealPlanRooms[room.id].rack">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Close</button>
      <button type="button" class="btn btn-primary" (click)="setMealPlans()">{{ "FX.edit" | translate }}</button>
    </div>
  </ng-template>


</div>
