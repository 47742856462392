<div class="page-title-box d-flex align-items-center justify-content-between">
  <h4 class="mb-0">{{ "FX.strsettingsuser" | translate }}</h4>
</div>
<div class="col-xxl-12 col-xl-12">
  <div class="card card-h-100 p-4">
    <div class="row">
      <div class="nav-menu col-2">
        <h3 *ngFor="let item of navItems" [class.nav-selected]="navItemSelected && navItemSelected.value == item.value"
            (click)="changeNavItem(item)">{{item.label | translate}}</h3>
      </div>
      <div class="configuration-main col-10" *ngIf="guiService.loaded">


        <app-structure-configuration-main *ngIf="navItemSelected && navItemSelected.value == 'MAIN'"></app-structure-configuration-main>

        <app-structure-configuration-user *ngIf="navItemSelected && navItemSelected.value == 'USERS'"></app-structure-configuration-user>

        <app-room-list *ngIf="navItemSelected && navItemSelected.value == 'ROOMS'"></app-room-list>

        <app-sales-channel-list *ngIf="navItemSelected && navItemSelected.value == 'SALES_CHANNEL'"></app-sales-channel-list>

        <app-structure-configuration-calendar *ngIf="navItemSelected && navItemSelected.value == 'CALENDAR'"></app-structure-configuration-calendar>

        <app-structure-configuration-pms *ngIf="navItemSelected && navItemSelected.value == 'PMS'"></app-structure-configuration-pms>

        <app-structure-configuration-com *ngIf="navItemSelected && navItemSelected.value == 'COMMUNICATION'"></app-structure-configuration-com>

        <app-structure-configuration-cm *ngIf="navItemSelected && navItemSelected.value == 'CHANNEL_MAN'"></app-structure-configuration-cm>

        <app-structure-configuration-ai *ngIf="navItemSelected && navItemSelected.value == 'AI'"></app-structure-configuration-ai>
      </div>


      <div *ngIf="!guiService.loaded" class="main-page-loading col-10">
        <div class="loading-bars-box">
          <div class="loading-bars"></div>
        </div>
      </div>

    </div>
  </div>
</div>
