import { Component, TemplateRef } from '@angular/core';
import {RestService} from "../../../../../../core/services/rest.service";
import {ActivatedRoute, Router} from "@angular/router";
import {StructureService} from "../../../../../../core/services/structure.service";
import {GuiService} from "../../../../../../core/services/gui.service";
import {TranslateService} from "@ngx-translate/core";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-verticalbooking',
  templateUrl: './verticalbooking.component.html',
  styleUrls: ['./verticalbooking.component.scss']
})
export class VerticalbookingComponent {
  helptxt: string = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam blandit aliquet nisl at tristique. Nunc tincidunt tempor consequat. Orci varius natoque penatibus et magnis dis.";

  mealPlanBase: any = {
    "mealPlans":[
       {
          "mealPlanId":1,
          "mealPlanName":"STDBB",
          "mealPlanRooms":{}
       }
    ]
  };
  rooms: Array<any> = [];
  mealAccordionShow: Array<boolean> = [true,true,true,true,true];

  constructor(private restService: RestService,private route: ActivatedRoute, public structureService: StructureService,private router: Router,public guiService: GuiService,
              public translate: TranslateService,private modalService: NgbModal) {

  }

  ngOnInit(): void {
    this.getRooms()
  }

  getRooms(){
    let params = {
      page: 0,
      searchCriteria:[],
      size: 100,
      structureId: this.structureService.selectedStructure.id
    }
    this.restService.postService('room_type','/v2/search',params).subscribe((ris =>{
      this.rooms = ris.items;
      this.prepareParams();
    }))
  }

  openMealPlansSettings(content: TemplateRef<any>) {
		this.modalService.open(content, { size: 'xl', scrollable: true });
	}

  copyMealPlansSettings() {
    navigator.clipboard.writeText(JSON.stringify(this.mealPlanBase));
  }

  prepareParams() {
    console.debug("Prepare Params");

    console.debug(this.structureService.selectedStructureConfig)
      let tmp = this.structureService.selectedStructureConfig.VERTICAL_MEAL_PLANS.value && this.structureService.selectedStructureConfig.VERTICAL_MEAL_PLANS.value!="" ? JSON.parse(this.structureService.selectedStructureConfig.VERTICAL_MEAL_PLANS.value).mealPlans : this.mealPlanBase.mealPlans;

      for(let k in tmp) {
        let mpId = tmp[k].mealPlanId;
        let mpRooms = tmp[k].mealPlanRooms;

        for(let i in this.rooms) {
          let roomId = this.rooms[i].id;
          let tmp: any = {
            "roomId": roomId,
            "bottom": null,
            "mealPlanFormula": null,
            "rack": null
          }
          this.mealPlanBase.mealPlans[mpId-1].mealPlanRooms[roomId] = mpRooms[roomId] ? mpRooms[roomId] : tmp;
          this.mealPlanBase.mealPlans[mpId-1].mealPlanRooms[roomId].enabled = mpRooms[roomId] ? true : false;
        }
        console.debug("MEAL PLANS", this.mealPlanBase);
      }
  }

  setMealPlans() {

    for(let k in this.mealPlanBase.mealPlans) {
      let item = this.mealPlanBase.mealPlans[k];

      for(let i in item.mealPlanRooms) {
        !item.mealPlanRooms[i].enabled ? delete item.mealPlanRooms[i] : delete item.mealPlanRooms[i].enabled;
      }
    }
    this.structureService.selectedStructureConfig.VERTICAL_MEAL_PLANS.value = JSON.stringify(this.mealPlanBase);
    this.prepareParams();
    this.modalService.dismissAll();
  }
}
