<div>
  <div class="card mb-0">
    <div class="card-header">
        <h1 class="text-center">Configurazione cliente</h1>
        <div class="text-center">
          <label class="px-2">Segna nella Blacklist</label>
          <ui-switch defaultBoColor="#dfdfdf" color="#51d28c" [(ngModel)]="blacklist" [ngModelOptions]="{standalone: true}" checkedLabel="Si"uncheckedLabel="No"></ui-switch>
        </div>
    </div><!-- end card header -->
    <form class="card-body" [formGroup]="form">
      <div class="row">
        <div class="col-12">
          <div class="mb-3 row">
            <div class="col-8 form-floating">
              <select class="form-select" type="text" id="typeCode" formControlName="typeCode" >
                <option [value]="code.value" [selected]="code.value == -1" disabled *ngFor="let code of typeCodeDropdown">{{code.label}}</option>
              </select>
            </div>
            <div class="col-4 form-floating">
              <input class="form-control" type="text" id="ficID" formControlName="ficID">
              <label for="ficID">ID Fatture in cloud</label>
            </div>
          </div>
          <div class="mb-3 row">
            <div class="col-6 form-floating">
              <select class="form-select" type="text" id="currencyCode" formControlName="currencyCode" >
                <option [value]="code.value" [selected]="code.value == -1" disabled *ngFor="let code of currencyCodeDropdown">{{code.label}}</option>
              </select>
            </div>
            <div class="col-6 form-floating">
              <select class="form-select" type="text" id="ivaCode" formControlName="ivaCode" >
                <option [value]="iva.value" [selected]="iva.value == -1" disabled *ngFor="let iva of ivaCodeDropdown">{{iva.label}}</option>
              </select>
            </div>
          </div>
          <div class="mb-3 row">
            <div class="col-6 form-floating">
              <select class="form-select" type="text" id="areaCode" formControlName="areaCode" >
                <option [value]="area.value" [selected]="area.value == -1" disabled *ngFor="let area of areaCodeDropdown">{{area.label}}</option>
              </select>
            </div>
            <div class="col-6 form-floating">
              <select class="form-select" type="text" id="languageCode" formControlName="languageCode" >
                <option [value]="language.value" [selected]="language.value == -1" disabled *ngFor="let language of languageCodeDropdown">{{language.label}}</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-3 text-center">
              <label class="col-form-label">INPS</label>
            </div>
            <div class="col-3 text-center">
              <label  class="col-form-label">Scorporo IVA</label>
            </div>
            <div class="col-3 text-center">
              <label  class="col-form-label">IVA</label>
            </div>
            <div class="col-3 text-center">
              <label  class="col-form-label">Esente ritenuta</label>
            </div>
          </div>
          <div class="mb-3 row">
            <div class="col-3 text-center">
              <ui-switch defaultBoColor="#dfdfdf" color="#51d28c" [(ngModel)]="inpsFlag" [ngModelOptions]="{standalone: true}" checkedLabel="Si"uncheckedLabel="No"></ui-switch>
            </div>
            <div class="col-3 text-center">
              <ui-switch defaultBoColor="#dfdfdf" color="#51d28c" [(ngModel)]="scorporoIva" [ngModelOptions]="{standalone: true}" checkedLabel="Si"uncheckedLabel="No"></ui-switch>
            </div>
            <div class="col-3 text-center">
              <ui-switch defaultBoColor="#dfdfdf" color="#51d28c" [(ngModel)]="ivaFlag" [ngModelOptions]="{standalone: true}" checkedLabel="Si"uncheckedLabel="No"></ui-switch>
            </div>
            <div class="col-3 text-center">
              <ui-switch defaultBoColor="#dfdfdf" color="#51d28c" [(ngModel)]="esenteRit" [ngModelOptions]="{standalone: true}" checkedLabel="Si"uncheckedLabel="No"></ui-switch>
            </div>
          </div>
          <div class="mb-3 row">
            <div class="col-4 form-floating">
              <input class="form-control" type="text" id="iban" formControlName="iban">
              <label for="iban">IBAN</label>
            </div>
            <div class="col-4 form-floating">
              <input class="form-control" type="text" id="swift" formControlName="swift">
              <label for="swift">BIC</label>
            </div>
            <div class="col-4 form-floating">
              <input class="form-control" type="text" id="ggScadenza" formControlName="ggScadenza">
              <label for="ggScadenza">GG Scadenza</label>
            </div>
          </div>
          <div class="mb-3 row form-floating">
            <textarea class="form-control" type="text" id="pagDefault" formControlName="pagDefault"></textarea>
            <label for="pagDefault">Pagamento di default</label>
          </div>
        </div>
      </div>
    </form><!-- end card body -->
</div>
<div class="d-flex justify-content-center m-2">
  <button class="btn-edit" (click)="save()">Salva</button>
</div>
</div>
