<div *ngIf="guiService.loaded" class="main-page-loading">
    <div class="loading-bars-box">
      <div class="loading-bars"></div>
    </div>
  </div>
<div *ngIf="!guiService.loaded">

  <div class="row mb-2" *ngIf="rates && rates.length > 1">
    <div class="col-3"></div>
    <div class="col-4"></div>
    <div class="col-5"><button class="btn-apply" (click)="applyAll()">{{"AI.apply_all_btn" | translate}}</button></div>
  </div>
  <div class="row row-apply" *ngFor="let item of rates; let i = index">
    <div class="col-3 fw">{{item.date}}</div>
    <div class="col-5">
      {{item.revolutionRate}} <span><i-feather name="arrow-right"></i-feather></span>
      <span class="fw">{{item.aiRate}}</span>
      <span [class]="checkRatePositive(item.rateDiff)" class="fw">
        <span *ngIf="item.quality!='CLOSED'">[{{item.rateDiff}}]</span>
        <span *ngIf="item.quality=='CLOSED'" class="badge bg-danger">{{"GENERIC.closed" | translate }}</span>
      </span>
    </div>
    <div class="col-1">
      <div *ngIf="item.quality!='CLOSED'" class="square" [style.background-color]="item.quality"></div>
    </div>
    <div class="col-3">
      <button *ngIf="item.quality!='CLOSED'" class="btn-apply" (click)="apply(item.date,i)" [disabled]="item.applyStatus > 0" [ngClass]="{'disabled': item.applyStatus && item.applyStatus != 0}">
        <i class="fas fa-spinner fa-spin" *ngIf="inProcessing && item.applyStatus === 1"></i>
        <span *ngIf="!item.applyStatus || item.applyStatus === 0">{{"AI.apply_btn" | translate}}</span>
        <span *ngIf="item.applyStatus === 2">
          {{"AI.applied_btn" | translate}}
        </span>
      </button>
    </div>
  </div>
</div>
