import {Component, SimpleChanges} from '@angular/core';
import {RestService} from "../../../../core/services/rest.service";
import {ActivatedRoute, Router} from "@angular/router";
import {StructureService} from "../../../../core/services/structure.service";
import {GuiService} from "../../../../core/services/gui.service";
import {TranslateService} from "@ngx-translate/core";
import { LanguageService } from 'src/app/core/services/language.service';

@Component({
  selector: 'app-structure-configuration-com',
  templateUrl: './structure-configuration-com.component.html',
  styleUrls: ['./structure-configuration-com.component.scss']
})
export class StructureConfigurationComComponent {
  com_lang = [{
    value: "en",
    label: "Inglese"
  },{
    value: "es",
    label: "Spagnolo"
  },{
    value: "fr",
    label: "Francese"
  },{
    value: "it",
    label: "Italiano"
  }]

  constructor(private restService: RestService,private route: ActivatedRoute, public structureService: StructureService,private router: Router,public guiService: GuiService,
              public translate: TranslateService,public language: LanguageService) {

  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  save() {
    this.guiService.loaded = false;
    let param = [];
    for(let c in this.structureService.selectedStructureConfig){
      param.push(this.structureService.selectedStructureConfig[c]);
    }

    this.restService.putService('structure_configuration', '/v2/update/'+this.structureService.selectedStructure.id+'/CALENDAR',param).subscribe((ris)=>{
      this.guiService.isConfigEdited = false
      this.guiService.loaded = true;
      this.guiService.editedSuccesfully()
    })
  }
  reset() {}

  configKeyChange(k:string) {
    this.structureService.selectedStructureConfig[k].value == "0" ? this.structureService.selectedStructureConfig[k].value = "1" : this.structureService.selectedStructureConfig[k].value = "0";
  }
}
