import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-left-side',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
  fixedMenuFlag: boolean = false;
  menuLayout: boolean = false;

  constructor(private cookieService: CookieService, private modalService: NgbModal){

  }
  ngOnInit(): void {
    if(this.cookieService.check('menu') && this.cookieService.get('menu') === 'true'){
      this.fixedMenuFlag = true
      if(this.cookieService.check('menu-size') && this.cookieService.get('menu-size') === 'true'){
        this.menuLayout = true
      }
    }else{
      this.fixedMenuFlag = false
    }
  }

  menuFixed(status:boolean){
    status == true ? this.fixedMenuFlag = true : this.fixedMenuFlag = false
    console.debug(this.fixedMenuFlag)
  }
  menuSize(status:boolean){
    status == true ? this.menuLayout = true : this.menuLayout = false
    console.debug('menu-size',this.menuLayout)
  }

  save(){
    this.cookieService.set('menu', String(this.fixedMenuFlag));
    this.cookieService.set('menu-size', String(this.menuLayout));
    location.reload()
  }

  close(){
    this.modalService.dismissAll()
  }

}
