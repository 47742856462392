import { GuiService } from 'src/app/core/services/gui.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { circle, latLng, tileLayer } from 'leaflet';
import { Router } from '@angular/router';
import { StructureService } from 'src/app/core/services/structure.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

/**
 * dashboard Component
 */
export class DashboardComponent implements OnInit {

  users: any;

  constructor(public translate: TranslateService,private router: Router, public guiService: GuiService, public structureService: StructureService) { }

  ngOnInit(): void {
    this.guiService.changeArea("FX.main");
    this.guiService.selectMenuItems("FX.main");

  }

}
