<div class="page-title-box d-flex align-items-center justify-content-between">
    <h4 class="mb-0">{{"FX.costpanel" | translate}}</h4>
  </div>
  <div class="col-xxl-12 col-xl-12" >
    <div class="card card-h-100 p-3">
        <iframe height="700" src="https://app.powerbi.com/view?r=eyJrIjoiOWUzMzRiNTEtNDY1OC00MWFjLWIzN2EtYjQwYmI5MmVmM2I5IiwidCI6ImNhMTZiNTY4LTc5MWItNDY2Zi04MDJiLWY1YTRkMGExMGJiYSIsImMiOjl9"></iframe>
    </div>
  
    <div *ngIf="!loaded" class="main-page-loading">
      <div class="loading-bars-box">
        <div class="loading-bars"></div>
      </div>
    </div>
  </div>
  