<div class="col-xxl-12 col-xl-12">
  <div class="card card-h-100" *ngIf="commissions">
    <div class="card-header justify-content-between d-flex align-items-center">
     <div class="col-sm-8 col-md-8">
      <div class="row">
        <div class="form-floating col-4">
          <input type="text" id="social" class="form-control" [(ngModel)]="socialCtrl">
          <label for="social">Ragione sociale agente</label>
        </div>
        <div class="form-floating col-4">
          <input type="date" id="dateFromCtrl" class="form-control" [(ngModel)]="dateFromCtrl">
          <label for="dateFromCtrl">Da</label>
        </div>
        <div class="form-floating col-4">
          <input type="date" id="dateToCtrl" class="form-control" [(ngModel)]="dateToCtrl">
          <label for="dateToCtrl">A</label>
        </div>
        <span class="icona cerca col-1">
          <i class="uil-search" (click)="getCommissions()"></i>
        </span>
      </div>
    </div>
    <div class="col-sm-4 col-md-4">
      <div class="d-flex align-items-start justify-content-around top-icons">
        <button class="btn btn-primary" (click)="guiService.chooseItemView('CARD')"
          [class.selected]="guiService.viewType == 'CARD'">
          <i class="uil-table"></i>
        </button>
        <button class="btn btn-primary" [class.selected]="guiService.viewType == 'TABLE'"
          (click)="guiService.chooseItemView('TABLE')">
          <i class="uil-list-ul"></i>
        </button>
        <ngb-pagination class="col-6"
        (pageChange)="loadPage($event)"
          [(page)]="page"
          [pageSize]="size"
          [collectionSize]="commissions.recordsTotal"
          [ellipses]="true"
          [maxSize]="1"
          ></ngb-pagination>
      </div>
    </div>

    </div><!-- end card header -->
    <div class="card-body row">

      <div [ngClass]="showDetail ? 'col-sm-2 col-md-6' : 'col-sm-12 col-md-12'" >

        <!-- START TABLE VIEW -->
        <div class="table-responsive" *ngIf="guiService.viewType == 'TABLE'">
          <table class="table table-sm table-hover mb-0">
            <thead>
            <tr>
              <th class="text-center"></th>
              <th>Segnalatore</th>
              <th class="text-center">Clienti</th>
              <th>Importo totale fatture clienti</th>
              <th>Importo totale segnalatore maturato</th>
              <th>Importo fatture segnalatore ricevute</th>
              <th>Importo fatture segnalatore saldato</th>
              <th>Importo fatture segnalatore non saldato</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let commission of commissions.data">
              <th scope="row" [class.selected]="commissionSelected && commission.agentId == commissionSelected.agentId"></th>
              <td [class.selected]="commissionSelected && commission.agentId == commissionSelected.agentId" class="name-color"><a>{{commission.agentName}}</a></td>
              <td [class.selected]="commissionSelected && commission.agentId == commissionSelected.agentId" class="text-center">{{commission.customers}}</td>
              <td [class.selected]="commissionSelected && commission.agentId == commissionSelected.agentId">{{commission.agentTotalAmount && commission.agentTotalAmount > 0 ? commission.agentTotalAmount +' '+ commission.valuta : '0,00' +' '+ commission.valuta}}</td>
              <td [class.selected]="commissionSelected && commission.agentId == commissionSelected.agentId">{{commission.customerTotalAmount && commission.customerTotalAmount > 0 ? commission.customerTotalAmount +' '+ commission.valuta : '0,00' +' '+ commission.valuta}}</td>
              <td [class.selected]="commissionSelected && commission.agentId == commissionSelected.agentId">{{commission.agentTotalInvoicesAmount && commission.agentTotalInvoicesAmount > 0 ? commission.agentTotalInvoicesAmount +' '+ commission.valuta : '0,00' +' '+ commission.valuta}}</td>
              <td [class.selected]="commissionSelected && commission.agentId == commissionSelected.agentId">{{commission.agentTotalInvoicesAmountSaldato && commission.agentTotalInvoicesAmountSaldato > 0 ? commission.agentTotalInvoicesAmountSaldato +' '+ commission.valuta : '0,00' +' '+ commission.valuta}}</td>
              <td [class.selected]="commissionSelected && commission.agentId == commissionSelected.agentId">{{commission.agentTotalPercAmount && commission.agentTotalPercAmount ? commission.agentTotalPercAmount + ' '+commission.valuta : '0,00' + ' '+commission.valuta}}</td>
              <td [class.selected]="commissionSelected && commission.agentId == commissionSelected.agentId">
                <div class="text-end mb-1" ngbDropdown>
                  <i class="uil-cog m-0 standard" ngbDropdownToggle id="dropdownBasic0"></i>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic0">
                    <button ngbDropdownItem (click)="commissionDetail(commission.agentId)">Detail</button>
                  </div>
                </div>
              </td>
            </tr>
            </tbody><!-- end tbody -->
          </table><!-- end table -->
        </div><!-- end table responsive -->


        <!-- START CARD VIEW -->
        <div class="card-group justify-content-center" *ngIf="guiService.viewType == 'CARD'">
          <div class="col-xl-3 col-xxl-2 col-sm-3 col-md-3 card-body-structure-wrapper" *ngFor="let commission of commissions.data">
            <div class="card-body-structure" [class.selected]="commissionSelected && commission.agentId == commissionSelected.agentId">
              <div class="action-btn">
                <div class="text-end mb-1" ngbDropdown>
                  <i class="uil-cog m-0 standard" ngbDropdownToggle id="dropdownBasic2"></i>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                    <button ngbDropdownItem (click)="commissionDetail(commission.agentId)">Detail</button>
                  </div>
                </div>
              </div>

              <h3 class="pid-area"></h3>

              <div class="titolo d-flex justify-content-between">
                <h1><a>{{commission.agentName}}</a></h1>
              </div>
              <div class="body-card">
                <div class="">
                  <h3>Clienti</h3>
                  <p>{{commission.customers}}</p>
                </div>
                <div class="">
                  <h3>Importo totale fatture clienti</h3>
                  <p>{{commission.agentTotalAmount && commission.agentTotalAmount > 0 ? commission.agentTotalAmount +' '+ commission.valuta : '0,00' +' '+ commission.valuta}}</p>
                </div>
                <div class="">
                  <h3>Importo totale segnalatore maturato</h3>
                  <p>{{commission.customerTotalAmount && commission.customerTotalAmount > 0 ? commission.customerTotalAmount +' '+ commission.valuta : '0,00' +' '+ commission.valuta}}</p>
                </div>
                <div class="">
                  <h3>Importo fatture segnalatore ricevute</h3>
                  <p>{{commission.agentTotalInvoicesAmount && commission.agentTotalInvoicesAmount > 0 ? commission.agentTotalInvoicesAmount +' '+ commission.valuta : '0,00' +' '+ commission.valuta}}</p>
                </div>
                <div class="">
                  <h3>Importo fatture segnalatore saldato</h3>
                  <p>{{commission.agentTotalInvoicesAmountSaldato && commission.agentTotalInvoicesAmountSaldato > 0 ? commission.agentTotalInvoicesAmountSaldato +' '+ commission.valuta : '0,00' +' '+ commission.valuta}}</p>
                </div>
                <div class="">
                  <h3>Importo fatture segnalatore non saldato</h3>
                  <p>{{commission.agentTotalPercAmount && commission.agentTotalPercAmount ? commission.agentTotalPercAmount + ' '+commission.valuta : '0,00' + ' '+commission.valuta}}</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-10 col-md-6" *ngIf="showDetail">
        <app-provvigioni-detail [commission]="commissionSelected" *ngIf="newCommission == false"></app-provvigioni-detail>
        <app-provvigioni-detail [commission]="{}" *ngIf="newCommission"></app-provvigioni-detail>
      </div>
    </div><!-- end card body -->

  </div><!-- end card -->
</div>
