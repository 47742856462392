import { Component } from '@angular/core';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';

@Component({
  selector: 'app-contratti',
  templateUrl: './contratti.component.html',
  styleUrls: ['./contratti.component.scss']
})
export class ContrattiComponent {
  companys: any = [];
  customers: any = [];
  structures: any = [];
  companyCtrl: any;
  customerCtrl: any;
  structureCtrl: any;
  loaded: boolean = false;
  contracts: any = [];

  contractSelected: any = null;
  newContract: boolean = false;
  page = 0
  size = 10;
  showDetail: boolean = false;

  constructor(private restService: RestService, public guiService: GuiService){
    this.guiService.closeDetail.subscribe(value => {
      if(value == true){
        this.contractSelected = null;
        this.showDetail = false;
      }
    });

    this.guiService.refreshList.subscribe( value => {
      if(value == true){
        this.page = 0
        this.getContracts()
        this.showDetail = false
      }
     });
  }

  ngOnInit(): void {
    this.getContracts();
  }

  loadPage(event:any){
    this.page = event
    console.debug(event)
    this.getContracts()
  }


  getContracts(){
    let tmpJson: any = {
      "draw":this.page,
      "start": this.page*10,
      "length": 10,
      "order[0][column]": "0",
      "order[0][dir]":"asc",
      "companyId": this.companyCtrl ? this.companyCtrl.id : '',
      "structureId": this.structureCtrl ? this.structureCtrl.id : '',
      "customerId": this.customerCtrl ? this.customerCtrl.id : ''
    };

    let json = new URLSearchParams(tmpJson).toString();
    this.loaded = false;
    this.restService.postService('crm_base_new','/inv_contract/list_active?'+json,{}).subscribe((ris)=>{
      console.debug(ris)
      this.contracts = [];
      this.contracts = ris;
      this.loaded = true;
    });
  }

  contractDetail(id: any){
    this.restService.getService('crm_base_new','/inv_contract/detail/'+id).subscribe((ris)=>{
      this.contractSelected = ris.model
      this.newContract = false;
      this.showDetail = true;
    })
  }

  newContractEnable() {
    this.newContract = true;
    this.contractSelected = {};
    this.showDetail = true;
  }

  getCompany(evento:any){
    if(evento.term.length > 2){
      this.restService.getService('crm_base_new','/inv_company/like_list?query='+evento.term).subscribe((ris)=>{
      this.companys = ris.data
    })
    }
  }

  getStructure(evento:any){
    if(evento.term.length > 2){
      this.restService.getService('crm_base_new','/structure/likeList?query='+evento.term).subscribe((ris)=>{
      this.structures = ris
    })
    }
  }

  getCustomer(evento:any){
    if(evento.term.length > 2){
      this.restService.getService('crm_base_new','/inv_customer/like_list?query='+evento.term).subscribe((ris)=>{
      this.customers = ris.data
    })
    }
  }

}
