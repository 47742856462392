import {Component, OnInit, inject} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {RestService} from "../../../core/services/rest.service";
import {StructureService} from "../../../core/services/structure.service";
import {GuiService} from "../../../core/services/gui.service";
import moment from "moment/moment";
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-updatemonitoring',
  templateUrl: './updatemonitoring.component.html',
  styleUrls: ['./updatemonitoring.component.scss']
})
export class UpdatemonitoringComponent implements OnInit {
  loaded: boolean = false;

  action_types: Array<any> = [];
  selected_type: any = null;

  users: any = null;
  selectedUser: any = null;

  page: number = 0;
  size: number = 10;

  logs: any = [];
  selectedLog: any = null;
  
  calendar = inject(NgbCalendar);

  calendarDisabled: string = "!disabled";
  manyMonths: number = 4;
	hoveredDate: NgbDate | null = null;
	now: NgbDate = this.calendar.getToday();
  startDate: NgbDate = this.calendar.getPrev(this.now,'m',3);
  fromDate: any = this.calendar.getPrev(this.now,'d',0)
	toDate: any = this.calendar.getNext(this.now, 'd', 0);

	onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
			this.toDate = date;
		} else {
			this.toDate = null;
			this.fromDate = date;
		}

    console.debug("DATE SELECTED", this.fromDate, this.toDate);
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

  json: any = JSON;
  load_more: string = "";

  constructor(private restService: RestService, private strService: StructureService, private guiService: GuiService, public translate: TranslateService) {
  }

  ngOnInit() {
    this.guiService.changeArea("FX.operation");
    this.guiService.selectMenuItems("FX.operation");
    let w = window.innerWidth;
    this.manyMonths = w < 1300 ? 3 : 4;
    this.startDate = w < 1300 ? this.calendar.getPrev(this.now,'m',2) : this.calendar.getPrev(this.now,'m',3);
    if(w <= 1024) {
      this.manyMonths = 2;
      this.startDate = this.calendar.getPrev(this.now,'m',1);
    }
    this.getActionType();
    this.loaded = true;
  }

  queryOwner(evento: any) {
    if(evento.term.length > 2){
      this.restService.getService('users','/likelist/?query='+evento.term).subscribe((ris)=>{
        this.users = ris;
      })
    }
  }

  selectType(ev: any) {

  }

  selectUser(ev: any) {
    if(ev.term.length > 2){
      this.restService.getService('users','/likelist/?query='+ev.term+"&_="+moment().format('x')).subscribe((ris)=>{
        ris.length > 0 ? this.users = ris : [];
      })
    }
  }


  getActionType(){
    this.restService.getService('trace','v4/values').subscribe((ris=>{
      console.debug("LIST ACTIONS",ris);
      this.action_types = [];
      for (let k in ris) {
        let item = ris[k];
        this.action_types.push({
          label: item,
          value: k
        })
      }

      console.debug(this.action_types);

      this.loaded = true;
    }))
  }


  logDetail(idx: number){
    if(this.selectedLog && this.selectedLog.id && idx == this.selectedLog.id) {
      this.selectedLog = null;
    } else {
      this.selectedLog = this.logs.items[idx];
      this.selectedLog.id = idx;
    }
  }



  getUpdateLog(pg?: string){
    pg ? this.page = parseInt(pg) : null;
    this.loaded = false; 
    this.logs = [];
    this.selectedLog = null;

    let param: any = {
      page: this.page,
      size: this.size,
      searchCriteria: {
        fromDay: moment(this.fromDate.year+'-'+this.fromDate.month+'-'+this.fromDate.day,"YYYY-M-D").format("YYYY-MM-DD"),
        toDay: moment(this.toDate.year+'-'+this.toDate.month+'-'+this.toDate.day,"YYYY-M-D").format("YYYY-MM-DD"),
        actionCode: this.selected_type ? this.selected_type.label : null,
        username: this.selectedUser ? this.selectedUser.username : null,
      }
    }

    param.searchCriteria.actionCode == null ? delete param.searchCriteria.actionCode : null;
    param.searchCriteria.username == null ? delete param.searchCriteria.username : null;

    this.restService.postService('trace','v4/'+this.strService.selectedStructure.id+'/search',param).subscribe({
      next: (res) => {
        console.debug("LOG -> ",res);
        this.logs = res;
  
        this.loaded = true;
      },
      error: (err) => {
        console.error(err);
      }
    });
  }
  
 loadPage(event:any){
  console.debug("PAGE EVENT", event);
  this.page = event-1;
  this.getUpdateLog();
 }
}
