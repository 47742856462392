<div>
  <div class="card mb-0" *ngIf="price">
    <div class="card-header">
      <h1 *ngIf="price.code"class="card-title">{{priceDuplicate ? price.code + '_' + now : price.code}}</h1>
      <h1 *ngIf="!price.code" class="card-title">Crea Scostamenti</h1>
      <i class="uil-times-square close-icon" (click)="close()"></i>
    </div><!-- end card header -->
    <div class="card-body">
      <div class="row">
        <div class="form-floating mb-3 row">
          <div class="col-4 form-floating pad-left">
            <input class="form-control" type="text" id="code" name="code" [(ngModel)]="rate_model.code"
                   placeholder="Codice">
            <label for="code">Codice *</label>
          </div>
          <div class="form-floating col-4">
            <input class="form-control" type="date" id="fromDate" name="fromDate" [(ngModel)]="rate_model.fromDate">
            <label for="fromDate">DA *</label>
          </div>
          <div class="form-floating col-4 pad-right">
            <input class="form-control" type="date" id="toDate" name="toDate" [(ngModel)]="rate_model.toDate">
            <label for="toDate">A *</label>
          </div>
        </div><!-- end row -->

        <div class="form-floating mb-3 row">
          <textarea class="form-control textarea-custom" type="text" id="description" name="description"
                    [(ngModel)]="rate_model.description" placeholder="Descrizione"></textarea>
          <label for="description" class="col-md-2 col-form-label">Descrizione *</label>
        </div><!-- end row -->

      </div>
    </div>


    <div class="card-header">
      <label class="col-md-12 col-form-label">{{ "PRICING.day_of_week_checkbox" | translate }}</label>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col day-col">
          <label class="day">{{ "DAYS.SHORT.1" | translate }}</label>
          <input class="form-check-input" type="checkbox" [(ngModel)]="rate_model.monday">
        </div>
        <div class="col day-col">
          <label class="day">{{ "DAYS.SHORT.2" | translate }}</label>
          <input class="form-check-input" type="checkbox" [(ngModel)]="rate_model.tuesday">
        </div>
        <div class="col day-col">
          <label class="day">{{ "DAYS.SHORT.3" | translate }}</label>
          <input class="form-check-input" type="checkbox" [(ngModel)]="rate_model.wednesday">
        </div>
        <div class="col day-col">
          <label class="day">{{ "DAYS.SHORT.4" | translate }}</label>
          <input class="form-check-input" type="checkbox" [(ngModel)]="rate_model.thursday">
        </div>
        <div class="col day-col">
          <label class="day">{{ "DAYS.SHORT.5" | translate }}</label>
          <input class="form-check-input" type="checkbox" [(ngModel)]="rate_model.friday">
        </div>
        <div class="col day-col">
          <label class="day">{{ "DAYS.SHORT.6" | translate }}</label>
          <input class="form-check-input" type="checkbox" [(ngModel)]="rate_model.saturday">
        </div>
        <div class="col day-col">
          <label class="day">{{ "DAYS.SHORT.7" | translate }}</label>
          <input class="form-check-input" type="checkbox" [(ngModel)]="rate_model.sunday">
        </div>
      </div><!-- end row -->


    </div>


    <div class="card-header">
      <label class="col-md-12 col-form-label">{{ "PRICING.model" | translate }}</label>
    </div>
    <div class="card-body rate-model-rooms-list">
      <div class="container room-container" *ngIf="rooms">
        <div class="mb-3 row" *ngFor="let room of rooms; let i = index">
          <div class="form-floating col-2 d-flex flex-row-reverse align-items-baseline">
            <label class="d-inline-block">
              {{room.title}}
            </label>
            <button type="button" class="btn btn-link d-inline-block px-0 mr-5" (click)="rate_model.rules[room.title].enabled = !rate_model.rules[room.title].enabled">
              <i class="bx bx-check-square" *ngIf="rate_model.rules[room.title].enabled"></i>
              <i class="bx bx-minus-circle" *ngIf="!rate_model.rules[room.title].enabled"></i>
            </button>
          </div>
          <div class="form-floating col-3">

            <ng-select [clearable]="true" placeholder="{{ 'PRICING.ref' | translate }}" [(ngModel)]="rate_model.rules[room.title].variable" class="" (change)="changeRuleRef(room.title)">
              <ng-option *ngFor="let item of rooms" [value]="item.title"
                [disabled]="item.title == room.title">
                {{item.title}}
              </ng-option>
            </ng-select>
          </div>

          <div class="form-floating col-3">
            <input class="form-control input-camere" id="formula" placeholder="formula" type="text" [(ngModel)]="rate_model.rules[room.title].formula" />
            <label for="formula">{{ "PRICING.formula" | translate }}</label>
          </div>
          <div class="form-floating col-2">
            <input class="form-control input-camere" id="minValue" placeholder="minValue" type="text" [(ngModel)]="rate_model.rules[room.title].minValue" />
            <label for="minValue">{{ "PRICING.min" | translate }}</label>
          </div>
          <div class="form-floating col-2">
            <input class="form-control input-camere" id="maxValue" placeholder="maxValue" type="text" [(ngModel)]="rate_model.rules[room.title].maxValue" />
            <label for="maxValue">{{ "PRICING.max" | translate }}</label>
          </div>

        </div><!-- end row -->
      </div>
    </div><!-- end col -->

  <div class="card-footer d-flex justify-content-center m-2">
    <button (click)="editPrice()" class="btn-edit" *ngIf="showBtnNew == false && !priceDuplicate">Modifica</button>
    <button (click)="newPrice()" class="btn-new" *ngIf="showBtnNew == true || priceDuplicate">Crea</button>
  </div>
  </div><!-- end card body -->
</div>
