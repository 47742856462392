<div class="main-container">
  <div class="head">
    <img src="assets/images/Logotipo-negativo.svg"/>
  </div>
  <div class="bg-over">
    <div class="background">
      <div class="back_to_login"> 
          <a [routerLink]="['/auth/login']" routerLinkActive="router-link-active" >
              <i class="uil-arrow-left"></i>
              {{"AUTH.BACK_TO_LOGIN" | translate}}
          </a>
      </div>
      <div class="out-box">
        <div class="box">
          <form>
            <div class="p-3">
              <div class="mb-3">
                <label for="floatingInput">{{ "AUTH.RESET_USERNAME" | translate }}</label>
                <input type="text" class="form-control" name="username" [(ngModel)]="username" />
              </div>
              <div class="mb-3">
                <label for="floatingInput">{{ "AUTH.RESET_EMAIL" | translate }}</label>
                <input type="email" class="form-control" name="email" [(ngModel)]="email" />
              </div>
              
              <button class="w-100 btn"(click)="send()" *ngIf="!isLoading">{{"AUTH.RESET_SEND" | translate}}</button>
              <div class="loading-bars-box" *ngIf="isLoading">
                <div class="loading-bars"></div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="footer-box">
        <a>Privacy Policy</a>
      </div>
    </div>

  </div>
</div>

<div class="tablet">
  <div class="contenitore">
    <div class="img-zone">
      <div class="head">
        <img src="assets/images/Logotipo-negativo.svg"/>
      </div>
    </div>
    <div class="login-zone">
      <div class="back_to_login"> 
          <a [routerLink]="['/auth/login']" routerLinkActive="router-link-active" >
              <i class="uil-arrow-left"></i>
              {{"AUTH.BACK_TO_LOGIN" | translate}}
          </a>
      </div>
      
      <form>
        <div class="p-3">
          <div class="mb-3">
            <label for="floatingInput">{{ "AUTH.RESET_USERNAME" | translate }}</label>
            <input type="text" class="form-control" />
          </div>
          <div class="mb-3">
            <label for="floatingInput">{{ "AUTH.RESET_EMAIL" | translate }}</label>
            <input type="email" class="form-control" />
          </div>
          
          <button class="w-100 btn"(click)="send()" *ngIf="!isLoading">{{"AUTH.RESET_SEND" | translate}}</button>
          <div class="loading-bars-box" *ngIf="isLoading">
            <div class="loading-bars"></div>
          </div>
        </div>
      </form>
      <div class="footer-zone">
        <a>Privacy Policy</a>
      </div>
    </div>
  </div>
</div>

<div class="mobile">
  <div class="head">
    <img src="assets/images/Simbolo-negativo.svg"/>
  </div>
    <div class="login-zone">
      <div class="back_to_login"> 
          <a [routerLink]="['/auth/login']" routerLinkActive="router-link-active" >
              <i class="uil-arrow-left"></i>
              {{"AUTH.BACK_TO_LOGIN" | translate}}
          </a>
      </div>
      <form>
          <div class="p-3">
            <div class="mb-3"> 
              <label for="floatingInput">{{ "AUTH.RESET_USERNAME" | translate }}</label>
              <input type="text" class="form-control" />
            </div>
            <div class="mb-3">
              <label for="floatingInput">{{ "AUTH.RESET_EMAIL" | translate }}</label>
              <input type="email" class="form-control" />
            </div>
            
            <button class="w-100 btn"(click)="send()" *ngIf="!isLoading">{{"AUTH.RESET_SEND" | translate}}</button>
            <div class="loading-bars-box" *ngIf="isLoading">
              <div class="loading-bars"></div>
            </div>
          </div>
        </form>
    </div>
</div>
