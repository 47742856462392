import {PriceListComponent} from './pages/settings/price-list/price-list.component';
import {RoomListComponent} from './pages/settings/room-list/room-list.component';
import {UsersListComponent} from './pages/admin/users-list/users-list.component';
import {StructureListComponent} from './pages/admin/structure-list/structure-list.component';
import {ConfirmResetPasswordComponent} from './core/auth/confirm-reset-password/confirm-reset-password.component';
import {ResetPasswordComponent} from './core/auth/reset-password/reset-password.component';
import {LoginComponent} from './core/auth/login/login.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {LayoutComponent} from './layouts/layout.component';
import {AuthGuard} from './core/guards/auth.guard';
import {ProfileComponent} from './pages/shared/profile/profile.component';
import {StructureConfigurationComponent} from './pages/settings/structure-configuration/structure-configuration.component';
import {SalesChannelListComponent} from './pages/settings/sales-channel-list/sales-channel-list.component';
import {PlanningComponent} from './pages/settings/planning/planning.component';
import {DataEntryComponent} from './pages/operation/data-entry/data-entry.component';
import {CalendarComponent} from './pages/operation/calendar/calendar.component';
import {AnagraficheComponent} from './pages/invoices/anagrafiche/anagrafiche.component';
import {ContrattiComponent} from './pages/invoices/contratti/contratti.component';
import {AgentiComponent} from './pages/invoices/agenti/agenti.component';
import {ConteggiComponent} from './pages/invoices/conteggi/conteggi.component';
import {AvailabilityComponent} from "./pages/operation/availability/availability.component";
import {UpdatehistoryComponent} from "./pages/operation/updatehistory/updatehistory.component";
import {ReportGeneralComponent} from "./pages/stats/report-general/report-general.component";
import {ReportViewMonthComponent} from "./pages/stats/report-view-month/report-view-month.component";
import {ReportViewClusterComponent} from "./pages/stats/report-view-cluster/report-view-cluster.component";
import {ReportBiComponent} from "./pages/stats/report-bi/report-bi.component";
import {ExtraServiceComponent} from "./pages/settings/extra-service/extra-service.component";
import {CustomerCareComponent} from "./pages/support/customer-care/customer-care.component";
import {VideoGuideComponent} from "./pages/support/video-guide/video-guide.component";
import {ManualsComponent} from "./pages/support/manuals/manuals.component";
import {UpdatemonitoringComponent} from "./pages/operation/updatemonitoring/updatemonitoring.component";
import {CalendarEventComponent} from "./pages/settings/calendar-event/calendar-event.component";
import {HighlightsComponent} from "./pages/dashboard/highlights/highlights.component";
import { ReliableComponent } from './pages/admin/reliable/reliable.component';
import { CostpanelComponent } from './pages/stats/costpanel/costpanel.component';
import { OtpComponent } from './core/auth/otp/otp.component';
import { ResetLinkComponent } from './core/auth/reset-link/reset-link.component';
import { FileExportComponent } from './pages/stats/file-export/file-export.component';

const routes: Routes = [
  {path: '', redirectTo: 'auth', pathMatch: 'prefix'},
  {path: 'reset/:id', component: ResetLinkComponent},
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent
      },
      {
        path: 'confirm-reset-password',
        component: ConfirmResetPasswordComponent
      },
      {
        path: 'confirm-otp',
        component: OtpComponent
      },
      {path: '', redirectTo: 'login', pathMatch: 'prefix'}
    ]
  },
  {
    path: 'main',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'highlights',
        component: HighlightsComponent
      },
      {path: '', redirectTo: 'dashboard', pathMatch: 'prefix'}
    ]
  },
  {
    path: 'admin',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'structure-list',
        component: StructureListComponent
      },
      {
        path: 'reliable',
        component: ReliableComponent
      },
      {
        path: 'structure-configuration',
        component: StructureConfigurationComponent
      },
      {
        path: 'structure-configuration/:id-structure',
        component: StructureConfigurationComponent
      },
      {
        path: 'users-list',
        component: UsersListComponent
      },
      {
        path: 'room-list',
        component: RoomListComponent
      },
      {
        path: 'price-list',
        component: PriceListComponent
      },
      {
        path: 'sales-channel',
        component: SalesChannelListComponent
      },
      {
        path: 'planning',
        component: PlanningComponent
      },
      {
        path: 'data-entry',
        component: DataEntryComponent
      },
      {
        path: 'calendar',
        component: CalendarComponent
      },
      {path: '', redirectTo: 'dashboard', pathMatch: 'prefix'}
    ]
  },
  {
    path: 'settings',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'strconfig',
        component: StructureConfigurationComponent
      },
      {
        path: 'rooms',
        component: RoomListComponent
      },
      {
        path: 'pricingmodel',
        component: PriceListComponent
      },
      {
        path: 'extraservice',
        component: ExtraServiceComponent
      },
      {
        path: 'saleschannels',
        component: SalesChannelListComponent
      },
      {
        path: 'planning',
        component: PlanningComponent
      },
      {
        path: 'calendarevent',
        component: CalendarEventComponent
      },
      {path: '', redirectTo: 'dashboard', pathMatch: 'prefix'}
    ]
  },
  {
    path: 'operation',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dataentry',
        component: DataEntryComponent
      },
      {
        path: 'calendar',
        component: CalendarComponent
      },
      {
        path: 'availability',
        component: AvailabilityComponent
      },
      {
        path: 'updatehistory',
        component: UpdatehistoryComponent
      },
      {
        path: 'updatemonitoring',
        component: UpdatemonitoringComponent
      },
      {path: '', redirectTo: 'dashboard', pathMatch: 'prefix'}
    ]
  },
  {
    path: 'stats',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'reportgeneral',
        component: ReportGeneralComponent
      },
      {
        path: 'fileexport',
        component: FileExportComponent
      },
      {
        path: 'reportviewmonth',
        component: ReportViewMonthComponent
      },
      {
        path: 'reportviewcluster',
        component: ReportViewClusterComponent
      },
      {
        path: 'report-bi',
        component: ReportBiComponent
      },
      {
        path: 'cost-panel',
        component: CostpanelComponent
      },
      {path: '', redirectTo: 'dashboard', pathMatch: 'prefix'}
    ]
  },
  {
    path: 'invoices',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'anagrafiche',
        component: AnagraficheComponent
      },
      {
        path: 'contratti',
        component: ContrattiComponent
      },
      {
        path: 'agenti',
        component: AgentiComponent
      },
      {
        path: 'conteggi',
        component: ConteggiComponent
      },
      {path: '', redirectTo: 'dashboard', pathMatch: 'prefix'}
    ]
  },
  {
    path: 'support',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'customercare',
        component: CustomerCareComponent
      },
      {
        path: 'videoguide',
        component: VideoGuideComponent
      },
      {
        path: 'manuals',
        component: ManualsComponent
      },
      {path: '', redirectTo: 'dashboard', pathMatch: 'prefix'}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
