<div class="row align-items-start">
    <div class="col-12 box-main-form">
      <fieldset>
        <div *ngIf="structureService.selectedStructureConfig && structureService.selectedStructureConfig.setup_phase">
  
          <div class="col-12">
            <label class="config-item">
              <h3 class="d-inline-block">{{ "CFG.CMX.KROSSBOOKING_HOTEL_ID" | translate }}</h3>
              <input class="form-control input-camere" type="text" [(ngModel)]="structureService.selectedStructureConfig.KROSSBOOKING_HOTEL_ID.value" />
              <small ngbTooltip="{{helptxt}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
                <i-feather name="help-circle"></i-feather>
              </small>
            </label>
          </div>
  
          <div class="col-12">
            <label class="config-item">
              <h3 class="d-inline-block">{{ "CFG.CMX.KROSSBOOKING_ACCOUNT" | translate }}</h3>
              <input class="form-control input-camere" type="text" [(ngModel)]="structureService.selectedStructureConfig.KROSSBOOKING_ACCOUNT.value" />
              <small ngbTooltip="{{helptxt}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
                <i-feather name="help-circle"></i-feather>
              </small>
            </label>
          </div>
  
          <div class="col-12">
            <label class="config-item">
              <h3 class="d-inline-block">{{ "CFG.CMX.KROSSBOOKING_PWD" | translate }}</h3>
              <input class="form-control input-camere" type="password" [(ngModel)]="structureService.selectedStructureConfig.KROSSBOOKING_PWD.value" />
              <small ngbTooltip="{{helptxt}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
                <i-feather name="help-circle"></i-feather>
              </small>
            </label>
          </div>
  
          <div class="col-12">
            <label class="config-item">
              <h3 class="d-inline-block">{{ "CFG.CMX.KROSSBOOKING_API_KEY" | translate }}</h3>
              <input class="form-control input-camere" type="text" [(ngModel)]="structureService.selectedStructureConfig.KROSSBOOKING_API_KEY.value" />
              <small ngbTooltip="{{helptxt}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
                <i-feather name="help-circle"></i-feather>
              </small>
            </label>
          </div>
  
          <div class="col-12">
            <label class="config-item">
              <h3 class="d-inline-block">{{ "CFG.CMX.KROSSBOOKING_ACTIVE_CHANNELS" | translate }}</h3>
              <input class="form-control input-camere" type="text" [(ngModel)]="structureService.selectedStructureConfig.KROSSBOOKING_ACTIVE_CHANNELS.value" />
              <small ngbTooltip="{{helptxt}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
                <i-feather name="help-circle"></i-feather>
              </small>
            </label>
          </div>
  
        </div>
      </fieldset>
    </div>
  
  </div>
  