import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';
import { StructureService } from 'src/app/core/services/structure.service';

@Component({
  selector: 'app-krossbooking',
  templateUrl: './krossbooking.component.html',
  styleUrls: ['./krossbooking.component.scss']
})
export class KrossbookingComponent {
  helptxt: string = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam blandit aliquet nisl at tristique. Nunc tincidunt tempor consequat. Orci varius natoque penatibus et magnis dis.";
  
  constructor(private restService: RestService,private route: ActivatedRoute, public structureService: StructureService,private router: Router,public guiService: GuiService,
              public translate: TranslateService) {

  }

  ngOnInit(): void {
    console.debug("CFG.CMX",this.structureService.selectedStructureConfig);
  }

}
