import Swal from 'sweetalert2';
import { StructureService } from '../../../core/services/structure.service';
import { GuiService } from 'src/app/core/services/gui.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RestService } from 'src/app/core/services/rest.service';

@Component({
  selector: 'app-room-list',
  templateUrl: './room-list.component.html',
  styleUrls: ['./room-list.component.scss']
})
export class RoomListComponent {
  roomView: boolean = false;
  roomSelected: any;
  newRoom: boolean = false;
  searchCtrl: any;
  page = 1;
  size = 10;
  rooms: any;
  showDetail: boolean = false;
  active = 1;
  tabCriteria: string | undefined;

  constructor(public guiService: GuiService, public structureService: StructureService,private router: Router, private restService:RestService){
    this.structureService.isStructureChanged.subscribe( value => {
      if(value == true){
        this.ngOnInit()
        this.roomSelected = null
        this.showDetail = false;
      }
  });

  this.guiService.closeDetail.subscribe( value => {
    if(value == true){
      this.roomSelected = null
      this.showDetail = false;
    }
});

this.guiService.refreshList.subscribe( value => {
  if(value == true){
    this.page = 0
    this.getRooms()
  }
 });
  }

  ngOnInit(): void {
    console.debug(this.structureService.selectedStructure)

    this.getRooms();
    this.structureService.selectedStructure ? null : Swal.fire({
       title: 'Nessuna struttura selezionata',
       icon: 'warning'
     }).then((result)=>{
       if(result.isConfirmed){
         this.router.navigate(['admin/dashboard']);
       }
     })
  }

  getRooms(){
    if(this.structureService.selectedStructure){
      console.debug(this.structureService.selectedStructure)
      let params = {
        page: this.page > 0 ? this.page-1 : this.page,
        searchCriteria: this.searchCtrl ? [{
          "key": "TITLE",
          "operation" : "CONTAINS",
          "value" : this.searchCtrl,
          "orPredicate" : false
        }] : [],
        size: this.size,
        structureId: this.structureService.selectedStructure.id
      }
      if(this.tabCriteria){
        params.searchCriteria.push({
          "key": 'ENABLED',
          "operation" : "EQUALITY",
          "value" : this.tabCriteria === 'ENABLED' ? true : false,
          "orPredicate" : false
        })
      }

      this.restService.postService('room_type','/v2/search',params).subscribe((ris =>{
      this.rooms = ris
      console.debug(ris)
    }))
    }

  }

  loadPage(event:any){
    this.page = event
    this.getRooms()
   }

  editRoom(idRoom: number){
    this.restService.getService('room_type','/v2/'+idRoom).subscribe((ris)=>{
      this.roomSelected = ris
      this.newRoom = false
      this.showDetail = true;
    })
   }

   newRoomEnable(){
   this.newRoom = true;
   this.roomSelected = {};
   this.showDetail = true;
   }

   enableRoom(room:any){
    let editRoom = {
      title: room.title,
      name: room.name,
      idParentRoomType: room.idParentRoomType && room.idParentRoomType!="" ? room.idParentRoomType : null,
      baseBeds: room.baseBeds,
      defaultAvailability: room.defaultAvailability,
      defaultPrice: parseFloat(room.defaultPrice),
      description: room.description,
      enabled: "true"
  }
    this.restService.putService('roomtypes','/'+this.structureService.selectedStructure.id+'/'+room.id, editRoom).subscribe((ris =>{
      this.guiService.editedSuccesfully()
      this.guiService.refreshList.next(true);
    }))
   }

   disableRoom(room:any){

    Swal.fire({
      title: 'Sei sicuro?',
      text: "Vuoi disabilitare questo elemento?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, disabilita!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        let editRoom = {
          title: room.title,
          name: room.name,
          idParentRoomType: room.idParentRoomType && room.idParentRoomType!="" ? room.idParentRoomType : null,
          baseBeds: room.baseBeds,
          defaultAvailability: room.defaultAvailability,
          defaultPrice: parseFloat(room.defaultPrice),
          description: room.description,
          enabled: "false"
      }
        this.restService.putService('roomtypes','/'+this.structureService.selectedStructure.id+'/'+room.id, editRoom).subscribe((ris =>{
          this.guiService.editedSuccesfully()
          this.guiService.refreshList.next(true);
        }));
      }
    })
   }

   activeTab(tabNum:any){
    if(tabNum === 1){
      this.tabCriteria = undefined;
    }if(tabNum === 2){
      this.tabCriteria = 'ENABLED';
    }else if(tabNum === 3){
      this.tabCriteria = 'DISABLED';
    }
    this.getRooms()
   }
}
