import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  user: any;
  structures: any = [];
  page = 0;
  strCount: any = 0;

  newPassword: string = "";
  isVisibileNewPassword: boolean = false;
  confirmPassword: string = "";
  isVisibileConfirmPassword: boolean = false;

  no_valid: boolean = false;

  langs: Array<any> = [
    { label: "Italiano", value: "it" },
    { label: "Inglese", value: "en" },
    { label: "Francese", value: "fr" },
    { label: "Spagnolo", value: "it" },
  ]

  constructor(public guiService: GuiService, private restService: RestService){

  }

  ngOnInit(): void {
    this.getUserInfo();

  }

  getUserInfo(){
    this.restService.getService('users','/current').subscribe((ris)=>{
      console.debug(ris);
      this.user = ris;
      console.debug("USER", this.user);
      this.getAssignedStructures();
    });
  }

  getAssignedStructures(){
    
    this.structures = [];
  this.guiService.loaded = false;

  let param: any = {
    page: this.page,
    size: 10,
    searchCriteria: {
      "usernameWithPermissions": this.user.username
    }
  }

  console.debug("PAGE",param);

  this.restService.postService('structure','v4/search',param).subscribe({
    next: (ris) => {
      this.structures = ris.items;
      this.strCount = ris.totalItems;
      this.guiService.loaded = true;
    },
    error:  () => {
      this.structures = [];
      this.guiService.loaded = true;
    }
  });
 }

  saveInfos(){
    let param = {    
      "firstName" : this.user.firstName,
      "lastName" : this.user.lastName,
      "email" : this.user.email,
      "language" : this.user.language,
      "mainProfileName": this.user.mainProfileName
    };

    this.restService.putService('users','/'+this.user.id,param).subscribe({
      next: (ris)=>{
        this.guiService.editedSuccesfully();
      },
      error: (err)=>{
        console.debug("ERR", err);
      }
    });
  }

  changePsw() {

    if(this.newPassword == this.confirmPassword) {

      let param: any = {
        "newPassword" : this.newPassword
      };

      this.restService.postService('users','/'+this.user.id+'/change-password',param).subscribe((ris)=>{
        console.debug("UPDATE", ris);
        this.guiService.editedSuccesfully();
      });
    } else {

    }
  }

  onScroll() {
    this.page = this.page+1
    this.restService.getService('structure',`/list?draw=0&from=${this.page}&size=5`).subscribe((ris)=>{
      this.structures.push(...ris.data);
    })
  }
}
