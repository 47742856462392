import { Component } from '@angular/core';

@Component({
  selector: 'app-passpartout',
  templateUrl: './passpartout.component.html',
  styleUrls: ['./passpartout.component.scss']
})
export class PasspartoutComponent {

}
