<div class="col-xxl-12 col-xl-12">
  <div class="row" [class.fixed-header-page]="guiService.scrollY">
    <div class="col-8">
      <app-top-calendar *ngIf="reloadComponent"></app-top-calendar>
    </div>
    <div class="col-4">
      <app-right-calendar *ngIf="reloadComponent" [monthlyIndicator]="calService.monthlyIndicator"></app-right-calendar>
    </div>
  </div>
  <div class="mt-4 main-body">
    <app-core-calendar *ngIf="reloadComponent" (reloadCalendarInCoreComponent)="reloadCalendarInCalendarComponent()"></app-core-calendar>
  </div><!-- end card -->
</div>
