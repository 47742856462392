import {StructureService} from 'src/app/core/services/structure.service';
import {GuiService} from '../../../../core/services/gui.service';
import {Component, Input, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {RestService} from 'src/app/core/services/rest.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent {

  @Input() user: any;
  clearPassword: string = "";
  showBtnNew: boolean = true;
  assignPostCreation: boolean = false;
  roles: any = []
  userStructure = {
    role: '', owner: ''
  }
  roleSelected: any;
  structureList: any;
  structureAssigned: Array<any> = [];
  structureToAssign: any;

  langs: Array<any> = [
    { label: "Italiano", value: "it" },
    { label: "Inglese", value: "en" },
    { label: "Francese", value: "fr" },
    { label: "Spagnolo", value: "it" },
  ]

  constructor(private fb: UntypedFormBuilder, public structureService: StructureService, public guiService: GuiService, private restService: RestService, public translate: TranslateService) {

  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initForm();
    this.getRoles();
    if(this.user.id && this.user.id != null) {
      this.showBtnNew = false;
      this.getAssignedStructures();
      console.debug(this.user)
    }
  }

  getRoles() {
    this.restService.getService('users', '/profiles').subscribe((ris => {
      this.roles = [];
      this.roles = ris;
      console.debug("ROLES",this.roles);
    }))
  }

  initForm() {
    if(this.user==null) {
      this.user = {    
        "firstName" : "",
        "lastName" : "",
        "email" : "",
        "username" : "",
        "language" : "",
        "mainProfileName" :""
      };
    } else {
      this.roleSelected = this.user.mainProfileName;
    }
  }

  populateForm() {
  }

  submitNew() {
    console.debug(this.user);
    this.user.mainProfileName = this.roleSelected;

    this.restService.postService('users', '', this.user).subscribe({
      next: (ris) => {
        this.guiService.createdSuccessfully()
        this.assignPostCreation = true
        this.guiService.refreshList.next(true);
        this.close()
      },
      error: (err) => {
        console.debug("ERR");
      }
    });
    
  }

  assignStructure(){
    this.restService.putService('structure','/set_owner/'+this.structureToAssign.id+'?ownerId='+this.user.account.id+"&profileId="+this.roleSelected,null).subscribe((ris =>{
      this.guiService.editedSuccesfully();
      this.getAssignedStructures();
    }))
  }

  removeOwnerStructure(strID: any) {

    this.guiService.deleteConfirm('structure','/owner',strID+'?ownerId='+this.user.account.username);
  
  }

  submitEdit() {
    let param = {    
      "firstName" : this.user.firstName,
      "lastName" : this.user.lastName,
      "email" : this.user.email,
      "language" : this.user.language,
      "mainProfileName" : this.roleSelected
  };

    this.restService.putService('users', '/'+this.user.id, param).subscribe((ris => {
      this.guiService.editedSuccesfully()
      this.guiService.refreshList.next(true);
    }))
  }

  close() {
    this.guiService.closeDetail.next(true);
  }


  selectStructure(selection: any) {
    console.debug(selection)
    if (selection) {
      this.structureToAssign = selection;
    }
  }

  search(event: any) {
    if(event.term.length > 2) {
      this.restService.getService('structure', '/likeList/?query=' + event.term).subscribe(ris => {
        this.structureList = ris;
      });
    }
  }

  getAssignedStructures(){

    let param: any = {
      page: 0,
      size: 100,
      searchCriteria: {
        usernameWithPermissions: this.user.username
      }
    }

    this.restService.postService('structure','v4/search',param).subscribe((ris)=>{
      this.structureAssigned = ris && ris.items && ris.items != null ? ris.items : [];
    });

  }

  resetUserPSW() {

    Swal.fire({
      title: this.translate.instant("ALERTS.warning"),
      text: this.translate.instant("ALERTS.reset_psw_confirm"),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '"#3085d6',
      cancelButtonColor: '"#d33',
      cancelButtonText: this.translate.instant("GENERIC.reset_btn"),
      confirmButtonText: this.translate.instant("AUTH.RESET_SEND")
    }).then((result) => {
      if (result.isConfirmed) {
        this.restService.postService('users', '/'+this.user.id+'/temp-password', {}).subscribe({
          next: (ris) => {
            this.guiService.createdSuccessfully();
            this.assignPostCreation = true;
            this.guiService.refreshList.next(true);
            this.close();
          },
          error: (err) => {
            console.debug("ERR",err);
          }
        });

      }
    })
  }

}
