<div class="row">

  <div class="col-8">

    <!-- YEAR PICKUP -->
    <div class="card card-w">
      <div class="card-header">
        <h3 class="d-inline">{{ "REPORT.year_pickup" | translate }}</h3>
        <input type="month" class="form-control form-control-sm year-select-field" [(ngModel)]="reportDateSelected" (change)="selectYearChange()" />
      </div>
      <div class="card-body chart-area-body">

        <div id="chart" *ngIf="monthsPickuploaded">
          <apx-chart
            [series]="chartOptions1.series"
            [chart]="chartOptions1.chart"
            [dataLabels]="chartOptions1.dataLabels"
            [plotOptions]="chartOptions1.plotOptions"
            [yaxis]="chartOptions1.yaxis"
            [xaxis]="chartOptions1.xaxis"
            [colors]="chartOptions1.colors"
          ></apx-chart>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="card card-w col-6">
        <div class="card-header">
          <h3>{{ "REPORT.month_indicator" | translate }}: {{"MONTHS.LONG."+selectedMonthIndex | translate}}</h3>
        </div>
        <div class="card-body">
          <app-right-calendar [monthlyIndicator]="calService.monthlyIndicator"></app-right-calendar>
        </div>
      </div>




      <!-- LOGS PICKUP -->
      <div class="card card-w col-6">
        <div class="card-header">
          <h3>{{ "FX.advancedhistory" | translate }}</h3>
        </div>
        <div class="card-body">
          <ul class="log-list">
            <li *ngFor="let log of action_logs">
              <span class="log-date">{{log.refDate | date: "dd-MM-YYYY HH:mm:ss z"}}</span> - <span class="log-action">{{'LOG.'+log.action | translate}}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>


  </div>



  <div class="col-4">

    <div class="card card-w">
      <div class="card-header">
        <h3>{{ "REPORT.month_pickup" | translate }}: {{"MONTHS.LONG."+selectedMonthIndex | translate}}</h3>
      </div>
      <div class="card-body">
        <ul class="list-group-flush row days-pickup">
          <li class="list-group-item" *ngFor="let day of pickups">
            <div class="day-data-left">
              <div class="flex-shrink-0 me-3">
                <div class="day-of-week">{{day.DoW}}</div>
                <div class="day-data">{{day.dt}}</div>
              </div>
              <div class="day-data-absolute">
                <div class="badge ms-2" [ngClass]="{'badge-success-subtle': day.pk>0, 'badge-danger-subtle': day.pk<0}" *ngIf="day.pk!=0"> {{day.pk}}
                  <i class="uil uil-arrow-up-right text-success ms-1" *ngIf="day.pk>0"></i>
                  <i class="uil uil-arrow-down-right text-danger ms-1" *ngIf="day.pk<0"></i>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

  </div>

</div>

