<div class="row align-items-start">
    <div class="col-12 box-main-form">
      <fieldset>
        <div *ngIf="structureService.selectedStructureConfig && structureService.selectedStructureConfig.setup_phase">
  
          <div class="col-12">
            <label class="config-item">
              <h3 class="d-inline-block">{{ "CFG.CMX.ZAK_CM_API_KEY" | translate }}</h3>
              <input class="form-control input-camere" type="text" [(ngModel)]="structureService.selectedStructureConfig.SCIDOO_API_KEY.value" />
              <small ngbTooltip="{{helptxt}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
                <i-feather name="help-circle"></i-feather>
              </small>
            </label>
          </div>
  
        </div>
      </fieldset>
    </div>
  
  </div>
  