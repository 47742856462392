import { Component } from '@angular/core';

@Component({
  selector: 'app-costpanel',
  templateUrl: './costpanel.component.html',
  styleUrls: ['./costpanel.component.scss']
})
export class CostpanelComponent {
  loaded: boolean = true;

}
