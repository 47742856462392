<div class="col-xxl-12 col-xl-12">
  <div class="card card-h-100" *ngIf="counters">
    <div class="card-header justify-content-between d-flex align-items-center">
     <div class="col-sm-8 col-md-8">
      <div class="row">
        <div class="form-floating col-3">
          <ng-select [clearable]="true" placeholder="Struttura" bindLabel="name" [items]="structures" [(ngModel)]="structureCtrl" class="p-0"
            (search)="getStructure($event)" [ngModelOptions]="{standalone: true}">
          </ng-select>
        </div>
        <div class="form-floating col-3">
          <ng-select [clearable]="false" placeholder="Anno" [(ngModel)]="yearFromCtrl" id="y_from">
            <ng-option *ngFor="let item of years" [value]="item">{{item}}</ng-option>
          </ng-select>
        </div>
        <div class="form-floating col-3">
          <ng-select [clearable]="false" placeholder="Anno Confronto" [(ngModel)]="yearToCtrl" id="y_to">
            <ng-option *ngFor="let item of years" [value]="item">{{item}}</ng-option>
          </ng-select>
        </div>
        <span class="icona cerca col-1">
          <i class="uil-search" (click)="getCounters()"></i>
        </span>
      </div>
    </div>
    <div class="col-sm-4 col-md-4">
      <div class="d-flex align-items-start justify-content-end top-icons">
        <button class="btn btn-primary" (click)="newCounterEnable()">
          <i class="uil-plus"></i>
        </button>
      </div>
    </div>

    </div><!-- end card header -->
    <div class="card-body row">

      <div [ngClass]="showDetail ? 'col-sm-2 col-md-9' : 'col-sm-12 col-md-12'" >

        <!-- START TABLE VIEW -->
        <div class="table-responsive" *ngIf="guiService.viewType == 'TABLE'">
          <table class="table table-sm table-hover mb-0">
            <thead>
            <tr>
              <th class="text-center">#</th>
              <th>{{"MONTHS.SHORT.1" | translate}}</th>
              <th>{{"MONTHS.SHORT.2" | translate}}</th>
              <th>{{"MONTHS.SHORT.3" | translate}}</th>
              <th>{{"MONTHS.SHORT.4" | translate}}</th>
              <th>{{"MONTHS.SHORT.5" | translate}}</th>
              <th>{{"MONTHS.SHORT.6" | translate}}</th>
              <th>{{"MONTHS.SHORT.7" | translate}}</th>
              <th>{{"MONTHS.SHORT.8" | translate}}</th>
              <th>{{"MONTHS.SHORT.9" | translate}}</th>
              <th>{{"MONTHS.SHORT.10" | translate}}</th>
              <th>{{"MONTHS.SHORT.11" | translate}}</th>
              <th>{{"MONTHS.SHORT.12" | translate}}</th>
              <th>Totali</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let counter of counters.data">
              <th scope="row">{{counter.portal}}</th>
              <td (click)="counterDetail(counter,'jan')">{{counter.data.jan}}</td>
              <td (click)="counterDetail(counter,'feb')">{{counter.data.feb}}</td>
              <td (click)="counterDetail(counter,'mar')">{{counter.data.mar}}</td>
              <td (click)="counterDetail(counter,'apr')">{{counter.data.apr}}</td>
              <td (click)="counterDetail(counter,'may')">{{counter.data.may}}</td>
              <td (click)="counterDetail(counter,'jun')">{{counter.data.jun}}</td>
              <td (click)="counterDetail(counter,'jul')">{{counter.data.jul}}</td>
              <td (click)="counterDetail(counter,'aug')">{{counter.data.aug}}</td>
              <td (click)="counterDetail(counter,'sep')">{{counter.data.sep}}</td>
              <td (click)="counterDetail(counter,'oct')">{{counter.data.oct}}</td>
              <td (click)="counterDetail(counter,'nov')">{{counter.data.nov}}</td>
              <td (click)="counterDetail(counter,'dec')">{{counter.data.dec}}</td>
              <td >{{counter.total}}</td>
            </tr>
            <tr *ngIf="sums[yearFromCtrl] && yearFromCtrl">
              <th scope="row">{{sums[yearFromCtrl].year}}</th>
              <td>{{sums[yearFromCtrl].values.jan}}</td>
              <td>{{sums[yearFromCtrl].values.feb}}</td>
              <td>{{sums[yearFromCtrl].values.mar}}</td>
              <td>{{sums[yearFromCtrl].values.apr}}</td>
              <td>{{sums[yearFromCtrl].values.may}}</td>
              <td>{{sums[yearFromCtrl].values.jun}}</td>
              <td>{{sums[yearFromCtrl].values.jul}}</td>
              <td>{{sums[yearFromCtrl].values.aug}}</td>
              <td>{{sums[yearFromCtrl].values.sep}}</td>
              <td>{{sums[yearFromCtrl].values.oct}}</td>
              <td>{{sums[yearFromCtrl].values.nov}}</td>
              <td>{{sums[yearFromCtrl].values.dec}}</td>
              <td >{{sums[yearFromCtrl].total}}</td>
            </tr>

            <tr *ngIf="sums[yearFromCtrl] && sums[yearToCtrl] && yearToCtrl && yearFromCtrl">
              <th scope="row">-</th>
              <td>{{sums[yearFromCtrl].values.jan - sums[yearToCtrl].values.jan}}</td>
              <td>{{sums[yearFromCtrl].values.feb - sums[yearToCtrl].values.feb}}</td>
              <td>{{sums[yearFromCtrl].values.mar - sums[yearToCtrl].values.mar}}</td>
              <td>{{sums[yearFromCtrl].values.apr - sums[yearToCtrl].values.apr}}</td>
              <td>{{sums[yearFromCtrl].values.may - sums[yearToCtrl].values.may}}</td>
              <td>{{sums[yearFromCtrl].values.jun - sums[yearToCtrl].values.jun}}</td>
              <td>{{sums[yearFromCtrl].values.jul - sums[yearToCtrl].values.jul}}</td>
              <td>{{sums[yearFromCtrl].values.aug - sums[yearToCtrl].values.aug}}</td>
              <td>{{sums[yearFromCtrl].values.sep - sums[yearToCtrl].values.sep}}</td>
              <td>{{sums[yearFromCtrl].values.oct - sums[yearToCtrl].values.oct}}</td>
              <td>{{sums[yearFromCtrl].values.nov - sums[yearToCtrl].values.nov}}</td>
              <td>{{sums[yearFromCtrl].values.dec - sums[yearToCtrl].values.dec}}</td>
              <td >{{sums[yearFromCtrl].total - sums[yearToCtrl].total}}</td>
            </tr>

            <tr *ngIf="sums[yearToCtrl] && yearToCtrl">
              <th scope="row">{{sums[yearToCtrl].year}}</th>
              <td>{{sums[yearToCtrl].values.jan}}</td>
              <td>{{sums[yearToCtrl].values.feb}}</td>
              <td>{{sums[yearToCtrl].values.mar}}</td>
              <td>{{sums[yearToCtrl].values.apr}}</td>
              <td>{{sums[yearToCtrl].values.may}}</td>
              <td>{{sums[yearToCtrl].values.jun}}</td>
              <td>{{sums[yearToCtrl].values.jul}}</td>
              <td>{{sums[yearToCtrl].values.aug}}</td>
              <td>{{sums[yearToCtrl].values.sep}}</td>
              <td>{{sums[yearToCtrl].values.oct}}</td>
              <td>{{sums[yearToCtrl].values.nov}}</td>
              <td>{{sums[yearToCtrl].values.dec}}</td>
              <td >{{sums[yearToCtrl].total}}</td>
            </tr>
            </tbody><!-- end tbody -->
          </table><!-- end table -->
        </div><!-- end table responsive -->

        <div id="chart" *ngIf="counters">
          <apx-chart *ngIf="chartOptions"
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [dataLabels]="chartOptions.dataLabels"
            [plotOptions]="chartOptions.plotOptions"
            [yaxis]="chartOptions.yaxis"
            [legend]="chartOptions.legend"
            [fill]="chartOptions.fill"
            [stroke]="chartOptions.stroke"
            [tooltip]="chartOptions.tooltip"
            [xaxis]="chartOptions.xaxis"
            [colors]="chartOptions.colors"
          ></apx-chart>
        </div>
      </div>

      <div class="col-sm-10 col-md-3" *ngIf="showDetail">
        <app-conteggi-detail [conteggio]="counterSelected" *ngIf="newCounter == false"></app-conteggi-detail>
        <app-conteggi-detail [conteggio]="counterSelected" *ngIf="newCounter"></app-conteggi-detail>
      </div>
    </div><!-- end card body -->

  </div><!-- end card -->
</div>
