<div class="main-container">
    <div class="head">
      <img src="assets/images/Logotipo-negativo.svg"/>
    </div>
    <div class="bg-over">
      <div class="background">
        <div class="back_to_login"> 
            <a [routerLink]="['/auth/login']" routerLinkActive="router-link-active" >
                <i class="uil-arrow-left"></i>
                {{"AUTH.BACK_TO_LOGIN" | translate}}
            </a>
        </div>
        <div class="out-box">
          <div class="box">
            <form>
              <div class="p-3">
                <div class="mb-3">
                  <label for="floatingInput">{{ "AUTH.OTP_LABEL" | translate }}</label>
                  
                    <div class="otp-input-field">
                        <input type="number" maxlength="1" id="otp-0" class="form-control" [disabled]="codeStep < 0" [(ngModel)]="otpCode[0]" name="otp-1" (keypress)="autoFocus(0)" (paste)="pasteCode($event)" />
                        <input type="number" maxlength="1" id="otp-1" class="form-control" [disabled]="otpCode[0]==null" [(ngModel)]="otpCode[1]" name="otp-2" (keypress)="autoFocus(1)" />
                        <input type="number" maxlength="1" id="otp-2" class="form-control" [disabled]="otpCode[1]==null" [(ngModel)]="otpCode[2]" name="otp-3" (keypress)="autoFocus(2)" />
                        <input type="number" maxlength="1" id="otp-3" class="form-control" [disabled]="otpCode[2]==null" [(ngModel)]="otpCode[3]" name="otp-4" (keypress)="autoFocus(3)" />
                        <input type="number" maxlength="1" id="otp-4" class="form-control" [disabled]="otpCode[3]==null" [(ngModel)]="otpCode[4]" name="otp-5" (keypress)="autoFocus(4)" />
                        <input type="number" maxlength="1" id="otp-5" class="form-control" [disabled]="otpCode[4]==null" [(ngModel)]="otpCode[5]" name="otp-6" (keypress)="autoFocus(5)" />
                    </div>
                </div>
                
                <button class="w-100 btn"(click)="validateOTP()" *ngIf="!isLoading">{{ "AUTH.VERIFY" | translate }}</button>
                <div class="loading-bars-box" *ngIf="isLoading">
                  <div class="loading-bars"></div>
                </div>
                <h3>{{ "AUTH.OTP_NOT_RECEIVED" | translate }} <a (click)="sendNewOTP()">{{ "AUTH.SEND_NEW_OTP" | translate }}</a></h3>
              </div>
            </form>
          </div>
        </div>
  
        <div class="footer-box">
          <a>Privacy Policy</a>
        </div>
      </div>
  
    </div>
  </div>
  
  <div class="tablet">
    <div class="contenitore">
      <div class="img-zone">
        <div class="head">
          <img src="assets/images/Logotipo-negativo.svg"/>
        </div>
      </div>
      <div class="login-zone">
        <form>
            <div class="p-3">
              <div class="mb-3">
                <label for="floatingInput">{{ "AUTH.OTP_LABEL" | translate }}</label>
                
                  <div class="inputfield">
                      <input type="number" maxlength="1" class="form-control" disabled name="otp-1" />
                      <input type="number" maxlength="1" class="form-control" disabled name="otp-2" />
                      <input type="number" maxlength="1" class="form-control" disabled name="otp-3" />
                      <input type="number" maxlength="1" class="form-control" disabled name="otp-4" />
                      <input type="number" maxlength="1" class="form-control" disabled name="otp-5" />
                      <input type="number" maxlength="1" class="form-control" disabled name="otp-6" />
                  </div>
              </div>
              
              <button class="w-100 btn"(click)="validateOTP()" *ngIf="!isLoading">Log in</button>
              <div class="loading-bars-box" *ngIf="isLoading">
                <div class="loading-bars"></div>
              </div>
              <h3>{{ "AUTH.OTP_NOT_RECEIVED" | translate }}<a (click)="sendNewOTP()">{{ "AUTH.SEND_NEW_OTP" | translate }}</a></h3>
            </div>
          </form>
        <div class="footer-zone">
          <a>Privacy Policy</a>
        </div>
      </div>
    </div>
  </div>
  
  <div class="mobile">
    <div class="head">
      <img src="assets/images/Simbolo-negativo.svg"/>
    </div>
      <div class="login-zone">
        <form>
            <div class="p-3">
              <div class="mb-3">
                <label for="floatingInput">{{ "AUTH.OTP_LABEL" | translate }}</label>
                
                  <div class="inputfield">
                      <input type="number" maxlength="1" class="form-control" disabled name="otp-1" />
                      <input type="number" maxlength="1" class="form-control" disabled name="otp-2" />
                      <input type="number" maxlength="1" class="form-control" disabled name="otp-3" />
                      <input type="number" maxlength="1" class="form-control" disabled name="otp-4" />
                      <input type="number" maxlength="1" class="form-control" disabled name="otp-5" />
                      <input type="number" maxlength="1" class="form-control" disabled name="otp-6" />
                  </div>
              </div>
              
              <button class="w-100 btn"(click)="validateOTP()" *ngIf="!isLoading">Log in</button>
              <div class="loading-bars-box" *ngIf="isLoading">
                <div class="loading-bars"></div>
              </div>
              <h3>{{ "AUTH.OTP_NOT_RECEIVED" | translate }}<a (click)="sendNewOTP()">{{ "AUTH.SEND_NEW_OTP" | translate }}</a></h3>
            </div>
          </form>
      </div>
  </div>
  