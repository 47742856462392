import { Component } from '@angular/core';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';

@Component({
  selector: 'app-fornitori',
  templateUrl: './fornitori.component.html',
  styleUrls: ['./fornitori.component.scss']
})
export class FornitoriComponent {
  socialCtrl: any;
  ivaCtrl: any;
  nationCtrl: any;
  loaded: boolean = false;
  suppliers: any = [];

  supplierSelected: any = null;
  newSupplier: boolean = false;
  page = 0
  size = 10;
  showDetail: boolean = false;

  constructor(private restService: RestService, public guiService: GuiService){
    this.guiService.closeDetail.subscribe(value => {
      if(value == true){
        this.supplierSelected = null;
        this.showDetail = false;
      }
    });

    this.guiService.refreshList.subscribe( value => {
      if(value == true){
        this.page = 0
        this.getSuppliers()
        this.showDetail = false
      }
     });
  }

  ngOnInit(): void {
    this.getSuppliers();
  }

  loadPage(event:any){
    this.page = event
    console.debug(event)
    this.getSuppliers()
  }


  getSuppliers(){
    let tmpJson: any = {
      "draw":this.page,
      "start": this.page*10,
      "length": 10,
      "order[0][column]": "0",
      "order[0][dir]":"asc",
      "ragioneSociale": this.socialCtrl ? this.socialCtrl : '',
      "piva": this.ivaCtrl ? this.ivaCtrl : '',
      "country": this.nationCtrl ? this.nationCtrl : ''
    };

    let json = new URLSearchParams(tmpJson).toString();
    this.loaded = false;
    this.restService.postService('crm_base_new','/inv_supplier/list?'+json,{}).subscribe((ris)=>{
      console.debug(ris)
      this.suppliers = [];
      this.suppliers = ris;
      this.loaded = true;
    });
  }

  supplierDetail(id: any){
    this.restService.getService('crm_base_new','/inv_supplier/'+id).subscribe((ris)=>{
      this.supplierSelected = ris.model
      this.newSupplier = false;
      this.showDetail = true;
    })
  }

  newSupplierEnable() {
    this.newSupplier = true;
    this.supplierSelected = {};
    this.showDetail = true;
  }

}
