import { Component, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as math from "mathjs";
import * as moment from 'moment';
import { from } from 'rxjs';
import { CalendarService } from 'src/app/core/services/calendar.service';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';
import { StructureService } from 'src/app/core/services/structure.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-report-general',
  templateUrl: './report-general.component.html',
  styleUrls: ['./report-general.component.scss']
})
export class ReportGeneralComponent {
  defaultDate: any = null;
  date: any = [];
  sumType: any = '1';
  sumTypes: any = [{
    label: "Daily",
    value: "1"
  },{
    label: "Monthly",
    value: "2"
  }];
  loaded: boolean = false;
  filterDays: any = null;
  switches: any = null;

  locale: any = {
    weekdays: {
      shorthand: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
      longhand: [
        "Domenica",
        "Lunedì",
        "Martedì",
        "Mercoledì",
        "Giovedì",
        "Venerdì",
        "Sabato",
      ],
    },
    months: {
      shorthand: [
        "Gen",
        "Feb",
        "Mar",
        "Apr",
        "Mag",
        "Giu",
        "Lug",
        "Ago",
        "Set",
        "Ott",
        "Nov",
        "Dic",
      ],
      longhand: [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre",
      ],
    },
    firstDayOfWeek: 1,
    ordinal: () => "°",
    rangeSeparator: " al ",
    weekAbbreviation: "Se",
    scrollTitle: "Scrolla per aumentare",
    toggleTitle: "Clicca per cambiare",
    time_24hr: true,
    defaultDate: [moment() , moment().add(30,"days")]
  };
  dateformat: any = "d-m-Y";
  days_data: Array<any> = [];
  channels: any
  rooms: any
  showTable: boolean = false;
  capacity: number = this.structureService.selectedStructure.rooms;

  headerWidth: any;
  scrollX: boolean = false;
  scrollY: boolean = false;
  listener: any;
  tmpResult: any = []
  year: any = {
    static: null,
    dynamic: null
  };
  selectyears: any = [];

  reportTyp: any;
  selectedTyp: any = "DAILY";

  rateResult: any = {};
  loadingCalandarData: boolean = true;

  constructor(public structureService: StructureService,private restService: RestService,private router: Router, private renderer2: Renderer2,
    public guiService: GuiService, public translate: TranslateService, public calService: CalendarService){
    this.listener = this.renderer2.listen('window', 'scroll', (e) => {
      // console.debug("SCROLLTOP",this.getScrollingElement().scrollTop);
      // console.debug("HEADER WIDTH",this.headerWidth);
      this.scrollY = this.guiService.getScrollingElement().scrollTop > 180 ? true : false;
    });
    this.listener = this.renderer2.listen('window', 'resize', (e) => {
      // console.debug("SCROLLTOP",this.getScrollingElement().scrollTop);
      this.headerWidth = document.getElementById("colslimit")!.offsetWidth;
      console.debug("HEADER WIDTH",this.headerWidth);
    });

    this.structureService.isStructureChanged.subscribe( value => {
      if(value == true){
        this.ngOnInit()
      }
  });
  }


  ngOnDestroy(): void {

    this.reportTyp = [{
      label: this.translate.instant("GENERIC.daily"),
      value: "DAILY"
    },{
      label: this.translate.instant("GENERIC.monthly"),
      value: "MONTHLY"
    }];
    this.selectedTyp = "DAILY";

    console.debug("REPORT TYPE",this.reportTyp)
    this.listener();
  }
  onXScroll(idx:any) {
    let scrollL = document.getElementById(idx)!.scrollLeft;
    let elements =  document.getElementsByClassName("element-h-scroll");
    if(elements.length>0) {
      for(let k in elements) {
        elements[k] ? elements[k].scrollLeft = scrollL : null;
      }
    }
  }

  ngOnInit(): void {
    this.structureService.selectedStructure ? null : Swal.fire({
      title: 'Nessuna struttura selezionata',
      icon: 'warning'
    }).then((result)=>{
      if(result.isConfirmed){
        this.router.navigate(['admin/dashboard']);
      }
    });
    this.populateSelectYear();
    this.getRooms();
    this.getChannels();

    this.defaultDate = [moment().subtract(1,"day").toDate(),moment().add(29,"days").toDate()];
    this.switches = [{
      label: this.translate.instant("DAYS.LONG.0"),
      value: true
    },{
      label: this.translate.instant("DAYS.LONG.1"),
      value: true
    },{
      label: this.translate.instant("DAYS.LONG.2"),
      value: true
    },{
      label: this.translate.instant("DAYS.LONG.3"),
      value: true
    },{
      label: this.translate.instant("DAYS.LONG.4"),
      value: true
    },{
      label: this.translate.instant("DAYS.LONG.5"),
      value: true
    },{
      label: this.translate.instant("DAYS.LONG.6"),
      value: true
    }];

    this.filterDays = this.switches;

  }

  preloadData() {

    typeof this.defaultDate != "string" ? this.defaultDate = moment(this.defaultDate[0]).format("DD-MM-YYYY")+this.locale.rangeSeparator+moment(this.defaultDate[1]).format("DD-MM-YYYY") : null;

    let dtRange = this.defaultDate.split(this.locale.rangeSeparator);
    this.date.from = moment(dtRange[0],"DD-MM-YYYY");
    this.date.to = moment(dtRange[1],"DD-MM-YYYY");


    this.days_data = [];
    let counter = this.date.to.diff(this.date.from,"days");

    !this.year.dynamic ? this.year.dynamic = moment(this.date.from).subtract(1,"year").format("YYYY") : null;
    !this.year.static ? this.year.static = moment(this.date.from).subtract(1,"year").format("YYYY") : null;

    // Calcolo la data di partenza per lo storico dinamico
    let dynDiffYear = parseInt(this.date.from.format("YYYY"))-parseInt(this.year.dynamic);
    let dynStart = moment(this.date.from).subtract(dynDiffYear,'year');
    while(dynStart.day()!=this.date.from.day()) {
      dynStart = dynStart.add(1,"day");
    }

    // Calcolo la data di partenza per lo storico statico (consuntivo)
    let staDiffYear = parseInt(this.date.from.format("YYYY"))-parseInt(this.year.static);
    let staStart = moment(this.date.from).subtract(staDiffYear,'year');
    while(staStart.day()!=this.date.from.day()) {
      staStart = staStart.add(1,"day");
    }

    for(let i=0;i<=counter;i++) {
      let dt = moment(this.date.from).add(i,"days");
      console.debug("DAY",dt.format("YYYY-MM-DD"));
      let tmp = moment(this.date.from).add(i,'d');
      let dyntmp = moment(dynStart).add(i,'d');
      let statmp = moment(staStart).add(i,'d');
      if(this.filterDays[dt.day()].value) {
        this.days_data.push(
          {
            d : tmp.format('DD'),
            m : tmp.format('MM'),
            y : tmp.format('YYYY'),
            DoW : tmp.day(),
            day: tmp.format('YYYY')+'-'+tmp.format('MM')+'-'+tmp.format('DD'),
            dynday: dyntmp.format('DD-MM-YYYY'),
            dynDoW: dyntmp.day(),
            staday: statmp.format('DD-MM-YYYY'),
            staDoW: statmp.day()
          }
        )
      }
    }
    console.debug("FILTER PERIOD",this.days_data);

    this.loadCalendarData();

  }

  exportFile() {

    typeof this.defaultDate != "string" ? this.defaultDate = moment(this.defaultDate[0]).format("DD-MM-YYYY")+this.locale.rangeSeparator+moment(this.defaultDate[1]).format("DD-MM-YYYY") : null;

    let dtRange = this.defaultDate.split(this.locale.rangeSeparator);
    this.date.from = moment(dtRange[0],"DD-MM-YYYY");
    this.date.to = moment(dtRange[1],"DD-MM-YYYY");

    !this.year.dynamic ? this.year.dynamic = moment(this.date.from).subtract(1,"year").format("YYYY") : null;
    !this.year.static ? this.year.static = moment(this.date.from).subtract(1,"year").format("YYYY") : null;

    if(this.selectedTyp=="MONTHLY") {
      this.restService.getExportFile("report","v4/"+this.structureService.selectedStructure.id+"/general-by-months?from="+this.date.from.format("YYYY-MM")+"&months=2&statMatchYear="+this.year.static+"&weekDays=1_2_3_4_5_6_7");
    } else {
      this.restService.getExportFile("report","v4/"+this.structureService.selectedStructure.id+"/general-by-days?from="+this.date.from.format("YYYY-MM")+"&months=2&statMatchYear="+this.year.static+"&weekDays=1_2_3_4_5_6_7");
    }
    }

  loadCalendarData() {
    this.loadingCalandarData = true;
    this.calService.calendarDataDays = null;

    let from = this.date.from.format("YYYY-MM-DD");
    let to = this.date.to.format("YYYY-MM-DD");

    this.calService.getCalendarData(from,to,this.year.static,this.year.dynamic,true).subscribe((ris)=> {
      this.rateResult = {};

      console.debug("load Calendar Data");
      for(let day in ris ) {
        let item = ris[day].occupancies.WHOLE_OCCUPANCY;

        // CURRENT
        if(item && item.current!=null) {
          let tmp_cap = ris[day].otherinfos.CAPACITY.current != null ? ris[day].otherinfos.CAPACITY.current.value : this.capacity;
          item.current.inv = item.current.occupancy>0 ? tmp_cap-item.current.occupancy : tmp_cap;
          item.current.rmc =  item.current.revenue / item.current.occupancy;
        }

        // DYN HISTORY
        if(item && item.dynHistoric!=null) {
          let tmp_cap = ris[day].otherinfos.CAPACITY.dynHistoric != null ? ris[day].otherinfos.CAPACITY.dynHistoric.value : this.capacity;
          item.dynHistoric.inv = item.dynHistoric.occupancy>0 ? tmp_cap-item.dynHistoric.occupancy : tmp_cap;
          item.dynHistoric.rmc =  item.dynHistoric.revenue / item.dynHistoric.occupancy;
        }

        // STA HISTORY
        if(item && item.statHistoric!=null) {
          let tmp_cap = ris[day].otherinfos.CAPACITY.statHistoric != null ? ris[day].otherinfos.CAPACITY.statHistoric.value : this.capacity;
          item.statHistoric.inv = item.statHistoric.occupancy>0 ? tmp_cap-item.statHistoric.occupancy : tmp_cap;
          ris[day].statHistoricDay = this.translate.instant('DAYS.SHORT.' + moment(ris[day].statHistoricDay,"YYYY-MM-DD").day() )+" "+moment(ris[day].statHistoricDay,"YYYY-MM-DD").format("DD-MM-YYYY");
          item.statHistoric.rmc =  item.statHistoric.revenue / item.statHistoric.occupancy;
        }


        let rates = ris[day].rates;
        this.rateResult[day] = rates;
        for(let i in rates) {
          if(rates[i].current == null) {
            rates[i].current = {
              forced:false,
              rate:''
            }
          }
        }
        rates.edited = false;
      }
      this.calService.calendarDataDays = ris;
      console.debug(this.days_data)
      this.loadingCalandarData = false;


      console.debug(ris,this.rateResult);
      console.debug("-----------------------------");
    });
  }

  getRooms(){
    if(this.structureService.selectedStructure){
      let params = {
        page: 0,
        searchCriteria: [{
          "key": "ENABLED",
          "operation" : "EQUALITY",
          "value" : true,
          "orPredicate" : false
        }],
        size: 100,
        structureId: this.structureService.selectedStructure.id
      }
      this.restService.postService('room_type','/v2/search',params).subscribe((ris) =>{
        this.rooms = ris.items
        this.getChannels()
    })
    }
  }

  getChannels(){
    let params = {
      page: 0,
      searchCriteria: [{
        "key": "ENABLED",
        "operation" : "EQUALITY",
        "value" : true,
        "orPredicate" : false
      }],
      size: 100,
      structureId: this.structureService.selectedStructure.id
    }
    this.restService.postService('sales_channel','/v2/search',params).subscribe((ris)=>{
      this.channels = ris.items;
    })
   }

   checkChanges(indexRow: number, rt: string,day: string) {
    this.rateResult[day].edited = true;
    this.calService.editedDays.indexOf(day) < 0 ? this.calService.editedDays.push(day) : null;
    if(this.rateResult[day][rt].currentFormula==null) {
      this.refreshBaseFormula(indexRow, rt,day);
    } else {
      this.rateResult[day][rt].current.forced = true;
      this.refreshBaseFormula(indexRow, rt,day);
    }
    this.guiService.isConfigEdited = true
  }

  refreshBaseFormula(indexRow: number, rt: string,day: string) {
    let dt = day;
    let price_model = this.rateResult[dt];

    let scope: any = {};

    for(let k in price_model) {
      if(k!= 'edited' && price_model[k].currentFormula==null) {
        scope[k] = price_model[k].current != null ? price_model[k].current.rate : price_model[k].currentMinValue;
      }
    }

    for(let k in price_model) {
      if(k!= 'edited' && price_model[k].currentFormula!=null) {

        if(price_model[k].current.forced) {
          scope[k] = price_model[k].current.rate;
        }
        let tmp = math.parse(price_model[k].currentFormula);
        tmp.forEach(function (node, path, parent) {
          switch (node.type) {
            case 'SymbolNode':
              if(!price_model[k].current.forced) {
                let t: any = node;
                scope[k] = scope[t.name] ? math.evaluate(price_model[k].currentFormula, scope) : price_model[k].currentFormula;
              }
              break
            default:
            // nothing
          }
        })
      }
    }

    for(let k in price_model) {
      if(k!= 'edited' && !price_model[k].current.forced) {
        price_model[k].currentFormula ? price_model[k].current.rate = math.evaluate(price_model[k].currentFormula, scope) : null;
      }
    }

    // VERIFICO CHE I PREZZI SIANO TUTTI IMPOSTATI SULLA GIORNATA
    for(let k in price_model) {
      if(k!= 'edited' && !price_model[k].current) {
        price_model[k].error = true;
      }
    }

  }

  populateSelectYear() {
    let tmp: any = moment();
    this.selectyears = [];

    for (let i=0;i<5;i++) {
      this.selectyears.push(moment(tmp).subtract(i,"year").year());
    }

  }
}
