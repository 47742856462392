<div class="page-title-box d-flex align-items-center justify-content-between">
  <h4 class="mb-0">Scostamenti</h4>
</div>
<div class="col-xxl-12 col-xl-12">
  <div class="card card-h-100" *ngIf="loaded">
    <div class="card-header row">
      <div class="col-sm-12 col-md-6 col-xl-8">
        <!-- <div class="row">
          <div class="form-floating col-3">
          <input type="text" class="form-control" [(ngModel)]="searchCtrl">
          <label>Search</label>
        </div>
        <div class="form-floating col-1">
          <button class="btn btn-primary" (click)="getPrices()">
            <i class="uil-search"></i>
          </button>
        </div>
        </div> -->
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4">
        <div class="row">
          <div class="col-4 d-flex justify-content-between align-items-start">
            <button class="btn btn-primary" (click)="newPriceEnable()">
              <i class="uil-plus"></i>
            </button>
            <button class="btn btn-primary" (click)="guiService.chooseItemView('CARD')"
              [class.selected]="guiService.viewType == 'CARD'">
              <i class="uil-table"></i>
            </button>
            <button class="btn btn-primary" [class.selected]="guiService.viewType == 'TABLE'"
              (click)="guiService.chooseItemView('TABLE')">
              <i class="uil-list-ul"></i>
            </button>
          </div>
          <ngb-pagination class="col-8 d-flex justify-content-end" (pageChange)="loadPage($event)" [(page)]="page" [pageSize]="size"
            [collectionSize]="prices.totalItems"></ngb-pagination>
        </div>
      </div>
    </div><!-- end card header -->
    <div class="card-body row" *ngIf="prices">

      <div [ngClass]="showDetail ? 'col-sm-2 col-md-6' : 'col-sm-12 col-md-12'">

        <!-- START TABLE VIEW -->
        <div class="table-responsive" *ngIf="guiService.viewType == 'TABLE'">
          <table class="table table-sm table-hover mb-0">
            <thead>
            <tr>
              <th>#</th>
              <th>{{ "PRICING.name" | translate }}</th>
              <th>{{ "PRICING.desc" | translate }}</th>
              <th>{{ "PRICING.period" | translate }}</th>
              <th>{{ "PRICING.day_of_week_checkbox" | translate }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let price of prices">
              <th scope="row" [class.selected]="priceSelected && price.id == priceSelected.id">#{{price.id}}</th>
              <td [class.selected]="priceSelected && price.id == priceSelected.id" class="name-color"><a
                (click)="editPrice(price)">{{price.code}}</a></td>
              <td [class.selected]="priceSelected && price.id == priceSelected.id">{{price.description}}</td>
              <td [class.selected]="priceSelected && price.id == priceSelected.id">{{price.fromDate | date: "dd-MM-YYYY"}} / {{price.toDate | date: "dd-MM-YYYY"}}</td>
              <td [class.selected]="priceSelected && price.id == priceSelected.id">
                <p class="text-muted text-truncate mb-0"><i class="mdi mdi-circle align-middle me-1 i-list"
                                                            [class.text-success]="price.monday"></i>{{ "DAYS.SHORT.1" | translate}}
                </p>
                <p class="text-muted text-truncate mb-0"><i class="mdi mdi-circle align-middle me-1 i-list"
                                                            [class.text-success]="price.tuesday"></i>{{ "DAYS.SHORT.2" | translate}}
                </p>
                <p class="text-muted text-truncate mb-0"><i class="mdi mdi-circle align-middle me-1 i-list"
                                                            [class.text-success]="price.wednesday"></i>{{ "DAYS.SHORT.3" | translate}}
                </p>
                <p class="text-muted text-truncate mb-0"><i class="mdi mdi-circle align-middle me-1 i-list"
                                                            [class.text-success]="price.thursday"></i>{{ "DAYS.SHORT.4" | translate}}
                </p>
                <p class="text-muted text-truncate mb-0"><i class="mdi mdi-circle align-middle me-1 i-list"
                                                            [class.text-success]="price.friday"></i>{{ "DAYS.SHORT.5" | translate}}
                </p>
                <p class="text-muted text-truncate mb-0"><i class="mdi mdi-circle align-middle me-1 i-list"
                                                            [class.text-success]="price.saturday"></i>{{ "DAYS.SHORT.6" | translate}}
                </p>
                <p class="text-muted text-truncate mb-0"><i class="mdi mdi-circle align-middle me-1 i-list"
                                                            [class.text-success]="price.sunday"></i>{{ "DAYS.SHORT.7" | translate}}
                </p></td>
              <td [class.selected]="priceSelected && price.id == priceSelected.id">
                <div class="text-center mb-1" ngbDropdown>
                  <i class="uil-cog m-0" ngbDropdownToggle id="dropdownBasic0"></i>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic0">
                    <button ngbDropdownItem (click)="editPrice(price)">Edit</button>
                    <button ngbDropdownItem (click)="duplicatePrice(price)">Duplicate</button>
                    <button ngbDropdownItem (click)="guiService.deleteConfirm('price_list','/'+structureService.selectedStructure.id,price.id)">Delete
                    </button>
                  </div>
                </div>
              </td>
            </tr>
            </tbody><!-- end tbody -->
          </table><!-- end table -->
        </div><!-- end table responsive -->


        <!-- START CARD VIEW -->
        <div class="card-group justify-content-center" *ngIf="guiService.viewType == 'CARD'">
          <div class="col-xl-3 col-xxl-2 col-sm-3 col-md-3 card-body-structure-wrapper"
               *ngFor="let price of prices">
            <div class="card-body-structure" [class.selected]="priceSelected && price.id == priceSelected.id">
              <div class="action-btn">
                <div class="text-end mb-1" ngbDropdown>
                  <i class="uil-cog m-0" ngbDropdownToggle id="dropdownBasic2"></i>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                    <button ngbDropdownItem (click)="editPrice(price)">Edit</button>
                    <button ngbDropdownItem (click)="duplicatePrice(price)">Duplicate</button>
                    <button ngbDropdownItem (click)="guiService.deleteConfirm('price_list','/'+structureService.selectedStructure.id,price.id)">Delete
                    </button>
                  </div>
                </div>
              </div>

              <h3 class="pid-area">#{{price.id}}</h3>

              <div class="titolo d-flex justify-content-between">
                <h1><a (click)="editPrice(price)">{{price.code}}</a></h1>
              </div>
              <div class="body-card">
                <div>
                  <p>{{price.description}}</p>
                </div>
                <div>
                  <p>{{price.fromDate | date: "dd-MM-YYYY"}} / {{price.toDate | date: "dd-MM-YYYY"}}</p>
                </div>
                <div class="week-days">
                  <p class="mb-0"><i class="mdi mdi-circle align-middle me-1"
                                                              [class.text-success]="price.monday"></i>{{ "DAYS.SHORT.1" | translate}}
                  </p>
                  <p class="mb-0"><i class="mdi mdi-circle align-middle me-1"
                                                              [class.text-success]="price.tuesday"></i>{{ "DAYS.SHORT.2" | translate}}
                  </p>
                  <p class="mb-0"><i class="mdi mdi-circle align-middle me-1"
                                                              [class.text-success]="price.wednesday"></i>{{ "DAYS.SHORT.3" | translate}}
                  </p>
                  <p class="mb-0"><i class="mdi mdi-circle align-middle me-1"
                                                              [class.text-success]="price.thursday"></i>{{ "DAYS.SHORT.4" | translate}}
                  </p>
                  <p class="mb-0"><i class="mdi mdi-circle align-middle me-1"
                                                              [class.text-success]="price.friday"></i>{{ "DAYS.SHORT.5" | translate}}
                  </p>
                  <p class="mb-0"><i class="mdi mdi-circle align-middle me-1"
                                                              [class.text-success]="price.saturday"></i>{{ "DAYS.SHORT.6" | translate}}
                  </p>
                  <p class="mb-0"><i class="mdi mdi-circle align-middle me-1"
                                                              [class.text-success]="price.sunday"></i>{{ "DAYS.SHORT.7" | translate}}
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-10 col-md-6" *ngIf="showDetail">
        <app-price-detail [price]="priceSelected" [priceDuplicate]="priceToDuplicate"
                          *ngIf="newPrice == false"></app-price-detail>
        <app-price-detail [price]="{}" *ngIf="newPrice"></app-price-detail>
      </div>
    </div>
  </div><!-- end card -->


  <div *ngIf="!loaded" class="main-page-loading">
    <div class="loading-bars-box">
      <div class="loading-bars"></div>
    </div>
  </div>
</div>
