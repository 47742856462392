import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpHandler, HttpRequest, HttpResponse} from "@angular/common/http";
import {BehaviorSubject, Observable, of, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {Router} from "@angular/router";
import 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from "sweetalert2";

export interface AuthData {
  token: string
}
@Injectable({
  providedIn: 'root'
})
export class RestService {
  tkn:any = "";
  lang = "";
  baseURL:any = environment.baseURL;
  baseOPT = {};
  public tknSubj = new BehaviorSubject<null | AuthData>(null);
  token$ = this.tknSubj.asObservable();

  REMEMBER_ME: any = {
    selected: false,
    appkey: ""
  };

  constructor(public router: Router, private httpClient: HttpClient) {

  }

  public setTkn(tkn:any) {
    this.tkn = tkn;
    sessionStorage.setItem("tkn", tkn);
  }
  public getTkn() {
    this.tkn = sessionStorage.getItem("tkn");
    return sessionStorage.getItem("tkn");
  }

  public getService(base:any, ctx:any, opt?:any): Observable<any> {
    !opt ? opt = this.baseOPT : null;

    if(sessionStorage.getItem("tkn") || sessionStorage.getItem("tkn") != null){
      opt = {
          headers: {
              "Authorization": "Bearer "+ sessionStorage.getItem("tkn"),
              'Content-Type': 'application/json'
          }
      };
    }

  // console.debug("OPT",opt);
    return this.httpClient.get(this.baseURL[base] + '' + ctx, opt).pipe(
        catchError((err) => {
          let errormsg = err.error && err.error.error && err.error.error.message ? err.error.error.message : err.message;
          console.debug("ERROR",errormsg);

          console.debug("ERROR GET", errormsg);
          /*Swal.fire(
            'ERROR!',
            errormsg,
            'error'
          );*/

          if(err.status==401) {
            // Check Remember-me
            if(this.REMEMBER_ME.selected && this.REMEMBER_ME.appkey!="") {
              this.refreshToken(this.getService(base, ctx, opt));
            } else {
              this.doLogout();
            }
          }

          //Handle the error here

          return throwError(() => err);    //Rethrow it back to component
        })
    );
  }

  public postService(base:any, ctx:any, json:any, opt?:any): Observable<any> {

    !opt ? opt = this.baseOPT : null;

    if(sessionStorage.getItem("tkn") || sessionStorage.getItem("tkn") != null){
      opt = {
          headers: {
              "Authorization": "Bearer "+ sessionStorage.getItem("tkn")
          }
      };
  }

    return this.httpClient.post(this.baseURL[base] + '' + ctx, json, opt).pipe(
        catchError((err) => {

          console.error(err);

          Swal.fire(
            'ERROR!',
            err.error.error.message,
            'error'
          );

          if(err.status==401) {
            // Check Remember-me
            if(this.REMEMBER_ME.selected && this.REMEMBER_ME.appkey!="") {
              this.refreshToken(this.postService(base, ctx, json, opt));
            } else {
              this.doLogout();
            }
          }

          //Handle the error here

          return throwError(() => err);    //Rethrow it back to component
        })
    );
  }

  public putService(base:any, ctx:any, json:any, opt?:any): Observable<any> {

    !opt ? opt = this.baseOPT : null;

    return this.httpClient.put(this.baseURL[base] + '' + ctx, json, opt).pipe(
        catchError((err) => {
          console.error(err);

          Swal.fire(
            'ERROR!',
            err.error.error.message,
            'error'
          );

          if(err.status==401) {
            // Check Remember-me
            if(this.REMEMBER_ME.selected && this.REMEMBER_ME.appkey!="") {
              this.refreshToken(this.putService(base, ctx, json, opt));
            } else {
              this.doLogout();
            }
          }

          //Handle the error here

          return throwError(() => err);    //Rethrow it back to component
        })
    );
  }

  public deleteService(base:any, ctx:any, json:any, opt?:any): Observable<any> {

    !opt ? opt = this.baseOPT : null;

    return this.httpClient.delete(this.baseURL[base] + '' + ctx, {responseType:'text'}).pipe(
        catchError((err) => {
          console.error(err);

          Swal.fire(
            'ERROR!',
            err.error.error.message,
            'error'
          )

          if(err.status==401) {
            // Check Remember-me
            if(this.REMEMBER_ME.selected && this.REMEMBER_ME.appkey!="") {

              if(err.status==401) {
                // Check Remember-me
                if(this.REMEMBER_ME.selected && this.REMEMBER_ME.appkey!="") {
                  this.refreshToken(this.deleteService(base, ctx, json, opt));
                } else {
                  this.doLogout();
                }
              }

            } else {
              this.doLogout();
            }
          }

          //Handle the error here

          return throwError(() => err);    //Rethrow it back to component
        })
    );
  }


  public getExportFile(base:any, ctx:any, opt?:any) {
    !opt ? opt = this.baseOPT : null;

    if(sessionStorage.getItem("tkn") || sessionStorage.getItem("tkn") != null){
      window.open(this.baseURL[base] + '' + ctx + '&jwt=' +sessionStorage.getItem("tkn"), "_blank");
    }

  }

  public doLogin(ctx:any, json:any) {
    let opt = {};
    return this.httpClient.post(this.baseURL.login + '' + ctx, json, opt).pipe(
        catchError((err) => {
          console.error(err);
          console.error(JSON.stringify(err.error.text));

          //Handle the error here

          return throwError(() => err);    //Rethrow it back to component
        })
    );
  }

  public rememberLogin(ctx:any, json:any) {
    let opt = {};
    return this.httpClient.post(this.baseURL.auth + '/authenticate-rm' + ctx, json, opt).pipe(
        catchError((err) => {
          console.error(err);
          console.error(JSON.stringify(err.error.text));

          //Handle the error here

          return throwError(() => err);    //Rethrow it back to component
        })
    );
  }

  private refreshToken(fx: any) {

    let param = {
      token: this.REMEMBER_ME.appkey
    }

    this.rememberLogin('',param).subscribe({
      next: (ris:any)=>{
        this.tknSubj.next(ris["token"])
        let tkn = ris["token"]
        sessionStorage.setItem("tkn", tkn);

        setTimeout(fx,50);
      },
      error: (e)=>{
        console.debug("ERROR",e);
      }

    });
  }

  public extResetPsw(base:any, ctx:any, json:any): Observable<any> {

    let opt = {};

    return this.httpClient.post(this.baseURL[base] + '' + ctx, json, opt).pipe(
        catchError((err) => {

          console.error(err);

          Swal.fire(
            'ERROR!',
            err.error.error.message,
            'error'
          );

          //Handle the error here

          return throwError(() => err);    //Rethrow it back to component
        })
    );
  }

  public doLogout() {

    if(this.REMEMBER_ME.selected && this.REMEMBER_ME.appkey!="") {
      this.deleteService("remember_me","/remove/"+this.REMEMBER_ME.appkey, {}).subscribe({
        next: () => {
          this.setTkn(null);
          this.tknSubj.next(null);
          this.tkn = null;
          sessionStorage.removeItem("tkn");
          localStorage.removeItem("appremre");
          this.router.navigate(["auth/login"]);
        },
        error: (err) => {
          console.debug("ERROR",err);
        }
      });
    } else {
      this.setTkn(null);
      this.tknSubj.next(null);
      this.tkn = null;
      sessionStorage.removeItem("tkn");
      localStorage.removeItem("appremre");
      this.router.navigate(["auth/login"]);
    }
  }



  public uploadFile(base:any, ctx:any, formdata:any, opt?:any): Observable<any> {

    !opt ? opt = this.baseOPT : null;

    if(sessionStorage.getItem("tkn") || sessionStorage.getItem("tkn") != null){
      opt = {
          headers: {
              "Authorization": "Bearer "+ sessionStorage.getItem("tkn"),
              'Content-Type': 'multipart/form-data'
          }
      };
  }

    return this.httpClient.post(this.baseURL[base] + '' + ctx, formdata, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
        catchError((err) => {

          console.error(err);

          Swal.fire(
            'ERROR!',
            err.error.error.message,
            'error'
          )

          if(err.status==401) {
            // Check Remember-me
            if(this.REMEMBER_ME.selected && this.REMEMBER_ME.appkey!="") {
              this.refreshToken(this.uploadFile(base, ctx, formdata, opt));
            } else {
              this.doLogout();
            }
          }

          //Handle the error here

          return throwError(() => err);    //Rethrow it back to component
        })
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      this.log(`${operation} failed: ${error.message}`);

      return of(result as T);
    };
  }

  private log(message: string) {
    console.debug(message);
  }

  public getReport(base:any, ctx:any, opt?:any): Observable<any> {
    !opt ? opt = this.baseOPT : null;

    if(sessionStorage.getItem("tkn") || sessionStorage.getItem("tkn") != null){
      opt = {
          headers: {
              "Authorization": "Bearer "+ sessionStorage.getItem("tkn"),
              'Content-Type': 'application/json'
          },
          responseType: 'blob'
      };
    }

  // console.debug("OPT",opt);
    return this.httpClient.get(this.baseURL[base] + '' + ctx, opt).pipe(
        catchError((err) => {
          let errormsg = err.error && err.error.error && err.error.error.message ? err.error.error.message : err.message;
          console.debug("ERROR",errormsg);

          //Handle the error here

          return throwError(() => err);    //Rethrow it back to component
        })
    );
  }
}
