import { Component } from '@angular/core';

@Component({
  selector: 'app-report-bi',
  templateUrl: './report-bi.component.html',
  styleUrls: ['./report-bi.component.scss']
})
export class ReportBiComponent {

}
