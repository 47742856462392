import { GuiService } from 'src/app/core/services/gui.service';
import { Component } from '@angular/core';
import { RestService } from 'src/app/core/services/rest.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent {
  userView: boolean = false;
  userSelected: any;
  newUser: boolean = false;
  searchCtrl: any;
  page = 1;
  size = 15;
  totalItems: any = 0;
  users: any = []
  showDetail: boolean = false;

 constructor(public guiService: GuiService, private restService: RestService){
  this.guiService.closeDetail.subscribe( value => {
    if(value == true){
      this.userSelected = null
      this.showDetail = false;
    }
});

  this.guiService.refreshList.subscribe( value => {
    if(value == true){
      this.getUsers()
    }
  });
 }

 ngOnInit(): void {
  this.guiService.changeArea("FX.main");
  this.guiService.selectMenuItems("FX.main");
  this.guiService.loaded = false;
  this.getUsers()
 }

 getUsers(criteria?: any){
  let param: any = {
    page: this.page-1,
    size: this.size,
    searchCriteria: []
  };

criteria ? param.searchCriteria.push(criteria) : null;

  this.restService.postService('users','/search',param).subscribe((ris=>{
    this.users = ris.items;
    this.totalItems = ris.totalItems;
    this.guiService.loaded = true;
  }))
 }

 searchUser(page?: any){
  this.guiService.loaded = false;
  this.page = page ? page : 1;

  if(this.searchCtrl && this.searchCtrl!="") {
    this.getUsers({
      "key": "NAME",
      "operation" : "CONTAINS",
      "value" : this.searchCtrl,
      "orPredicate" : false
    });
  } else {
    this.getUsers();
  }

 }

 editUser(idUser: number){
  this.restService.getService('users','/'+idUser).subscribe((ris=>{
   this.userSelected = ris
   this.newUser = false
   this.showDetail = true;

  }))
 }

 newUserEnable(){
  this.newUser = true;
  this.userSelected = {    
    "firstName" : "",
    "lastName" : "",
    "email" : "",
    "username" : "",
    "language" : "",
    "mainProfileName" :""
  };
  this.showDetail = true;
 }

 loadPage(event:any){
  console.debug("PAGE EVENT", event);
  this.page = event;
  this.searchUser(this.page);
 }
}
