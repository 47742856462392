import { Injectable } from '@angular/core';
import {StructureService} from "./structure.service";
import {TranslateService} from "@ngx-translate/core";
import {GuiService} from "./gui.service";
import {RestService} from "./rest.service";
import {BehaviorSubject, Observable, of, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import * as moment from "moment/moment";
import {now} from "moment/moment";
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  nowForCalendar: any = moment();
  calendarDataDays: any = null;

  daysForCalendar = new Array(36);
  yearSelectedForCalendar: any;
  monthSelectedForCalendar: any;
  selectedDay: any;
  dynamicHistoryYear: any;
  staticHistoryYear: any;
  dynamicHistoryYearRef: any = 0;
  staticHistoryYearRef: any = 0;
  monthlyIndicator: any;

  editedDays: Array<any> = [];
  expandArea: any;
  reloadCalendarData: boolean = false; // UTILIZZATA PER NON FAR PARTIRE LE CHIAMATE OGNI VOLTA CHE SI CAMBIA MESE
  showDynamicHistory: boolean = false;

  constructor(private structureService: StructureService, private translate: TranslateService, private guiService: GuiService, private restService: RestService) {
  }

  public getCalendarData(from?: string, to?: string, statMatchYear?: string, dynMatchYear?: string, disableMonthIndicator?: boolean): Observable<any> {

    disableMonthIndicator ? null : this.getMonthlyIndicator();
    return this.restService.getService('calendar','v4/'+this.structureService.selectedStructure.id+'/days?from='+from+'&to='+to+'&statMatchYear='+statMatchYear+'&dynMatchYear='+dynMatchYear).pipe(

      catchError((err) => {
        console.log('error caught in service');
        console.error(err.message);

        //Handle the error here

        return throwError(err);    //Rethrow it back to component
      })
    )
  }

  public getCalendarPickUp(from?: string, size?: number): Observable<any> {

    return this.restService.getService('calendar','v4/'+this.structureService.selectedStructure.id+'/pickups?from='+from+'&months='+size).pipe(

      catchError((err) => {
        console.log('error caught in service');
        console.error(err.message);

        //Handle the error here

        return throwError(err);    //Rethrow it back to component
      })
    )
  }

  public getCalendarDataEntry(from?: string, to?: string): Observable<any> {

    return this.restService.getService('calendar','v4/'+this.structureService.selectedStructure.id+'/days?from='+from+'&to='+to).pipe(

      catchError((err) => {
        console.log('error caught in service');
        console.error(err.message);

        //Handle the error here

        return throwError(err);    //Rethrow it back to component
      })
    )
  }

  public updateCalendarDataEntry(param: any): Observable<any> {

    return this.restService.putService('calendar','v4/'+this.structureService.selectedStructure.id+'/days/edit-occupancies',param).pipe(

      catchError((err) => {
        console.log('error caught in service');
        console.error(err.message);


        //Handle the error here

        return throwError(err);    //Rethrow it back to component
      })
    )
  }

  prepareDays(month?: any) {

    let base_month = parseInt(month)-1;
    this.daysForCalendar = [];
    let now = month ? moment().year(this.yearSelectedForCalendar).month(base_month).date(1) : moment();
    let start = moment(now.format('YYYY')+now.format('MM')+'01','YYYYMMDD').subtract(3,'d');

    !this.dynamicHistoryYear ? this.dynamicHistoryYear = moment(start).subtract(1,"year").format("YYYY") : null;
    !this.staticHistoryYear ? this.staticHistoryYear = moment(start).subtract(1,"year").format("YYYY") : null;

    // Calcolo la data di partenza per lo storico dinamico
    let dynDiffYear = parseInt(start.format("YYYY"))-parseInt(this.dynamicHistoryYear);
    let dynStart = moment(start).subtract(dynDiffYear,'year');
    while(dynStart.day()!=start.day()) {
      dynStart = dynStart.add(1,"day");
    }

    // Calcolo la data di partenza per lo storico statico (consuntivo)
    let staDiffYear = parseInt(start.format("YYYY"))-parseInt(this.staticHistoryYear);
    let staStart = moment(start).subtract(staDiffYear,'year');
    while(staStart.day()!=start.day()) {
      staStart = staStart.add(1,"day");
    }

    for(let i=0; i<36; i++) {
      let tmp = moment(start).add(i,'d');
      let dyntmp = moment(dynStart).add(i,'d');
      let statmp = moment(staStart).add(i,'d');
      this.daysForCalendar.push({
        d : tmp.format('DD'),
        m : tmp.format('MM'),
        y : tmp.format('YYYY'),
        DoW : tmp.day(),
        day: tmp.format('YYYY')+'-'+tmp.format('MM')+'-'+tmp.format('DD'),
        dynday: dyntmp.format('DD-MM-YYYY'),
        dynDoW: dyntmp.day(),
        staday: statmp.format('DD-MM-YYYY'),
        staDoW: statmp.day()
      })
    }
    console.debug("DAYS FOR CALENDAR",this.daysForCalendar);
    this.reloadCalendarData = true;
  }

  evalPriceDayChange(idx:any, day:string,title:string,val: any) {
    if(this.calendarDataDays[day]['rates'][title]) {
      this.calendarDataDays[day].rates[title].current.rate = val.target.value;
    } else {
      this.calendarDataDays[day].rates[title] = {
        current: {
          rate: val.target.value
        }
      }
    }

    this.markDayEdited(idx);
  }

  markDayEdited(idx: any) {
    this.editedDays.indexOf(idx)<0 ? this.editedDays.push(idx) : null;
  }

  expandCollapse(idx: any) {
    this.expandArea == idx ? this.expandArea = null : this.expandArea = idx;
  }




  getMonthlyIndicator(){

    const baseData = {
      "occupancy": 0,
      "capacity": 0,
      "revenue": 0,
      "rmc": 0,
      "revpar": 0,
      "warnings": {}
    };

    console.debug("DYN "+this.dynamicHistoryYearRef,"STA "+this.staticHistoryYearRef);
    let statMatchYear = this.staticHistoryYearRef==0 ? parseInt(this.yearSelectedForCalendar)-1 : this.staticHistoryYearRef;
    let dynMatchYear = this.dynamicHistoryYearRef==0 ? parseInt(this.yearSelectedForCalendar)-1 : this.dynamicHistoryYearRef;
    this.restService.getService('calendar', 'v4/'+this.structureService.selectedStructure.id+'/monthly-revenue-indicators?from='+this.yearSelectedForCalendar+'-'+this.monthSelectedForCalendar+'&months='+1+'&statMatchYear='+statMatchYear+'&dynMatchYear='+dynMatchYear).subscribe((ris)=>{
      this.monthlyIndicator = ris.indicators[this.yearSelectedForCalendar+'-'+this.monthSelectedForCalendar];

      this.monthlyIndicator.current == null ? this.monthlyIndicator.current = baseData : null;
      this.monthlyIndicator.dynHistoric == null ? this.monthlyIndicator.dynHistoric = baseData : null;
      this.monthlyIndicator.statHistoric == null ? this.monthlyIndicator.statHistoric = baseData : null;

      // DIFF INDICATOR CALC
      this.monthlyIndicator.current.occDynDiff = (this.monthlyIndicator.current.occupancy - this.monthlyIndicator.dynHistoric.occupancy).toFixed(2);
      this.monthlyIndicator.current.occStaDiff = (this.monthlyIndicator.current.occupancy - this.monthlyIndicator.statHistoric.occupancy).toFixed(2);
      this.monthlyIndicator.current.revenueDynDiff = (this.monthlyIndicator.current.revenue - this.monthlyIndicator.dynHistoric.revenue).toFixed(2);
      this.monthlyIndicator.current.revenueStaDiff = (this.monthlyIndicator.current.revenue - this.monthlyIndicator.statHistoric.revenue).toFixed(2);
      this.monthlyIndicator.current.rmcDynDiff = (this.monthlyIndicator.current.rmc - this.monthlyIndicator.dynHistoric.rmc).toFixed(2);
      this.monthlyIndicator.current.rmcStaDiff = (this.monthlyIndicator.current.rmc - this.monthlyIndicator.statHistoric.rmc).toFixed(2);
      this.monthlyIndicator.current.revparDynDiff = (this.monthlyIndicator.current.revpar - this.monthlyIndicator.dynHistoric.revpar).toFixed(2);
      this.monthlyIndicator.current.revparStaDiff = (this.monthlyIndicator.current.revpar - this.monthlyIndicator.statHistoric.revpar).toFixed(2);

    });
  }

  toggleDynamicHistory(){
    /* SETTO LA SELECT SU AUTO PER SICUREZZA */
    this.dynamicHistoryYearRef = 0;
    this.dynamicHistoryYear = null;

    this.showDynamicHistory ? this.showDynamicHistory = false : this.showDynamicHistory = true;
    console.debug('SHOW DYNAMIC HISTORY:', this.showDynamicHistory)
  }
}
