<div class="contenitore">
  <div class="row">
    <div class="col-6">
      <div class="row">
        <div class="form-floating">
          <input class="form-control" type="date" id="init" [(ngModel)]="date.from">
          <label for="init">DA</label>
        </div>
        <div class="form-floating">
          <input class="form-control" type="date" id="expire" [(ngModel)]="date.to">
          <label for="expire">A</label>
        </div>
        <button class="btn icona cerca" [class.btn-light]="!roomsType || !salesChannel">
          <div class="loading-bars-box" *ngIf="!roomsType || !salesChannel">
            <div class="loading-bars"></div>
          </div>
          <i class="uil-search" *ngIf="roomsType && salesChannel" (click)="loadData()"></i>
        </button>
      </div>
    </div>
    <div class="col-6 text-end" *ngIf="showTable && loaded">
      <div class="d-inline flag">
        <div class="form-floating select-floating">
          <select [(ngModel)]="sumType" class="form-select">
            <option value="1">Somma da tipologia</option>
            <option value="2">Somma da canali</option>
            <option value="3">Inserisci il totale</option>
          </select>
        </div>
        <div class="elem-box">
          <p class="m-2 d-inline">Visualizza TOT</p>
          <ui-switch defaultBoColor="#dfdfdf" color="#51d28c" [(ngModel)]="showTOT"
                     [ngModelOptions]="{standalone: true}"></ui-switch>
        </div>

        <div class="elem-box">
          <p class="m-2 d-inline">Visualizza GRP</p>
          <ui-switch defaultBoColor="#dfdfdf" color="#51d28c" [(ngModel)]="showGRP"
                     [ngModelOptions]="{standalone: true}"></ui-switch>
        </div>


      </div>
      <button class="btn btn-danger d-inline-block icona annulla" *ngIf="showTable" (click)="reset()">
        <i-feather name="refresh-cw"></i-feather>
      </button>
      <button class="btn btn-primary d-inline-block icona cerca" *ngIf="showTable" (click)="prepareSave()">
        <i-feather name="save"></i-feather>
      </button>
    </div>
  </div>
  <div class="tabella row" *ngIf="showTable && loaded">
    <div class="vuoto"></div>
    <div id="colslimit" class="col-10 col-sm-custom">
      <div id="main-h-scroll" [class.fixed-header]="scrollY" [style.width]="headerWidth+'px'"
           (scroll)="onXScroll('main-h-scroll')">
        <div class="row h-header" [style.width]="(84*((roomsType.length + salesChannel.length)+2)+86) + 'px'">
          <div class="intestazione-orizzontale tot-header" *ngIf="showTOT">
            <h2>TOT</h2>
          </div>
          <div class="intestazione-orizzontale tot-header" *ngIf="showGRP">
            <h2>GRP</h2>
          </div>
          <div class="intestazione-orizzontale" *ngFor="let item of roomsType">
            <h2 class="text-truncate">{{item.title}}</h2>
          </div>
          <div class="intestazione-orizzontale sales-header" *ngFor="let item of salesChannel">
            <h2 class="text-truncate">{{item.code}}</h2>
          </div>
          <div class="intestazione-orizzontale tot-header">
            <h2>CAP</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row days-area" *ngIf="showTable && loaded">
    <div *ngFor="let item of tmpResult; let i = index">
      <div class="vuoto">
        <div class="colonna-verticale" [class.fixed-first-col]="scrollX">
          <div class="intestazione-verticale">
            <h4 class="day" *ngIf="item.number != 0">{{"DAYS.SHORT." + (item.number) | translate}}</h4>
            <h4 class="day" *ngIf="item.number == 0">{{"DAYS.SHORT.7" | translate}}</h4>
            <h3 class="day-number">{{item.day}}</h3>
            <h4>{{"MONTHS.SHORT." + (item.month) | translate}}</h4>
            <h4>{{item.year}}</h4>
          </div>
          <div class="header-vertical">
            <div>OCC</div>
            <div>PROD</div>
          </div>
        </div>
      </div>
      <div class="col-10 col-sm-custom colonna-verticale element-h-scroll" [style.width]="headerWidth+'px'" id="row_{{i}}">
        <div [style.width]="(84*((roomsType.length + salesChannel.length)+2)+86) + 'px'">
          <!-- TOT. COLUMN -->
          <div *ngIf="showTOT">
            <input type="number" [disabled]="sumType!=3" [class.changed-value]="item.data['tot']['occ_changed']" type="text" [(ngModel)]="item.data['tot'].occ" (change)="checkChanges('tot','occ',i,0)" (paste)="onPaste($event,'tot','occ',i,0)">
            <input type="number" [disabled]="sumType!=3" [class.changed-value]="item.data['tot']['prod_changed']" type="text" [(ngModel)]="item.data['tot'].prod" (change)="checkChanges('tot','prod',i,0)" (paste)="onPaste($event,'tot','prod',i,0)">
          </div>

          <!-- GRP COLUMN -->
          <div *ngIf="showGRP">
            <input [class.changed-value]="item.data['grp']['occ_changed']" type="text" [(ngModel)]="item.data['grp'].occ" (change)="checkChanges('cap','occ',i,0)" (paste)="onPaste($event,'tot','occ',i,0)">
            <input [class.changed-value]="item.data['grp']['prod_changed']" type="text" [(ngModel)]="item.data['grp'].prod" (change)="checkChanges('cap','prod',i,0)" (paste)="onPaste($event,'tot','prod',i,0)">
          </div>

          <!-- ROOMS COLUMN -->
          <div *ngFor="let h of roomsType; let indice = index">
            <input
              [class.changed-value]="item.data['roomtype'][indice]['occ_changed']"
              type="number" [(ngModel)]="item.data['roomtype'][indice].occ"
              (paste)="onPaste($event,'roomtype','occ',i,indice)"
              (change)="checkChanges('roomtype','occ',i,indice)">

            <input
              [class.changed-value]="item.data['roomtype'][indice]['prod_changed']"
              type="text" [(ngModel)]="item.data['roomtype'][indice].prod"
              (paste)="onPaste($event,'roomtype','prod',i,indice)"
              (change)="checkChanges('roomtype','prod',i,indice)">
          </div>

          <!-- SALES CHANNEL COLUMN -->
          <div *ngFor="let h of salesChannel; let indice = index">
            <input [class.changed-value]="item.data['saleschannel'][indice]['occ_changed']" type="number" [(ngModel)]="item.data['saleschannel'][indice].occ" (paste)="onPaste($event,'saleschannel','occ',i,indice)" (change)="checkChanges('saleschannel','occ',i,indice)">
            <input [class.changed-value]="item.data['saleschannel'][indice]['prod_changed']" type="number" [(ngModel)]="item.data['saleschannel'][indice].prod" (paste)="onPaste($event,'saleschannel','prod',i,indice)" (change)="checkChanges('saleschannel','prod',i,indice)">
          </div>

          <div>
            <input class="input-heigth" [class.changed-value]="item.data['cap']['cap_changed']" type="text"
                   [(ngModel)]="item.data['cap'].cap"
                   (change)="checkCapacityChanges('cap','cap',i)"
                   (paste)="onPaste($event,'cap','cap',i,0)">
          </div>


        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!loaded" class="main-page-loading">
    <div class="loading-bars-box">
      <div class="loading-bars"></div>
    </div>
  </div>
</div>
