<div class="page-title-box d-flex align-items-center justify-content-between">
  <h4 class="mb-0">Periodo di Apertura</h4>
</div>
<div class="col-xxl-12 col-xl-12">
  <div class="card card-h-100">
    <div class="card-header justify-content-between d-flex align-items-center">
      <div class="col-sm-2 col-md-2">
        <div class="dataTables_filter text-md-start">
          <select type="text" name="searchTerm" [(ngModel)]="searchCtrl" (change)="getMonths(searchCtrl)">
            <option [value]="year" *ngFor="let year of years" >{{year}}</option>
          </select></div>
      </div>
      <div class="col-9"></div>
      <div class="col-1">
        <button class="btn-new" (click)="savePlanning()">Salva</button>
      </div>


    </div><!-- end card header -->
    <div class="card-body">
      <div class="row" *ngIf="months && loaded">
        <div class="month col-sm-12 col-md-4 col-xl-3" *ngFor="let item of months; let i = index">
          <div class="card month-padding">
            <div class="row card-header">
              <h1 class="col-9 month-name">{{"MONTHS.LONG."+(i+1) | translate}}</h1>
              <ui-switch class="col-3" defaultBoColor="#dfdfdf" color="#51d28c" [(ngModel)]="this.months[i].allmarked" [ngModelOptions]="{standalone: true}" (change)="markAllDays(i,$event)"></ui-switch>
            </div>
            <div class="card-body">
              <ul class="mt-3 mb-3">
                <li class="day">Lun</li>
                <li class="day">Mar</li>
                <li class="day">Mer</li>
                <li class="day">Gio</li>
                <li class="day">Ven</li>
                <li class="day">Sab</li>
                <li class="day">Dom</li>
                <li *ngFor="let free of months[i]['skipDays']">
                  <span>&nbsp;</span>
                </li>
                <li *ngFor="let item of months[i].data; let d = index" [class.open]="!item" [class.closed]="item" (click)="markDay(i,d)">
                  <span *ngIf="d+1 <= 9">{{'0'+(d+1)}}</span>
                  <span *ngIf="d+1 > 9">{{d+1}}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>


      <div *ngIf="!loaded" class="main-page-loading">
        <div class="loading-bars-box">
          <div class="loading-bars"></div>
        </div>
      </div>

    </div><!-- end card body -->
  </div><!-- end card -->
</div>
