import {Component, OnInit, inject} from '@angular/core';
import {RestService} from "../../../core/services/rest.service";
import {GuiService} from "../../../core/services/gui.service";
import {TranslateService} from "@ngx-translate/core";
import {StructureService} from "../../../core/services/structure.service";
import moment from "moment";
import Swal from "sweetalert2";
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-updatehistory',
  templateUrl: './updatehistory.component.html',
  styleUrls: ['./updatehistory.component.scss']
})
export class UpdatehistoryComponent implements OnInit {
  loaded: boolean = false;
  timelineData: Array<any> = [];
  req_from: any = null;
  req_to: any = null;

  forecasts: Array<any> = [];
  selectedForecast: any = null;
  
  calendar = inject(NgbCalendar);

  calendarDisabled: string = "!disabled";
  manyMonths: number = 6;
	hoveredDate: NgbDate | null = null;
	now: NgbDate = this.calendar.getToday();
  startDate: NgbDate = this.calendar.getPrev(this.now,'m',3);
  fromDate: any = this.calendar.getPrev(this.now,'d',0)
	toDate: any = this.calendar.getNext(this.now, 'd', 0);

	onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
			this.toDate = date;
		} else {
			this.toDate = null;
			this.fromDate = date;
		}

    console.debug("DATE SELECTED", this.fromDate, this.toDate);
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

  constructor(private restService: RestService, private strService: StructureService, private guiService: GuiService, public translate: TranslateService) {
  }

  ngOnInit() {
    this.guiService.changeArea("FX.operation");
    this.guiService.selectMenuItems("FX.operation");
    let w = window.innerWidth;
    this.manyMonths = w <= 1024 ? 4 : 6;
    this.loaded = true;
  }

  getForecastHistory() {
    this.forecasts = [];
    this.selectedForecast = null;

    let from = moment(this.fromDate.year+'-'+this.fromDate.month+'-'+this.fromDate.day,"YYYY-M-D").format("YYYY-MM-DD");
    let to = moment(this.toDate.year+'-'+this.toDate.month+'-'+this.toDate.day,"YYYY-M-D").format("YYYY-MM-DD");

    this.restService.getService('forecasts','/'+this.strService.selectedStructure.id+'/completed?from='+from+'&to='+to).subscribe({
      next: (res: any)=>{
        console.debug("RES",res);
        this.forecasts = res.forecasts;
      },
      error: (err: any)=>{
        console.debug("ERROR",err);
      }
    })

  }

  forecastDetail(idx: number){
    if(this.selectedForecast && idx == this.selectedForecast.id) {
      this.selectedForecast = null;
    } else {
      let items = this.forecasts[idx].days;
      let data: Array<any> = [];

      for(let k in items) {
        data.push({
          dt: k,
          rates: items[k].rates
        });
      }

      data.sort((a, b) => (a.dt > b.dt ? 1 : -1));
      console.debug("data",data);

      this.selectedForecast = {
        id: idx,
        data: data
      };


      console.debug("DETAIL",this.selectedForecast)
    }
  }


  /* Funzione ereditata dalla versione 3 */
  getUpdateHistory(from?: string,to?: string){
    from && from!=null ? this.req_from = from : this.req_from = moment().subtract(1,'month').format("YYYY-MM-DD");
    to && to!=null ? this.req_to = to : this.req_to = moment().format("YYYY-MM-DD");


    this.restService.getService('note','/list/CALENDAR_UPDATE/'+this.strService.selectedStructure.id+'?from='+this.req_from+'&to='+this.req_to).subscribe((ris=>{

      if(ris.length<1) {
        Swal.fire(this.translate.instant("ALERTS.load_update"), this.translate.instant("ALERTS.no_more_update"), "success")
      }
      this.timelineData.push({
        from: moment(this.req_from,"YYYY-MM-DD").format("DD-MM-YYYY"),
        to:  moment(this.req_to,"YYYY-MM-DD").format("DD-MM-YYYY"),
        data: ris
      });
      console.debug("TIMELINEDATA",this.timelineData);

      this.loaded = true;
    }))
  }

  loadMore() {
      let new_to = this.req_from;
      let new_from = moment(new_to,"YYYY-MM-DD").subtract(1,'month').format("YYYY-MM-DD");

      this.getUpdateHistory(new_from,new_to);
  }
}
