import { Component, Input, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';

@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.scss']
})
export class ClientDetailComponent {
  @Input() client: any;
  form!: UntypedFormGroup
  showBtnNew: boolean = false;
  province: any;
  country: any;
  structures = [];
  structureSelected: any;

  constructor(private fb: UntypedFormBuilder, public guiService: GuiService, private restService: RestService){

  }

  ngOnInit(): void {
    console.debug(this.client)
    this.initForm()
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.initForm()
  }

  initForm(){
    this.form = this.fb.group({
      name: [null, Validators.required],
      vatNumber: [null, Validators.required],
      cf: [null, Validators.required],
      legalAddressStreet: [null, Validators.required],
      legalAddressCity: [null, Validators.required],
      legalAddressCap: [null, Validators.required],
      legalAddressProvince: [null, Validators.required],
      legalAddressCountry: [null, Validators.required],
      note: [null],
      contact_email: [null],
      contact_name: [null],
      contact_phone: [null]
    })

    this.client && Object.keys(this.client).length > 0 ? (this.populateForm(),this.showBtnNew= false) : this.showBtnNew= true
   }

   populateForm(){
    this.form.controls['name'].setValue(this.client.name)
    this.form.controls['vatNumber'].setValue(this.client.vatNumber)
    this.form.controls['cf'].setValue(this.client.cf)
    this.form.controls['legalAddressStreet'].setValue(this.client.legalAddressStreet)
    this.form.controls['legalAddressCity'].setValue(this.client.legalAddressCity)
    this.form.controls['legalAddressCap'].setValue(this.client.legalAddressCap)
    this.form.controls['legalAddressProvince'].setValue(this.client.legalAddressProvince.name)
    this.form.controls['legalAddressCountry'].setValue(this.client.legalAddressCountry.name)
    this.form.controls['note'].setValue(this.client.note)
   }

  getProvince(){
    this.restService.getService('core_base_new','/common/provinces_like?query='+this.form.value.legalAddressProvince).subscribe((ris)=>{
      this.province = ris[0]
    })
  }

  getCountry(){
    this.restService.getService('core_base_new','/common/countries_like?query='+this.form.value.legalAddressCountry).subscribe((ris)=>{
      this.country = ris[0]
    })
  }

  editClient(){
    let editedClient = {
      id: this.client.id,
      name: this.form.value.name,
      vatNumber: this.form.value.vatNumber,
      cf: this.form.value.cf,
      legalAddressStreet: this.form.value.legalAddressStreet,
      legalAddressCity: this.form.value.legalAddressCity,
      legalAddressCap: this.form.value.legalAddressCap,
      legalAddressProvince: this.province ? this.province : this.client.legalAddressProvince,
      legalAddressCountry: this.country ? this.country : this.client.legalAddressCountry,
      note: this.form.value.note
    }
      console.debug(editedClient)

      this.restService.postService('crm_base_new','/inv_customer/update',editedClient).subscribe((ris)=>{
        this.guiService.editedSuccesfully()
        this.guiService.refreshList.next(true);
      })
  }

  newClient(){
    let newClient = {
      name: this.form.value.name,
      vatNumber: this.form.value.vatNumber,
      cf: this.form.value.cf,
      legalAddressStreet: this.form.value.legalAddressStreet,
      legalAddressCity: this.form.value.legalAddressCity,
      legalAddressCap: this.form.value.legalAddressCap,
      legalAddressProvince: this.province,
      legalAddressCountry: this.country,
      note: this.form.value.note
    }

    this.restService.postService('crm_base_new','/inv_customer/create/1',newClient).subscribe((ris)=>{
      this.guiService.createdSuccessfully()
      this.guiService.refreshList.next(true);
    })
  }

  createContact(){
    let newContact = {
      email: this.form.value.contact_email,
      name: this.form.value.contact_name,
      phone: this.form.value.contact_phone
    }
    this.restService.postService('crm_base_new','/inv_customer/create_contact/'+this.client.id,newContact).subscribe((ris)=>{
      this.client.contacts = ris.data
    })
  }

  deleteContact(idContact: any, indexContact: any){
    this.restService.deleteService('crm_base_new','/inv_customer/delete_contact/'+this.client.id+'/'+idContact,{}).subscribe((ris)=>{
      this.client.contacts.splice(indexContact, 1)
    })
  }

  queryOwner(evento: any) {
    if(evento.term.length > 2){
      console.debug(evento.term, evento.term.length);
      this.restService.getService('structure','/likeList/?query='+evento.term).subscribe((ris)=>{
        this.structures = ris;

      })
    }
  }

  addStructure(){
    this.restService.postService('crm_base_new','/inv_customer/associate_structure/'+this.client.id+'/'+this.structureSelected.id,{}).subscribe((ris)=>{
      this.client.structures = ris.data
    })
  }

  close(){
    this.guiService.closeDetail.next(true);
   }
}
