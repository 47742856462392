import { GuiService } from 'src/app/core/services/gui.service';
import { Component, Input, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { StructureService } from 'src/app/core/services/structure.service';
import { RestService } from 'src/app/core/services/rest.service';
import { er } from '@fullcalendar/core/internal-common';

@Component({
  selector: 'app-room-detail',
  templateUrl: './room-detail.component.html',
  styleUrls: ['./room-detail.component.scss']
})
export class RoomDetailComponent {
  @Input() selectedroom: any;
  room: any;

  showBtnNew: boolean = false;
  rooms: any;
  enabled: any;

  constructor(private fb: UntypedFormBuilder, public guiService: GuiService, public structureService: StructureService, private restService: RestService){

  }

  ngOnInit(): void {
    this.initForm()
   }

   ngOnChanges(changes: SimpleChanges): void {
    this.initForm()
  }

  initForm(){
    console.debug("ROOM SELECTED",this.selectedroom);
    if(!this.selectedroom) {
      this.room = {
        code: null,
        name: null,
        description: null,
        enabled: null,
        baseBeds: null,
        defaultAvailability: null,
        defaultPrice: null,
        idParentRoomType: null,
        maxAvailability: null,
        viewOrder: null
      }
      this.showBtnNew = true
    } else {
      this.room = {
        name: this.selectedroom.name,
        code: this.selectedroom.title,
        description: this.selectedroom.description,
        enabled: this.selectedroom.enabled,
        baseBeds: this.selectedroom.baseBeds,
        defaultAvailability: this.selectedroom.defaultAvailability,
        defaultPrice: this.selectedroom.defaultPrice,
        idParentRoomType: this.selectedroom.parent ? this.selectedroom.parent.id : null,
        maxAvailability: this.selectedroom.maxAvailability,
        viewOrder: this.selectedroom.viewOrder
      }
      this.showBtnNew = false
    }
    this.getRooms()
  }


   newRoom(){
    let newRoom = {
      code: this.room.code,
      name: this.room.name,
      idParentRoomType: this.room.idParentRoomType && this.room.idParentRoomType!="" ? this.room.idParentRoomType : null,
      baseBeds: this.room.baseBeds,
      defaultAvailability: this.room.defaultAvailability,
      defaultPrice: this.room.defaultPrice,
      description: this.room.description ? this.room.description : '',
      enabled: this.room.enabled === null ? false : this.room.enabled
  }
     this.restService.postService('roomtypes','/'+this.structureService.selectedStructure.id, newRoom).subscribe({
      next: (ris) => {
          this.guiService.createdSuccessfully();
          this.guiService.refreshList.next(true);
          this.close();
        },
      error: (err) => {
        console.error(err);
        }
     });
   }

   editRoom(){
    let editRoom = {
      title: this.room.title,
      name: this.room.name,
      idParentRoomType: this.room.idParentRoomType && this.room.idParentRoomType!="" ? this.room.idParentRoomType : null,
      baseBeds: this.room.baseBeds,
      defaultAvailability: this.room.defaultAvailability,
      defaultPrice: parseFloat(this.room.defaultPrice),
      description: this.room.description,
      enabled: this.room.enabled
  }
    this.restService.putService('roomtypes','/'+this.structureService.selectedStructure.id+'/'+this.selectedroom.id, editRoom).subscribe({
      next: (ris) => {
        this.guiService.editedSuccesfully()
        this.guiService.refreshList.next(true);
        this.close();
      },
      error: (err) => {
        console.error(err);
      }
    })
   }

   close(){
    this.guiService.closeDetail.next(true);
   }

   getRooms(){
    let params = {
      page: 0,
      searchCriteria: [{
        "key": "ENABLED",
        "operation" : "EQUALITY",
        "value" : true,
        "orPredicate" : false
      }],
      size: 100,
      structureId: this.structureService.selectedStructure.id
    }
    this.restService.postService('room_type','/v2/search',params).subscribe((ris =>{
    this.rooms = ris.items;
    }))
  }
}
