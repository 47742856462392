import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, Output, type OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-edit-period-modal',
  templateUrl: './edit-period-modal.component.html',
  styleUrls: ['./edit-period-modal.component.scss']
})
export class EditPeriodModalComponent implements OnInit {
  @Input() date!: any;
  @Input() rooms!: any;
  @Output() onCloseHandler = new EventEmitter<any>();
  @Input() items: any;
  controls: any = {
    'status':{},
    'ava': {},
    'min':{}
  };

  ngOnInit(): void {
    console.debug(this.date)
    console.debug(this.rooms)
    console.debug(this.items)
  }

  closeModal(){
    this.onCloseHandler.emit('close')
  }

  edit(){
    console.debug(this.controls)
    for(let item of this.items){
      for(let room in item.data){
        this.controls.ava[room] ? item.data[room].avl = this.controls.ava[room] : null
        this.controls.min[room] ? item.data[room].ms = this.controls.min[room] : null
        this.controls.status[room] ? item.data[room].status = this.controls.status[room] : null
      }
    }
    this.onCloseHandler.emit('close')
  }

}
