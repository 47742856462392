import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';
import { StructureService } from 'src/app/core/services/structure.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss']
})
export class PlanningComponent {
  months: any = [];
  loaded: boolean = true;

  searchCtrl: any;
  years: any = [];

  constructor(public guiService: GuiService, private restService: RestService, public structureService: StructureService,private router: Router, public translate: TranslateService){

    this.structureService.isStructureChanged.subscribe( value => {
      if(value == true){
        this.ngOnInit()
      }
   });

    this.structureService.selectedStructure ? null : Swal.fire({
    title: 'Nessuna struttura selezionata',
    icon: 'warning'
  }).then((result)=>{
    if(result.isConfirmed){
      this.router.navigate(['admin/dashboard']);
    }
  })
  }

  ngOnInit(): void {
    this.getYears()
  }

  getYears(){
    this.years.push(moment().add(2, 'year').format('YYYY'))
    this.years.push(moment().add(1, 'year').format('YYYY'))
    this.years.push(moment().format('YYYY'))
    for(let i = 1; i <= 3; i++){
      this.years.push(moment().subtract(i, 'year').format('YYYY'))
    }
    this.searchCtrl = moment().format('YYYY')
    this.getMonths(this.searchCtrl)
    this.years.reverse()
  }

  getMonths(year:any){


    this.loaded = false;
    
    this.restService.getService('calendar','v4/'+this.structureService.selectedStructure.id+'/closures?from='+year+'-01&months=12').subscribe((ris)=>{
      this.months = [];

      for(let k in ris.planning) {
        let monthID = moment(k,"YYYY-MM").month();
        let items = ris.planning[k].current;
        this.months[monthID] = items;

        let dt = moment(k+"-01",'YYYY-MM-DD');
        this.months[monthID].data = Array(dt.daysInMonth());
        this.months[monthID].skipDays = Array(dt.isoWeekday()-1);


        for(let i=0;i<items.data.length;i++) {
          let tmp = i==0 ? dt.format("YYYY-MM-DD") : dt.add(1,"day").format("YYYY-MM-DD");
          this.months[monthID].data[i] = items.closures[tmp];
        }

        this.months[monthID].allmarked = this.checkIfAllMarked(this.months[monthID].data);
        
      }
      
      this.loaded = true;
    })
  }

  markDay(monthIdx:any,dayIdx:any){
    this.months[monthIdx].data[dayIdx] = !this.months[monthIdx].data[dayIdx];
      
    this.checkIfAllMarked(this.months[monthIdx].data) == true ? this.months[monthIdx].allmarked = true : this.months[monthIdx].allmarked = false;
  }

  markAllDays(monthIdx:any, event:any){
    console.debug(event)
      for(let k in this.months[monthIdx].data){
        if(event){
          this.months[monthIdx].data[k] = false;
        }else{
          this.months[monthIdx].data[k] = true;
        }
      }
  }

  checkIfAllMarked(array:any){
    let result = true;

    for(let k in array) {
      result = !array[k] && result;
      if(!(!array[k] && result)) {
        break;
      }
    }

    return result;
  }

  savePlanning() {
    this.loaded = false;
    let param: any = {
      plannedClosures: {}
    }
    for(let m in this.months){
      let month = this.months[m];
      let dt = moment([this.searchCtrl,m,1]);

      for(let d=0;d<month.data.length;d++) {
        d > 0 ? dt.add(1,"day") : null;

        if(month.data[d] != month.closures[dt.format("YYYY-MM-DD")]) {
          param.plannedClosures[dt.format("YYYY-MM-DD")] = {
            CLOSURE: month.data[d]
          };
        }

      }
    }
    console.debug(param)
     this.restService.putService('calendar','v4/'+this.structureService.selectedStructure.id+'/days/edit-closures', param).subscribe((ris)=>{
       this.guiService.editedSuccesfully();
       this.loaded = true;
     });
  }

}
