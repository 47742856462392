import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-structure-configuration-inv',
  templateUrl: './structure-configuration-inv.component.html',
  styleUrls: ['./structure-configuration-inv.component.scss']
})
export class StructureConfigurationInvComponent {
  @Input() invFlag: boolean = false;
  @Output() public invValue = new EventEmitter<boolean>();

  invValueChange(){
    this.invValue.emit(this.invFlag)
  }

}
