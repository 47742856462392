<div class="page-title-box d-flex align-items-center justify-content-between">
  <h4 class="mb-0">Structure List</h4>
</div>
<div class="col-xxl-12 col-xl-12" *ngIf="guiService.loaded">
  <div class="card card-h-100" *ngIf="structures">
    <div class="card-header row">
      <div class="col-sm-12 col-md-12 col-xl-12">
        <div class="form-floating d-inline-block mx-1">
          <label *ngIf="queryStructureName && queryStructureName!=''">{{ "REPORT.str_name_header" | translate}}</label>
          <input placeholder="{{ 'REPORT.str_name_header' | translate}}" [(ngModel)]="queryStructureName" [class.no_data]="!queryStructureName || queryStructureName==''" />
        </div>
        <div class="form-floating d-inline-block mx-1">
          <ng-select [clearable]="true" placeholder="{{ 'GENERIC.username' | translate }}" [(ngModel)]="ownerCtrl"
            class="" (search)="queryOwner($event)">
            <ng-option *ngFor="let user of users" [value]="user">{{user['username']}}</ng-option>
          </ng-select>
        </div>
        <div class="form-floating d-inline-block mx-1">
          <label>{{ "GENERIC.role" | translate}}:</label>
          <select placeholder="Stato" [(ngModel)]="profileCtrl" [disabled]="!ownerCtrl || ownerCtrl==null">
            <option [value]="null"> - </option>
            <option *ngFor="let item of profiles" [value]="item.id">{{item.name}}</option>
          </select>
        </div>
        <div class="form-floating d-inline-block mx-1">
          <label>{{ "GENERIC.status" | translate}}:</label>
          <select placeholder="Stato" [(ngModel)]="statusCtrl">
            <option *ngFor="let item of status" [value]="item.value">{{ item.label | translate }}</option>
          </select>
        </div>
        <div class="form-floating d-inline-block mx-1">
          <label>{{ "GENERIC.in_consulting" | translate}}:</label>
          <select [(ngModel)]="consultingCtrl">
            <option *ngFor="let item of consulting" [value]="item.value">{{item.label | translate }}</option>
          </select>
        </div>
        <div class="form-floating d-inline-block mx-1">
          <label>{{ "GENERIC.licensed" | translate}}:</label>
          <select [(ngModel)]="licenceCtrl">
            <option *ngFor="let item of licences" [value]="item.value">{{item.label | translate }}</option>
          </select>
        </div>
        <div class="form-floating d-inline-block mx-1">
          <label>{{ "GENERIC.str_enabled" | translate}}:</label>
          <select [(ngModel)]="enableCtrl">
            <option *ngFor="let item of enabled" [value]="item.value">{{item.label | translate }}</option>
          </select>
        </div>
        <div class="form-floating d-inline-block mx-1">
          <button class="btn btn-primary" (click)="getStructures()">
            <i class="uil-search"></i>
          </button>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-xl-12">
        <div class="custom-align-end">
          <button class="btn btn-primary" (click)="newStructureEnable()">
            <i class="uil-plus"></i>
          </button>
          <button class="btn btn-primary" (click)="guiService.chooseItemView('CARD')"
            [class.selected]="guiService.viewType == 'CARD'">
            <i class="uil-table"></i>
          </button>
          <button class="btn btn-primary" [class.selected]="guiService.viewType == 'TABLE'"
            (click)="guiService.chooseItemView('TABLE')">
            <i class="uil-list-ul"></i>
          </button>
          <ngb-pagination class="" (pageChange)="loadPage($event)" [(page)]="page" [pageSize]="size"
            [collectionSize]="structures.totalItems" [maxSize]="3"></ngb-pagination>
        </div>
      </div>
    </div><!-- end card header -->
    <div class="card-body row">

      <div [ngClass]="showDetail ? 'col-sm-2 col-md-6' : 'col-sm-12 col-md-12'">

        <!-- START TABLE VIEW -->
        <div class="table-responsive" *ngIf="guiService.viewType == 'TABLE'">
          <table class="table table-sm table-hover mb-0">
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th class="text-center">{{ 'FX.rooms' | translate }}</th>
                <th class="text-center">{{ 'FX.account' | translate }}</th>
                <th>{{ 'GENERIC.lastUpdate' | translate }}</th>
                <th>{{ 'GENERIC.updated' | translate }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let structure of structures.items">
                <th scope="row" [class.selected]="structureSelected && structure.id == structureSelected.id">
                  #{{structure.id}}</th>
                <td [class.selected]="structureSelected && structure.id == structureSelected.id" class="name-color"><a
                    (click)="detailStructure(structure.id)">{{structure.name}}</a></td>
                <td [class.selected]="structureSelected && structure.id == structureSelected.id" class="text-center">
                  {{structure.rooms}}</td>
                <td [class.selected]="structureSelected && structure.id == structureSelected.id" class="text-center">
                  <span class="badge bg-custom mx-1" *ngFor="let user of structure.owners">
                    <div>{{user.username}}</div>
                    <small>{{user.profileName}}</small>
                  </span>
                </td>
                <td [class.selected]="structureSelected && structure.id == structureSelected.id" class="text-center">
                  {{structure.updatedOccupanciesDatetime | date: 'dd/MM/yyyy HH:mm'}}</td>
                <td [class.selected]="structureSelected && structure.id == structureSelected.id" class="text-center">
                  <p *ngIf="structure.hasNewOccupanciesSinceLastForecast == true"><i class="fas fa-check-square"></i></p>
                </td>
                <td [class.selected]="structureSelected && structure.id == structureSelected.id">
                  <div class="text-end mb-1" ngbDropdown>
                    <i class="uil-cog m-0" ngbDropdownToggle id="dropdownBasic0"></i>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic0">
                      <button ngbDropdownItem (click)="detailStructure(structure.id)">{{"FX.view" | translate}}</button>
                      <button ngbDropdownItem (click)="selectStructure(structure.id)">{{"FX.strselect" | translate}}</button>
                      <button ngbDropdownItem (click)="structureCalendar(structure.id)">{{"FX.calendar" | translate}}</button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody><!-- end tbody -->
          </table><!-- end table -->
        </div><!-- end table responsive -->


        <!-- START CARD VIEW -->
        <div class="card-group justify-content-center" *ngIf="guiService.viewType == 'CARD'">
          <div class="col-xl-3 col-xxl-2 col-sm-3 col-md-3 card-body-structure-wrapper"
            *ngFor="let structure of structures.items">
            <div class="card-body-structure"
              [class.selected]="structureSelected && structure.id == structureSelected.id">
              <div class="action-btn">
                <div class="text-end mb-1" ngbDropdown>
                  <i class="uil-cog m-0" ngbDropdownToggle id="dropdownBasic2"></i>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                    <button ngbDropdownItem (click)="detailStructure(structure.id)">{{"FX.view" | translate}}</button>
                    <button ngbDropdownItem (click)="selectStructure(structure.id)">{{"FX.strselect" | translate}}</button>
                    <button ngbDropdownItem (click)="structureCalendar(structure.id)">{{"FX.calendar" | translate}}</button>
                  </div>
                </div>
              </div>

              <h3 class="pid-area">#{{structure.id}}</h3>

              <div class="titolo d-flex justify-content-between">
                <h1><a (click)="detailStructure(structure.id)">{{structure.name}}</a></h1>
              </div>
              <div class="body-card">
                <div class="">
                  <h3>Rooms:</h3>
                  <p>{{structure.rooms}}</p>
                </div>
                <div class="">
                  <h3>Owner:</h3>
                  <p>{{structure.owner}}</p>
                </div>
                <div class="">
                  <h3>Last Modify: </h3>
                  <p>{{structure.dateModified | date: 'dd/MM/YYYY HH:mm'}}</p>
                </div>
                <div class="">
                  <h3>Updated:</h3>
                  <p *ngIf="structure.updated == true"><i class="fas fa-check-square"></i></p>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-10 col-md-6" *ngIf="showDetail">
        <app-structure-detail [structure]="structureSelected" *ngIf="newStructure == false"></app-structure-detail>
        <app-structure-detail [structure]="structureSelected" *ngIf="newStructure"></app-structure-detail>
      </div>
    </div><!-- end card body -->

  </div><!-- end card -->
</div>

<div *ngIf="!guiService.loaded" class="main-page-loading">
  <div class="loading-bars-box">
    <div class="loading-bars"></div>
  </div>
</div>
