import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RestService } from '../../services/rest.service';
import Swal from 'sweetalert2';
import {GuiService} from "../../services/gui.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  isLoading: boolean = false;
  email: string = "";
  username: string = "";

  constructor(private restService: RestService,private router: Router, public guiService: GuiService, public translate: TranslateService) {
    this.isLoading = false;
  }

  ngOnInit(): void {
  }

  send() {
    this.isLoading = true;
    let param = {
      "email": this.email,
      "username": this.username 
    }

    console.debug("PARAM", param);
    this.restService.extResetPsw("resetpsw", "/reset-link", param).subscribe({
      next: (res)=> {
        console.debug("RES", res);

        Swal.fire({
          title: 'Success!',
          text: this.translate.instant("AUTH.RESET_SEND_EMAIL_SUCCESS"),
          icon: 'success'
        });
        this.isLoading = false;
      },
      error: (err)=> {
        console.debug("ERR", err);
        this.isLoading = false;
      }
    })
  }
}
