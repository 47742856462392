import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable, take } from 'rxjs';
import { RestService } from '../services/rest.service';
import { GuiService } from '../services/gui.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public restService: RestService,private router: Router, public guiService: GuiService){

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.restService.token$.pipe(
      take(1), map((token)=>{
        if(token || sessionStorage.getItem('tkn')){

          this.guiService.userLogged = this.guiService.decodeToken();
          if(localStorage.getItem("appremre") !== null && this.guiService.isJsonString(atob(localStorage.getItem("appremre")+""))) {
            let rem_me = atob(localStorage.getItem("appremre")+"");
            this.restService.REMEMBER_ME = JSON.parse(rem_me);
          }
          return true;
        }
        return this.router.createUrlTree(['/'])
      })
    );
  }

}
