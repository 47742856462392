import {Component, Output, EventEmitter, Renderer2, TemplateRef} from '@angular/core';
import {GuiService} from 'src/app/core/services/gui.service';
import {RestService} from 'src/app/core/services/rest.service';
import {StructureService} from 'src/app/core/services/structure.service';

import {CalendarService} from "../../../../core/services/calendar.service";
import {CookieService} from "ngx-cookie-service";
import { NgbDropdownModule, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';


import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-core-calendar',
  templateUrl: './core-calendar.component.html',
  styleUrls: ['./core-calendar.component.scss']
})
export class CoreCalendarComponent {
  @Output() settingsButtonClicked = new EventEmitter();
  @Output() reloadCalendarInCoreComponent = new EventEmitter();

  Object = Object;

  loadedChannels: boolean = false;
  loadedRooms: boolean = false;
  loadingLegend: boolean = true;
  syncLoading: boolean = false;
  listener: any;
  rooms: any = [];
  channels: any = [];
  selectyears: any = [];
  tooltipInfos: any;

  public Editor = ClassicEditor;
  editorCfg: any = null;
  editorData: any = "";
  isLoadedText: boolean = false;

  items = this.calService.daysForCalendar;
  view_mode: any = 'chart';
  startDateAi: any;
  endDateAi: any;

  dateToSync: any = {
    from: moment().format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD")
  }

  constructor(public structureService: StructureService, private restService: RestService, public guiService: GuiService, public calService: CalendarService,
    private cookieService: CookieService, private renderer2: Renderer2, private offcanvasService: NgbOffcanvas, public translate: TranslateService) {
    this.listener = this.renderer2.listen('window', 'scroll', (e) => {
      // console.debug("SCROLLTOP",this.getScrollingElement().scrollTop);
      // console.debug("HEADER WIDTH",this.headerWidth);
      this.guiService.scrollY = this.guiService.getScrollingElement().scrollTop > 10 ? true : false;
    });


    this.editorCfg = {
      balloonToolbar: {
        items: [ 'bold', 'italic', 'undo', 'redo' ],
        shouldNotGroupWhenFull: true
      },
      toolbar: [ 'bold', 'italic', '|', 'undo', 'redo' ]
    };
  }


  ngOnInit(): void {

    console.debug("###############",this.structureService.selectedStructureConfig);
    if(this.structureService.selectedStructureConfig && this.structureService.selectedStructureConfig["calendar_config"].value != "") {
      this.structureService.structureCalendarConfig = [];
      let tmp: any = JSON.parse(this.structureService.selectedStructureConfig["calendar_config"].value);
      for(let k in tmp) {
        this.structureService.structureCalendarConfig.push(tmp[k].code);
      }
      console.debug("CALENDAR CONFIG", this.structureService.structureCalendarConfig);

      this.getRooms();
      this.getChannels();
      this.loadSelectYear();

      this.view_mode = this.cookieService.get('cal_view_mode') ? this.cookieService.get('cal_view_mode') : 'chart';

    } else {
      this.restService.getService('structure_configuration', '/list_base/'+this.structureService.selectedStructure.id).subscribe((ris)=>{
        this.structureService.selectedStructureConfig = {};

        for (let k in ris.data) {
          let item = ris.data[k];
          this.structureService.selectedStructureConfig[item.key] = item;
        }

        this.structureService.structureCalendarConfig = [];
        let tmp: any = this.structureService.selectedStructureConfig["calendar_config"].value!="" ? JSON.parse(this.structureService.selectedStructureConfig["calendar_config"].value) : [];
        for(let k in tmp) {
          this.structureService.structureCalendarConfig.push(tmp[k].code);
        }
        console.debug("CALENDAR CONFIG", this.structureService.selectedStructureConfig);

        this.getRooms();
        this.getChannels();
        this.loadSelectYear();

        this.view_mode = this.cookieService.get('cal_view_mode') ? this.cookieService.get('cal_view_mode') : 'chart';
      })
    }
  }

  changeTabItem(ctx: string) {
    if (ctx == 'rooms') {
      this.getRooms();
    }
    if (ctx == 'channels') {
      this.getChannels()
    }
  }

  getRooms() {
    if (this.structureService.selectedStructure) {
      console.debug(this.structureService.selectedStructure)
      let params = {
        page: 0,
        searchCriteria: [{
          "key": "ENABLED",
          "operation" : "EQUALITY",
          "value" : true,
          "orPredicate" : false
        }],
        size: 50,
        structureId: this.structureService.selectedStructure.id
      }
      this.restService.postService('room_type', '/v2/search', params).subscribe((ris) => {
        if(this.structureService.structureCalendarConfig && this.structureService.structureCalendarConfig.length>0) {
        this.rooms = [];
          for(let k in ris.items) {
            this.structureService.structureCalendarConfig.indexOf(ris.items[k].title) >= 0 ? this.rooms.push(ris.items[k]) : null;
          }
        } else {
          this.rooms = ris.items;
        }
        console.debug("ROOMS", this.rooms);
        this.loadedRooms = true;
      });
    }

  }

  getChannels() {
    let params = {
      page: 0,
      searchCriteria: [{
        "key": "ENABLED",
        "operation" : "EQUALITY",
        "value" : true,
        "orPredicate" : false
      }],
      size: 30,
      structureId: this.structureService.selectedStructure.id
    }
    this.restService.postService('sales_channel', '/v2/search', params).subscribe((ris) => {
      if(this.structureService.structureCalendarConfig && this.structureService.structureCalendarConfig.lenght>0) {
        this.channels = [];
        for(let k in ris.items) {
          this.structureService.structureCalendarConfig.indexOf(ris.items[k].code) >= 0 ? this.channels.push(ris.items[k]) : null;
        }
      } else {
        this.channels = ris.items;
      }
      console.debug("CHANNELS", this.channels);
      this.loadedChannels = true;
    })
  }


  tooltip(info: any) {
    console.debug(info.title)
    return this.tooltipInfos = info.title
  }

  changeMode(typ: string) {
    this.calService.reloadCalendarData = true;
    this.view_mode = typ;
    this.cookieService.set('cal_view_mode',this.view_mode);
  }

  loadSelectYear() {
    let tmp: any = moment();
    this.selectyears = [];

    for (let i=0;i<5;i++) {
      this.selectyears.push(moment(tmp).subtract(i,"year").year());
    }

  }
  reloadCalendarData() {
    this.reloadCalendarInCoreComponent.emit();
//     this.calService.prepareDays(this.calService.monthSelectedForCalendar);
//    this.calService.calendarDataDays = null;

  }

  closeForecastJob(content: TemplateRef<any>) {

   // /{structureId}/rates-from-last-forecast
   let cfg: any = this.structureService.selectedStructureConfig;
    if(!this.isLoadedText) {
      this.editorData = "";
      if(cfg && cfg.REVOLUTION_EMAIL_ENABLED && cfg.REVOLUTION_EMAIL_ENABLED.value=='1') {

        this.restService.getService('calendar','v4/'+this.structureService.selectedStructure.id+'/rates-from-last-forecast').subscribe( (ris) => {
            console.debug("rates-from-last-forecast", ris);

            if(cfg.mail_header) {
              this.editorData = "<p>"+cfg.mail_header.value+"</p>";
            }

            let cnt = 0;
            let items = Object.keys(ris.days).sort().reduce(
              (obj: any, key: any) => {
                obj[key] = ris.days[key];
                return obj;
              },
              {}
            );
            for(let k in items) {
              let item = ris.days[k];
              cnt++;
              this.editorData += "<small><b>"+k+"</b>: </small><br>";
              for(let i in item.rates) {
                let rate = item.rates[i];
                this.editorData += "<small>- <b>"+rate.roomCode+"</b>: "+rate.rate+" ["+rate.updatedDatetime+"]</small>";
                this.editorData += rate.forced ? "* </small><br>" : "</small><br>";
              }
              this.editorData +="<br>";
            }


            if(cnt==0) {
              this.editorData += "<p>"+this.translate.instant("GENERIC.no_data_edited")+"</p>";
            }

            if(cfg.mail_footer) {
              this.editorData += "<p>"+cfg.mail_footer.value+"</p>";
            }

            this.offcanvasService.open(content, {
              position: 'bottom',
              panelClass: 'summary-edit-rates',
              scroll: true
            });

        });

      } else {
        this.resetPickup("");
      }
    } else {

      this.resetPickup("");
    }
  }

  resetPickup(body?: any) {
    this.loadedChannels = false;
    this.loadedRooms = false;
    let param: any = {
      mailBody: body ? body : this.editorData
    };

    this.restService.postService('email','/close-forecast/'+this.structureService.selectedStructure.id,param).subscribe( (ris)=> {
      console.debug("SEND EMAIL"+ris);
      this.offcanvasService.dismiss('Cross click');

      this.reloadCalendarData();
    });

  }

  reduceBox() {
    this.isLoadedText = true;
    this.offcanvasService.dismiss('Cross click');
  }

  closeBox() {
    this.isLoadedText = false;
    this.offcanvasService.dismiss('Cross click');
  }

  interrogateAi(){

    console.debug(this.calService.monthSelectedForCalendar)
    let startMonthDate = moment(this.calService.yearSelectedForCalendar+'-'+this.calService.monthSelectedForCalendar+'-01','yyyy-MM-DD').format('yyyy-MM-DD');

    let endMonthDate = moment(startMonthDate,'yyyy-MM-DD').endOf('month').format('yyyy-MM-DD');

    let todayPlus10 = moment().add(10,'days').format('yyyy-MM-DD');

    if(moment(startMonthDate,'yyyy-MM-DD').isSameOrAfter(moment(todayPlus10))){
      console.debug('CASO 1')
      this.startDateAi = startMonthDate;
      this.endDateAi = endMonthDate;
      this.guiService.showAiRate = true;
    }else{
      if(moment(endMonthDate,'yyyy-MM-DD').isSameOrAfter(moment(todayPlus10))){
        console.debug('CASO 2')
        this.startDateAi = todayPlus10;
        this.endDateAi = endMonthDate;
        this.guiService.showAiRate = true;
      }else{
        Swal.fire({
          title: "Attenzione!",
          text: "Non è possibile richiedere l'ai con questi valori",
          icon: "warning"
        });
      }
    }
  }

  openSyncPeriodModal(content: TemplateRef<any>) {

    this.offcanvasService.open(content, {
      position: 'bottom',
      panelClass: 'sync-period',
      scroll: true
    });

  }
  syncPeriod() {
    this.syncLoading = true;
    let from = moment(this.dateToSync.from,"YYYY-MM-DD").format("DDMMYYYY");
    let to = moment(this.dateToSync.to,"YYYY-MM-DD").format("DDMMYYYY");

    this.restService.putService('channel','/sync_period/'+this.structureService.selectedStructure.id+'?fromDate='+from+'&toDate='+to,null).subscribe( (ris) => {
      console.debug("SYNC CALENDAR PERIOD", ris);

      this.guiService.genericSuccesfully();
      this.syncLoading = false;
     });


  }

}
