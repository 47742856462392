import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, SimpleChanges, type OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';
import { StructureService } from 'src/app/core/services/structure.service';

@Component({
  selector: 'app-extra-service-detail',
  templateUrl: './extra-service-detail.component.html',
  styleUrls: ['./extra-service-detail.component.scss']
})
export class ExtraServiceDetailComponent implements OnInit {
  @Input() service: any;
  form!: UntypedFormGroup;
  showBtnNew: boolean = true;
  enabled: any;
  servicesList: any = [];
  serviceCtrl:any;
  priceList: any = [];
  priceCtrl:any;
  tantumCtrl!: boolean;

  extraservice: any = {
    "typeId": null,
    "dailyPrice": null,
    "perPerson": false,
    "fromDate": null,
    "toDate": null,
    "monday": true,
	  "tuesday": true,
	  "wednesday": true,
	  "thursday": true,
	  "friday": true,
	  "saturday": true,
	  "sunday": true
  }

  constructor(private fb: UntypedFormBuilder, public guiService: GuiService, public structureService: StructureService, private restService: RestService){

  }

  ngOnInit(): void {
    this.initForm();
    this.getServiceTypeList();
   }

   ngOnChanges(changes: SimpleChanges): void {
    this.initForm()
  }

  initForm(){
    if (this.service && this.service != null) { 
      this.extraservice = this.service;
      this.showBtnNew = false;
    }
  }

   getServiceTypeList(){
    this.restService.getService('extra_service','/types').subscribe( (ris) => {
      console.debug("Service Type List",ris);
      this.servicesList = ris
    })
   }

   newExtraService(){

    console.debug(this.extraservice)
    this.restService.postService('extra_service','/'+this.structureService.selectedStructure.id,this.extraservice).subscribe((ris)=>{
      this.guiService.createdSuccessfully()
       this.form.reset()
       this.guiService.refreshList.next(true);
       this.close();
    })
   }

   editExtraService(){

    console.debug(this.extraservice)
    this.restService.putService('extra_service','/'+this.structureService.selectedStructure.id+'/'+this.extraservice.id,this.extraservice).subscribe({
      next: (ris)=>{
        this.guiService.editedSuccesfully();
         this.guiService.refreshList.next(true);
         this.close();
      },
      error: (err) => {
        console.error(err);
      }
    })
   }

   close(){
    this.guiService.closeDetail.next(true);
   }
}
