import {Component, Input, Output, EventEmitter, Renderer2, OnInit, OnChanges} from '@angular/core';
import {StructureService} from "../../../../../core/services/structure.service";
import {RestService} from "../../../../../core/services/rest.service";
import {GuiService} from "../../../../../core/services/gui.service";
import moment from "moment";
import {CalendarService} from "../../../../../core/services/calendar.service";
import * as math from "mathjs";
import {TranslateService} from "@ngx-translate/core";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarRateHistoryComponent } from '../calendar-rate-history/calendar-rate-history.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-chart-calendar',
  templateUrl: './chart-calendar.component.html',
  styleUrls: ['./chart-calendar.component.scss']
})
export class ChartCalendarComponent implements OnInit, OnChanges {
  loaded: boolean = false;
  loadingCalandarData: boolean = true;
  tmpload: boolean = false;
  loadingLegend: boolean = true;
  @Input('month_year') month_year: any;
  @Input('days_data') days_data: any;
  @Input('rooms') rooms: any;
  @Input('channels') channels: any;
  moment: any = moment;

  @Output() changeTabEvent = new EventEmitter<string>();

  tooltipInfos: any;
  listener: any;

  firstCall: boolean = true;
  roomsIndex: Array<any> = [];

  daysprocessing: Array<any> = [];

  // PER TEST
  capacity: number = this.structureService.selectedStructure.rooms;
  inv: number = this.structureService.selectedStructure.rooms;
  colorMap: any = [];
  colorMap2: any = [];
  rateResult: any = {};
  isAiBtnPress: any = null;

  JSON: any = JSON;

  constructor(public structureService: StructureService, private restService: RestService, public guiService: GuiService, private renderer2: Renderer2,
              public calService: CalendarService, public translate: TranslateService,
              private modalService: NgbModal) {
    this.listener = this.renderer2.listen('window', 'scroll', (e) => {
      // console.debug("SCROLLTOP",this.getScrollingElement().scrollTop);
      // console.debug("HEADER WIDTH",this.headerWidth);
      this.guiService.scrollY = this.guiService.getScrollingElement().scrollTop > 10 ? true : false;
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.rooms.length>0 ? this.loadColorRoomsMap() : null;
    this.calService.editedDays = [];
    console.debug(this.days_data);

    this.loadCalendarData();
  }

  loadCalendarData() {
    console.debug("structureService.structureCalendarConfig",this.structureService.selectedStructureConfig);
    this.loadingCalandarData = true;
    this.calService.calendarDataDays = null;

    let from = moment().year(this.calService.yearSelectedForCalendar).month(parseInt(this.calService.monthSelectedForCalendar)-1).date(1).subtract(3,'days').format("YYYY-MM-DD");
    let to = moment().year(this.calService.yearSelectedForCalendar).month(parseInt(this.calService.monthSelectedForCalendar)-1).date(31).add(3,'days').format("YYYY-MM-DD");

    if(this.calService.dynamicHistoryYearRef==0) {
      this.calService.dynamicHistoryYear = moment(from,"YYYY-MM-DD").subtract(1,'year').format("YYYY");
    } else {
      this.calService.dynamicHistoryYear = this.calService.dynamicHistoryYearRef;
    }


    if(this.calService.staticHistoryYearRef==0) {
      this.calService.staticHistoryYear = moment(from,"YYYY-MM-DD").subtract(1,'year').format("YYYY");
    } else {
      this.calService.staticHistoryYear = this.calService.staticHistoryYearRef;
    }


    this.calService.getCalendarData(from,to,this.calService.staticHistoryYear,this.calService.dynamicHistoryYear).subscribe({
      next: (ris)=> {
      this.rateResult = {};

      console.debug("load Calendar Data");
      for(let day in ris ) {
        let occs = ris[day].occupancies;

for(let k in occs) {

  if(k=="WHOLE_OCCUPANCY") {
    let item = ris[day].occupancies.WHOLE_OCCUPANCY;

    // CURRENT
    if(item) {
      let tmp_cap = ris[day].otherinfos.CAPACITY.current != null ? ris[day].otherinfos.CAPACITY.current.value : this.structureService.selectedStructure.rooms;

      item.current==null ? item.current = {} : null;
      item.current.inv = item.current && item.current.occupancy>0 ? tmp_cap-item.current.occupancy : tmp_cap;
    }

    // DYN HISTORY
    if(item && item.dynHistoric!=null) {
      let tmp_cap = ris[day].otherinfos.CAPACITY.dynHistoric != null ? ris[day].otherinfos.CAPACITY.dynHistoric.value : this.structureService.selectedStructure.rooms;

      item.dynHistoric.inv = item.dynHistoric && item.dynHistoric.occupancy>0 ? tmp_cap-item.dynHistoric.occupancy : tmp_cap;
    }

    // STA HISTORY
    if(item && item.statHistoric!=null) {
      let tmp_cap = ris[day].otherinfos.CAPACITY.statHistoric != null ? ris[day].otherinfos.CAPACITY.statHistoric.value : this.structureService.selectedStructure.rooms;

      item.statHistoric.inv = item.statHistoric && item.statHistoric.occupancy>0 ? tmp_cap-item.statHistoric.occupancy : tmp_cap;
      ris[day].statHistoricDay = this.translate.instant('DAYS.SHORT.' + moment(ris[day].statHistoricDay,"YYYY-MM-DD").day() )+" "+moment(ris[day].statHistoricDay,"YYYY-MM-DD").format("DD-MM-YYYY");
    }
  } else {
    let item = ris[day].occupancies[k];
    if(item.current == null) {
      item.current = {
        "day": day,
        "occupancy": 0,
        "occupancyPickup": 0,
        "occupancyFromGroups": 0,
        "occupancyFromGroupsPickup": 0,
        "groupsVRplus": 0,
        "groupsVRminus": 0,
        "revenue": 0,
        "revenueFromGroups": 0,
        "vrminus": 0,
        "vrplus": 0
      }
    }
    if(item.dynHistoric == null) {
      item.dynHistoric = {
        "day": day,
        "occupancy": 0,
        "occupancyPickup": 0,
        "occupancyFromGroups": 0,
        "occupancyFromGroupsPickup": 0,
        "groupsVRplus": 0,
        "groupsVRminus": 0,
        "revenue": 0,
        "revenueFromGroups": 0,
        "vrminus": 0,
        "vrplus": 0
      }
    }
    if(item.statHistoric == null) {
      item.statHistoric = {
        "day": day,
        "occupancy": 0,
        "occupancyPickup": 0,
        "occupancyFromGroups": 0,
        "occupancyFromGroupsPickup": 0,
        "groupsVRplus": 0,
        "groupsVRminus": 0,
        "revenue": 0,
        "revenueFromGroups": 0,
        "vrminus": 0,
        "vrplus": 0
      }
    }
  }
}

        let rates = ris[day].rates;
        this.rateResult[day] = rates;
        for(let i in rates) {
          if(rates[i].current == null) {
            rates[i].current = {
              forced:false,
              rate:'',
              showAi: false
            }
          }else if(rates[i].current && rates[i].current.rate){
            /* SHOW OR NOT AI BUTTON */
            if(i === this.structureService.selectedStructureConfig['AI_ROOM_REF_0']?.value && moment(day,'YYYY-MM-DD').isSameOrAfter(moment().add(10,'days'),'day')){
              rates[i].current.showAi = true;
            }else{
              rates[i].current.showAi = false;
            }
          }
        }
        rates.edited = false;
      }
      this.calService.calendarDataDays = ris;
      this.loadingCalandarData = false;


      console.debug(ris,this.rateResult);
      console.debug("-----------------------------");
    },
    error: (err)=> {
      console.debug(err);
      this.loadingCalandarData = false;
      this.calService.calendarDataDays = [];
    }
  });
  }
  // PER ORA I DATI SONO CALCOLATI RANDOMICAMENTE
  loadColorRoomsMap() {
    this.colorMap = [];
    this.roomsIndex = [];
    for (let k = 0; k < this.rooms.length; k++) {
      this.roomsIndex.push(this.rooms[k].title);
      this.colorMap.push({
        "label": this.rooms[k].title,
        "color": this.guiService.chart_colors[k],
      });
    }
    this.loaded = true;
  }
  loadColorChannelsMap() {
    this.colorMap2 = [];
    for (let k = 0; k < this.channels.length; k++) {
      this.channels[k].occ = Math.floor(Math.random() * 5);
      this.colorMap2.push({
        "label": this.channels[k].code,
        "color": this.guiService.chart_colors[k],
      });
    }
    this.loaded = true;
    console.debug("CHANNELS",this.channels);
  }

  selectTab(ctx: string) {
    this.guiService.calendarTabSelected = ctx;
    this.changeTabItem(ctx)
  }

  changeTabItem(value: string) {
    this.changeTabEvent.emit(value);
    if(value == "rooms") {

    } else if(value == "channels") {
      this.loadColorChannelsMap();
    }
  }

  tooltip(info: any) {
    console.debug('TOOLTIP TITLE',info.title);
    return this.tooltipInfos = info.title;
  }

  evalDtOld(y:string,m:string,d:string) {
    console.debug("isSameOrBefore",y+m+d);
    return moment().isSameOrBefore(y+m+d, 'day');
  }
  calcInv(day:string,typ:string) {
    setTimeout(() => {
      if(this.calService.calendarDataDays[day].occupancies['WHOLE_OCCUPANCY'] && this.calService.calendarDataDays[day].occupancies['WHOLE_OCCUPANCY'][typ].occupancy>0) {
        let occ = this.calService.calendarDataDays[day].occupancies['WHOLE_OCCUPANCY'][typ].occupancy;
        this.calService.calendarDataDays[day].occupancies['WHOLE_OCCUPANCY'][typ].inv = this.capacity-this.calService.calendarDataDays[day].occupancies['WHOLE_OCCUPANCY'][typ].occupancy;
      } else {
        this.calService.calendarDataDays[day].occupancies['WHOLE_OCCUPANCY'][typ].inv = this.capacity;
      }
    },500);
  }


  checkChanges(indexRow: number, rt: string,day: string) {
    this.rateResult[day].edited = true;
    this.calService.editedDays.indexOf(day) < 0 ? this.calService.editedDays.push(day) : null;
    if(this.rateResult[day][rt].currentFormula==null) {
      this.refreshBaseFormula(indexRow, rt,day);
    } else {
      this.rateResult[day][rt].current.forced = true;
      this.refreshBaseFormula(indexRow, rt,day);
    }
    this.guiService.isConfigEdited = true
  }

  refreshBaseFormula(indexRow: number, rt: string,day: string) {
    let dt = day;
    let price_model = this.rateResult[dt];

    let scope: any = {};

    for(let k in price_model) {
      if(k!= 'edited' && price_model[k].currentFormula==null) {
        scope[k] = price_model[k].current != null ? price_model[k].current.rate : price_model[k].currentMinValue;
      }
    }

    for(let k in price_model) {
      if(k!= 'edited' && price_model[k].currentFormula!=null) {

        if(price_model[k].current.forced) {
          scope[k] = price_model[k].current.rate;
        }
        let tmp = math.parse(price_model[k].currentFormula);
        tmp.forEach(function (node, path, parent) {
          switch (node.type) {
            case 'SymbolNode':
              if(!price_model[k].current.forced) {
                let t: any = node;
                scope[k] = scope[t.name] ? math.evaluate(price_model[k].currentFormula, scope) : price_model[k].currentFormula;
              }
              break
            default:
            // nothing
          }
        })
      }
    }

    for(let k in price_model) {
      if(k!= 'edited' && !price_model[k].current.forced) {
        price_model[k].currentFormula ? price_model[k].current.rate = math.evaluate(price_model[k].currentFormula, scope) : null;
      }
    }

    // VERIFICO CHE I PREZZI SIANO TUTTI IMPOSTATI SULLA GIORNATA
    for(let k in price_model) {
      if(k!= 'edited' && !price_model[k].current) {
        price_model[k].error = true;
      }
    }

  }

  unlockPrice(indexRow: number, rt: string,day: string) {
    this.rateResult[day][rt].current.forced = false;
    this.refreshBaseFormula(indexRow, rt,day);
  }


  calendarRateHistory(day: string) {
    const modalRef = this.modalService.open(CalendarRateHistoryComponent, {size: 'xl'});
    modalRef.componentInstance.day = day;
    modalRef.componentInstance.rooms = this.rooms;
  }


  rateSave(day?: string) {
    this.daysprocessing.indexOf(day) < 0 ? this.daysprocessing.push(day) : null;

    let rateItems: any = {};
    if(day) {
      rateItems[day] = this.rateResult[day];
    } else {
      rateItems = this.rateResult;
    }
    let param: any = {};
    let errorParam: boolean = false;

    console.debug("rateItems", rateItems, this.rooms);
    for(let k in rateItems) {
      let item = rateItems[k];

      if(item.edited) {
        param[k] = {
          rates: {}
        }
        for(let i in item) {
          if(this.roomsIndex.indexOf(i)>=0) {
            if (item[i].currentFormula == null || item[i].current.forced) {
              if (item[i].current != null) {
                delete item[i]['error'];
                param[k].rates[i] = {
                  rateValue: item[i].current.rate,
                  forced: item[i].current.forced ? item[i].current.forced : false
                }
              } else {
                if (i != 'edited') {
                  delete param[k];
                  break;
                }
              }
            }
          }

        }
      }
    }

    console.debug("PARAM TO SAVE", param);
    this.restService.putService('calendar','v4/'+this.structureService.selectedStructure.id+'/days/edit-rates',param).subscribe((ris)=>{
      this.guiService.editedSuccesfully();

      if(day) {
        this.rateResult[day].edited = false;
        this.daysprocessing.splice(this.daysprocessing.indexOf(day), 1);
        this.calService.editedDays.splice(this.calService.editedDays.indexOf(day),1);
      } else {
        this.loadCalendarData();
      }
    });
  }

  loadAiRate(indexRow: number, day: string, code:string) {
    this.isAiBtnPress = indexRow;

    this.restService.getService('ai_rate',this.structureService.selectedStructure.id +'/?from='+ day+ '&to='+day).subscribe((ris)=>{

      let msg = day + "<br>" +
      code + "<br>";

      if(ris.rates[day].quality=="CLOSED") {
        msg += "<span class='badge bg-danger'>"+this.translate.instant("GENERIC.closed")+"</h6>";
      } else {
        msg += ris.rates[day].revolutionRate+" <i class='bx bx-right-arrow-alt'></i> <b>"+ris.rates[day].aiRate+"</b>";
      }

      Swal.fire({
        title: this.translate.instant("AI.ai_rate_proposed"),
        html: msg,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '"#3085d6',
        cancelButtonColor: '"#d33',
        cancelButtonText: this.translate.instant("GENERIC.logout_no"),
        confirmButtonText: this.translate.instant("AI.apply_btn"),
      }).then((result) => {
        this.isAiBtnPress = null;
        if (result.isConfirmed) {
          this.rateResult[day][code].current.rate = ris.rates[day].aiRate;
          this.checkChanges(indexRow,code,day);
        }
      });
    })
  }

  checkOverRevPar(item: any){
    const current = this.capacity> 0 && this.calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current ? this.calService.calendarDataDays[item.day]?.occupancies['WHOLE_OCCUPANCY']?.current && this.calService.calendarDataDays[item.day]?.occupancies['WHOLE_OCCUPANCY']?.current?.revenue / this.capacity : '0';
    const statHistoric = this.capacity> 0 && this.calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric ? this.calService.calendarDataDays[item.day]?.occupancies['WHOLE_OCCUPANCY']?.statHistoric?.revenue / this.capacity : '0';
    return current > statHistoric ? true : false;
  }

  inputStatus(day: any){
    if(this.calService.selectedDay && !this.calService.nowForCalendar.isSameOrBefore(day,'day')){
      if(this.guiService.userLogged.roles !== 'REVOLUTION USER'){
        return true
      }else{
        return true
      }
    }else{
      if(this.guiService.userLogged.roles !== 'REVOLUTION USER'){
        return false
      }else{
        return true
      }
    }
  }
}
