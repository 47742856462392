<div class="page-title-box d-flex align-items-center justify-content-between">
  <h4 class="mb-0">{{"FX.log" | translate}}</h4>
</div>
<div class="col-xxl-12 col-xl-12" >
  <div class="card card-h-100 p-3">
    <div class="card-header justify-content-between d-flex align-items-start p-0">
      <div class="col-sm-12 col-md-12 text-start">
        <ngb-datepicker #dp 
        (dateSelect)="onDateSelection($event)" 
        [displayMonths]="manyMonths" 
        navigation="arrows"
        [maxDate]="now"
        [startDate]="startDate"
        [dayTemplate]="t" outsideDays="collapsed" />

        <ng-template #t let-date let-focused="focused">
          <span
            class="custom-day"
            [class.focused]="focused"
            [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)"
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null"
          >
            {{ date.day }}
          </span>
        </ng-template>

        <div class="col-12 col-md-3 d-inline-block align-top mx-3 text-end">
          <div class="form-floating">
            <ng-select [clearable]="true" placeholder="Utente" [(ngModel)]="selectedUser" class=""
                        (search)="queryOwner($event)">
              <ng-option *ngFor="let user of users" [value]="user">{{user['username']}}</ng-option>
            </ng-select>
          </div>
          <div class="form-floating">
            <ng-select [clearable]="true" placeholder="{{ 'GENERIC.log_action' | translate}}" [(ngModel)]="selected_type" class="">
              <ng-option *ngFor="let item of action_types" [value]="item">{{ "LOG."+item.label | translate}}</ng-option>
            </ng-select>
          </div>
          <div class="form-floating">
            <button type="button" class="btn btn-primary" (click)="getUpdateLog('0')" [disabled]="!fromDate || !toDate">
              <i class="uil uil-search me-2"></i> {{ "FX.view" | translate}}
            </button>
          </div>
        </div>

      </div>

      
    </div><!-- end card header -->
    
    <div class="card-body">
      <div class="row" *ngIf="loaded">
        <div class="col-sm-12 col-md-4">
          <ul id="events-list" *ngIf="logs">
            <li *ngFor="let item of logs.items; let i = index">
              <small>
                {{ item.refDate | date: "dd-MM-YYYY HH:mm:ss z"}}
              </small>
              <h6 (click)="logDetail(i)">{{ "LOG."+item.action | translate }}</h6>
              <small>{{item.ip}} {{item.user}}</small>

              <button type="button" class="btn btn-sm" [ngClass]="selectedLog && selectedLog.id==i ? 'btn-warning' : 'btn-link'" (click)="logDetail(i)">
                <i *ngIf="!selectedLog || selectedLog.id!=i" class="uil uil-circle"></i>
                <i *ngIf="selectedLog && selectedLog.id==i" class="uil uil-angle-right"></i>
              </button>
            </li>
          </ul>

          <ngb-pagination *ngIf="logs" class="" (pageChange)="loadPage($event)" [(page)]="page" [pageSize]="size" [collectionSize]="logs.totalItems" [maxSize]="3"></ngb-pagination>


          <div *ngIf="logs.length<=0">
            <small>{{ "GENERIC.no_data" | translate }}</small>
          </div>
        </div>
        <div class="col-sm-12 col-md-8">
          <ul id="events-detail" *ngIf="selectedLog">
            <li>
              <h6>
                {{ selectedLog.refDate | date: "dd-MM-YYYY HH:mm:ss z"}} - {{ "LOG."+selectedLog.action | translate }}
              </h6>
              <div class="action-data">
                <ngx-json-viewer [json]="json.parse(selectedLog.actionData)"></ngx-json-viewer>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div *ngIf="!loaded" class="main-page-loading">
        <div class="loading-bars-box">
          <div class="loading-bars"></div>
        </div>
      </div>

    </div>

  </div>

</div>
