import { Component, TemplateRef } from '@angular/core';
import { RestService } from "../../../../../../core/services/rest.service";
import { ActivatedRoute, Router } from "@angular/router";
import { StructureService } from "../../../../../../core/services/structure.service";
import { GuiService } from "../../../../../../core/services/gui.service";
import { TranslateService } from "@ngx-translate/core";
import { ChannelParam } from 'src/app/core/enum/channel-param';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from 'src/app/core/services/language.service';


@Component({
  selector: 'app-simplebooking',
  templateUrl: './simplebooking.component.html',
  styleUrls: ['./simplebooking.component.scss']
})
export class SimplebookingComponent {
  helptxt: string = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam blandit aliquet nisl at tristique. Nunc tincidunt tempor consequat. Orci varius natoque penatibus et magnis dis.";
  REF_MEAL_PLAN: Array<any> = [{
    label: "Room Only",
    val: "14"
  }, {
    label: "Bed & Breakfast",
    val: "3"
  }, {
    label: "Half Board",
    val: "12"
  }, {
    label: "Full Board",
    val: "10"
  }, {
    label: "All Inclusive",
    val: "1"
  }];

  editMealPlanSettings: boolean = false;
  mealAccordionShow: Array<boolean> = [true,true,true,true,true];
  mealPlanBase: any = {
    "mealPlans":[
       {
          "mealPlanId":1,
          "mealPlanName":"B&B",
          "enabled": false,
          "mealPlanRooms":{}
       },
       {
          "mealPlanId":2,
          "mealPlanName":"HB",
          "enabled": false,
          "mealPlanRooms":{}
       },
       {
          "mealPlanId":3,
          "mealPlanName":"FB",
          "enabled": false,
          "mealPlanRooms":{

          }
       },
       {
          "mealPlanId":4,
          "mealPlanName":"RO",
          "enabled": false,
          "mealPlanRooms":{}
       },
       {
          "mealPlanId":5,
          "mealPlanName":"AL",
          "enabled": false,
          "mealPlanRooms":{}
       }
    ]
  };
  mealPlanForCopy: any;
  rooms: Array<any> = [];


  constructor(private restService: RestService, private route: ActivatedRoute, public structureService: StructureService, private router: Router, public guiService: GuiService,
    private modalService: NgbModal, public translate: TranslateService, public language: LanguageService) {

  }

  ngOnInit() {
    this.structureService.selectedStructureConfig.SIMPLE_BOOKING_PKEY.value = ChannelParam.SIMPLEBOOKING_PKEY;
    this.getRooms();
  }

  toggleAccordion() {
   // this.mealAccordionShow[i] = !this.mealAccordionShow[i];
  }

  openMealPlansSettings(content: TemplateRef<any>) {
		this.modalService.open(content, { size: 'xl', scrollable: true });
	}

  copyMealPlansSettings() {
    navigator.clipboard.writeText(JSON.stringify(this.mealPlanForCopy ? this.mealPlanForCopy : this.mealPlanBase));
  }

  getRooms(){
    let params = {
      page: 0,
      searchCriteria:[],
      size: 100,
      structureId: this.structureService.selectedStructure.id
    }
    this.restService.postService('room_type','/v2/search',params).subscribe((ris =>{
      this.rooms = ris.items;
      this.prepareParams();
    }))
  }

  prepareParams() {
    console.debug("Prepare Params");

    let tmp = this.structureService.selectedStructureConfig.SIMPLE_BOOKING_MEAL_PLANS.value && this.structureService.selectedStructureConfig.SIMPLE_BOOKING_MEAL_PLANS.value!="" ? JSON.parse(this.structureService.selectedStructureConfig.SIMPLE_BOOKING_MEAL_PLANS.value).mealPlans : this.mealPlanBase.mealPlans;

    for(let k in tmp) {
      let mpId = tmp[k].mealPlanId;
      let mpRooms = tmp[k].mealPlanRooms;

      for(let i in this.rooms) {
        let roomId = this.rooms[i].id;
        let tmp: any = {
          "roomId": roomId,
          "bottom": null,
          "mealPlanFormula": null,
          "rack": null
        }
        this.mealPlanBase.mealPlans[mpId-1].enabled = true
        this.mealPlanBase.mealPlans[mpId-1].mealPlanRooms[roomId] = mpRooms[roomId] ? mpRooms[roomId] : tmp;
        this.mealPlanBase.mealPlans[mpId-1].mealPlanRooms[roomId].enabled = mpRooms[roomId] ? true : false;
      }
      console.debug("MEAL PLANS", this.mealPlanBase);
    }
  }

  setMealPlans() {

    let tmpMealPlan = JSON.parse(JSON.stringify(this.mealPlanBase.mealPlans))

    for(let k in tmpMealPlan) {
      let item = tmpMealPlan[k];
      console.debug(k)
      if(!item.enabled){
        tmpMealPlan.splice(1,k)
      }else{
        for(let i in item.mealPlanRooms) {
        !item.mealPlanRooms[i].enabled ? delete item.mealPlanRooms[i] : delete item.mealPlanRooms[i].enabled;
      }
      }


    }
    this.mealPlanForCopy = tmpMealPlan
    this.structureService.selectedStructureConfig.SIMPLE_BOOKING_MEAL_PLANS.value = JSON.stringify(tmpMealPlan);
    this.prepareParams();
    this.modalService.dismissAll();
  }

  checkPlanEnabled(status: boolean,index:any){
    console.debug(status,index)
    if(!status){
      console.debug(this.mealPlanBase.mealPlans[index])
      for(let roomIndex in this.mealPlanBase.mealPlans[index].mealPlanRooms){
        let room = this.mealPlanBase.mealPlans[index].mealPlanRooms
        room[roomIndex].enabled = false
      }
    }
  }

  checkRoomEnabled(status: boolean,index:any){
    if(status){
      this.mealPlanBase.mealPlans[index].enabled = true;
    }else{
      console.debug('ELSE')
      let control = [];
      for(let roomIndex in this.mealPlanBase.mealPlans[index].mealPlanRooms){
        let room = this.mealPlanBase.mealPlans[index].mealPlanRooms
        console.debug('ROOM',room,room[roomIndex].enabled)
        if(!room[roomIndex].enabled){
          control.push(false)
        }else{
          control.push(true)
        }
      }

      control.includes(true) === true ? this.mealPlanBase.mealPlans[index].enabled = true : this.mealPlanBase.mealPlans[index].enabled = false;
    }
  }
}
