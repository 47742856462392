<div>
  <div class="card mb-0">
    <div class="card-header">
      <h1 class="card-title text-center" *ngIf="user && user.id">{{user.username}}</h1>
      <h1 *ngIf="user && !user.id" class="text-center">Crea nuova utente</h1>
      <i class="uil-times-square close-icon" (click)="close()"></i>
    </div><!-- end card header -->
    <form class="card-body">
      <div class="row">
        <div class="col-12">
          <div class="mb-3 row">
            <label for="name" class="col-md-2 col-form-label">{{"USERS.firstname" | translate}}</label>
            <div class="col-md-10">
              <input class="form-control" type="text" name="name" [(ngModel)]="user.firstName">
            </div>
          </div><!-- end row -->
          <div class="mb-3 row">
            <label for="lastName" class="col-md-2 col-form-label">{{"USERS.lastname" | translate}}</label>
            <div class="col-md-10">
              <input class="form-control" type="text" name="lastName" [(ngModel)]="user.lastName">
            </div>
          </div><!-- end row -->
          <div class="mb-3 row">
            <label for="username" class="col-md-2 col-form-label">{{"USERS.username" | translate}}</label>
            <div class="col-md-10">
              <input class="form-control" type="text" name="username" [disabled]="user.id" [(ngModel)]="user.username">
            </div>
          </div><!-- end row -->
          <!--div class="mb-3 row">
            <label for="clearPassword" class="col-md-2 col-form-label">Password</label>
            <div class="col-md-10">
              <input type="password" class="form-control" name="clearPassword" [(ngModel)]="clearPassword">
            </div>
          </div--><!-- end row -->
          <div class="mb-3 row">
            <label for="email" class="col-md-2 col-form-label">{{"USERS.email" | translate}}</label>
            <div class="col-md-10">
              <input class="form-control" type="text" name="email" [(ngModel)]="user.email">
            </div>
          </div><!-- end row -->
          <div class="mb-3 row">
            <label for="email" class="col-md-2 col-form-label">{{"USERS.lang" | translate}}</label>
            <div class="col-md-10">
              <select [(ngModel)]="user.language" name="language">
                <option *ngFor="let lang of langs" [value]="lang.value">{{lang.label}}</option>
              </select>
            </div>
          </div><!-- end row -->
          <div class="mb-3 row">
            <label for="role" class="col-md-2 col-form-label">{{"USERS.role" | translate}}</label>
            <div class="col-md-10">
              <select [(ngModel)]="roleSelected" name="role">
                <option *ngFor="let role of roles" [value]="role.name">{{role.name}}</option>
              </select>
            </div>
          </div>
          <!-- end row -->
        </div><!-- end col -->
      </div><!-- end row -->
    </form><!-- end card body -->
    <div class="card-footer">
      <div class="m-2 text-center">
        <button (click)="resetUserPSW()" class="btn-reset" *ngIf="showBtnNew == false">{{ "GENERIC.reset_psw_btn" | translate }}</button>
        <button (click)="submitEdit()" class="btn-edit" *ngIf="showBtnNew == false">{{ "GENERIC.edit_btn" | translate }}</button>
        <button (click)="submitNew()" class="btn-edit" *ngIf="showBtnNew == true">{{ "GENERIC.crea_btn" | translate }}</button>
      </div>
    </div>
  </div>


  <div class="card" *ngIf="showBtnNew == false">
    <div class="card-body">
      <div class="row">
        <div class="col-md-5 col-">
          <ng-select [items]="structureList" bindLabel="name" (change)="selectStructure($event)" [(ngModel)]="structureToAssign" (search)="search($event)" placeholder="Seleziona Struttura"></ng-select>
        </div>
        <div class="col-md-5">
          <select [(ngModel)]="roleSelected" [ngModelOptions]="{standalone: true}">
            <option *ngFor="let role of roles" [value]="role.name">{{role.name}}</option>
          </select>
        </div>
        <div class="col-md-2">
          <button class="btn-list-add btn-edit" (click)="assignStructure()">{{ "GENERIC.add_btn" | translate}}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="card" *ngIf="showBtnNew == false">
    <div id="assigned-box" class="card-body">
      <div class="row" *ngFor="let str of structureAssigned">
        <div class="col-md-5 col-">{{str.name}}</div>
        <div class="col-md-5 col-"> ... </div>
        <div class="col-md-2 col-">
          <button class="btn btn-sm btn-link" (click)="removeOwnerStructure(str.id)">Rimuovi</button>
        </div>
      </div>
    </div>
  </div>



</div>
