<div>
  <div class="card mb-0" *ngIf="event">
    <div class="card-header">
      <h1 *ngIf="!event.name"
          class="card-title">{{ "EVENTS.create" | translate }} {{ "EVENTS.event" | translate }} </h1>
      <h1 *ngIf="event.name" class="card-title">{{ "EVENTS.event" | translate }}: {{event.name}} </h1>
      <i class="uil-times-square close-icon" (click)="close()"></i>
    </div><!-- end card header -->
    <div class="card-body">
      <div class="row">
        <div class="form-floating mb-3 row">
          <div class="col-12 form-floating pad-left">
            <input class="form-control" type="text" id="ename" name="ename" [(ngModel)]="event_model.name"
                   placeholder="{{ 'EVENTS.name' | translate }}">
            <label for="ename">{{ "EVENTS.name" | translate }} *</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-floating mb-3 row">
          <div class="form-floating col-3">
            <input class="form-control" type="date" id="eventDay" name="eventDay" [(ngModel)]="event_model.eventDay">
            <label for="eventDay">{{ "EVENTS.start_event" | translate }} *</label>
          </div>
          <div class="form-floating col-3 pad-right">
            <input class="form-control" type="number" min="1" id="eventDuration" name="eventDuration"
                   [(ngModel)]="event_model.eventDuration">
            <label for="eventDuration">{{ "EVENTS.dur_event" | translate }} *</label>
          </div>
          <div class="form-floating col-3">
            <input class="form-control" type="number" min="0" id="daysBefore" name="daysBefore"
                   [(ngModel)]="event_model.daysBefore">
            <label for="daysBefore">{{ "EVENTS.pre_event_day" | translate }} *</label>
          </div>
          <div class="form-floating col-3 pad-right">
            <input class="form-control" type="number" min="0" id="daysAfter" name="daysAfter"
                   [(ngModel)]="event_model.daysAfter">
            <label for="daysAfter">{{ "EVENTS.post_event_day" | translate }} *</label>
          </div>
        </div><!-- end row -->
      </div>


      <div class="row">
        <div class="form-floating col-12">
          <ng-select [clearable]="true" placeholder="{{ 'EVENTS.old_event' | translate}}" [(ngModel)]="overlappedEventId" class=""
                     (search)="queryEvent($event)">
            <ng-option *ngFor="let item of eventlist" [value]="item.id" [disabled]="item.id==event_model.id">
              {{item['name']}} <small>[{{item['eventDay'] | date: 'dd/MM/YYYY'}}]</small>
            </ng-option>
          </ng-select>
        </div>
      </div>

    </div>
    <!-- end col -->

    <div class="card-footer d-flex justify-content-center m-2">
      <button (click)="editEvent()" class="btn-edit" *ngIf="showBtnNew == false">{{"EVENTS.edit" | translate}}</button>
      <button (click)="newEvent()" class="btn-new" *ngIf="showBtnNew == true">{{"EVENTS.create" | translate}}</button>
    </div>
  </div><!-- end card body -->
</div>
