import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';
import { StructureService } from 'src/app/core/services/structure.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-main-cm-settings',
  templateUrl: './main-cm-settings.component.html',
  styleUrls: ['./main-cm-settings.component.scss']
})
export class MainCmSettingsComponent implements OnInit {
  helptxt: string = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam blandit aliquet nisl at tristique. Nunc tincidunt tempor consequat. Orci varius natoque penatibus et magnis dis.";
  selectedCM: any = null;
  isConfigured: boolean = false;

  ch_man = [{
    value: "5STELLE",
    label: "Hotel 5 Stelle"
  },{
    value: "BEDDY",
    label: "Beddy"
  },{
    value: "BEDZZLE",
    label: "Bedzzle"
  },{
    value: "KROSSBOOKING",
    label: "Krossbooking"
  },{
    value: "OCTORATE",
    label: "Octorate"
  },{
    value: "SCIDOO",
    label: "Scidoo"
  },{
    value: "SIMPLEBOOKING",
    label: "Simple booking"
  },{
    value: "VERTICAL",
    label: "Vertical booking"
  },{
    value: "WUBOOK",
    label: "Wubook"
  },{
    value: "ZAK",
    label: "Zak"
  }]

  constructor(private restService: RestService,private route: ActivatedRoute, public structureService: StructureService,private router: Router,public guiService: GuiService,
              public translate: TranslateService) {

  }

  ngOnInit(): void {
    console.debug("On change", this.structureService.selectedStructureConfig.WUBOOK_SYNC_ACTIVE.value);
    this.loadChannelConfig();
  }

  loadChannelConfig() {
    this.selectedCM = this.structureService.selectedStructureConfig.CHANNEL_SYSTEM.value ? this.structureService.selectedStructureConfig.CHANNEL_SYSTEM.value : null;
    this.isConfigured = this.structureService.selectedStructureConfig.CHANNEL_SYSTEM.value ? true : false;
  }

  save() {
    this.guiService.loaded = false;
    let param = [];
    for(let c in this.structureService.selectedStructureConfig){
      param.push(this.structureService.selectedStructureConfig[c]);
    }

    this.structureService.selectedStructureConfig.WUBOOK_URL.value = "https://wired.wubook.net/xrws";

    this.restService.putService('structure_configuration', '/v2/update/'+this.structureService.selectedStructure.id+'/CM',param).subscribe((ris)=>{
      this.guiService.isConfigEdited = false
      this.isConfigured = this.structureService.selectedStructureConfig.CHANNEL_SYSTEM.value ? true : false;
      this.guiService.loaded = true;
      this.guiService.editedSuccesfully()
    })
  }
  reset() {
    this.structureService.loadConfigStructure();
    setTimeout(()=> {
      this.loadChannelConfig();
    },500);
  }

  verify() {
    this.guiService.loaded = false;
    this.restService.getService('channel', '/list/rooms/' + this.structureService.selectedStructure.id).subscribe({
      next: (ris) => {
        if(ris.data && ris.data.length > 0){
          Swal.fire(
          this.translate.instant("GENERIC.verify_btn"),
          this.translate.instant("ALERTS.ch_verify_success"),
          'success'
        );
        } else if(ris.data && ris.data.length==0) {
          Swal.fire(
            this.translate.instant("GENERIC.verify_btn"),
            this.translate.instant("ALERTS.ch_verify_no_data"),
            'warning'
          );
        } else if(!ris.data) {
          Swal.fire(
            this.translate.instant("GENERIC.verify_btn"),
            this.translate.instant("ALERTS.ch_verify_fail"),
            'error'
          );
        }
        this.guiService.loaded = true;
      },
      error: (err) => {
        Swal.fire(
          this.translate.instant("GENERIC.verify_btn"),
          this.translate.instant("ALERTS.ch_verify_fail"),
          'error'
        );
        this.guiService.loaded = true;
      }
    });
  }

  changeCM() {
    console.debug("this.selectedCM",this.selectedCM)
    this.structureService.selectedStructureConfig.CHANNEL_SYSTEM.value = this.selectedCM!=null ? this.selectedCM : '';
    this.selectedCM==null || this.selectedCM=="" ? this.structureService.selectedStructureConfig.WUBOOK_SYNC_ACTIVE.value = '0' : null;
  }

  configKeyChange(k:string) {
    this.structureService.selectedStructureConfig[k].value == "0" ? this.structureService.selectedStructureConfig[k].value = "1" : this.structureService.selectedStructureConfig[k].value = "0";
  }

  activeChangeValue() {

  }
}
