import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';
import { StructureService } from 'src/app/core/services/structure.service';

@Component({
  selector: 'app-plan-mapping',
  templateUrl: './rates-mapping.component.html',
  styleUrls: ['./rates-mapping.component.scss']
})
export class RatesMappingComponent implements OnInit {
  rates: any;
  rateIndex: any;
  channelRates: Array<any> = [];
  channelMapping: Array<any> = [];
  loaded: boolean = true;
  createUpdatePlan: boolean = false;

  rateplan: any;

  constructor(private restService: RestService, private strService: StructureService, private guiService: GuiService, public translate: TranslateService) {

  }

  ngOnInit(): void {
    this.getChannelMapping();
    this.getChannelRates();
  }

  getChannelMapping() {
    this.loaded = false;
    this.restService.getService('channel', '_mapping/get_rates/' + this.strService.selectedStructure.id).subscribe((ris) => {
      ris && ris.data ? this.channelMapping = ris.data : null;

      console.debug("RATES MAPPING", this.channelMapping);

      this.getRates();
    })
  }

  getRates() {
    this.restService.getService('cm_rate', '/list/' + this.strService.selectedStructure.id).subscribe((ris) => {
      console.debug("RATES", ris);
      ris && ris.data ? this.rates = ris.data : null;

      this.preparerateIndex();
    });
  }

  getChannelRates() {
    this.loaded = false;
    this.restService.getService('channel', '/list/rates/' + this.strService.selectedStructure.id).subscribe((ris) => {
      ris && ris.data ? this.channelRates = ris.data : null;

      console.debug("Channel RATES", this.channelRates);

      this.loaded = true;
    })
  }

  close() {
    this.guiService.closeDetail.next(true);
  }
  preparerateIndex() {
    this.rateIndex = {};

    for (let k in this.rates) {
      this.rateIndex[this.rates[k].id] = this.rates[k];
      this.rateIndex[this.rates[k].id].data = null;
      this.rateIndex[this.rates[k].id].channelRateId = this.channelMapping.find(x => x.roomId === this.rates[k].id).channelRoomCode;
      this.rateIndex[this.rates[k].id].channelRateCode = this.channelMapping.find(x => x.roomId === this.rates[k].id).channelRoomCode;
    }
    console.debug("rateIndex",this.rateIndex);

  }

  viewSelect(idx: any) {
    console.debug(this.rateIndex[idx])
  }

  savePlan() {

    let param: any = [];

    for (let k in this.rates) {
      let rate = this.rates[k];

      param.push({
        "roomId": rate.id,
        "channelRoomId": rate.channelRateCode == '' ? null : rate.channelRateCode,
        "channelRoomCode": rate.channelRateCode == '' ? null : rate.channelRateCode
      });
    }
    console.debug("RATE MAPPING", param)

    this.restService.putService('channel', '_mapping/edit_rate/' + this.strService.selectedStructure.id, param).subscribe((ris) => {

      this.guiService.editedSuccesfully();

      this.createUpdatePlan = false;
    })

  }
}