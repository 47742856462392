import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CalculatorService {
  defaultDate: any = null;
  dayPreventive: any;
  rooms: any;
  cart:any = [];
  total: number = 0;

  allowRestore: boolean = false;

  constructor() {

  }

  reset(){
    this.defaultDate  = null;
    this.dayPreventive = undefined;
    this.rooms = undefined;
    this.cart = [];
    this.total= 0;
    this.allowRestore = false;
  }

}
