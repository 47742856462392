<!-- Right Sidebar -->
<div class="right-bar">
  <div data-simplebar class="h-100">
    <div class="rightbar-title d-flex align-items-center p-3">

      <h5 class="m-0 me-2 text-white">Calcolatrice</h5>

      <a href="javascript:void(0);" class="right-bar-toggle-close right-bar-toggle ms-auto" (click)="close();">
        <i class="mdi mdi-close noti-icon"></i>
      </a>
    </div>

    <!-- Settings -->
    <hr class="m-0" />

    <div class="p-2">
      <div class="row">
        <div class="form-floating col-10">
          <input class="form-control flatpickr-input" type="text" mwlFlatpickr mode="range" [(ngModel)]="defaultDate"
            [locale]="locale" [dateFormat]="dateformat">
          <label for="init">From / To</label>
        </div>
        <button class="btn btn-primary col-2" (click)="getCalcInfo()">
          <i class="uil-search"></i>
        </button>

        <div class="calculator" *ngIf="showRooms">
          <div class="row" >
            <div class="col-4" *ngFor="let item of rooms">
              <span class="calc-button" (click)="addToCart(item)">
                {{item.title}}
                <small class="rate">
                  {{item.rate}} {{structureService.selectedStructureConfig && structureService.selectedStructureConfig.currency ? structureService.selectedStructureConfig.currency.value : null}}
                </small>
              </span>
            </div>
          </div>
        </div>
        <div class="cart" *ngIf="showRooms">
          <h1>
            Riepilogo
            <span *ngIf="dayPreventive">
              Giorni Preventivo: {{dayPreventive}}
            </span>
          </h1>

          <div class="cart-items" >
            <div *ngFor="let item of cart" class="item">
              <div class="inner-item">
                <h2>
                {{item.name}}
              </h2>
              <span>
                <i class="uil-plus-circle" (click)="cartAddOrRemove('add',item)"></i>
                {{item.selected}}
                <i class="uil-minus-circle" (click)="cartAddOrRemove('remove',item)"></i>
              </span>
            </div>
              <small>({{item.title}})</small>
            </div>
            <div class="total">
              <span>TOTAL:</span> {{total}} {{structureService.selectedStructureConfig && structureService.selectedStructureConfig.currency ? structureService.selectedStructureConfig.currency.value : null}}
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-4" *ngIf="showRooms">
        <button class="btn btn-edit" (click)="confirmClose()">{{"GENERIC.reset_btn" | translate}}</button>
      </div>
    </div>
  </div> <!-- end slimscroll-menu-->
</div>
<!-- /Right-bar -->
<template id="my-template">
  <swal-title>
   Perche stai abbandonando il preventivo?
  </swal-title>
  <swal-html>
    <button class="btn btn-primary thumbs-up">
      <i-feather class="icon-sm" name="thumbs-up"></i-feather>
      <span class="d-block">Downsell</span>
    </button>
    <button class="btn btn-primary thumbs-down">
      <i-feather class="icon-sm" name="thumbs-down"></i-feather>
      <span class="d-block">Rpt</span>
    </button>
    <button class="btn btn-primary frown">
      <i-feather class="icon-sm" name="frown"></i-feather>
      <span class="d-block">Rpo</span>
    </button>
  </swal-html>
  <swal-icon type="warning" color="red"></swal-icon>
</template>
