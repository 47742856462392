<div>
  <div class="card mb-0">
    <div class="card-header">
        <h1 class="card-title text-center" *ngIf="contract.extra && contract.extra.Customer">{{contract.extra.Customer}}</h1>
        <div *ngIf="contract.extra && contract.extra.Customer" class="text-center">
          <a  [href]="baseURL+'/inv_contract/scan/'+contract.id+'?jwt='+tkn" target="_blank">Visualizza contratto originale</a>
        </div>
        <h1 *ngIf="!contract.extra" class="text-center">Crea nuova contratto</h1>
        <i class="uil-times-square close-icon" (click)="close()"></i>
    </div><!-- end card header -->
    <form class="card-body" [formGroup]="form">
      <div class="row">
        <div class="col-12">
          <div class="mb-3 row form-floating">
            <ng-select [clearable]="true" placeholder="Azienda" bindLabel="name" [items]="companys" [(ngModel)]="companySelected" class="p-0"
              (search)="getCompany($event)" [ngModelOptions]="{standalone: true}">
            </ng-select>
          </div>
          <div class="mb-3 row form-floating">
            <ng-select [clearable]="true" placeholder="Cliente" bindLabel="name" [items]="customers" [(ngModel)]="customerSelected" class="p-0"
              (search)="getCustomer($event)" [ngModelOptions]="{standalone: true}">
            </ng-select>
          </div>
          <div class="mb-3 row form-floating">
            <ng-select [clearable]="true" placeholder="Struttura" bindLabel="name" [items]="structures" [(ngModel)]="structureSelected" class="p-0"
              (search)="getStructure($event)" [ngModelOptions]="{standalone: true}">
            </ng-select>
          </div>
          <div class="mb-3 row form-floating">
            <input type="text" id="startupFee" class="form-control" formControlName="startupFee">
            <label for="startupFee">Fisso per startup</label>
          </div>
          <div class="mb-3 row">
            <div class="form-floating col-4">
              <input type="date" id="startDate" class="form-control" formControlName="startDate">
              <label for="startDate">Data inizio validità</label>
            </div>
            <div class="form-floating col-4">
              <input type="date" id="endDate" class="form-control" formControlName="endDate">
              <label for="endDate">Data fine validità</label>
            </div>
            <div class="form-floating col-4">
              <input type="date" id="revocationDate" class="form-control" formControlName="revocationDate">
              <label for="revocationDate">Data interruzione</label>
            </div>
          </div>
          <div class="row new-contact-container">
            <div class="col-5 form-floating">
              <ng-select [clearable]="true" placeholder="Nome" bindLabel="name" [items]="segnalatori" [(ngModel)]="segnalatoreSelected" class="p-0"
                (search)="getSign($event)" [ngModelOptions]="{standalone: true}">
              </ng-select>
            </div>
            <div class="form-floating col-3">
              <input type="text" id="baseFee" class="form-control" formControlName="baseFee">
              <label for="baseFee">Fisso</label>
            </div>
            <div class="form-floating col-3">
              <input type="text" id="commissionPerc" class="form-control" formControlName="commissionPerc">
              <label for="commissionPerc">Percentuale</label>
            </div>
            <div class="col-1">
              <i class="uil-plus-square" (click)="createSign()"></i>
            </div>
          </div>
          <div *ngFor="let agent of contract.agents; let indice = index" class="contact-container">
            <div class="row"><h4 class="col-5 text-center">{{agent.reference}}</h4> <h4 class="col-3 text-center">{{agent.baseFee}}</h4> <h4 class="col-3 text-center">{{agent.commissionPerc}}</h4>  <div class="col-1 text-center"><i class="uil-trash" (click)="deleteSign(indice)"></i></div></div>
          </div>
          <div class="mb-3 mt-3 row form-floating">
            <textarea class="form-control" type="text" id="note" formControlName="note"></textarea>
            <label for="note" class="col-md-2 col-form-label">Note</label>
          </div>
          <div class="mb-3 mt-3 row">
            <label for="file" class="col-md-2 col-form-label" >File PDF</label>
            <input class="form-control" type="file" id="file" (change)="onFileSelected($event)">
          </div>
        </div>
      </div>
    </form><!-- end card body -->
    <div class="card mb-0" *ngIf="contract.extra && contract.extra.Customer">
      <div class="card-header">
        <h3 class="card-title text-center">Periodi</h3>
      </div>
      <form class="card-body" [formGroup]="form">
        <div class="row new-contact-container">
          <div class="form-floating col-2">
            <input type="date" id="period_startDate" class="form-control" formControlName="period_startDate">
            <label for="period_startDate">Da</label>
          </div>
          <div class="form-floating col-2">
            <input type="date" id="period_endDate" class="form-control" formControlName="period_endDate">
            <label for="period_endDate">A</label>
          </div>
          <div class="form-floating col-1">
            <input type="text" id="period_baseFee" class="form-control" formControlName="period_baseFee">
            <label for="period_baseFee">Fisso</label>
          </div>
          <div class="form-floating col-2">
            <input type="text" id="period_commissionPerc" class="form-control" formControlName="period_commissionPerc">
            <label for="period_commissionPerc">Percentuale</label>
          </div>
          <div class="form-floating col-2">
            <select class="form-select" type="text" id="period_commissionType" formControlName="period_commissionType" >
              <option value="0">Normale</option>
              <option value="1">A scalare</option>
            </select>
          </div>
          <div class="form-floating col-2">
            <select class="form-select" type="text" id="period_refAmountType" formControlName="period_refAmountType" >
              <option value="0">Su fatturato</option>
              <option value="1">Su incremento</option>
            </select>
          </div>
          <div class="col-1">
            <i class="uil-plus-square" (click)="createPeriod()"></i>
          </div>
        </div>
        <div *ngFor="let period of contract.periods; let indice = index" class="contact-container">
          <div class="row">
            <h4 class="col-2">{{period.startDate | date: 'shortDate'}}</h4>
            <h4 class="col-2">{{period.endDate | date: 'shortDate'}}</h4>
            <h4 class="col-1">{{period.baseFee}}</h4>
            <h4 class="col-2">{{period.commissionPerc}}</h4>
            <h4 class="col-2">{{period.commissionType}}</h4>
            <h4 class="col-2">{{period.refAmountType}}</h4>
            <div class="col-1">
              <i class="uil-trash" (click)="deletePeriod(period.id,indice)"></i>
            </div>
          </div>
        </div>
      </form>
    </div>
</div>
<div class="d-flex justify-content-center m-2">
  <button class="btn-new" *ngIf="showBtnNew == true" (click)="newContract()">Crea</button>
</div>
</div>
