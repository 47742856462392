import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reliable',
  templateUrl: './reliable.component.html',
  styleUrls: ['./reliable.component.scss']
})
export class ReliableComponent {

  structures: any;
  originStructures: any;
  page = 1
  size = 15;
  sizeOption: Array<number> = [15, 25, 50, 100];

  constructor(public guiService: GuiService, private restService: RestService, public translate: TranslateService){}


  ngOnInit() {
    this.guiService.changeArea("FX.main");
    this.guiService.selectMenuItems("FX.main");
    this.getStructures();
  }
  
  searchFilter() {
    this.page = 0;
  }
  
  getStructures(){
    this.guiService.loaded = false;

    let param: any = {
      page: this.page-1,
      size: this.size,
      searchCriteria: {}
    }

    console.debug("PAGE",param);

    this.restService.postService('structure','v4/search',param).subscribe({
      next: (ris) => {
        this.structures = JSON.parse(JSON.stringify(ris));
        this.originStructures = JSON.parse(JSON.stringify(ris.items));
        this.guiService.loaded = true;
      },
      error:  () => {
        this.structures = [];
        this.guiService.loaded = true;
      }
    });
  }
   

 loadPage(event:any){
  this.page = event
  this.getStructures()
 }

 saveSingleStructure(id: any) {

  let item: any = null;
  let oriItemId: any = null;

  for(let k in this.structures.items) {
    console.debug("ITEM",this.structures.items[k]);
    if(this.structures.items[k].id === id) {
      item = this.structures.items[k]
    }
  }

  for(let i=0; i<this.originStructures.length; i++) {
    if(this.originStructures[i].id === id) {
      oriItemId = i;
    }
  }
  
  item.reliable ? null : item.occupancyReliable = false;
  item.occReliable ? null : item.occReliable = false;
  item.clusterReliable ? null : item.clusterReliable = false;

  this.restService.postService("structure", "/reliable_status", [item]).subscribe({
    next: (res) => {
      this.guiService.editedSuccesfully();
      this.originStructures[oriItemId] = JSON.parse(JSON.stringify(item));
    },
    error: (err) => {
      
      Swal.fire(
        'Error!',
        this.translate.instant("ALERTS.generic_fail"),
        'error'
      );
    }
  });
 }

 reloadStructureInfo(id: any) {
  console.debug("ID", id);

  let item: any = null;

  for(let k in this.originStructures) {
    if(this.originStructures[k].id === id) {
      item = JSON.stringify(this.originStructures[k]);
    }
  }

  for(let k in this.structures.items) {
    if(this.structures.items[k].id === id) {
      this.structures.items[k] = JSON.parse(item);
    }
  }

 }

 savAll() {
  this.restService.postService("structure", "/reliable_status", this.structures.items).subscribe({
    next: (res) => {
      this.guiService.editedSuccesfully();
    }
  });

 }

 changeSize() {
  console.debug("CHANGE")
  this.page = 1;
  this.getStructures();
 }

}
