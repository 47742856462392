<div class="page-title-box d-flex align-items-center justify-content-between">
  <h4 class="mb-0">{{"TITLE.monthly_report" | translate}}</h4>
</div>
<div class="col-xxl-12 col-xl-12">
  <div class="card card-h-100">
    <div class="card-header justify-content-between d-flex align-items-center">
      <div class="col-sm-12 col-md-12">
        <div class="form-floating">
          <ng-select [clearable]="false" placeholder="Anno 1" [(ngModel)]="year.from" id="y_from">
            <ng-option *ngFor="let item of years" [value]="item">{{item}}</ng-option>
          </ng-select>
        </div>
        <div class="form-floating ">
          <ng-select [clearable]="false" placeholder="Anno 2" [(ngModel)]="year.to" id="y_to">
            <ng-option *ngFor="let item of years" [value]="item">{{item}}</ng-option>
          </ng-select>
        </div>
        <div class="form-floating">
          <span class="btn btn-primary" (click)="getTableDatas()"><i class="uil-search"></i></span>
          <button class="btn btn-link">Esporta</button>
        </div>
      </div>
    </div>
    <div class="contenitore">
      <div class="row tabella" *ngIf="showTable && loaded">
        <div class="col-6">
          <div class="row">
           <div class="col-1 tabella-header">{{"MONTHS.MONTHS" | translate}}</div>
           <div class="col-1 tabella-header">Anni</div>
           <div class="col-2 tabella-header" (click)="initChartOcc('','','','',true,'occ')">Occupazione</div>
           <div class="col-4 tabella-header" (click)="initChartProd('','','','',true,'prod')">Produzione</div>
           <div class="col-2 tabella-header" (click)="initChartRmc('','','','',true,'rmc')">Ricavo medio camera</div>
           <div class="col-2 tabella-header" (click)="initChartRevpar('','','','',true,'revpar')">Rate par</div>
          </div>
          <div class="row row-border" [class.selected-row]="i == rowSelectedIndex" *ngFor="let item of startYear; let i = index" [class.total-row]="item.monthName =='Totale'" (click)="initAllCharts(year.from,year.to,item.occ,compareYear[i].occ,item.prod,compareYear[i].prod,item.rmc,compareYear[i].rmc,item.revpar,compareYear[i].revpar,i)">
            <div class="col-1">
              <span *ngIf="item.month != 0" class="month-name">{{"MONTHS.SHORT."+item.month | translate}}</span>
            </div>
            <div class="col-1 text-center">
              <p>{{year.from}}</p>
              <p *ngIf="compareYear.length > 0" class="compare-data">{{year.to}}</p>
            </div>
            <div class="col-2" [class.selected-col]="selectedColumn == 'occ' && i < 12">
              <div class="row">
                <div class="col-6 d-flex justify-content-center flex-column" *ngIf="compareYear.length > 0">
                  <h4 *ngIf="i === 12" [class.negative]="item.occ < compareYear[12].occ" [class.positive]="item.occ > compareYear[12].occ" [class.neutral]="item.occ === compareYear[12].occ">
                      {{ tmpCalculateDiffrence(item.occ, compareYear[12].occ)}}
                  </h4>
                  <h4 [class.negative]="compareYear[i].occ_calc < 0" [class.positive]="compareYear[i].occ_calc >= 0" *ngIf="i < 12">{{compareYear[i].occ_calc}}</h4>
                </div>
                <div class="col-6 d-flex justify-content-center flex-column" >
                  <p>{{item.occ}}</p>
                  <p *ngIf="compareYear.length > 0" class="compare-data">{{compareYear[i].occ}}</p>
                </div>
              </div>
            </div>
            <div class="col-4" [class.selected-col]="selectedColumn == 'prod' && i < 12">
              <div class="row">
                <div class="col-6 d-flex justify-content-center flex-column" *ngIf="compareYear.length > 0">
                  <h4 *ngIf="i === 12" [class.negative]="item.prod < compareYear[12].prod" [class.positive]="item.prod > compareYear[12].prod" [class.neutral]="item.prod === compareYear[12].prod">
                    {{ tmpCalculateDiffrence(item.prod, compareYear[12].prod) | number : '1.2-2'}}
                  </h4>
                  <h4 [class.negative]="compareYear[i].prod_calc < 0" [class.positive]="compareYear[i].prod_calc >= 0" *ngIf="i < 12">{{compareYear[i].prod_calc | number : '1.2-2'}}</h4>
                </div>
                <div class="col-6 d-flex justify-content-center flex-column">
                  <p>{{item.prod | number : '1.2-2'}}</p>
                  <p *ngIf="compareYear.length > 0" class="compare-data">{{compareYear[i].prod | number : '1.2-2'}}</p>
                </div>
              </div>
            </div>
            <div class="col-2" [class.selected-col]="selectedColumn == 'rmc' && i < 12">
              <div class="row">
                <div class="col-6 d-flex justify-content-center flex-column" *ngIf="compareYear.length > 0">
                  <h4 *ngIf="i === 12" [class.negative]="item.rmc < compareYear[12].rmc" [class.positive]="item.rmc > compareYear[12].rmc" [class.neutral]="item.rmc === compareYear[12].rmc">
                    {{ tmpCalculateDiffrence(item.rmc, compareYear[12].rmc) | number : '1.2-2'}}
                  </h4>
                  <h4 [class.negative]="compareYear[i].rmc_calc < 0" [class.positive]="compareYear[i].rmc_calc >= 0" *ngIf="i < 12">{{compareYear[i].rmc_calc | number : '1.2-2'}}</h4>
                </div>
                <div class="col-6 d-flex justify-content-center flex-column">
                  <p>{{item.rmc | number : '1.2-2'}}</p>
                  <p *ngIf="compareYear.length > 0" class="compare-data">{{compareYear[i].rmc | number : '1.2-2'}}</p>
                </div>
              </div>
            </div>
            <div class="col-2" [class.selected-col]="selectedColumn == 'revpar' && i < 12">
              <div class="row">
                <div class="col-6 d-flex justify-content-center flex-column" *ngIf="compareYear.length > 0">
                  <h4 *ngIf="i === 12" [class.negative]="item.revpar < compareYear[12].revpar" [class.positive]="item.revpar > compareYear[12].revpar" [class.neutral]="item.revpar === compareYear[12].revpar">
                    {{ tmpCalculateDiffrence(item.revpar, compareYear[12].revpar) | number : '1.2-2'}}
                  </h4>
                  <h4 [class.negative]="compareYear[i].revpar_calc < 0" [class.positive]="compareYear[i].revpar_calc >= 0" *ngIf="i < 12">{{compareYear[i].revpar_calc | number : '1.2-2'}}</h4>
                </div>
                <div class="col-6 d-flex justify-content-center flex-column">
                  <p>{{item.revpar | number : '1.2-2'}}</p>
                  <p *ngIf="compareYear.length > 0" class="compare-data">{{compareYear[i].revpar | number : '1.2-2'}}</p>
                </div>
              </div>
            </div>
           </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div [ngClass]="fullChart ? 'col-12' : 'col-6'">
              <div id="chart_occ">
                <apx-chart *ngIf="chartOptions_occ"
                  [series]="chartOptions_occ.series"
                  [chart]="chartOptions_occ.chart"
                  [dataLabels]="chartOptions_occ.dataLabels"
                  [plotOptions]="chartOptions_occ.plotOptions"
                  [yaxis]="chartOptions_occ.yaxis"
                  [legend]="chartOptions_occ.legend"
                  [fill]="chartOptions_occ.fill"
                  [stroke]="chartOptions_occ.stroke"
                  [tooltip]="chartOptions_occ.tooltip"
                  [xaxis]="chartOptions_occ.xaxis"
                  [colors]="chartOptions_occ.colors"
                ></apx-chart>
              </div>
            </div>
            <div [ngClass]="fullChart ? 'col-12' : 'col-6'">
              <div id="chart_prod">
                <apx-chart *ngIf="chartOptions_prod"
                  [series]="chartOptions_prod.series"
                  [chart]="chartOptions_prod.chart"
                  [dataLabels]="chartOptions_prod.dataLabels"
                  [plotOptions]="chartOptions_prod.plotOptions"
                  [yaxis]="chartOptions_prod.yaxis"
                  [legend]="chartOptions_prod.legend"
                  [fill]="chartOptions_prod.fill"
                  [stroke]="chartOptions_prod.stroke"
                  [tooltip]="chartOptions_prod.tooltip"
                  [xaxis]="chartOptions_prod.xaxis"
                  [colors]="chartOptions_prod.colors"
                ></apx-chart>
              </div>
            </div>
          </div>
          <div class="row">
            <div [ngClass]="fullChart ? 'col-12' : 'col-6'">
              <div id="chart_rmc">
                <apx-chart *ngIf="chartOptions_rmc"
                  [series]="chartOptions_rmc.series"
                  [chart]="chartOptions_rmc.chart"
                  [dataLabels]="chartOptions_rmc.dataLabels"
                  [plotOptions]="chartOptions_rmc.plotOptions"
                  [yaxis]="chartOptions_rmc.yaxis"
                  [legend]="chartOptions_rmc.legend"
                  [fill]="chartOptions_rmc.fill"
                  [stroke]="chartOptions_rmc.stroke"
                  [tooltip]="chartOptions_rmc.tooltip"
                  [xaxis]="chartOptions_rmc.xaxis"
                  [colors]="chartOptions_rmc.colors"
                ></apx-chart>
              </div>
            </div>
            <div [ngClass]="fullChart ? 'col-12' : 'col-6'">
              <div id="chart_revpare">
                <apx-chart *ngIf="chartOptions_revpare"
                  [series]="chartOptions_revpare.series"
                  [chart]="chartOptions_revpare.chart"
                  [dataLabels]="chartOptions_revpare.dataLabels"
                  [plotOptions]="chartOptions_revpare.plotOptions"
                  [yaxis]="chartOptions_revpare.yaxis"
                  [legend]="chartOptions_revpare.legend"
                  [fill]="chartOptions_revpare.fill"
                  [stroke]="chartOptions_revpare.stroke"
                  [tooltip]="chartOptions_revpare.tooltip"
                  [xaxis]="chartOptions_revpare.xaxis"
                  [colors]="chartOptions_revpare.colors"
                ></apx-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!loaded" class="main-page-loading">
        <div class="loading-bars-box" *ngIf="startYear.length > 0">
          <div class="loading-bars"></div>
        </div>
      </div>
    </div>
  </div>
</div>

