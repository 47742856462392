<div class="page-title-box d-flex align-items-center justify-content-between">
  <div>
    <h5>{{ "AI.SETTINGS.HELP" | translate}}</h5>
  </div>
</div>
<div class="row align-items-start">
<div class="col-11">
  <div class="row d-flex justify-content-between">
    <div class="col-12">
      <div class="box">
        <label class="config-item">
          <h3 class="d-inline-block">{{"AI.SETTINGS.MAIN_REF" | translate}}</h3>
          <select [(ngModel)]="refTypologies[0]" class="d-inline-block" (change)="resetIfFirstRefEmpty()">
            <option value=""></option>
            <option *ngFor="let item of rooms" [value]="item.title"
              [disabled]="refTypologies.indexOf(item.title) >= 0">
              {{item.title}} - {{item.name}}
            </option>
          </select>
        </label>
      </div>
      <div class="box">
        <label class="config-item">
          <h3 class="d-inline-block">{{"AI.SETTINGS.REF_1" | translate}}</h3>
          <select [(ngModel)]="refTypologies[1]" class="d-inline-block" [disabled]="refTypologies[0] === ''">
            <option value=""></option>
            <option *ngFor="let item of rooms" [value]="item.title"
              [disabled]="refTypologies.indexOf(item.title) >= 0">
              {{item.title}} - {{item.name}}
            </option>
          </select>
        </label>
      </div>
      <div class="box">
        <label class="config-item">
          <h3 class="d-inline-block">{{"AI.SETTINGS.REF_2" | translate}}</h3>
          <select [(ngModel)]="refTypologies[2]" class="d-inline-block" [disabled]="refTypologies[0] === ''">
            <option value=""></option>
            <option *ngFor="let item of rooms" [value]="item.title"
              [disabled]="refTypologies.indexOf(item.title) >= 0">
              {{item.title}} - {{item.name}}
            </option>
          </select>
        </label>
      </div>
      <div class="box">
        <label class="config-item">
          <h3 class="d-inline-block">{{"AI.SETTINGS.REF_3" | translate}}</h3>
          <select [(ngModel)]="refTypologies[3]" class="d-inline-block" [disabled]="refTypologies[0] === ''">
            <option value=""></option>
            <option *ngFor="let item of rooms" [value]="item.title"
              [disabled]="refTypologies.indexOf(item.title) >= 0">
              {{item.title}} - {{item.name}}
            </option>
          </select>
        </label>
      </div>
      <div class="box">
        <label class="config-item">
          <h3 class="d-inline-block">{{"AI.SETTINGS.REF_4" | translate}}</h3>
          <select [(ngModel)]="refTypologies[4]" class="d-inline-block" [disabled]="refTypologies[0] === ''">
            <option value=""></option>
            <option *ngFor="let item of rooms" [value]="item.title"
              [disabled]="refTypologies.indexOf(item.title) >= 0">
              {{item.title}} - {{item.name}}
            </option>
          </select>
        </label>
      </div>
    </div>
  </div>
</div>
<div class="col-1 btn-group-vertical right-sidebar" role="group">
  <button class="btn-send" (click)="save()">
    <span class="uil-folder-check"></span>
    <h1>save</h1>
  </button>
  <button class="btn-delete" (click)="reset()">
    <span class="uil-trash"></span>
    <h1>{{ "GENERIC.reset_btn" | translate }}</h1>
  </button>
</div>
</div>
