<app-chart-calendar *ngIf="view_mode=='chart' && loadedChannels && loadedRooms"
                    [days_data]="calService.daysForCalendar"
                    [month_year]="calService.monthSelectedForCalendar"
                    [rooms]="rooms"
                    [channels]="channels" (changeTabEvent)="changeTabItem($event)"></app-chart-calendar>

<app-table-calendar *ngIf="view_mode=='table' && loadedChannels && loadedRooms"
                    [days_data]="calService.daysForCalendar"
                    [rooms]="rooms"
                    [channels]="channels"></app-table-calendar>

<div *ngIf="!loadedChannels && !loadedRooms" class="main-page-loading">
  <div class="loading-bars-box" *ngIf="channels.length <= 0">
    <div class="loading-bars"></div>
  </div>
</div>

<div class="row legend-box">
  <div class="col-6">
    <div ngbDropdown class="btn-dropdown">
      <a class="table-icon mx-2" id="dropdownBasic1" ngbDropdownToggle>
        <i class="uil-ellipsis-v"></i>
      </a>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem (click)="interrogateAi()">{{"AI.interrogate" | translate}}</button>
        <button ngbDropdownItem (click)="calService.toggleDynamicHistory()">{{(calService.showDynamicHistory ? "FX.hide_dynamic_history" : "FX.show_dynamic_history") | translate}}</button>
        <button ngbDropdownItem (click)="closeForecastJob(content)">{{ "FX.reset_pickup" | translate }}</button>
        <button ngbDropdownItem>{{ "FX.weather" | translate }}</button>
        <button ngbDropdownItem (click)="openSyncPeriodModal(contentsync)">{{ "FX.sync_period" | translate }}</button>
      </div>
    </div>


    <a class="table-icon" *ngIf="view_mode=='table'" (click)="changeMode('chart')">
      <i class="uil-chart-bar-alt"></i>
    </a>
    <a class="table-icon" *ngIf="view_mode=='chart'" (click)="changeMode('table')">
      <i class="uil-table"></i>
    </a>
    <a class="divider">
    </a>
    <label class="config-year-item" *ngIf="calService.showDynamicHistory">
      <small class="d-inline-block dyh">{{"CALENDAR.DYH" | translate}}</small>
      <ng-select [clearable]="false" [(ngModel)]="calService.dynamicHistoryYearRef" class="d-inline-block small">
        <ng-option [value]="0">AUTO</ng-option>
        <ng-option *ngFor="let item of selectyears" [value]="item">{{item}}</ng-option>
      </ng-select>
    </label>
    <label class="config-year-item">
      <small class="d-inline-block sta">{{"CALENDAR.STA" | translate}}</small>
      <ng-select [clearable]="false" [(ngModel)]="calService.staticHistoryYearRef" class="d-inline-block small">
        <ng-option [value]="0">AUTO</ng-option>
        <ng-option *ngFor="let item of selectyears" [value]="item">{{item}}</ng-option>
      </ng-select>
    </label>
    <a class="btn btn-outline-primary" (click)="reloadCalendarData()">
      <i class="uil-sync"></i>
    </a>
  </div>
  <div class="legend-box-col col-6">
    <div class="legend" *ngIf="view_mode=='chart'">
      <div class="ext-square-container" *ngIf="guiService.calendarTabSelected == 'rooms' && rooms && rooms.length>0">
        <div class="square-container" *ngFor="let cm of rooms; let i = index">
          <div class="square-color" [style.background-color]="guiService.chart_colors[i]"></div>
          <h3>{{cm.title}}</h3>
        </div>
        <div class="loading-bars-box" *ngIf="rooms.length <= 0">
          <div class="loading-bars"></div>
        </div>
      </div>
      <div class="ext-square-container" *ngIf="guiService.calendarTabSelected == 'channels' && channels">
        <div class="square-container" *ngFor="let cm of channels; let i = index">
          <div class="square-color" [style.background-color]="guiService.chart_colors[i]"></div>
          <h3>{{cm.code}}</h3>
        </div>
        <div class="loading-bars-box" *ngIf="channels.length <= 0">
          <div class="loading-bars"></div>
        </div>
      </div>
    </div>
</div>
</div>


<!-- NOTIFICATION -->
<div *ngIf="guiService.toastService.toasts.length > 0" class="toast-absolute-br" >
  <ngb-toast
        *ngFor="let toast of guiService.toastService.toasts"
        [class]="toast.classname"
        [autohide]="true"
        [delay]="toast.delay || 5000"
        (hidden)="guiService.toastService.remove(toast)"
      >
      <a (click)='guiService.toastService.remove(toast)' class='tooltip-close'>X</a>
        <ng-template [ngIf]="guiService.isTemplate(toast)" [ngIfElse]="text">
          <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
        </ng-template>

        <ng-template #text>
          <div [innerHtml]=toast.textOrTpl></div>
        </ng-template>
      </ngb-toast>

</div>

<!-- OFF CANVAS -->
<ng-template #content let-offcanvas>
	<div class="row offcanvas-body">
		<div class="col-11">
      <ckeditor [editor]="Editor" [(ngModel)]="editorData" [config]="editorCfg"></ckeditor>
    </div>
    <div class="col-1">
      <button type="button" class="btn btn-light my-2 btn-sm w-100" aria-label="Close" (click)="closeBox()"><i class="bx bx-x me-2"></i> {{ "GENERIC.close_btn" | translate }}</button>
      <button type="button" class="btn btn-warning my-2 btn-sm w-100" (click)="reduceBox()"><i class="bx bx-minus me-2"></i> {{ "FX.reduce_box" | translate }}</button>
      <button type="button" class="btn btn-primary my-5 w-100" (click)="resetPickup()"><i class="bx bx-mail-send me-2"></i> {{ "FX.send_email" | translate }}</button>
    </div>
	</div>
</ng-template>

<!-- OFF CANVAS RMC DETAIL -->
<div class="offcanvas offcanvas-end" tabindex="-1"
  id="offcanvasRight"
  aria-labelledby="offcanvasRightLabel"
  *ngIf="guiService.rmcDetailDayInfo!=null"
  [class.show]="guiService.rmcDetailDayInfo!=null">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasRightLabel">{{guiService.rmcDetailDayInfo.day}}</h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" (click)="guiService.rmcDetailDayInfo = null"></button>
  </div>
  <div class="offcanvas-body">
    <div *ngFor="let room of Object.keys(guiService.rmcDetailDayInfo.data)">
      <div class="row" *ngIf="room!='WHOLE_OCCUPANCY'">
        <div class="col-3"><label>{{room}}</label></div>
        <div class="col-3 current">
          <span class="current">
            <i *ngIf="guiService.rmcDetailDayInfo.data[room].current">
              {{guiService.rmcDetailDayInfo.data[room].current.occupancy>0 ? (guiService.rmcDetailDayInfo.data[room].current.revenue/guiService.rmcDetailDayInfo.data[room].current.occupancy).toFixed(2) : '/'}}
            </i>
          </span>
        </div>
        <div class="col-3 dynHistoric">
          <span class="dynHistoric">
            <i *ngIf="guiService.rmcDetailDayInfo.data[room].dynHistoric">
              {{guiService.rmcDetailDayInfo.data[room].dynHistoric.occupancy>0 ? (guiService.rmcDetailDayInfo.data[room].dynHistoric.revenue/guiService.rmcDetailDayInfo.data[room].dynHistoric.occupancy).toFixed(2) : '/'}}
            </i>
          </span>
        </div>
        <div class="col-3 statHistoric">
          <span class="statHistoric">
            <i *ngIf="guiService.rmcDetailDayInfo.data[room].statHistoric">
              {{guiService.rmcDetailDayInfo.data[room].statHistoric.occupancy>0 ? (guiService.rmcDetailDayInfo.data[room].statHistoric.revenue/guiService.rmcDetailDayInfo.data[room].statHistoric.occupancy).toFixed(2) : '/'}}
            </i>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- OFF CANVAS RATE DETAIL -->

<div class="offcanvas offcanvas-end" tabindex="-1"
  id="offcanvasRight"
  aria-labelledby="offcanvasRightLabel"
  *ngIf="guiService.rateDetailDayInfo!=null"
  [class.show]="guiService.rateDetailDayInfo!=null">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasRightLabel">{{guiService.rateDetailDayInfo.day}}</h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" (click)="guiService.rateDetailDayInfo = null"></button>
  </div>
  <div class="offcanvas-body">
    <div class="row">
      <div class="col-3"></div>
      <div class="col-3 text-center">{{ "REPORT.current" | translate}}</div>
      <div class="col-3 text-center">{{ "REPORT.dynamic_history" | translate}}</div>
      <div class="col-3 text-center">{{ "REPORT.stat_history" | translate}}</div>
    </div>
    <div *ngFor="let room of Object.keys(guiService.rateDetailDayInfo.data)">
      <div class="row" *ngIf="room!='WHOLE_OCCUPANCY'">
        <div class="col-3"><label>{{room}}</label></div>
        <div class="col-3 current">
          <span class="current">
            <i *ngIf="guiService.rateDetailDayInfo.data[room].current">
              {{guiService.rateDetailDayInfo.data[room].current.rate>0 ? guiService.rateDetailDayInfo.data[room].current.rate.toFixed(2) : '/'}}
            </i>
          </span>
        </div>
        <div class="col-3 dynHistoric">
          <span class="dynHistoric">
            <i *ngIf="guiService.rateDetailDayInfo.data[room].dynHistoric">
              {{guiService.rateDetailDayInfo.data[room].dynHistoric.rate>0 ? guiService.rateDetailDayInfo.data[room].dynHistoric.rate.toFixed(2) : '/'}}
            </i>
          </span>
        </div>
        <div class="col-3 statHistoric">
          <span class="statHistoric">
            <i *ngIf="guiService.rateDetailDayInfo.data[room].statHistoric">
              {{guiService.rateDetailDayInfo.data[room].statHistoric.rate>0 ? guiService.rateDetailDayInfo.data[room].statHistoric.rate.toFixed(2) : '/'}}
            </i>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- OFF CANVAS AI RATE-->
<div class="offcanvas offcanvas-end" tabindex="-1"
  id="offcanvasRight"
  aria-labelledby="offcanvasRightLabel"
  *ngIf="guiService.showAiRate"
  [class.show]="guiService.showAiRate">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasRightLabel">{{"AI.room_ref" | translate}}: {{structureService.selectedStructureConfig['AI_ROOM_REF_0'] ? this.structureService.selectedStructureConfig['AI_ROOM_REF_0'].value : null}}</h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" (click)="guiService.showAiRate = false;" *ngIf="!guiService.loaded"></button>
  </div>
  <div class="offcanvas-body">
    <app-ai-rate [startDate]="startDateAi" [endDate]="endDateAi"></app-ai-rate>
  </div>
</div>

<!-- OFF CANVAS SYNC PERIOD-->
<ng-template #contentsync let-offcanvas>
	<div class="row offcanvas-body">
		<div class="form-floating">
      <input class="form-control" type="date" id="init" [(ngModel)]="dateToSync.from">
      <label for="init">DA</label>
    </div>
    <div class="form-floating">
      <input class="form-control" type="date" id="expire" [(ngModel)]="dateToSync.to">
      <label for="expire">A</label>
    </div>
    <button class="btn icona cerca" *ngIf="!syncLoading">
      <i class="uil-search" (click)="syncPeriod()"></i>
    </button>
    <div class="loading-bars-box sync-button" *ngIf="syncLoading">
      <div class="loading-bars"></div>
    </div>
	</div>
</ng-template>
