<div class="col-xxl-12 col-xl-12">
  <div class="card card-h-100" *ngIf="invoices">
    <div class="card-header justify-content-between d-flex align-items-center">
     <div class="col-sm-8 col-md-8">
      <div class="row">
        <div class="form-floating col-4">
          <input type="text" id="social" class="form-control" [(ngModel)]="socialCtrl">
          <label for="social">Ragione sociale agente</label>
        </div>
        <span class="icona cerca col-1">
          <i class="uil-search" (click)="getInvoices()"></i>
        </span>
      </div>
    </div>
    <div class="col-sm-4 col-md-4">
      <div class="d-flex align-items-start justify-content-around top-icons">
        <button class="btn btn-primary" (click)="guiService.chooseItemView('CARD')"
          [class.selected]="guiService.viewType == 'CARD'">
          <i class="uil-table"></i>
        </button>
        <button class="btn btn-primary" [class.selected]="guiService.viewType == 'TABLE'"
          (click)="guiService.chooseItemView('TABLE')">
          <i class="uil-list-ul"></i>
        </button>
        <ngb-pagination class="col-6"
        (pageChange)="loadPage($event)"
          [(page)]="page"
          [pageSize]="size"
          [collectionSize]="invoices.recordsTotal"
          [ellipses]="true"
          [maxSize]="1"
          ></ngb-pagination>
      </div>
    </div>

    </div><!-- end card header -->
    <div class="card-body row">

      <div [ngClass]="showDetail ? 'col-sm-2 col-md-6' : 'col-sm-12 col-md-12'" >

        <!-- START TABLE VIEW -->
        <div class="table-responsive" *ngIf="guiService.viewType == 'TABLE'">
          <table class="table table-sm table-hover mb-0">
            <thead>
            <tr>
              <th class="text-center"></th>
              <th>Agente</th>
              <th class="text-center">Data fattura</th>
              <th>Numero fattura</th>
              <th>Id fattura fic</th>
              <th>Importo netto fattura</th>
              <th>Importo totale fattura</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let invoice of invoices.data">
              <th scope="row" [class.selected]="invoiceSelected && invoice.id == invoiceSelected.id"></th>
              <td [class.selected]="invoiceSelected && invoice.id == invoiceSelected.id" class="name-color"><a>{{invoice.agentName}}</a></td>
              <td [class.selected]="invoiceSelected && invoice.id == invoiceSelected.id" class="text-center">{{invoice.docDate}}</td>
              <td [class.selected]="invoiceSelected && invoice.id == invoiceSelected.id">{{invoice.docNumber}}</td>
              <td [class.selected]="invoiceSelected && invoice.id == invoiceSelected.id">{{invoice.documentId}}</td>
              <td [class.selected]="invoiceSelected && invoice.id == invoiceSelected.id">{{invoice.importoNetto}}</td>
              <td [class.selected]="invoiceSelected && invoice.id == invoiceSelected.id">{{invoice.importoTotale}}</td>
              <td [class.selected]="invoiceSelected && invoice.id == invoiceSelected.id">
                <div class="text-end mb-1" ngbDropdown>
                  <i class="uil-cog m-0 standard" ngbDropdownToggle id="dropdownBasic0"></i>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic0">
                    <button ngbDropdownItem (click)="invoiceDetail(invoice.id,invoice.agentId)">Detail</button>
                    <button ngbDropdownItem (click)="openCloudInvoice(invoice.documentLink)">Fattura Cloud</button>
                  </div>
                </div>
              </td>
            </tr>
            </tbody><!-- end tbody -->
          </table><!-- end table -->
        </div><!-- end table responsive -->


        <!-- START CARD VIEW -->
        <div class="card-group justify-content-center" *ngIf="guiService.viewType == 'CARD'">
          <div class="col-xl-3 col-xxl-2 col-sm-3 col-md-3 card-body-structure-wrapper" *ngFor="let invoice of invoices.data">
            <div class="card-body-structure" [class.selected]="invoiceSelected && invoice.id == invoiceSelected.id">
              <div class="action-btn">
                <div class="text-end mb-1" ngbDropdown>
                  <i class="uil-cog m-0 standard" ngbDropdownToggle id="dropdownBasic2"></i>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                    <button ngbDropdownItem (click)="invoiceDetail(invoice.id,invoice.agentId)">Detail</button>
                    <button ngbDropdownItem (click)="openCloudInvoice(invoice.documentLink)">Fattura Cloud</button>
                  </div>
                </div>
              </div>

              <h3 class="pid-area">#{{invoice.id}}</h3>

              <div class="titolo d-flex justify-content-between">
                <h1><a>{{invoice.agentName}}</a></h1>
              </div>
              <div class="body-card">
                <div class="">
                  <h3>Data fattura:</h3>
                  <p>{{invoice.docDate}}</p>
                </div>
                <div class="">
                  <h3>Numero fattura:</h3>
                  <p>{{invoice.docNumber}}</p>
                </div>
                <div class="">
                  <h3>Id fattura fic:</h3>
                  <p>{{invoice.documentId}}</p>
                </div>
                <div class="">
                  <h3>Importo netto fattura:</h3>
                  <p>{{invoice.importoNetto}}</p>
                </div>
                <div class="">
                  <h3>Importo totale fattura:</h3>
                  <p>{{invoice.importoTotale}}</p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-10 col-md-6" *ngIf="showDetail">
        <app-fatture-agenti-detail [invoice]="invoiceSelected" *ngIf="newInvoice == false"></app-fatture-agenti-detail>
        <app-fatture-agenti-detail [invoice]="{}" *ngIf="newInvoice"></app-fatture-agenti-detail>
      </div>
    </div><!-- end card body -->

  </div><!-- end card -->
</div>
