<div>
  <h1>Fatture</h1>
  <div class="fatture" *ngIf="commission && commission.customersInvoices && commission.customersInvoices.length > 0">
    <div class="box" *ngFor="let invoice of commission.customersInvoices; let i = index">
      <div class="row mb-2">
        <h2 class="col-6">{{invoice.customerName}}</h2>
        <p class="col-6 invoice_number">Fattura N. {{invoice.docNumber}} del {{invoice.docDate | date: 'shortDate'}}</p>
      </div>
      <div class="row mb-2">
        <h4 class="col-6">Fisso: {{invoice.importoFissoAgent == 0 ? '0,00' : invoice.importoFissoAgent}} {{invoice.valuta}} </h4>
        <p class="col-6 status" class="{{invoice.agentFeeStatus}}">{{invoice.agentFeeStatus}}</p>
      </div>
      <div class="row mb-2">
        <h4 class="col-8">Perc. : {{invoice.percentualeAgente}} %</h4>
        <div class="col-4 text-end"><span *ngIf="invoice.agentFeeStatus == 'PAID'"><a href="{{invoice.agentInvoiceUrl}}" target="_blank">Visualizza fattura</a></span></div>
      </div>
      <div [class.show]="invoice.showDiv == true" [class.no-show]="invoice.showDiv == false || invoice.showDiv == undefined">
        <div class="row mb-2 border-bot">
          <h4 class="col-6 smaller">Spese Decurtate :</h4>
          <p class="col-6 amounts">{{invoice.speseDecurtate && invoice.speseDecurtate > 0 ? invoice.speseDecurtate : '0,00'}} {{invoice.valuta}}</p>
        </div>
        <div class="row mb-2 border-bot">
          <h4 class="col-6 smaller">Importo FT. :</h4>
          <p class="col-6 amounts">{{invoice.importoTotaleAgente}} {{invoice.valuta}}</p>
        </div>
        <div class="row mb-2">
          <h4 class="col-6 smaller">Importo % :</h4>
          <p class="col-6 amounts">{{invoice.importoPercentualeAgente}} {{invoice.valuta}}</p>
        </div>
      </div>
      <div class="footer-custom row">
        <div class="col-3">
          <i class="uil-angle-up" *ngIf="invoice.showDiv == true" (click)="showDiv(i,'customersInvoices')"></i>
          <i class="uil-angle-down" *ngIf="invoice.showDiv == false || invoice.showDiv == undefined" (click)="showDiv(i,'customersInvoices')"></i>
        </div>
        <div class="col-9">
          <div class="row">
            <p class="total"><span class="small">totale:</span> {{invoice.importoNetto}} {{invoice.valuta}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
