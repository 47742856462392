<div class="page-title-box d-flex align-items-center justify-content-between">
  <h4 class="mb-0">Caricamento Dati</h4>
</div>
<div class="col-xxl-12 col-xl-12">
  <div class="card card-h-100">
    <div class="card-header justify-content-between d-flex align-items-center p-0">
      <ul ngbNav #customNav="ngbNav" [activeId]="activeTab" class="nav nav-tabs nav-tabs-custom">
          <li [ngbNavItem]="1">
              <a ngbNavLink>
                  <span class="d-none d-sm-block">{{ "FX.uploadfile" | translate }}</span>
              </a>
              <ng-template ngbNavContent>
                <app-upload></app-upload>
              </ng-template>
          </li>
          <li [ngbNavItem]="2">
              <a ngbNavLink>
                  <span class="d-none d-sm-block">{{ "FX.manualedit" | translate }}</span>
              </a>
              <ng-template ngbNavContent>
                <app-manual-mod></app-manual-mod>
              </ng-template>
          </li>
          <li [ngbNavItem]="3" *ngIf="checkRole()">
            <a ngbNavLink>
                <span class="d-none d-sm-block">{{ "FX.manualrate" | translate }}</span>
            </a>
            <ng-template ngbNavContent>
              <app-rates></app-rates>
            </ng-template>
        </li>
      </ul>
    </div><!-- end card header -->
    <div class="card-body">
    <div [ngbNavOutlet]="customNav"></div>
    </div><!-- end card body -->
  </div><!-- end card -->
</div>
