<!-- ========== Left Sidebar Start ========== -->
<div class="vertical-menu" [class.no-sidebar]="guiService.SideBarFlag == true">

  <!-- LOGO -->
  <div class="navbar-brand-box">
    <a routerLink="/" class="logo logo-dark">
      <span class="logo-sm">
        <img src="assets/images/revolution_p_logo.svg" alt="" height="22">
      </span>
      <span class="logo-lg">
        <img src="assets/images/revolution_p_logo.svg" alt="" height="22">
      </span>
    </a>

    <a routerLink="/" class="logo logo-light">
      <span class="logo-lg">
        <img src="assets/images/revolution_p_logo.svg" alt="" height="22">
      </span>
      <span class="logo-sm">
        <img src="assets/images/revolution_p_logo.svg" alt="" height="22">
      </span>
    </a>
  </div>

  <button type="button" class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
    (click)="toggleMobileMenu($event)">
    <i class="fa fa-fw fa-bars"></i>
  </button>

  <!--- Sidemenu -->
  <ngx-simplebar class="sidebar-menu-scroll" style="max-height: 100vh">
    <!--- Sidemenu -->
    <div id="sidebar-menu">
      <!-- Left Menu Start -->
      <ul class="metismenu list-unstyled" id="side-menu">

        <ng-container *ngFor="let item of menuItems">
          <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li>

          <li *ngIf="!item.isTitle && !item.isLayout">
            <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
              [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
              <i-feather name="{{ item.icon }}" class="me-1 nav-icon"></i-feather>
              <span class="menu-item"> {{ item.label | translate }}</span>
              <span class="badge rounded-pill float-end bg-{{item.badge.variant}}" *ngIf="item.badge">{{item.badge.text
                | translate}}</span>
            </a>

            <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
              <i-feather name="{{ item.icon }}" *ngIf="item.icon" class="me-1 nav-icon"></i-feather>
              <span class="menu-item"> {{ item.label | translate }}</span>
              <span class="badge rounded-pill {{item.badge.variant}} float-end" *ngIf="item.badge">{{item.badge.text |
                translate}}</span>
            </a>

            <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
              <li *ngFor="let subitem of item.subItems">
                <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
                  [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                  {{ subitem.label | translate}}
                </a>
                <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                  [attr.data-parent]="subitem.parentId">
                  {{ subitem.label | translate}}
                </a>
                <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                  <li *ngFor="let subSubitem of subitem.subItems">
                    <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link" routerLinkActive="active"
                      class="side-nav-link-ref">
                      {{ subSubitem.label | translate }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ng-container>
      </ul>
    </div>
    <!-- Sidebar -->
  </ngx-simplebar>
  <!-- Sidebar -->

</div>
<!-- Left Sidebar End -->
