<div class="row align-items-start">
  <div class="col-11">
    <app-structure-configuration-inv (invValue)="getInvConfigValue($event)" [invFlag]="invSetting"></app-structure-configuration-inv>
    <div>
      <h5>{{ "CFG.CALENDAR.info" | translate}}</h5>
      <h5>{{ "CFG.CALENDAR.info2" | translate}}</h5>
      <small>{{ "CFG.CALENDAR.info3" | translate}}</small>
    </div>
    <div class="box-calendar">
      <div class="box-config-calendar" *ngFor="let item of calendar; let i = index" (click)="selectCalendar(i)"
        [class.selected-calendar]="i == selectedCalendarIndex">
        <div class="row">
          <!--div class="col-2">
            <span class="top-arrow">
              <span class="fas fa-angle-up d-block icona-arrow" [class.disabled-arrow-move]="i == 0"
                (click)="moveCalendar(i,i-1)"></span>
              <span class="fas fa-angle-down d-block icona-arrow" [class.disabled-arrow-move]="i == calendar.length-1"
                (click)="moveCalendar(i,i+1)"></span>
            </span>
            <span class="top-counter">
              <h3 class="id-config-calendar">#{{i + 1}}</h3>
            </span>
          </div-->
          <div class="col-6 form-floating">
            <input class="form-control" type="text" id="name" placeholder="Nome" [(ngModel)]="item.label"
              [disabled]="item.type != 'custom'">
            <label for="name">Nome</label>
          </div>
          <div class="col-6 form-floating">
            <input class="form-control" type="text" id="code" placeholder="Codice" [(ngModel)]="item.code"
              [disabled]="item.type != 'custom'" (change)="toUpperCaseValue(i,'code')">
            <label for="code">Codice</label>
            <small>(Max 5 Caratteri)</small>
          </div>
        </div>

        <!-- RESOURCES ROW -->
        <!--div class="row mt-3" *ngIf="item.type == 'resource'">
          <div class="col-3">
            <ng-select [clearable]="false" placeholder="Resource Type"
                       (change)="addChipRoom(roomSelected,i)" [(ngModel)]="roomSelected"
                       (search)="getRooms($event)">
              <ng-option *ngFor="let room of rooms" [value]="room"
                         [disabled]="item.resources.includes(room.id)">{{room.title}}</ng-option>
            </ng-select>
          </div>
          <div class="col-9">
            <div class="chip" *ngFor="let resource of item.resources_full; let k = index">
              <h2>{{resource.title}}</h2>
              <i class="uil-times-square chip-icon" (click)="removeChipRoom(k,i)"></i>
            </div>
          </div>
        </div-->


        <!-- SALES CHANNEL ROW -->
        <!--div class="row mt-3" *ngIf="item.type == 'channel'">
          <div class="col-3">
            <ng-select [clearable]="false" placeholder="Sales Channel"
                       (change)="addChipSales(channelSelected,i)" [(ngModel)]="channelSelected">
              <ng-option *ngFor="let channel of sales_channels" [value]="channel"
                         [disabled]="item.channels.includes(channel.id)">{{channel.code}}</ng-option>
            </ng-select>
          </div>
          <div class="col-9">
            <div class="chip" *ngFor="let ch of item.channels_full; let y = index">
              <h2>{{ch.code}}</h2>
              <i class="uil-times-square chip-icon" (click)="removeChipSales(y,i)"></i>
            </div>
          </div>
        </div-->
      </div>
    </div>
  </div>
  <div class="col-1 btn-group-vertical right-sidebar" role="group">
    <div class="btn-group" role="group" ngbDropdown>
      <button id="btnGroupVerticalDrop1" class="btn-add" ngbDropdownToggle>
        <span class="uil-plus"></span>
        <h1>add</h1>
      </button>
      <div class="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1" ngbDropdownMenu>
        <!--a class="dropdown-item cursore" (click)="addCalendar('grp', {title: 'grp'})">Add GRP</a-->
        <!--a class="dropdown-item cursore" (click)="addCalendar('ind', {title: 'ind'})">Add IND</a-->
        <!--a class="dropdown-item cursore" (click)="addCalendar('opz', {title: 'opz'})">Add OPZ</a-->
        <a class="dropdown-item cursore" *ngFor="let room of rooms">
          <a (click)="addCalendar('resource',room)">{{ "CFG.ADD" | translate }} {{room.title}}</a>
        </a>
        <a class="dropdown-item cursore" *ngFor="let sc of sales_channels">
          <a (click)="addCalendar('channel',sc)">{{ "CFG.ADD" | translate }} {{sc.code}}</a></a>
      </div>
    </div>
    <button class="btn-send" (click)="sendCalendar()">
      <span class="uil-folder-check"></span>
      <h1>save</h1>
    </button>
    <button class="btn-delete" (click)="deleteCalendar()">
      <span class="uil-trash"></span>
      <h1>Delete</h1>
    </button>
  </div>

</div>
