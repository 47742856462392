<div class="modal-header">

  <h5>
    <span class="blue">
      #{{structureService.selectedStructure.id}}
    </span>
    {{structureService.selectedStructure.name}}
  </h5>
  <small>
    {{structureService.selectedStructure.city}} ({{structureService.selectedStructure.province}})
  </small>
</div>
<div class="modal-body">
  <div *ngIf="loaded[0] && loaded[1] && loaded[2]">
    <fieldset class="row">
      <legend>
        <h3>
          Dati Struttura
          <span class="expand-icon">
            <i-feather class="icon-sm" name="minus" *ngIf="expandFlags.str" (click)="expandFlags.str = false"></i-feather>
            <i-feather class="icon-sm" name="plus" *ngIf="!expandFlags.str" (click)="expandFlags.str = true"></i-feather>
          </span>
        </h3>
      </legend>
    </fieldset>
    <div class="main-content" [class.hide]="!expandFlags.str">
      <form class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="mb-3 row">
              <div class="row">
                <div class="col-3 form-floating">
                  <input class="form-control" type="text" name="name" id="name"
                    [(ngModel)]="structure.name">
                    <label for="name">Nome</label>
                </div>
                <div class="col-3 form-floating">
                  <input class="form-control" type="text" name="address"
                    [(ngModel)]="structure.address">
                  <label for="address">Indirizzo</label>
                </div>
                <div class="col-3 form-floating">
                  <input class="form-control" type="text" name="cap"
                    [(ngModel)]="structure.cap">
                    <label for="cap">CAP/ZIP code</label>
                </div>
                <div class="col-3 form-floating">
                  <input class="form-control" type="text" name="city"
                    [(ngModel)]="structure.city">
                  <label for="city">Città</label>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <div class="row">
                <div class="col-3 form-floating">
                  <select class="form-select" type="text" name="province"
                    [(ngModel)]="structure.province">
                    <option [value]="provincia.sigla" *ngFor="let provincia of provinces">{{provincia.name}}</option>
                  </select>
                  <label for="province">Provincia</label>
                </div>
                <div class="col-3 form-floating">
                  <input class="form-control" type="text" name="country"
                    [(ngModel)]="structure.country">
                    <label for="country">Nazione</label>
                </div>
                <div class="col-3 form-floating">
                  <input class="form-control" type="text" name="latitude"
                    [(ngModel)]="structure.latitude">
                    <label for="latitude">Latitudine</label>
                </div>
                <div class="col-3 form-floating">
                  <input class="form-control" type="text" name="longitude"
                    [(ngModel)]="structure.longitude">
                    <label for="longitude">Longitudine</label>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <div class="row">
                <div class="col-3 form-floating">
                  <input class="form-control" type="text" name="rooms"
                    [(ngModel)]="structure.rooms">
                  <label for="rooms">Camere</label>
                </div>
                <div class="col-3 form-floating">
                  <input class="form-control" type="text" name="phoneNumber"
                    [(ngModel)]="structure.phoneNumber">
                  <label for="phoneNumber">Telefono</label>
                </div>
                <div class="col-3 form-floating">
                  <input class="form-control" type="number" name="fax"
                    [(ngModel)]="structure.fax">
                  <label for="fax">Fax</label>
                </div>
                <div class="col-3 form-floating">
                  <input class="form-control" type="text" name="webSite"
                    [(ngModel)]="structure.webSite">
                  <label for="webSite">Sito web</label>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <div class="row">
                <div class="col-4 form-floating">
                  <input class="form-control" type="text" name="responsible"
                    [(ngModel)]="structure.responsible">
                  <label for="responsible">Referente</label>
                </div>
                <div class="col-4 form-floating">
                  <input class="form-control" type="email" name="communicationEmail"
                    [(ngModel)]="structure.communicationEmail">
                  <label for="communicationEmail">Comunicazioni (Email)</label>
                </div>
                <div class="col-4 form-floating">
                  <input class="form-control" type="email" name="reservationEmail"
                    [(ngModel)]="structure.reservationEmail">
                  <label for="reservationEmail">Prenotazioni (Email)</label>
                </div>
              </div>
            </div>
            <div class="mb-3 row">
              <div class="row">
                <label for="" class="col-1"></label>
                <label for="enabled" class="col-2 col-form-label text-center">Abilitata</label>
                <label for="consulting" class="col-2 col-form-label text-center">In consulenza</label>
                <label for="consultingTerminated" class="col-2 col-form-label text-center">Consulenza terminata</label>
                <label for="licensed" class="col-2 col-form-label text-center">Licenza</label>
                <label for="licenseTerminated" class="col-2 col-form-label text-center">Licenza terminata</label>
                <label for="" class="col-1"></label>
              </div>
              <div class="row">
                <div class="col-1"></div>
                <div class="col-2 d-flex justify-content-center">
                  <ui-switch defaultBoColor="#dfdfdf" color="#51d28c"
                    [(ngModel)]="structure.enabled" [ngModelOptions]="{standalone: true}"
                    checkedLabel="Si" uncheckedLabel="No"></ui-switch>
                </div>
                <div class="col-2 d-flex justify-content-center">
                  <ui-switch defaultBoColor="#dfdfdf" color="#51d28c"
                    [(ngModel)]="structure.consulting" [ngModelOptions]="{standalone: true}"
                    checkedLabel="Si" uncheckedLabel="No"></ui-switch>
                </div>
                <div class="col-2 d-flex justify-content-center">
                  <ui-switch defaultBoColor="#dfdfdf" color="#51d28c"
                    [(ngModel)]="structure.consultingTerminated"
                    [ngModelOptions]="{standalone: true}" checkedLabel="Si" uncheckedLabel="No"></ui-switch>
                </div>
                <div class="col-2 d-flex justify-content-center">
                  <ui-switch defaultBoColor="#dfdfdf" color="#51d28c"
                    [(ngModel)]="structure.licensed" [ngModelOptions]="{standalone: true}"
                    checkedLabel="Si" uncheckedLabel="No"></ui-switch>
                </div>
                <div class="col-2 d-flex justify-content-center">
                  <ui-switch defaultBoColor="#dfdfdf" color="#51d28c"
                    [(ngModel)]="structure.licenseTerminated"
                    [ngModelOptions]="{standalone: true}" checkedLabel="Si" uncheckedLabel="No"></ui-switch>
                </div>
                <div class="col-1"></div>
              </div>
            </div>
          </div><!-- end col -->
        </div><!-- end row -->
        <div class="text-center mt-3 mb-2">
          <button class="btn btn-edit" (click)="editInfoStr()">{{"FX.edit" | translate}}</button>
        </div>
      </form>
    </div>
    <fieldset class="row">
      <legend>
        <h3>
          Tag
          <span class="expand-icon">
            <i-feather class="icon-sm" name="minus" *ngIf="expandFlags.tag" (click)="expandFlags.tag = false"></i-feather>
            <i-feather class="icon-sm" name="plus" *ngIf="!expandFlags.tag" (click)="expandFlags.tag = true"></i-feather>
          </span>
        </h3>
      </legend>
    </fieldset>
    <div class="main-content" [class.hide]="!expandFlags.tag">
      <div class="row">
        <label class="col-4 col-form-label text-center">Geo</label>
        <label class="col-4 col-form-label text-center">Struttura</label>
        <label class="col-4 col-form-label text-center">Tipo</label>
      </div>
      <div class="row mb-4">
        <div class="col-4">
          <select class="form-select" type="text" name="geo"
            [(ngModel)]="tag_geoCtrl">
            <option [value]="item.id" *ngFor="let item of tag_geo">{{item.value}}</option>
          </select>
        </div>
        <div class="col-4">
          <select class="form-select" type="text" name="struttura"
            [(ngModel)]="tag_strTypeCtrl">
            <option [value]="item.id" *ngFor="let item of tag_strType">{{item.value}}</option>
          </select>
        </div>
        <div class="col-4">
          <select class="form-select" type="text" name="tipo"
            [(ngModel)]="tag_typeCtrl">
            <option [value]="item.id" *ngFor="let item of tag_type">{{item.value}}</option>
          </select>
        </div>
      </div>
      <div class="text-center mt-3 mb-2">
        <button class="btn btn-edit" (click)="editTags()">{{"FX.edit" | translate}}</button>
      </div>
    </div>
    <fieldset class="row">
      <legend>
        <h3>
          Note
          <span class="expand-icon">
            <i-feather class="icon-sm" name="minus" *ngIf="expandFlags.note" (click)="expandFlags.note = false"></i-feather>
            <i-feather class="icon-sm" name="plus" *ngIf="!expandFlags.note" (click)="expandFlags.note = true"></i-feather>
          </span>
        </h3>
      </legend>
    </fieldset>
    <div class="main-content" [class.hide]="!expandFlags.note">
      <div class="note-list">
        <div class="note" *ngFor="let note of notes">
          <span class="date">{{note.date | date:'shortDate'}}</span>
          <span class="badge">{{note.type}}</span>
          {{note.value}}
            <span class="trash" (click)="guiService.deleteConfirm('note','/delete',note.id)">
              <i-feather class="icon-sm" name="trash"></i-feather>
            </span>
        </div>
      </div>
      <textarea class="form-control" type="text" rows="4"  [(ngModel)]="noteCtrl"></textarea>
      <div class="text-center mt-3 mb-2">
        <button class="btn btn-new" (click)="addNote()">Aggiungi Nota</button>
      </div>
    </div>
  </div>
  <div *ngIf="!loaded[0] || !loaded[1] || !loaded[2]" class="main-page-loading">
    <div class="loading-bars-box">
      <div class="loading-bars"></div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="modalService.dismissAll()">{{"GENERIC.close_btn" | translate}}</button>
</div>
