import { formatDate } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contratti-detail',
  templateUrl: './contratti-detail.component.html',
  styleUrls: ['./contratti-detail.component.scss']
})
export class ContrattiDetailComponent {
  @Input() contract: any;
  form!: UntypedFormGroup
  showBtnNew: boolean = false;
  companys: any = [];
  customers: any = [];
  structures: any = [];
  segnalatori: any = [];
  segnalatoreSelected: any;
  companySelected: any;
  customerSelected: any;
  structureSelected: any;
  selectedFile: any = null;

  baseURL: any = environment.baseURL.crm_base_new
  tkn: any;

  constructor(private fb: UntypedFormBuilder, public guiService: GuiService, private restService: RestService){

  }
  ngOnInit(): void {
    this.initForm()
    this.tkn = sessionStorage.getItem('tkn')
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initForm()
  }

  initForm(){
    this.form = this.fb.group({
      note: [null, Validators.required],
      startupFee: [null, Validators.required],
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      revocationDate: [null],
      commissionPerc: [null],
      baseFee: [null],
      period_startDate: [null],
      period_endDate: [null],
      period_baseFee: [null],
      period_commissionPerc: [null],
      period_commissionType: [null],
      period_refAmountType: [null]
    })
    console.debug(this.contract)
    this.contract && Object.keys(this.contract).length > 0 ? (this.populateForm(),this.showBtnNew= false) : this.showBtnNew= true
   }

   populateForm(){
    this.form.controls['note'].setValue(this.contract.description),
    this.form.controls['startDate'].setValue(this.contract.startDate ? formatDate(this.contract.startDate,'yyyy-MM-dd','en'): null),
    this.form.controls['endDate'].setValue(this.contract.endDate ? formatDate(this.contract.endDate,'yyyy-MM-dd','en'): null),
    this.form.controls['revocationDate'].setValue(this.contract.revocationDate ? formatDate(this.contract.revocationDate,'yyyy-MM-dd','en') : null),
    this.form.controls['startupFee'].setValue(this.contract.startupFee),
    this.companySelected = this.contract.extra.Company ? this.contract.extra.Company : null,
    this.customerSelected = this.contract.extra.Customer ? this.contract.extra.Customer : null,
    this.structureSelected = this.contract.extra.Structure ? this.contract.extra.Structure : null
   }

  getCompany(evento:any){
    if(evento.term.length > 2){
      this.restService.getService('crm_base_new','/inv_company/like_list?query='+evento.term).subscribe((ris)=>{
      this.companys = ris.data
    })
    }
  }

  getStructure(evento:any){
    if(evento.term.length > 2){
      this.restService.getService('crm_base_new','/structure/likeList?query='+evento.term).subscribe((ris)=>{
      this.structures = ris
    })
    }
  }

  getCustomer(evento:any){
    if(evento.term.length > 2){
      this.restService.getService('crm_base_new','/inv_customer/like_list?query='+evento.term).subscribe((ris)=>{
      this.customers = ris.data
    })
    }
  }

  getSign(evento:any){
    if(evento.term.length > 2){
      this.restService.getService('crm_base_new','/inv_supplier/like_list?query='+evento.term).subscribe((ris)=>{
      this.segnalatori = ris.data
    })
    }
  }

  createSign(){
    let sign = {
      "baseFee": this.form.value.baseFee,
      "commissionPerc": this.form.value.commissionPerc,
      "reference": this.segnalatoreSelected.name,
      "referenceId": this.segnalatoreSelected.id
    }
    this.contract && this.contract.agents ? null : this.contract['agents'] = []
    this.contract.agents.push(sign)
    console.debug(this.contract)
  }

  deleteSign(index: any){
    this.contract.agents.splice(index,1)
    console.debug(this.contract)
  }

  saveSigns(){
    this.restService.postService('crm_base_new','/inv_contract/associate_agents/'+this.contract.id,this.contract.agents).subscribe((ris)=>{

    })
  }

  onFileSelected(event: any) {
    console.debug(event)
    this.selectedFile = event.target.files[0];
    console.log(this.selectedFile);
  }

  newContract(){
    this.contract && this.contract.agents ? null : this.contract['agents'] = []
    let payload = new FormData();
    payload.append('file', this.selectedFile)
    payload.append('startDate', this.form.value.startDate)
    payload.append('endDate', this.form.value.endDate)
    payload.append('startupFee', this.form.value.startupFee)
    payload.append('structureId', this.structureSelected.id)
    payload.append('companyId', this.companySelected.id)
    payload.append('customerId', this.customerSelected.id)
    payload.append('note', this.form.value.note)
    payload.append('agents', JSON.stringify(this.contract.agents))

    console.debug(this.selectedFile)

    this.restService.postService('crm_base_new','/inv_contract/create_with_files',payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).subscribe((ris)=>{
      this.guiService.createdSuccessfully();
      this.guiService.refreshList.next(true);
    })
  }


  close(){
    this.guiService.closeDetail.next(true);
  }

  createPeriod(){
    let newPeriod = {
      "_startDate": this.form.value.period_startDate,
      "_endDate": this.form.value.period_endDate,
      "baseFee": this.form.value.period_baseFee,
      "commissionPerc": this.form.value.period_commissionPerc,
      "contractId": this.contract.id,
      "commissionType": this.form.value.period_commissionType,
      "refAmountType": this.form.value.period_refAmountType
  }
    this.restService.postService('crm_base_new','/inv_contract/associate_period/',newPeriod).subscribe((ris)=>{
      this.guiService.createdSuccessfully()
      this.contract.periods = ris.data
    })
  }

  deletePeriod(idPeriod: any, indexContact: any){
    this.restService.deleteService('crm_base_new','/inv_contract/delete_period/'+this.contract.id+'/'+idPeriod,{}).subscribe((ris)=>{
      this.contract.periods.splice(indexContact, 1)
    })
  }

}
