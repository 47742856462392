import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';
import { StructureService } from 'src/app/core/services/structure.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-extra-service',
  templateUrl: './extra-service.component.html',
  styleUrls: ['./extra-service.component.scss']
})
export class ExtraServiceComponent implements OnInit {
  structureView: boolean = false;
  serviceSelected: any = null;
  newService: boolean = false;
  serviceCtrl: any;
  page = 1
  size = 15;
  services: any;
  showDetail: boolean = false;

  constructor(public guiService: GuiService, private restService: RestService, public structureService: StructureService,private router: Router){
    this.structureService.isStructureChanged.subscribe( value => {
      if(value == true){
        this.ngOnInit()
        this.serviceSelected = null;
        this.showDetail = false;
      }
   });

    this.newService = false

    this.guiService.closeDetail.subscribe( value => {
      if(value == true){
        this.serviceSelected = null;
        this.showDetail = false;
      }
  });

  this.guiService.refreshList.subscribe( value => {
    if(value == true){
      this.page = 1
      this.getServices()
    }
   });

   this.structureService.selectedStructure ? null : Swal.fire({
    title: 'Nessuna struttura selezionata',
    icon: 'warning'
  }).then((result)=>{
    if(result.isConfirmed){
      this.router.navigate(['admin/dashboard']);
    }
  })

  }


  ngOnInit(): void {
    this.getServices()
  }

  getServices(){
    console.debug(this.structureService.selectedStructure.id)
    this.restService.getService('extra_service','/'+this.structureService.selectedStructure.id).subscribe((ris)=>{
      console.debug('services',this.services)
      this.services = ris
    })
  }

  loadPage(event:any){
    this.page = event
    this.getServices()
   }


   detailService(ch: number) {
      this.serviceSelected = ch;
      this.newService = false
      this.showDetail = true;
   }

   newServiceEnable() {
     this.newService = true;
     this.serviceSelected = null;
     this.showDetail = true;
   }

}
