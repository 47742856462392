import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {RestService} from "../../../../core/services/rest.service";
import {ActivatedRoute, Router} from "@angular/router";
import {StructureService} from "../../../../core/services/structure.service";
import {GuiService} from "../../../../core/services/gui.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-structure-configuration-cm',
  templateUrl: './structure-configuration-cm.component.html',
  styleUrls: ['./structure-configuration-cm.component.scss']
})
export class StructureConfigurationCmComponent implements OnInit {
  loaded: boolean = false;
  contentAreaLoaded: boolean = false;
  showDetail: boolean= false;
  subArea: string = "";
  enableSubArea: boolean = false;

  constructor(private restService: RestService, public structureService: StructureService, private guiService: GuiService, public translate: TranslateService) {

  }
  ngOnInit(): void {
    this.loadTab('MAIN');

    this.enableSubArea = this.structureService.selectedStructureConfig && this.structureService.selectedStructureConfig.WUBOOK_SYNC_ACTIVE==1;
  }

  loadTab(content:string) {
    this.showDetail = true;
    this.subArea = content;
  }

}
