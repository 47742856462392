import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {UiSwitchModule} from 'ngx-ui-switch';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {NgbDropdownModule, NgbModalModule, NgbModule, NgbNav, NgbNavItem, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../environments/environment';

import {LayoutsModule} from './layouts/layouts.module';
import {LoginComponent} from './core/auth/login/login.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ResetPasswordComponent} from './core/auth/reset-password/reset-password.component';
import {ConfirmResetPasswordComponent} from './core/auth/confirm-reset-password/confirm-reset-password.component';
import {StructureListComponent} from './pages/admin/structure-list/structure-list.component';
import {StructureDetailComponent} from './pages/admin/structure-list/structure-detail/structure-detail.component';
import {UsersListComponent} from './pages/admin/users-list/users-list.component';
import {UserDetailComponent} from './pages/admin/users-list/user-detail/user-detail.component';
import {RoomDetailComponent} from './pages/settings/room-list/room-detail/room-detail.component';
import {PriceDetailComponent} from './pages/settings/price-list/price-detail/price-detail.component';
import {TokenInterceptor} from './core/services/token.interceptor';
import {ProfileComponent} from './pages/shared/profile/profile.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {NgSelectModule} from '@ng-select/ng-select';
import {SalesChannelDetailComponent} from './pages/settings/sales-channel-list/sales-channel-detail/sales-channel-detail.component';
import {DataEntryComponent} from './pages/operation/data-entry/data-entry.component';
import {UploadComponent} from './pages/operation/data-entry/upload/upload.component';
import {ManualModComponent} from './pages/operation/data-entry/manual-mod/manual-mod.component';
import {FeatherModule} from "angular-feather";
import {NgApexchartsModule} from 'ng-apexcharts';
import {CalendarComponent} from './pages/operation/calendar/calendar.component';
import {TopCalendarComponent} from './pages/operation/calendar/top-calendar/top-calendar.component';
import {RightCalendarComponent} from './pages/operation/calendar/right-calendar/right-calendar.component';
import {CoreCalendarComponent} from './pages/operation/calendar/core-calendar/core-calendar.component';
import {
  RatesComponent
} from './pages/operation/data-entry/rates/rates.component';
import {ChartCalendarComponent} from './pages/operation/calendar/core-calendar/chart-calendar/chart-calendar.component';
import {TableCalendarComponent} from './pages/operation/calendar/core-calendar/table-calendar/table-calendar.component';
import {AnagraficheComponent} from './pages/invoices/anagrafiche/anagrafiche.component';
import {ContrattiComponent} from './pages/invoices/contratti/contratti.component';
import {AgentiComponent} from './pages/invoices/agenti/agenti.component';
import {ConteggiComponent} from './pages/invoices/conteggi/conteggi.component';
import {ClientiComponent} from './pages/invoices/anagrafiche/clienti/clienti.component';
import {FornitoriComponent} from './pages/invoices/anagrafiche/fornitori/fornitori.component';
import {ProvvigioniComponent} from './pages/invoices/agenti/provvigioni/provvigioni.component';
import {FattureAgentiComponent} from './pages/invoices/agenti/fatture-agenti/fatture-agenti.component';
import {ClientDetailComponent} from './pages/invoices/anagrafiche/clienti/client-detail/client-detail.component';
import {ClientConfigComponent} from './pages/invoices/anagrafiche/clienti/client-config/client-config.component';
import {
  FornitoriDetailComponent
} from './pages/invoices/anagrafiche/fornitori/fornitori-detail/fornitori-detail.component';
import {ContrattiDetailComponent} from './pages/invoices/contratti/contratti-detail/contratti-detail.component';
import {
  FattureAgentiDetailComponent
} from './pages/invoices/agenti/fatture-agenti/fatture-agenti-detail/fatture-agenti-detail.component';
import {
  ProvvigioniDetailComponent
} from './pages/invoices/agenti/provvigioni/provvigioni-detail/provvigioni-detail.component';
import {ConteggiDetailComponent} from './pages/invoices/conteggi/conteggi-detail/conteggi-detail.component';
import {AvailabilityComponent} from './pages/operation/availability/availability.component';
import {UpdatehistoryComponent} from './pages/operation/updatehistory/updatehistory.component';
import {ReportGeneralComponent} from './pages/stats/report-general/report-general.component';
import {ReportViewMonthComponent} from './pages/stats/report-view-month/report-view-month.component';
import {ReportViewClusterComponent} from './pages/stats/report-view-cluster/report-view-cluster.component';
import {ReportBiComponent} from './pages/stats/report-bi/report-bi.component';
import {ExtraServiceComponent} from './pages/settings/extra-service/extra-service.component';
import {CustomerCareComponent} from './pages/support/customer-care/customer-care.component';
import {SettingsComponent} from "./pages/left-side/settings.component";
import {RoomListComponent} from "./pages/settings/room-list/room-list.component";
import {PriceListComponent} from "./pages/settings/price-list/price-list.component";
import {SalesChannelListComponent} from "./pages/settings/sales-channel-list/sales-channel-list.component";
import {
  StructureConfigurationComponent
} from "./pages/settings/structure-configuration/structure-configuration.component";
import {
  StructureConfigurationUserComponent
} from "./pages/settings/structure-configuration/structure-configuration-user/structure-configuration-user.component";
import {
  StructureConfigurationCalendarComponent
} from "./pages/settings/structure-configuration/structure-configuration-calendar/structure-configuration-calendar.component";
import {PlanningComponent} from "./pages/settings/planning/planning.component";
import {
  StructureConfigurationPmsComponent
} from "./pages/settings/structure-configuration/structure-configuration-pms/structure-configuration-pms.component";
import { VideoGuideComponent } from './pages/support/video-guide/video-guide.component';
import { ManualsComponent } from './pages/support/manuals/manuals.component';
import { UpdatemonitoringComponent } from './pages/operation/updatemonitoring/updatemonitoring.component';
import { SafePipe } from './pages/shared/safe.pipe';
import {NgxYoutubePlayerModule} from "ngx-youtube-player";
import {LightboxModule} from "ngx-lightbox";
import { FlatpickrModule } from 'angularx-flatpickr';
import {NgxJsonViewerModule} from "ngx-json-viewer";
import { StructureConfigurationMainComponent } from './pages/settings/structure-configuration/structure-configuration-main/structure-configuration-main.component';
import { StructureConfigurationComComponent } from './pages/settings/structure-configuration/structure-configuration-com/structure-configuration-com.component';
import { StructureConfigurationCmComponent } from './pages/settings/structure-configuration/structure-configuration-cm/structure-configuration-cm.component';
import { BeddyComponent } from './pages/settings/structure-configuration/structure-configuration-cm/Providers/beddy/beddy.component';
import { BedzzleComponent } from './pages/settings/structure-configuration/structure-configuration-cm/Providers/bedzzle/bedzzle.component';
import { H5stelleComponent } from './pages/settings/structure-configuration/structure-configuration-cm/Providers/h5stelle/h5stelle.component';
import { DhynetComponent } from './pages/settings/structure-configuration/structure-configuration-cm/Providers/dhynet/dhynet.component';
import { HotelbbComponent } from './pages/settings/structure-configuration/structure-configuration-cm/Providers/hotelbb/hotelbb.component';
import { OctorateComponent } from './pages/settings/structure-configuration/structure-configuration-cm/Providers/octorate/octorate.component';
import { ParityrateComponent } from './pages/settings/structure-configuration/structure-configuration-cm/Providers/parityrate/parityrate.component';
import { PasspartoutComponent } from './pages/settings/structure-configuration/structure-configuration-cm/Providers/passpartout/passpartout.component';
import { SimplebookingComponent } from './pages/settings/structure-configuration/structure-configuration-cm/Providers/simplebooking/simplebooking.component';
import { VerticalbookingComponent } from './pages/settings/structure-configuration/structure-configuration-cm/Providers/verticalbooking/verticalbooking.component';
import { WubookComponent } from './pages/settings/structure-configuration/structure-configuration-cm/Providers/wubook/wubook.component';
import { ZakComponent } from './pages/settings/structure-configuration/structure-configuration-cm/Providers/zak/zak.component';
import { LeorevooComponent } from './pages/settings/structure-configuration/structure-configuration-cm/Providers/leorevoo/leorevoo.component';
import { CalendarEventComponent } from './pages/settings/calendar-event/calendar-event.component';
import { CalendarEventDetailComponent } from './pages/settings/calendar-event/calendar-event-detail/calendar-event-detail.component';
import { HighlightsComponent } from './pages/dashboard/highlights/highlights.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { RateChannelComponent } from './pages/settings/structure-configuration/structure-configuration-cm/rate-channel/rate-channel.component';
import { RoomMappingComponent } from './pages/settings/structure-configuration/structure-configuration-cm/room-mapping/room-mapping.component';
import { RatesMappingComponent } from './pages/settings/structure-configuration/structure-configuration-cm/rates-mapping/rates-mapping.component';
import { MainCmSettingsComponent } from './pages/settings/structure-configuration/structure-configuration-cm/main-cm-settings/main-cm-settings.component';
import { FileSizePipe } from './pages/shared/file-size-pipe';
import { EditPeriodModalComponent } from './pages/operation/availability/edit-period-modal/edit-period-modal.component';
import { ExtraServiceDetailComponent } from './pages/settings/extra-service/extra-service-detail/extra-service-detail.component';
import { InfoStructureComponent } from './pages/shared/info-structure/info-structure.component';
import { CalendarRateHistoryComponent } from './pages/operation/calendar/core-calendar/calendar-rate-history/calendar-rate-history.component';
import { ReliableComponent } from './pages/admin/reliable/reliable.component';
import { CostpanelComponent } from './pages/stats/costpanel/costpanel.component';
import { OtpComponent } from './core/auth/otp/otp.component';
import { OtpAutotabDirective } from './core/auth/otp/otp-autotab.directive';
import { ResetLinkComponent } from './core/auth/reset-link/reset-link.component';
import { ScidooComponent } from './pages/settings/structure-configuration/structure-configuration-cm/Providers/scidoo/scidoo.component';
import { StructureConfigurationAiComponent } from './pages/settings/structure-configuration/structure-configuration-ai/structure-configuration-ai.component';
import { AiRateComponent } from './pages/operation/calendar/ai-rate/ai-rate.component';
import { StructureConfigurationInvComponent } from './pages/settings/structure-configuration/structure-configuration-calendar/structure-configuration-inv/structure-configuration-inv.component';
import { FileExportComponent } from './pages/stats/file-export/file-export.component';
import { KrossbookingComponent } from './pages/settings/structure-configuration/structure-configuration-cm/Providers/krossbooking/krossbooking.component';


export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    SettingsComponent,
    ResetPasswordComponent,
    ConfirmResetPasswordComponent,
    StructureListComponent,
    StructureDetailComponent,
    UsersListComponent,
    UserDetailComponent,
    RoomListComponent,
    RoomDetailComponent,
    PriceListComponent,
    PriceDetailComponent,
    ProfileComponent,
    StructureConfigurationComponent,
    StructureConfigurationUserComponent,
    StructureConfigurationCalendarComponent,
    SalesChannelListComponent,
    SalesChannelDetailComponent,
    PlanningComponent,
    StructureConfigurationPmsComponent,
    DataEntryComponent,
    UploadComponent,
    ManualModComponent,
    CalendarComponent,
    TopCalendarComponent,
    RightCalendarComponent,
    CoreCalendarComponent,
    RatesComponent,
    ChartCalendarComponent,
    TableCalendarComponent,
    AnagraficheComponent,
    ContrattiComponent,
    AgentiComponent,
    ConteggiComponent,
    ClientiComponent,
    FornitoriComponent,
    ProvvigioniComponent,
    FattureAgentiComponent,
    ClientDetailComponent,
    ClientConfigComponent,
    FornitoriDetailComponent,
    ContrattiDetailComponent,
    FattureAgentiDetailComponent,
    ProvvigioniDetailComponent,
    ConteggiDetailComponent,
    AvailabilityComponent,
    UpdatehistoryComponent,
    ReportGeneralComponent,
    ReportViewMonthComponent,
    ReportViewClusterComponent,
    ReportBiComponent,
    ExtraServiceComponent,
    CustomerCareComponent,
    VideoGuideComponent,
    ManualsComponent,
    UpdatemonitoringComponent,
    SafePipe,
    StructureConfigurationMainComponent,
    StructureConfigurationComComponent,
    StructureConfigurationComComponent,
    StructureConfigurationCmComponent,
    BeddyComponent,
    BedzzleComponent,
    H5stelleComponent,
    DhynetComponent,
    HotelbbComponent,
    OctorateComponent,
    ParityrateComponent,
    PasspartoutComponent,
    SimplebookingComponent,
    VerticalbookingComponent,
    WubookComponent,
    ZakComponent,
    LeorevooComponent,
    CalendarEventComponent,
    CalendarEventDetailComponent,
    HighlightsComponent,
    RateChannelComponent,
    RoomMappingComponent,
    RatesMappingComponent,
    MainCmSettingsComponent,
    FileSizePipe,
    EditPeriodModalComponent,
    ExtraServiceDetailComponent,
    InfoStructureComponent,
    CalendarRateHistoryComponent,
    ReliableComponent,
    CostpanelComponent,
    OtpComponent,
    OtpAutotabDirective,
    ResetLinkComponent,
    ScidooComponent,
    StructureConfigurationAiComponent,
    AiRateComponent,
    StructureConfigurationInvComponent,
    FileExportComponent,
    KrossbookingComponent
  ],
  imports: [
    NgSelectModule,
    FlatpickrModule.forRoot(),
    InfiniteScrollModule,
    TranslateModule.forRoot({
      defaultLanguage: 'it',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    LayoutsModule,
    NgbModule,
    HttpClientModule,
    UiSwitchModule.forRoot({
      size: 'small'
    }),
    NgbDropdownModule,
    FeatherModule,
    NgApexchartsModule,
    NgxYoutubePlayerModule,
    LightboxModule,
    NgbTooltipModule,
    NgbNav,
    NgbNavItem,
    NgxJsonViewerModule,
    CKEditorModule,
    NgbModalModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
