import { Component } from '@angular/core';
import {RestService} from "../../../../../../core/services/rest.service";
import {ActivatedRoute, Router} from "@angular/router";
import {StructureService} from "../../../../../../core/services/structure.service";
import {GuiService} from "../../../../../../core/services/gui.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-scidoo',
  templateUrl: './scidoo.component.html',
  styleUrls: ['./scidoo.component.scss']
})
export class ScidooComponent {
  helptxt: string = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam blandit aliquet nisl at tristique. Nunc tincidunt tempor consequat. Orci varius natoque penatibus et magnis dis.";

  constructor(private restService: RestService,private route: ActivatedRoute, public structureService: StructureService,
    private router: Router,public guiService: GuiService, public translate: TranslateService) {
      !this.structureService.selectedStructureConfig.SCIDOO_API_KEY.value ? this.structureService.selectedStructureConfig.SCIDOO_API_KEY.value = null : null;
  
  }

}
