<div class="page-title-box d-flex align-items-center justify-content-between">
  <h4 class="mb-0">Anagrafiche</h4>
</div>
<div class="col-xxl-12 col-xl-12">
  <div class="card card-h-100">
    <div class="card-header justify-content-between d-flex align-items-center p-0">
      <ul ngbNav #customNav="ngbNav" [activeId]="activeTab" class="nav nav-tabs nav-tabs-custom">
          <li [ngbNavItem]="1">
              <a ngbNavLink>
                  <span class="d-none d-sm-block">Clienti</span>
              </a>
              <ng-template ngbNavContent>
                <app-clienti></app-clienti>
              </ng-template>
          </li>
          <li [ngbNavItem]="2">
              <a ngbNavLink>
                  <span class="d-none d-sm-block">Fornitori</span>
              </a>
              <ng-template ngbNavContent>
                <app-fornitori></app-fornitori>
              </ng-template>
          </li>
      </ul>
    </div><!-- end card header -->
    <div class="card-body">
    <div [ngbNavOutlet]="customNav"></div>
    </div><!-- end card body -->
  </div><!-- end card -->
</div>
