<div class="page-title-box d-flex align-items-center justify-content-between">
  <h4 class="mb-0">{{ "FX.rooms" | translate}}</h4>
</div>
<div class="col-xxl-12 col-xl-12">
  <div class="card card-h-100" *ngIf="rooms">
    <div class="card-header row">
      <div class="col-sm-12 col-md-6 col-xl-8">
        <div class="row">
          <div class="form-floating col-3">
          <input type="text" class="form-control" [(ngModel)]="searchCtrl">
          <label>Search</label>
        </div>
        <div class="form-floating col-1">
          <button class="btn btn-primary" (click)="getRooms()">
            <i class="uil-search"></i>
          </button>
        </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 d-flex justify-content-end">
            <button class="btn btn-primary btn-right-action" (click)="newRoomEnable()">
              <i class="uil-plus"></i>
            </button>
            <button class="btn btn-primary btn-right-action" (click)="guiService.chooseItemView('CARD')"
              [class.selected]="guiService.viewType == 'CARD'">
              <i class="uil-table"></i>
            </button>
            <button class="btn btn-primary btn-right-action" [class.selected]="guiService.viewType == 'TABLE'"
              (click)="guiService.chooseItemView('TABLE')">
              <i class="uil-list-ul"></i>
            </button>
          <ngb-pagination (pageChange)="loadPage($event)" [(page)]="page" [pageSize]="size"
            [collectionSize]="rooms.totalItems"></ngb-pagination>
      </div>
      <div class="col-12">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs custom-tab" (click)="activeTab(active)">
          <li [ngbNavItem]="1">
            <button ngbNavLink>Tutte</button>
            <ng-template ngbNavContent>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <button ngbNavLink>Attive</button>
            <ng-template ngbNavContent>
            </ng-template>
          </li>
          <li [ngbNavItem]="3">
            <button ngbNavLink>Disattive</button>
            <ng-template ngbNavContent>
            </ng-template>
          </li>
        </ul>
      </div>

    </div><!-- end card header -->
    <div class="card-body row" *ngIf="rooms">

      <div [ngClass]="showDetail ? 'col-sm-2 col-md-6' : 'col-sm-12 col-md-12'">

        <!-- START TABLE VIEW -->
        <div class="table-responsive" *ngIf="guiService.viewType == 'TABLE'">
          <table class="table table-sm table-hover mb-0">
            <thead>
            <tr>
              <th>#</th>
              <th>Codice</th>
              <th>Nome</th>
              <th>Descrizione</th>
              <th>Status</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let room of rooms.items">
              <th scope="row" [class.selected]="roomSelected && room.id == roomSelected.id">#{{room.id}}</th>
              <td [class.selected]="roomSelected && room.id == roomSelected.id" class="name-color"><a
                (click)="editRoom(room.id)">{{room.title}}</a></td>
              <td [class.selected]="roomSelected && room.id == roomSelected.id">{{room.name}}</td>
              <td [class.selected]="roomSelected && room.id == roomSelected.id">{{room.description}}</td>
              <td [class.selected]="roomSelected && room.id == roomSelected.id">
                <i class="uil-ban text-danger" *ngIf="!room.enabled"></i>
                <i class="uil-thumbs-up text-success" *ngIf="room.enabled"></i>
              </td>
              <td [class.selected]="roomSelected && room.id == roomSelected.id">
                <div class="text-end mb-1" ngbDropdown>
                  <i class="uil-cog m-0" ngbDropdownToggle id="dropdownBasic0"></i>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic0">
                    <button ngbDropdownItem (click)="editRoom(room.id)">{{ "FX.edit" | translate }}</button>
                    <button ngbDropdownItem *ngIf="room.enabled" (click)="disableRoom(room)">{{ "FX.disable" | translate }}</button>
                    <button ngbDropdownItem *ngIf="!room.enabled" (click)="enableRoom(room)">{{ "FX.enable" | translate }}</button>
                  </div>
                </div>
              </td>
            </tr>
            </tbody><!-- end tbody -->
          </table><!-- end table -->
        </div><!-- end table responsive -->


        <!-- START CARD VIEW -->
        <div class="card-group justify-content-center" *ngIf="guiService.viewType == 'CARD'">
          <div class="col-xl-3 col-xxl-2 col-sm-3 col-md-3 card-body-structure-wrapper"
               *ngFor="let room of rooms.items">
            <div class="card-body-structure" [class.selected]="roomSelected && room.id == roomSelected.id">
              <div class="action-btn">
                <div class="text-end mb-1" ngbDropdown>
                  <i class="uil-cog m-0" ngbDropdownToggle id="dropdownBasic2"></i>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                    <button ngbDropdownItem (click)="editRoom(room.id)">{{ "FX.edit" | translate }}</button>
                    <button ngbDropdownItem (click)="disableRoom(room)">{{ "FX.disable" | translate }}</button>
                  </div>
                </div>
              </div>

              <h3 class="pid-area">#{{room.id}}</h3>

              <div class="titolo d-flex justify-content-between">
                <h1><a (click)="editRoom(room.id)">{{room.title}}</a></h1>
              </div>
              <div class="body-card">
                <div><h3>Nome:</h3>
                  <p>{{room.name}}</p></div>
                <div><h3>Enabled:</h3>
                  <p *ngIf="room.enabled == true"><i class="fas fa-check-square"></i></p>
                  <p *ngIf="room.enabled != true"><i class="fas fa-ban"></i></p>
                </div>
                <div><h3>Data Creazione:</h3>
                  <p>{{room.dateCreated | date: 'medium'}}</p></div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-10 col-md-6" *ngIf="showDetail">
        <app-room-detail [selectedroom]="roomSelected" *ngIf="newRoom == false"></app-room-detail>
        <app-room-detail [selectedroom]="null" *ngIf="newRoom"></app-room-detail>
      </div>
    </div><!-- end card body -->
  </div><!-- end card -->
</div>
