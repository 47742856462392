import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-provvigioni-detail',
  templateUrl: './provvigioni-detail.component.html',
  styleUrls: ['./provvigioni-detail.component.scss']
})
export class ProvvigioniDetailComponent {
  @Input() commission: any;

  ngOnInit(): void {
    console.debug(this.commission)
  }

  showDiv(index:any, type: any){
    console.debug(this.commission[type][index])
    this.commission[type][index].showDiv == true ?  this.commission[type][index].showDiv = false : this.commission[type][index].showDiv = true;
    console.debug(this.commission[type][index].showDiv)
  }
}
