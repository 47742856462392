import { Component, ViewChild } from '@angular/core';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip
} from "ng-apexcharts";
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};

@Component({
  selector: 'app-conteggi',
  templateUrl: './conteggi.component.html',
  styleUrls: ['./conteggi.component.scss']
})
export class ConteggiComponent {
  structureCtrl: any;
  structures: any = [];
  yearFromCtrl:any;
  yearToCtrl: any;
  loaded: boolean = false;

  counters: any;
  counterSelected: any = {};
  newCounter: boolean = false;
  page = 0
  size = 10;
  showDetail: boolean = false;
  monthMap: any = {
    1: 'jan',
    2: 'feb',
    3: 'mar',
    4: 'apr',
    5: 'may',
    6: 'jun',
    7: 'jul',
    8: 'aug',
    9: 'sep',
    10: 'oct',
    11: 'nov',
    12: 'dec',
  }

  sums: any = {};
  years: any = [];

  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;

  constructor(private restService: RestService, public guiService: GuiService, public translate: TranslateService){
    this.guiService.closeDetail.subscribe(value => {
      if(value == true){
        this.counterSelected = {};
        this.showDetail = false;
      }
    });

    this.guiService.refreshList.subscribe( value => {
      if(value == true){
        this.page = 0
        this.getCounters()
        this.showDetail = false
      }
     });
  }

  ngOnInit(): void {
    this.generateYearsArray()
    this.getCounters();
  }

  loadPage(event:any){
    this.page = event
    console.debug(event)
    this.getCounters()
  }

  generateYearsArray(){
    console.debug()
    let nextYear = moment().add('1','year').format('YYYY')
    this.years.push(nextYear)
    for(let i = 1; i < 10; i++){
      this.years.push(moment(nextYear).subtract(i,'years').format('YYYY'))
    }
    console.debug(this.years)
  }

  getCounters(){
    let tmpJson: any = {
      "draw":1,
      "start": 0,
      "length": -1,
      "order[0][column]": "0",
      "order[0][dir]":"asc",
      "structureId": this.structureCtrl ? this.structureCtrl.id : '',
      "year": this.yearFromCtrl ? this.yearFromCtrl: ''
    };

    let json = new URLSearchParams(tmpJson).toString();
    this.loaded = false;
    this.restService.postService('crm_base_new','/inv_counters/search?'+json,{}).subscribe((ris)=>{
      console.debug(ris)
      this.counters = [];
      this.counters = ris;
      for(let counter of this.counters.data){
        counter.data = JSON.parse(counter.data)
        let tmpSum: any = 0
        for(let k = 1; k <= 12; k++){
          let month = Number(counter.data[this.monthMap[k]])
          tmpSum += month
        }
        counter.total = tmpSum.toFixed(2)
      }
      console.debug(this.counters.data)
      this.yearToCtrl ? this.initChart(this.counters.data) : null;
      this.loaded = true;
      this.sumValues(this.yearFromCtrl,this.counters.data)
    });
  }

  sumValues(yearParam:any,items:any){
    this.sums[yearParam] = {year: yearParam,values: {apr: 0,aug: 0,dec:0,feb:0,jan: 0,jul: 0,jun: 0,mar: 0,may: 0,nov: 0,oct: 0,sep: 0},total:0}

    for(let counter of items){
      this.sums[yearParam].total += Number(counter.total)
      for(let month in counter.data){
        this.sums[yearParam].values[month] += Number(counter.data[month])
      }
    }
    console.debug(this.sums[yearParam])
  }

  counterDetail(obj: any,monthToChange:any){
    this.showDetail = false;
    setTimeout(() => {
    this.counterSelected = obj
    this.counterSelected.structureId = this.structureCtrl.id
    this.counterSelected.monthToChange = monthToChange
    this.newCounter = false;
    this.showDetail = true;
    }, 200);
  }

  newCounterEnable() {
    console.debug(this.structureCtrl)
    this.newCounter = true;
    this.counterSelected.structureId = this.structureCtrl.id;
    this.counterSelected.yearFromCtrl = this.yearFromCtrl
    this.counterSelected.yearToCtrl = this.yearToCtrl
    this.showDetail = true;
  }

  getStructure(evento:any){
    if(evento.term.length > 2){
      this.restService.getService('crm_base_new','/structure/likeList?query='+evento.term).subscribe((ris)=>{
      this.structures = ris
    })
    }
  }

  initChart(fromYear: any){
    let arrDataFromYear: any = [0,0,0,0,0,0,0,0,0,0,0,0]
    for(let i = 0; i < fromYear.length; i++){
      let portal = fromYear[i].data
      console.debug(portal)
      for(let k = 1; k <= 12; k++){
        let month: number = Number(portal[this.monthMap[k]])
        arrDataFromYear[k-1] += typeof month == 'string' ? Number(month) : Number(month)
      }
    }

    for(let i = 0; i < 12; i++){
      arrDataFromYear[i] = Number(arrDataFromYear[i].toFixed(2))
    }
    console.debug(arrDataFromYear)

    let tmpJson: any = {
      "draw":1,
      "start": 0,
      "length": -1,
      "order[0][column]": "0",
      "order[0][dir]":"asc",
      "structureId": this.structureCtrl ? this.structureCtrl.id : '',
      "year": this.yearToCtrl ? this.yearToCtrl: ''
    };

    let tmpCounters: any
    let arrDataToYear: any = [0,0,0,0,0,0,0,0,0,0,0,0]

    let json = new URLSearchParams(tmpJson).toString();
    this.loaded = false;
    this.restService.postService('crm_base_new','/inv_counters/search?'+json,{}).subscribe((ris)=>{
      tmpCounters = [];
      tmpCounters = ris.data;

      for(let counter of tmpCounters){
        counter.data = JSON.parse(counter.data)
        let tmpSum: any = 0
        for(let k = 1; k <= 12; k++){
          let month = Number(counter.data[this.monthMap[k]])
          tmpSum += month
        }
        counter.total = tmpSum.toFixed(2)
      }

      for(let i = 0; i < tmpCounters.length; i++){
        let portal = tmpCounters[i].data
        let tmpSum: any = 0
        for(let k = 1; k <= 12; k++){
          let month = Number(portal[this.monthMap[k]])
          arrDataToYear[k-1] += typeof month == 'string' ? Number(month) : Number(month)
        }
      }
      this.sumValues(this.yearToCtrl,tmpCounters)

      for(let i = 0; i < 12; i++){
        arrDataToYear[i] = Number(arrDataToYear[i].toFixed(2))
      }


      this.chartOptions = {
      colors:['#333333','#3a9ade'],
      series: [
        {
          name: fromYear[0].year,
          data: arrDataFromYear
        },
        {
          name: tmpCounters[0].year,
          data: arrDataToYear
        }
      ],
      chart: {
        type: "bar",
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: [
          this.translate.instant("MONTHS.SHORT.1"),
          this.translate.instant("MONTHS.SHORT.2"),
          this.translate.instant("MONTHS.SHORT.3"),
          this.translate.instant("MONTHS.SHORT.4"),
          this.translate.instant("MONTHS.SHORT.5"),
          this.translate.instant("MONTHS.SHORT.6"),
          this.translate.instant("MONTHS.SHORT.7"),
          this.translate.instant("MONTHS.SHORT.8"),
          this.translate.instant("MONTHS.SHORT.9"),
          this.translate.instant("MONTHS.SHORT.10"),
          this.translate.instant("MONTHS.SHORT.11"),
          this.translate.instant("MONTHS.SHORT.12")
        ]
      },
      yaxis: {
        title: {
          text: "Confronto"
        }
      },
      fill: {
        colors:['#333333','#3a9ade'],
        opacity: 1
      },
      tooltip: {
        y: {
        }
      }
    };
    });


  }

}
