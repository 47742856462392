import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { StructureService } from 'src/app/core/services/structure.service';
import Swal from 'sweetalert2';
import {GuiService} from "../../../core/services/gui.service";
import {CalendarService} from "../../../core/services/calendar.service";

import * as moment from 'moment';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent {
  reloadComponent: boolean = true;

  constructor(public structureService: StructureService,private router: Router, public guiService: GuiService, public calService: CalendarService){

  }

  ngOnInit(): void {
    this.guiService.changeArea("FX.operation");
    this.guiService.selectMenuItems("FX.operation");
    if(this.structureService.selectedStructure) {
      // this.calService.prepareDays();
    } else {
      Swal.fire({
        title: 'Nessuna struttura selezionata',
        icon: 'warning'
      }).then((result)=>{
        if(result.isConfirmed){
          this.router.navigate(['admin/dashboard']);
        }
      });
    }
  }

  ngOnDestroy(): void {
    console.debug('ON DESTROY CALENDAR_________')
    this.calService.monthSelectedForCalendar = null;
    this.calService.yearSelectedForCalendar = null;
    this.calService.dynamicHistoryYearRef = 0;
    this.calService.staticHistoryYearRef = 0;
    this.calService.dynamicHistoryYear = null;
    this.calService.staticHistoryYear = null;
  }

  reloadCalendarInCalendarComponent() {
    console.debug("Reload");
    this.reloadComponent = false;

    setTimeout(() => {
      this.reloadComponent = true;
    },500);
  }
}
