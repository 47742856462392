import { Component } from '@angular/core';

@Component({
  selector: 'app-agenti',
  templateUrl: './agenti.component.html',
  styleUrls: ['./agenti.component.scss']
})
export class AgentiComponent {
  activeTab: any = 1;

}
