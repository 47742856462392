import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GuiService } from 'src/app/core/services/gui.service';
import { StructureService } from 'src/app/core/services/structure.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-data-entry',
  templateUrl: './data-entry.component.html',
  styleUrls: ['./data-entry.component.scss']
})
export class DataEntryComponent {
  activeTab: any = 1;

  constructor(public structureService: StructureService, private router: Router, public guiService: GuiService){
    this.structureService.isStructureChanged.subscribe( value => {
      if(value == true){
        this.ngOnInit()
        this.activeTab = 1
      }
  });
  }

  ngOnInit(): void {
    this.guiService.changeArea("FX.operation");
    this.guiService.selectMenuItems("FX.operation");
    this.structureService.selectedStructure ? null : Swal.fire({
      title: 'Nessuna struttura selezionata',
      icon: 'warning'
    }).then((result)=>{
      if(result.isConfirmed){
        this.router.navigate(['admin/dashboard']);
      }
    })
  }

  checkRole(){
    if(this.guiService.userLogged?.roles !== 'REVOLUTION USER'){
      return true
    }else{
      return false
    }
  }

}
