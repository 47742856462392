<div class="row align-items-start">
  <div class="col-11 box-main-form">

    <div *ngIf="structureService.selectedStructureConfig && structureService.selectedStructureConfig.setup_phase">

      <!-- FORM MAIN SETTINGS -->

      <!-- MAIN FIELDSET -->
      <fieldset class="row">
        <legend>
          <h3>{{ "CFG.main_legend" | translate }}</h3>
        </legend>
        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.setup_phase" | translate }}</h3>
            <div dir="ltr" class="form-check form-switch" *ngIf="structureService.selectedStructureConfig.setup_phase">
              <input type="checkbox" id="customSwitchsizesm" [checked]="structureService.selectedStructureConfig.setup_phase.value == '1'" class="form-check-input" (change)="configKeyChange('setup_phase')">
            </div>
            <!--small ngbTooltip="{{language.getHelpTranslate('PRINCIPAL','TIME_MACHINE')}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
              <i-feather name="help-circle"></i-feather>
            </small-->

          </label>
        </div>
        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.closure_hour" | translate }}</h3>
            <input class="form-control input-camere" type="text" [(ngModel)]="structureService.selectedStructureConfig.closure_hour.value" />
            <!--small ngbTooltip="{{language.getHelpTranslate('PRINCIPAL','TIME_CLOSING')}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
              <i-feather name="help-circle"></i-feather>
            </small-->
          </label>
        </div>
        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.time_machine_enable" | translate }}</h3>
            <div dir="ltr" class="form-check form-switch" *ngIf="structureService.selectedStructureConfig.TIME_MACHINE_ACTIVE">
              <input type="checkbox" id="customSwitchsizesm" [checked]="structureService.selectedStructureConfig.TIME_MACHINE_ACTIVE.value == '1'" class="form-check-input" (change)="configKeyChange('TIME_MACHINE_ACTIVE')">
            </div>
            <!--small ngbTooltip="{{language.getHelpTranslate('PRINCIPAL','TIME_MACHINE')}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
              <i-feather name="help-circle"></i-feather>
            </small-->

          </label>
        </div>
      </fieldset>

      <!-- WEATHER FIELDSET -->
      <fieldset class="row">
        <legend>
          <h3>{{ "CFG.weather" | translate }}</h3>
        </legend>
        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.city_id" | translate }}</h3>
            <input class="form-control input-camere" type="text" [(ngModel)]="structureService.selectedStructureConfig.city_id.value" />
            <small ngbTooltip="{{language.getHelpTranslate('PRINCIPAL','WEATHER_CITY')}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
              <i-feather name="help-circle"></i-feather>
            </small>
          </label>
        </div>
        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.sea_id" | translate }}</h3>
            <input class="form-control input-camere" type="text" [(ngModel)]="structureService.selectedStructureConfig.sea_id.value" />
            <small ngbTooltip="{{language.getHelpTranslate('PRINCIPAL','WEATHER_SEA')}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
              <i-feather name="help-circle"></i-feather>
            </small>
          </label>
        </div>
      </fieldset>

      <!-- CURRENCY FIELDSET -->
      <fieldset class="row">
        <legend>
          <h3>{{ "CFG.currency" | translate }}</h3>
        </legend>
        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.currency_primary" | translate }}</h3>
            <ng-select [clearable]="true" placeholder="" [(ngModel)]="structureService.selectedStructureConfig.currency.value">
              <ng-option *ngFor="let item of currency_list" [value]="item['code']"><b>{{item['code']}}</b> {{item['name']}}</ng-option>
            </ng-select>
            <small ngbTooltip="{{language.getHelpTranslate('CURRENCY','PRIMARY','')}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
              <i-feather name="help-circle"></i-feather>
            </small>
          </label>
        </div>
        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.currency_secondary" | translate }}</h3>
            <ng-select [clearable]="true" placeholder="" [(ngModel)]="structureService.selectedStructureConfig.argentina_cambio.value">
              <ng-option *ngFor="let item of currency_list" [value]="item['code']"><b>{{item['code']}}</b> {{item['name']}}</ng-option>
            </ng-select>
            <small ngbTooltip="{{language.getHelpTranslate('CURRENCY','SECONDARY')}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
              <i-feather name="help-circle"></i-feather>
            </small>
          </label>
        </div>
        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.currency_prod" | translate }}</h3>
            <ng-select [clearable]="true" placeholder="" [(ngModel)]="structureService.selectedStructureConfig.valuta_produzione.value">
              <ng-option *ngFor="let item of currency_list" [value]="item['code']"><b>{{item['code']}}</b> {{item['name']}}</ng-option>
            </ng-select>
            <small ngbTooltip="{{language.getHelpTranslate('CURRENCY','PROD')}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
              <i-feather name="help-circle"></i-feather>
            </small>
          </label>
        </div>
      </fieldset>

    </div>

  </div>
  <div class="col-1 btn-group-vertical right-sidebar" role="group">
    <button class="btn-send" (click)="save()">
      <span class="uil-folder-check"></span>
      <h1>save</h1>
    </button>
    <button class="btn-delete" (click)="reset()">
      <span class="uil-trash"></span>
      <h1>{{ "GENERIC.reset_btn" | translate }}</h1>
    </button>
  </div>

</div>
