<div class="contenitore">
  <div class="file-container" *ngIf="!file">
    <input type="file" class="file" (change)="getFile($event)" placeholder="">
    <small>{{ "UPLOAD_FILE.drag_drop_file" | translate }}</small>
  </div>
  <div *ngIf="file" class="file-name-container">
    <i class="uil-file-blank icona"></i>
    <p>{{file.name}}</p>
    <div class="progress-area" *ngIf="progressValue">
      <ngb-progressbar [showValue]="true" [value]="progressValue" type="warning" [animated]="true" [striped]="true" height="20px"></ngb-progressbar>
    </div>
  </div>
  <div class="text-center">
    <button class="btn-new delete" (click)="clear()">{{ "UPLOAD_FILE.clear" | translate }}</button>
    <button class="btn-new" (click)="sendFile()">{{ "UPLOAD_FILE.upload" | translate }}</button>
  </div>

  <div class="card mt-4">
    <div class="card-header">
      <h3 class="titolo-top-card">
        {{ "UPLOAD_FILE.last_uploaded_files" | translate }}
      </h3>

    <div class="progress-area" *ngIf="progressBar>0">
      <ngb-progressbar [showValue]="false" [value]="progressBar" type="warning" class="progress-bar progress-bar-striped progress-bar-animated" 
      [animated]="true" [striped]="true" height="1px">&nbsp;</ngb-progressbar>
    </div>
    </div>
    <div class="card-body row">
      <div class="card-group row g-1">
        <div class="col-xl-3 col-xxl-2 col-sm-3 col-md-3 card-body-structure-wrapper" *ngFor="let file of fileList">
          <div class="card-body-structure" [class.selected]="fileSelected && file.id == fileSelected.id">
            <div class="action-btn">
              <div class="text-end mb-1" ngbDropdown>
                <i class="uil-cog m-0" ngbDropdownToggle id="dropdownBasic2"></i>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                  <!--button ngbDropdownItem (click)="detail(file.id, dataModal)">Detail</button-->
                  <button ngbDropdownItem (click)="downloadFile(file)" >Download</button>
                </div>
              </div>
            </div>

            <h3 class="pid-area">#{{file.id}}</h3>

            <div class="titolo d-flex justify-content-between">
              <h1>
                <i class="fas fa-file-excel" *ngIf="file.ext == 'xls' || file.ext == 'xlsx'"></i>
                <i class="fas fa-file-csv" *ngIf="file.ext == 'csv'"></i>
                <i class="fas fa-file" *ngIf="file.ext == 'json'"></i>
                <a> {{file.uploadDate}}</a>
              </h1>
            </div>
            <div class="body-card">
              <div>
                <h3>Nome:</h3>
                <p> {{file.fileName}}</p></div>
              <div>
                <h3>Dimensione:</h3>
                <p> {{file.fileSize | filesize }}</p>
              </div>
              <div>
                <h3>Stato:</h3>
                <p>{{file.status}}</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #dataModal let-modal>
  <div class="row p-2">
      <div class="col-5">
        <div class="form-floating">
          <input class="form-control" type="date" id="init" [(ngModel)]="date.from">
          <label for="init">DA</label>
        </div>
        <div class="form-floating">
          <input class="form-control" type="date" id="expire" [(ngModel)]="date.to">
          <label for="expire">A</label>
        </div>
        <!--span class="icona cerca"><i class="uil-search" (click)="modalSearch()"></i></span-->
        <!--span class="icona cerca"><i class="uil-search" (click)="modalSearchTwo()"></i></span-->
      </div>
      <div class="col-5 text-center"><h5 class="modal-title mt-0 ">{{fileSelected.name}}</h5></div>
      <div class="col-2 text-end">
        <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')"
            aria-hidden="true">
          </button>
      </div>
  </div>
  <!--div class="modal-body" id="modal-body-identifier" (scroll)="onYScroll('modal-body-identifier')">
    <div id="chart" *ngIf="renderFlag">
      <apx-chart *ngIf="chartOptions"
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [fill]="chartOptions.fill"
        [dataLabels]="chartOptions.dataLabels"
        [plotOptions]="chartOptions.plotOptions"
        [xaxis]="chartOptions.xaxis"
        [yaxis]="chartOptions.yaxis"
        [tooltip]="chartOptions.tooltip"
        [grid]="chartOptions.grid"
      ></apx-chart>
    </div>
    <div *ngIf="renderTable">
      <div class="tabella row">
        <div class="vuoto"></div>
        <div id="colslimit" class="col-10 col-sm-custom">
          <div id="main-h-scroll" [class.fixed-header]="scrollY" [style.width]="headerWidth+'px'"
               (scroll)="onXScroll('main-h-scroll')">
            <div class="row h-header" [style.width]="(84*((roomsType.length + salesChannel.length)+1)) + 'px'">
              <div class="intestazione-orizzontale tot-header">
                <h2>TOT</h2>
              </div>
              <div class="intestazione-orizzontale" *ngFor="let item of roomsType">
                <h2>{{item}}</h2>
                <ul>
                  <li>IND</li>
                  <li>GRP</li>
                </ul>
              </div>
              <div class="intestazione-orizzontale sales-header" *ngFor="let item of salesChannel">
                <h2>{{item}}</h2>
                <ul>
                  <li>IND</li>
                  <li>GRP</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row days-area">
        <div *ngFor="let item of tmpResult; let i = index" class="border-box">
          <div class="vuoto">
            <div class="colonna-verticale" [class.fixed-first-col]="scrollX">
              <div class="intestazione-verticale">
                <h4 class="day" *ngIf="item.day.number != 0">{{"DAYS.SHORT." + (item.day.number) | translate}}</h4>
                <h4 class="day" *ngIf="item.day.number == 0">{{"DAYS.SHORT.7" | translate}}</h4>
                <h3 class="day-number">{{item.day.day}}</h3>
                <h4>{{"MONTHS.SHORT." + (item.day.month) | translate}}</h4>
                <h4>{{item.day.year}}</h4>
              </div>
              <div class="header-vertical">
                <div class="padd first-color">OCC</div>
                <div class="second-color">PROD</div>
              </div>
            </div>
          </div>
          <div class="col-10 col-sm-custom colonna-verticale element-h-scroll" [style.width]="headerWidth+'px'" id="row_{{i}}">
            <div [style.width]="(84*((roomsType.length + salesChannel.length)+1)) + 'px'">
              <div>
                <ul>
                  <li class="d-block first-ul-color">{{item ? item.data['tot'][0].occ : ''}}</li>
                  <li class="d-block second-ul-color">{{item ? item.data['tot'][0].prod : ''}}</li>
                </ul>
              </div>
              <div *ngFor="let h of roomsType; let indice = index">
                <ul class="first-ul-color">
                  <li>{{item && item.data['roomtype'][transformString(h)] ? item.data['roomtype'][transformString(h)].ind.occ : ''}}</li>
                  <li>{{item && item.data['roomtype'][transformString(h)] ? item.data['roomtype'][transformString(h)].grp.occ : ''}}</li>
                </ul>
                <ul class="second-ul-color">
                  <li>{{item && item.data['roomtype'][transformString(h)] ? item.data['roomtype'][transformString(h)].ind.prod : ''}}</li>
                  <li>{{item && item.data['roomtype'][transformString(h)] ? item.data['roomtype'][transformString(h)].grp.prod : ''}}</li>
                </ul>
              </div>
              <div *ngFor="let h of salesChannel; let indice = index">
                <ul class="first-ul-color">
                  <li>{{item && item.data['saleschannel'][transformString(h)] ? item.data['saleschannel'][transformString(h)].ind.occ : ''}}</li>
                  <li>{{item && item.data['saleschannel'][transformString(h)] ? item.data['saleschannel'][transformString(h)].grp.occ : ''}}</li>
                </ul>
                <ul class="second-ul-color">
                  <li>{{item && item.data['saleschannel'][transformString(h)] ? item.data['saleschannel'][transformString(h)].ind.prod : ''}}</li>
                  <li>{{item && item.data['saleschannel'][transformString(h)] ? item.data['saleschannel'][transformString(h)].grp.prod: ''}}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div-->
  <div class="modal-footer" *ngIf="!renderTable">
    <button class="btn btn-link" [class.disabled]="chartpage == 0" (click)="prevData()">Indietro</button>
    <button class="btn btn-link" (click)="nextData()">Avanti</button>
  </div>
</ng-template><!-- /.modal -->
