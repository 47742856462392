import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';
import { StructureService } from 'src/app/core/services/structure.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-structure-list',
  templateUrl: './structure-list.component.html',
  styleUrls: ['./structure-list.component.scss']
})
export class StructureListComponent {
  loaded: boolean = true;
  structureView: boolean = false;
  structureSelected: any = null;
  newStructure: boolean = false;
  queryStructureName: any = null;
  users: Array<any> = [];
  ownerCtrl: any;
  consulting: Array<any> = [
    {value: null,label: "STRUCTURE.all_structures"},
    {value: true,label: "GENERIC.in_consulting"},
    {value: false,label: "GENERIC.no_consulting"}
  ];
  consultingCtrl: any = null;
  licences: Array<any> = [
    {value: null,label: "STRUCTURE.all_structures"},
    {value: true,label: "GENERIC.licensed"},
    {value: false,label: "GENERIC.no_licensed"}
  ];
  licenceCtrl: any = null;
  enableCtrl: any = null;
  enabled: Array<any> = [
    {value: null,label: "STRUCTURE.all_structures"},
    {value: true,label: "STRUCTURE.yes"},
    {value: false,label: "STRUCTURE.no"}
  ];
  profileCtrl: any = null;
  profiles: Array<any> = [];
  statusCtrl: any = null;
  page = 1;
  size = 15;
  searchCriteria: any = {
    hasNewOccupancies: null,
    hasConsultingService: null,
    hasLicence: null,
    isEnabled: null,
    usernameWithPermissions: null,
    profileOfUsername: null,
    nameLikeString: null
  };
  structures: any;
  showDetail: boolean = false;
  status = [
    {value: null,label: "STRUCTURE.all_structures"},
    {value: true,label: "STRUCTURE.only_updated"},
    {value: false,label: "STRUCTURE.no_updated"}
  ]

 constructor(public guiService: GuiService, private restService: RestService, public structureService: StructureService,public router: Router, public translate: TranslateService){
  this.newStructure = false

  this.guiService.closeDetail.subscribe( value => {
    if(value == true){
      this.structureSelected = null;
      this.showDetail = false;
    }
});

this.guiService.refreshList.subscribe( value => {
  if(value == true){
    this.page = 1
    this.getStructures()
  }
 });
 }

 ngOnInit(): void {
  this.getRoles();
  this.guiService.changeArea("FX.main");
  this.guiService.selectMenuItems("FX.main");
  this.loadPage(1);
 }

 getRoles() {
  this.restService.getService('users', '/profiles').subscribe((ris => {
    this.profiles = [];
    this.profiles = ris;
    console.debug("ROLES",this.profiles);
  }))
}
queryOwner(evento: any) {
  if(evento.term.length > 2){
    console.debug(evento.term, evento.term.length);
    this.restService.getService('users','/likelist/?query='+evento.term).subscribe((ris)=>{
      this.users = ris;
    })
  }
}

searchFilter() {
  this.page = 0;
}

 getStructures(){
  this.guiService.loaded = false;

  this.queryStructureName && this.queryStructureName!="" ? this.searchCriteria.nameLikeString = this.queryStructureName : delete this.searchCriteria.nameLikeString;
  this.ownerCtrl && this.ownerCtrl!=null ? this.searchCriteria.usernameWithPermissions = this.ownerCtrl.username : delete this.searchCriteria.usernameWithPermissions;
  this.profileCtrl && this.profileCtrl!=null ? this.searchCriteria.profileOfUsername = this.profileCtrl : delete this.searchCriteria.profileOfUsername;
  this.statusCtrl && this.statusCtrl!=null && this.statusCtrl!="null" ? this.searchCriteria.hasNewOccupancies = this.statusCtrl : delete this.searchCriteria.hasNewOccupancies;
  this.consultingCtrl && this.consultingCtrl!=null ? this.searchCriteria.hasConsultingService = this.consultingCtrl : delete this.searchCriteria.hasConsultingService;
  this.licenceCtrl && this.licenceCtrl!=null ? this.searchCriteria.hasLicence = this.licenceCtrl : delete this.searchCriteria.hasLicence;
  this.enableCtrl && this.enableCtrl!=null ? this.searchCriteria.isEnabled = this.enableCtrl : delete this.searchCriteria.isEnabled;

  let param: any = {
    page: this.page-1,
    size: this.size,
    searchCriteria: this.searchCriteria
  }

  console.debug("PAGE",param);

  this.restService.postService('structure','v4/search',param).subscribe({
    next: (ris) => {
      this.structures = ris;
      this.guiService.loaded = true;
    },
    error:  () => {
      this.structures = [];
      this.guiService.loaded = true;
    }
  });
 }

 loadPage(event:any){
  console.debug("PAGE EVENT", event);
  this.page = event;
  this.getStructures();
 }

 editStructure(idStructure: number){

  this.restService.postService('structure_detail', '', {id: idStructure}).subscribe((ris)=>{
    console.debug(ris)
    this.structureSelected = ris.structure;
    this.newStructure = false;
    this.structureService.isStructureChanged.next(true);
    this.showDetail = true;
  })
 }

 selectStructure(idStructure: number) {

  this.restService.postService('structure_detail', '', {id: idStructure}).subscribe((ris)=>{
    sessionStorage.setItem('selected-structure', JSON.stringify(ris.structure));
    this.structureService.selectedStructure = ris.structure;
    this.guiService.goTo('/main/highlights','main');
  })
 }

 structureSettings(idStructure: number){
  this.restService.postService('structure_detail', '', {id: idStructure}).subscribe((ris)=>{
    this.structureSelected = ris.structure
    sessionStorage.setItem('selected-structure', JSON.stringify(ris.structure))
    this.structureService.selectedStructure = ris.structure
    this.structureService.isStructureChanged.next(true);
    this.guiService.goTo('/admin/structure-configuration/'+idStructure,'impostazioni')
  })
 }

 structureCalendar(idStructure: number){
  this.restService.postService('structure_detail', '', {id: idStructure}).subscribe((ris)=>{
    this.structureSelected = ris.structure
    sessionStorage.setItem('selected-structure', JSON.stringify(ris.structure))
    this.structureService.selectedStructure = ris.structure
    this.structureService.isStructureChanged.next(true);
    this.guiService.goTo('/operation/calendar','operation')
  })
 }

 detailStructure(idStructure: number) {
   this.editStructure(idStructure);
 }
 newStructureEnable() {
  this.structureSelected = {
    "name":"",
    "address":"",
    "cap":"",
    "city":"",
    "province":"",
    "country":"",
    "rooms":"",
    "phoneNumber":"",
    "fax":"",
    "webSite":"",
    "responsible":"",
    "communicationEmail":"",
    "reservationEmail":"",
    "latitude":"",
    "longitude":"",
    "enabled":false,
    "consulting":false,
    "consultingTerminated":false,
    "licensed":false,
    "licenseTerminated":false
  };

  this.newStructure = true;
   this.structureSelected = {};
   this.showDetail = true;
 }

resetSearchCriteria() {
  this.searchCriteria = {
    hasNewOccupancies: null,
    hasConsultingService: null,
    hasLicence: null,
    isEnabled: null,
    usernameWithPermissions: null,
    profileOfUsername: null,
  };
}

}
