<div class="page-title-box d-flex align-items-center justify-content-between">
  <h4 class="mb-0">Report per Cluster</h4>
</div>
<div class="col-xxl-12 col-xl-12">
  <div class="card card-h-100">
    <div class="card-header">
      <fieldset class="row">
        <legend>
          <h3>
            Filtri
            <span class="expand-icon">
              <i-feather class="icon-sm" name="minus" *ngIf="expandFlags.filters"
                (click)="expandFlags.filters = false"></i-feather>
              <i-feather class="icon-sm" name="plus" *ngIf="!expandFlags.filters"
                (click)="expandFlags.filters = true"></i-feather>
            </span>
          </h3>
        </legend>
      </fieldset>
      <div *ngIf="expandFlags.filters" class="row">
        <div class="col-11">
          <div class="row">
            <div class="col-3 mb-1 form-floating">
              <select class="form-select" type="text" name="year_from" [(ngModel)]="filter.year_from">
                <option [value]="item" *ngFor="let item of selectyears">{{item}}</option>
              </select>
              <label for="year_from">Anno 1</label>
            </div>
            <div class="col-3 mb-1 form-floating">
              <select class="form-select" type="text" name="year_to" [(ngModel)]="filter.year_to">
                <option [value]="item" *ngFor="let item of selectyears">{{item}}</option>
              </select>
              <label for="year_to">Anno 2</label>
            </div>
            <div class="col-3 mb-1 form-floating">
              <select class="form-select" type="text" name="geo_tag" [(ngModel)]="filter.geo_tag">
                <option value=""></option>
                <option [value]="item.value" *ngFor="let item of geoTags">{{item.value}}</option>
              </select>
              <label for="geo_tag">TAG Geografico</label>
            </div>
            <div class="col-3 mb-1 form-floating">
              <select class="form-select" type="text" name="str_tag" [(ngModel)]="filter.str_tag">
                <option value=""></option>
                <option [value]="item.value" *ngFor="let item of strTags">{{item.value}}</option>
              </select>
              <label for="str_tag">TAG Struttura</label>
            </div>
            <div class="col-3 form-floating">
              <select class="form-select" type="text" name="type_tag" [(ngModel)]="filter.type_tag">
                <option value=""></option>
                <option [value]="item.value" *ngFor="let item of typeTags">{{item.value}}</option>
              </select>
              <label for="type_tag">TAG Tipo</label>
            </div>
            <div class="col-3 form-floating">
              <select class="form-select" type="text" name="region" [(ngModel)]="filter.region">
                <option value=""></option>
                <option [value]="item.name" *ngFor="let item of regions">{{item.name}}</option>
              </select>
              <label for="region">Regione</label>
            </div>
            <div class="col-3 form-floating">
              <select class="form-select" type="text" name="license" [(ngModel)]="filter.license">
                <option [value]="item.value" *ngFor="let item of licenses">{{item.title}}</option>
              </select>
              <label for="license">Consulenza / In Licenza</label>
            </div>
            <div class="col-3 form-floating">
              <select class="form-select" type="text" name="status" [(ngModel)]="filter.status">
                <option [value]="item.value" *ngFor="let item of status">{{item.title}}</option>
              </select>
              <label for="status">Stato</label>
            </div>
          </div>
        </div>
        <div class="col-1 d-flex justify-content-center align-items-center">
          <button class="btn btn-primary" (click)="getReport()">
            <i class="uil-search"></i>
          </button>
        </div>

      </div>
    </div>
    <div class="card-body row">
      <fieldset class="row">
        <legend>
          <h3>
            Tabella
            <span class="expand-icon">
              <i-feather class="icon-sm" name="minus" *ngIf="expandFlags.table"
                (click)="expandFlags.table = false"></i-feather>
              <i-feather class="icon-sm" name="plus" *ngIf="!expandFlags.table"
                (click)="expandFlags.table = true"></i-feather>
            </span>
          </h3>
        </legend>
      </fieldset>

      <div class="col-12 mb-3" *ngIf="reportRes && expandFlags.table">
        <div class="row mb-2 d-flex justify-content-between">
          <div class="form-floating col-2">
            <input class="form-control" type="text" [(ngModel)]="searchCtrl" (keyup)="searchStr()">
            <label for="init">Cerca</label>
          </div>
          <div class="col-2 text-end">
            <button class="btn btn-primary btn-icon" (click)="createReport()" [title]="'REPORT.PRINT' | translate">
              <i class="uil-chart-bar"></i>
            </button>
            <button class="btn btn-primary btn-icon" [title]="'REPORT.CREATE_REPORT' | translate">
              <i class="uil-file-download"></i>
            </button>
          </div>
        </div>

        <!-- START TABLE VIEW -->
        <div class="tableFixHead" *ngIf="loaded">
          <table class="table table-sm table-hover mb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>Nome Struttura</th>
                <th>Inizio</th>
                <th>Fine</th>
                <th>Camere</th>
                <th>Geo</th>
                <th>Str</th>
                <th>Typ</th>
                <th class="text-center "><input class="form-check-input" (click)="toggleSelected()" type="checkbox"
                    style="font-size: 16px;vertical-align: middle;" [(ngModel)]="isFlaged"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let str of reportRes.data">
                <th scope="row">#{{str.structureId}}</th>
                <td class="name-color">{{str.structureName}}</td>
                <td>{{str.activationDate}}</td>
                <td>{{str.endDate}}</td>
                <td>{{str.rooms}}</td>
                <td>{{str.tagGeo}}</td>
                <td>{{str.tagStructure}}</td>
                <td>{{str.tagType}}</td>
                <td class="text-center"><input class="form-check-input" type="checkbox" style="font-size: 16px;"
                    [(ngModel)]="str.selected"></td>
              </tr>
            </tbody><!-- end tbody -->
          </table><!-- end table -->
        </div><!-- end table responsive -->
      </div>

      <fieldset class="row">
        <legend>
          <h3>
            Grafico
            <span class="expand-icon">
              <i-feather class="icon-sm" name="minus" *ngIf="expandFlags.chart"
                (click)="expandFlags.chart = false"></i-feather>
              <i-feather class="icon-sm" name="plus" *ngIf="!expandFlags.chart"
                (click)="expandFlags.chart = true"></i-feather>
            </span>
          </h3>
        </legend>
      </fieldset>
      <div class="contenitore">
        <div class="row tabella" *ngIf="expandFlags.chart">
          <div class="col-6">
            <div class="row">
              <div class="col-1 tabella-header">{{"MONTHS.MONTHS" | translate}}</div>
              <div class="col-1 tabella-header">Anni</div>
              <div class="col-2 tabella-header" (click)="initChartOcc('','','','',true,'occ')">Occupazione</div>
              <div class="col-4 tabella-header" (click)="initChartProd('','','','',true,'prod')">Produzione</div>
              <div class="col-2 tabella-header" (click)="initChartRmc('','','','',true,'rmc')">Ricavo medio camera</div>
              <div class="col-2 tabella-header" (click)="initChartRevpar('','','','',true,'revpar')">Rate par</div>
            </div>
            <div class="row row-border" [class.selected-row]="i == rowSelectedIndex"
              *ngFor="let item of startYear; let i = index" [class.total-row]="item.monthName =='Totale'"
              (click)="initAllCharts(filter.year_from,filter.year_to,item.occupancy,compareYear[i].occupancy,item.prod,compareYear[i].prod,item.rmc,compareYear[i].rmc,item.revpar,compareYear[i].revpar,i)">
              <div class="col-1">
                <span *ngIf="item.month != 0" class="month-name">{{"MONTHS.SHORT."+item.month | translate}}</span>
              </div>
              <div class="col-1 text-center">
                <p>{{filter.year_from}}</p>
                <p *ngIf="compareYear.length > 0" class="compare-data">{{filter.year_to}}</p>
              </div>
              <div class="col-2" [class.selected-col]="selectedColumn == 'occ' && i < 12">
                <div class="row">
                  <div class="col-6 d-flex justify-content-center flex-column" *ngIf="compareYear.length > 0">
                    <h4 [class.negative]="compareYear[i].occ_calc < 0" [class.positive]="compareYear[i].occ_calc >= 0">
                      {{compareYear[i].occ_calc}}</h4>
                  </div>
                  <div class="col-6 d-flex justify-content-center flex-column">
                    <p>{{item.occupancy}}</p>
                    <p *ngIf="compareYear.length > 0" class="compare-data">{{compareYear[i].occupancy}}</p>
                  </div>
                </div>
              </div>
              <div class="col-4" [class.selected-col]="selectedColumn == 'prod' && i < 12">
                <div class="row">
                  <div class="col-6 d-flex justify-content-center flex-column" *ngIf="compareYear.length > 0">
                    <h4 [class.negative]="compareYear[i].prod_calc < 0"
                      [class.positive]="compareYear[i].prod_calc >= 0">{{compareYear[i].prod_calc | number : '1.2-2'}}
                    </h4>
                  </div>
                  <div class="col-6 d-flex justify-content-center flex-column">
                    <p>{{item.revenue | number : '1.2-2'}}</p>
                    <p *ngIf="compareYear.length > 0" class="compare-data">{{compareYear[i].revenue | number : '1.2-2'}}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-2" [class.selected-col]="selectedColumn == 'rmc' && i < 12">
                <div class="row">
                  <div class="col-6 d-flex justify-content-center flex-column" *ngIf="compareYear.length > 0">
                    <h4 [class.negative]="compareYear[i].rmc_calc < 0" [class.positive]="compareYear[i].rmc_calc >= 0">
                      {{compareYear[i].rmc_calc | number : '1.2-2'}}</h4>
                  </div>
                  <div class="col-6 d-flex justify-content-center flex-column">
                    <p>{{item.rmc | number : '1.2-2'}}</p>
                    <p *ngIf="compareYear.length > 0" class="compare-data">{{compareYear[i].rmc | number : '1.2-2'}}</p>
                  </div>
                </div>
              </div>
              <div class="col-2" [class.selected-col]="selectedColumn == 'revpar' && i < 12">
                <div class="row">
                  <div class="col-6 d-flex justify-content-center flex-column" *ngIf="compareYear.length > 0">
                    <h4 [class.negative]="compareYear[i].revpar_calc < 0"
                      [class.positive]="compareYear[i].revpar_calc >= 0">{{compareYear[i].revpar_calc | number :
                      '1.2-2'}}</h4>
                  </div>
                  <div class="col-6 d-flex justify-content-center flex-column">
                    <p>{{item.revpar | number : '1.2-2'}}</p>
                    <p *ngIf="compareYear.length > 0" class="compare-data">{{compareYear[i].revpar | number : '1.2-2'}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row">
              <div [ngClass]="fullChart ? 'col-12' : 'col-6'">
                <div id="chart_occ">
                  <apx-chart *ngIf="chartOptions_occ" [series]="chartOptions_occ.series"
                    [chart]="chartOptions_occ.chart" [dataLabels]="chartOptions_occ.dataLabels"
                    [plotOptions]="chartOptions_occ.plotOptions" [yaxis]="chartOptions_occ.yaxis"
                    [legend]="chartOptions_occ.legend" [fill]="chartOptions_occ.fill" [stroke]="chartOptions_occ.stroke"
                    [tooltip]="chartOptions_occ.tooltip" [xaxis]="chartOptions_occ.xaxis"
                    [colors]="chartOptions_occ.colors"></apx-chart>
                </div>
              </div>
              <div [ngClass]="fullChart ? 'col-12' : 'col-6'">
                <div id="chart_prod">
                  <apx-chart *ngIf="chartOptions_prod" [series]="chartOptions_prod.series"
                    [chart]="chartOptions_prod.chart" [dataLabels]="chartOptions_prod.dataLabels"
                    [plotOptions]="chartOptions_prod.plotOptions" [yaxis]="chartOptions_prod.yaxis"
                    [legend]="chartOptions_prod.legend" [fill]="chartOptions_prod.fill"
                    [stroke]="chartOptions_prod.stroke" [tooltip]="chartOptions_prod.tooltip"
                    [xaxis]="chartOptions_prod.xaxis" [colors]="chartOptions_prod.colors"></apx-chart>
                </div>
              </div>
            </div>
            <div class="row">
              <div [ngClass]="fullChart ? 'col-12' : 'col-6'">
                <div id="chart_rmc">
                  <apx-chart *ngIf="chartOptions_rmc" [series]="chartOptions_rmc.series"
                    [chart]="chartOptions_rmc.chart" [dataLabels]="chartOptions_rmc.dataLabels"
                    [plotOptions]="chartOptions_rmc.plotOptions" [yaxis]="chartOptions_rmc.yaxis"
                    [legend]="chartOptions_rmc.legend" [fill]="chartOptions_rmc.fill" [stroke]="chartOptions_rmc.stroke"
                    [tooltip]="chartOptions_rmc.tooltip" [xaxis]="chartOptions_rmc.xaxis"
                    [colors]="chartOptions_rmc.colors"></apx-chart>
                </div>
              </div>
              <div [ngClass]="fullChart ? 'col-12' : 'col-6'">
                <div id="chart_revpare">
                  <apx-chart *ngIf="chartOptions_revpare" [series]="chartOptions_revpare.series"
                    [chart]="chartOptions_revpare.chart" [dataLabels]="chartOptions_revpare.dataLabels"
                    [plotOptions]="chartOptions_revpare.plotOptions" [yaxis]="chartOptions_revpare.yaxis"
                    [legend]="chartOptions_revpare.legend" [fill]="chartOptions_revpare.fill"
                    [stroke]="chartOptions_revpare.stroke" [tooltip]="chartOptions_revpare.tooltip"
                    [xaxis]="chartOptions_revpare.xaxis" [colors]="chartOptions_revpare.colors"></apx-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="!loaded" class="main-page-loading">
      <div class="loading-bars-box">
        <div class="loading-bars"></div>
      </div>
    </div>
  </div>
</div>
