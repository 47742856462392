import { Component, Input } from '@angular/core';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';

@Component({
  selector: 'app-fatture-agenti-detail',
  templateUrl: './fatture-agenti-detail.component.html',
  styleUrls: ['./fatture-agenti-detail.component.scss']
})
export class FattureAgentiDetailComponent {
  @Input() invoice: any;
  show: boolean = false;

  constructor(private restService: RestService, public guiService: GuiService){

  }

  ngOnInit(): void {
    console.debug(this.invoice)
  }

  showDiv(index:any, type: any){
    console.debug(this.invoice[type][index])
    this.invoice[type][index].showDiv == true ?  this.invoice[type][index].showDiv = false : this.invoice[type][index].showDiv = true;
    console.debug(this.invoice[type][index].showDiv)
  }

  reconcile(id: any){
    this.restService.postService('crm_base_new','/inv_agent/report/reconcile/'+id+'/'+this.invoice.id,{}).subscribe((ris)=>{
      this.guiService.editedSuccesfully()
      this.getReconciled(this.invoice.id)
      this.getToBeReconciled(this.invoice.ToBeReconciledId)
    });
  }

  setPaid(id:any){
    this.restService.postService('crm_base_new','/inv_agent/report/'+id+'/status/PAID',{}).subscribe((ris)=>{
      this.guiService.editedSuccesfully()
      this.getReconciled(this.invoice.id)
      this.getToBeReconciled(this.invoice.ToBeReconciledId)
    });
  }

  getReconciled(id:any){
    this.restService.getService('crm_base_new','/inv_agent/report_compact/reconciled/'+id).subscribe((ris)=>{
      this.invoice.reconciled = ris.data
    })
  }

  getToBeReconciled(id:any){
    this.restService.getService('crm_base_new','/inv_agent/report_compact/to_be_reconciled/'+id).subscribe((ris)=>{
      this.invoice.toBeReconciled = ris.data
    })
  }

}
