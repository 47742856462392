<div class="modal-header">

  <h5>
    {{day}}
  </h5>
</div>
<div class="modal-body">
  <div *ngIf="loaded">
    <div class="row">
      <div id="datetime_scatter" class="col-12 apex-charts" dir="ltr" *ngIf="chartOptions.series.length > 0">
        <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis"
          [yaxis]="chartOptions.yaxis" [grid]="chartOptions.grid" [colors]="chartOptions.colors"></apx-chart>
      </div>
    </div>

    <div class="edit-histiry-list">
      <div *ngFor="let item of ratesChanges; let i = index">
        <!-- ROW with edit rate -->
        <div class="card mb-1 p-2" *ngIf="item.dt">
          <div class="row">
            <div class="col-4 dt-info">
              <div>
                <i class="bx bx-time-five me-1"></i>
                <label>{{item.dt | date:"dd/MM/YYYY hh:mm ZZZZ"}}</label>
              </div>
              <div>
                <label *ngIf="item.dt">{{"TITLE.dur" | translate}}</label>: {{item.dur ? item.dur : '-'}}
              </div>
            </div>
            <div class="col-8 generic-info">
              <span class="mx-1" *ngFor="let room of rooms; let c = index">
                <span class="badge badge-outline-primary" [ngClass]="item.changes[room.title] ? 'changed' : 'not-changed'">
                  <div class="room-title">{{room.title}}</div>
                  <div class="rate-info">{{item.changes[room.title] ? item.changes[room.title].newRateValue: "-" }}</div>
                  <div class="currency" *ngIf="structureService.selectedStructureConfig['currency']">
                    {{structureService.selectedStructureConfig['currency'].value}}</div>
                </span>
              </span>
            </div>
          </div>
        </div>

        <!-- ROW without edit rate -->
        <div class="card mb-1 p-2 no_edit_rate" *ngIf="!item.dt">
          <div class="row">
            <div class="col-4 dt-info">
              <div>
                <label>{{"TITLE.occ" | translate}}</label>: {{item.occ}}
              </div>
            </div>
            <div class="col-8 generic-info">
              <span class="mx-1" *ngFor="let room of rooms; let c = index">
                <span class="badge badge-outline-primary" [ngClass]="item.changes[room.title]? 'changed' : 'not-changed'">
                  <div class="room-title">{{room.title}}</div>
                  <div class="room_vr">
                    <span class="vrplus">{{item.changes[room.title] ? item.changes[room.title].vrplusBeforeChange : "-"}}</span>
                    <span class="vrminus">{{item.changes[room.title] ? item.changes[room.title].vrminusBeforeChange : "-"}}</span>
                  </div>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>


    </div>
    <div *ngIf="ratesChanges.length==0">
      {{"GENERIC.no_data_edited" | translate}}
    </div>

  </div>
  <div *ngIf="!loaded" class="main-page-loading">
    <div class="loading-bars-box">
      <div class="loading-bars"></div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="modalService.dismissAll()">{{"GENERIC.close_btn" |
    translate}}</button>
</div>