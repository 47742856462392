

<div class="row align-items-start">
    <div class="col-11 box-main-form">

      <div *ngIf="structureService.selectedStructureConfig && structureService.selectedStructureConfig.setup_phase">

        <!-- FORM MAIN SETTINGS -->

        <!-- MAIN FIELDSET -->
        <fieldset class="row first">
          <div class="col-12">
            <label class="config-item">
              <h3 class="d-inline-block">{{ "CFG.channel_manager" | translate }}</h3>
              <ng-select [clearable]="true" placeholder="..." [(ngModel)]="selectedCM" (change)="changeCM()">
                <ng-option *ngFor="let item of ch_man" [value]="item.value">{{item['label']}}</ng-option>
              </ng-select>
              <small ngbTooltip="{{helptxt}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
                <i-feather name="help-circle"></i-feather>
              </small>
            </label>
          </div>

          <div class="col-12">
            <label class="config-item">
              <h3 class="d-inline-block">{{ "CFG.channel_manager_active" | translate }}</h3>
              <div dir="ltr" class="form-check form-switch" *ngIf="structureService.selectedStructureConfig && structureService.selectedStructureConfig.WUBOOK_SYNC_ACTIVE">
                <input type="checkbox" id="customSwitchsizesm"
                       [disabled]="selectedCM==null"
                       [checked]="structureService.selectedStructureConfig.WUBOOK_SYNC_ACTIVE.value == '1'"
                       class="form-check-input" (change)="configKeyChange('WUBOOK_SYNC_ACTIVE')">
              </div>
              <small ngbTooltip="{{helptxt}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
                <i-feather name="help-circle"></i-feather>
              </small>
            </label>
          </div>
        </fieldset>

        <!-- MAIN FIELDSET -->
        <fieldset class="row" *ngIf="selectedCM!=null">
          <legend>
            <h3>{{ "CFG.channel_manager_detail" | translate }}: <b>{{selectedCM.label}}</b></h3>
          </legend>
          <div class="col-12">
            <app-h5stelle *ngIf="structureService.selectedStructureConfig.CHANNEL_SYSTEM.value == '5STELLE'" ></app-h5stelle>
            <app-beddy *ngIf="structureService.selectedStructureConfig.CHANNEL_SYSTEM.value == 'BEDDY'" ></app-beddy>
            <app-bedzzle *ngIf="structureService.selectedStructureConfig.CHANNEL_SYSTEM.value == 'BEDZZLE'" ></app-bedzzle>
            <app-dhynet *ngIf="structureService.selectedStructureConfig.CHANNEL_SYSTEM.value == 'DHYNET'" ></app-dhynet>
            <app-hotelbb *ngIf="structureService.selectedStructureConfig.CHANNEL_SYSTEM.value == 'HOTELBB'" ></app-hotelbb>
            <app-krossbooking *ngIf="structureService.selectedStructureConfig.CHANNEL_SYSTEM.value == 'KROSSBOOKING'"></app-krossbooking>
            <app-leorevoo *ngIf="structureService.selectedStructureConfig.CHANNEL_SYSTEM.value == 'LEOREVOO'"></app-leorevoo>
            <app-octorate *ngIf="structureService.selectedStructureConfig.CHANNEL_SYSTEM.value == 'OCTORATE'" ></app-octorate>
            <app-parityrate *ngIf="structureService.selectedStructureConfig.CHANNEL_SYSTEM.value == 'PARITY_RATE'" ></app-parityrate>
            <app-passpartout *ngIf="structureService.selectedStructureConfig.CHANNEL_SYSTEM.value == 'PASSPARTOUT'" ></app-passpartout>
            <app-scidoo *ngIf="structureService.selectedStructureConfig.CHANNEL_SYSTEM.value == 'SCIDOO'" ></app-scidoo>
            <app-simplebooking *ngIf="structureService.selectedStructureConfig.CHANNEL_SYSTEM.value == 'SIMPLEBOOKING'" ></app-simplebooking>
            <app-verticalbooking *ngIf="structureService.selectedStructureConfig.CHANNEL_SYSTEM.value == 'VERTICAL'" ></app-verticalbooking>
            <app-wubook *ngIf="structureService.selectedStructureConfig.CHANNEL_SYSTEM.value == 'WUBOOK'" ></app-wubook>
            <app-zak *ngIf="structureService.selectedStructureConfig.CHANNEL_SYSTEM.value == 'ZAK'" ></app-zak>
          </div>
        </fieldset>

      </div>

    </div>
    <div class="col-1 btn-group-vertical right-sidebar" role="group">
      <button class="btn-send" (click)="save()">
        <span class="uil-folder-check"></span>
        <h1>{{ "GENERIC.save_btn" | translate }}</h1>
      </button>
      <button class="btn-delete" (click)="reset()">
        <span class="uil-trash"></span>
        <h1>{{ "GENERIC.reset_btn" | translate }}</h1>
      </button>
      <!--div class="divider"></div>
      <button class="btn-link" (click)="verify()" [disabled]="isConfigured == false">
        <span class="uil-cloud-data-connection"></span>
        <h1>{{ "GENERIC.verify_btn" | translate }}</h1>
      </button>
    </div-->
    <button class="btn-verify" *ngIf="selectedCM" (click)="verify()">
      <span class="uil-shield-check"></span>
      <h1>{{ "GENERIC.verify_btn" | translate }}</h1>
    </button>

  </div>
