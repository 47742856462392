<div class="page-title-box d-flex align-items-center justify-content-between">
  <h4 class="mb-0">{{"FX.videoguide" | translate}}</h4>
</div>
<div class="col-xxl-12 col-xl-12" >
  <div class="card card-h-100" *ngIf="loaded">
    <div class="row p-3">
      <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2" *ngFor="let item of video_items; let k = index">
        <aside class="profile-card m-1">

          <header>
            <a (click)="openYoutubeModal(youtube)">
              <i class="uil-play"></i>
            </a>
            <h1>{{item.title}}</h1>
          </header>

        </aside>

        <ng-template #youtube role="document" let-modal>
          <div class="modal-header">
            <button type="button" class="btn-close bg-white" aria-hidden="true"
                    (click)="modal.dismiss('Cross click')"></button>
          </div>
          <div class="modal-body">
            <youtube-player [videoId]="item.url" [height]="450" [width]="750"></youtube-player>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
