<div class="col-sm-12 col-md-12">
  <!-- Nav tabs -->
  <ul class="nav nav-tabs nav-tabs-custom">
    <li [class.active]="subArea=='MAIN'">
      <a (click)="loadTab('MAIN')" [class.active]="subArea=='MAIN'">
        <span class="d-none d-sm-block">{{"CFG.channel_manager_main" | translate}}</span>
      </a>
    </li>
    <li [class.active]="subArea=='RATE_PLAN'" *ngIf="structureService.selectedStructureConfig['CHANNEL_SYSTEM'].value !== 'VERTICAL'">
      <a (click)="loadTab('RATE_PLAN')">
        <span class="d-none d-sm-block" *ngIf="structureService.selectedStructureConfig['CHANNEL_SYSTEM'].value !== 'SIMPLEBOOKING'">{{"FX.rates_plan" | translate}}</span>
        <span class="d-none d-sm-block" *ngIf="structureService.selectedStructureConfig['CHANNEL_SYSTEM'].value === 'SIMPLEBOOKING'">{{"FX.rates_plan_restr" | translate}}</span>
      </a>
    </li>
    <li [class.active]="subArea=='ROOMS_MAPPING'">
      <a (click)="loadTab('ROOMS_MAPPING')">
        <span class="d-none d-sm-block">{{"AVAILABILITY.availability_map_rooms_title" | translate}}</span>
      </a>
    </li>
    <li [class.active]="subArea=='RATES_MAPPING'" *ngIf="structureService.selectedStructureConfig['CHANNEL_SYSTEM'].value !== 'VERTICAL'">
      <a (click)="loadTab('RATES_MAPPING')">
        <span class="d-none d-sm-block">{{"AVAILABILITY.availability_map_plan_title" | translate}}</span>
      </a>
    </li>
  </ul>

</div>



<div class="col-sm-12 col-md-12" *ngIf="showDetail">
  <app-main-cm-settings *ngIf="subArea=='MAIN'"></app-main-cm-settings>
  <app-rate-channel *ngIf="subArea=='RATE_PLAN'"></app-rate-channel>
  <app-room-mapping *ngIf="subArea=='ROOMS_MAPPING'"></app-room-mapping>
  <app-plan-mapping *ngIf="subArea=='RATES_MAPPING'"></app-plan-mapping>
</div>
