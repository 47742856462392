<div class="main-container">
    <div class="head">
      <img src="assets/images/Logotipo-negativo.svg"/>
    </div>
    <div class="bg-over">
      <div class="background">
        <div class="back_to_login"> 
            <a [routerLink]="['/auth/login']" routerLinkActive="router-link-active" >
                <i class="uil-arrow-left"></i>
                {{"AUTH.BACK_TO_LOGIN" | translate}}
            </a>
        </div>
        <div class="out-box">
          <div class="box">
            <h5 class="p-3">{{ "USERS.password-info" | translate}}</h5>
            <form>
              <div class="p-3">
                <div class="mb-3">
                  <label for="floatingInput">{{ "AUTH.PASSWORD" | translate }}</label>
                  <div class="custom-password-field">
                      <input [type]="isVisibileNewPassword ? 'text' : 'password'" class="form-control" name="newPassword" 
                      [class.no_valid]="newPassword !='' && (confirmPassword != newPassword)" [(ngModel)]="newPassword">
                      <i class="uil-eye" *ngIf="!isVisibileNewPassword" (click)="isVisibileNewPassword = true"></i>
                      <i class="uil-eye-slash" *ngIf="isVisibileNewPassword" (click)="isVisibileNewPassword = false"></i>
                  </div>
                </div>
                <div class="mb-3">
                  <label for="floatingInput">{{ "AUTH.CONFIRM_PASSWORD" | translate }}</label>
                  <div class="custom-password-field">
                      <input [type]="isVisibileConfirmPassword ? 'text' : 'password'" class="form-control" name="confirmPassword" 
                      [class.no_valid]="confirmPassword !='' && (confirmPassword != newPassword)" [(ngModel)]="confirmPassword">
                      <i class="uil-eye" *ngIf="!isVisibileConfirmPassword" (click)="isVisibileConfirmPassword = true"></i>
                      <i class="uil-eye-slash" *ngIf="isVisibileConfirmPassword" (click)="isVisibileConfirmPassword = false"></i>
                  </div>
                </div>
                
                <button class="w-100 btn"(click)="send()" *ngIf="!isLoading">{{"AUTH.RESET_SEND" | translate}}</button>
                <div class="loading-bars-box" *ngIf="isLoading">
                  <div class="loading-bars"></div>
                </div>
              </div>
            </form>
          </div>
        </div>
  
        <div class="footer-box">
          <a>Privacy Policy</a>
        </div>
      </div>
  
    </div>
  </div>
  
  <div class="tablet">
    <div class="contenitore">
      <div class="img-zone">
        <div class="head">
          <img src="assets/images/Logotipo-negativo.svg"/>
        </div>
      </div>
      <div class="login-zone">
        <div class="back_to_login"> 
            <a [routerLink]="['/auth/login']" routerLinkActive="router-link-active" >
                <i class="uil-arrow-left"></i>
                {{"AUTH.BACK_TO_LOGIN" | translate}}
            </a>
        </div>

        <form>
          <div class="p-3">
            <div class="mb-3">
              <label for="floatingInput">{{ "AUTH.PASSWORD" | translate }}</label>
              <div class="custom-password-field">
                  <input [type]="isVisibileNewPassword ? 'text' : 'password'" class="form-control" name="newPassword" 
                  [class.no_valid]="newPassword !='' && (confirmPassword != newPassword)" [(ngModel)]="newPassword">
                  <i class="uil-eye" *ngIf="!isVisibileNewPassword" (click)="isVisibileNewPassword = true"></i>
                  <i class="uil-eye-slash" *ngIf="isVisibileNewPassword" (click)="isVisibileNewPassword = false"></i>
              </div>
            </div>
            <div class="mb-3">
              <label for="floatingInput">{{ "AUTH.CONFIRM_PASSWORD" | translate }}</label>
              <div class="custom-password-field">
                  <input [type]="isVisibileConfirmPassword ? 'text' : 'password'" class="form-control" name="confirmPassword" 
                  [class.no_valid]="confirmPassword !='' && (confirmPassword != newPassword)" [(ngModel)]="confirmPassword">
                  <i class="uil-eye" *ngIf="!isVisibileConfirmPassword" (click)="isVisibileConfirmPassword = true"></i>
                  <i class="uil-eye-slash" *ngIf="isVisibileConfirmPassword" (click)="isVisibileConfirmPassword = false"></i>
              </div>
            </div>
            
            <button class="w-100 btn"(click)="send()" *ngIf="!isLoading">{{"AUTH.RESET_SEND" | translate}}</button>
            <div class="loading-bars-box" *ngIf="isLoading">
              <div class="loading-bars"></div>
            </div>
          </div>
        </form>

        <div class="footer-zone">
          <a>Privacy Policy</a>
        </div>
      </div>
    </div>
  </div>
  
  <div class="mobile">
    <div class="head">
      <img src="assets/images/Simbolo-negativo.svg"/>
    </div>
      <div class="login-zone">
        <div class="back_to_login"> 
            <a [routerLink]="['/auth/login']" routerLinkActive="router-link-active" >
                <i class="uil-arrow-left"></i>
                {{"AUTH.BACK_TO_LOGIN" | translate}}
            </a>
        </div>
        <form>
          <div class="p-3">
            <div class="mb-3">
              <label for="floatingInput">{{ "AUTH.PASSWORD" | translate }}</label>
              <div class="custom-password-field">
                  <input [type]="isVisibileNewPassword ? 'text' : 'password'" class="form-control" name="newPassword" 
                  [class.no_valid]="newPassword !='' && (confirmPassword != newPassword)" [(ngModel)]="newPassword">
                  <i class="uil-eye" *ngIf="!isVisibileNewPassword" (click)="isVisibileNewPassword = true"></i>
                  <i class="uil-eye-slash" *ngIf="isVisibileNewPassword" (click)="isVisibileNewPassword = false"></i>
              </div>
            </div>
            <div class="mb-3">
              <label for="floatingInput">{{ "AUTH.CONFIRM_PASSWORD" | translate }}</label>
              <div class="custom-password-field">
                  <input [type]="isVisibileConfirmPassword ? 'text' : 'password'" class="form-control" name="confirmPassword" 
                  [class.no_valid]="confirmPassword !='' && (confirmPassword != newPassword)" [(ngModel)]="confirmPassword">
                  <i class="uil-eye" *ngIf="!isVisibileConfirmPassword" (click)="isVisibileConfirmPassword = true"></i>
                  <i class="uil-eye-slash" *ngIf="isVisibileConfirmPassword" (click)="isVisibileConfirmPassword = false"></i>
              </div>
            </div>
            
            <button class="w-100 btn"(click)="send()" *ngIf="!isLoading">{{"AUTH.RESET_SEND" | translate}}</button>
            <div class="loading-bars-box" *ngIf="isLoading">
              <div class="loading-bars"></div>
            </div>
          </div>
        </form>
      </div>
  </div>
  