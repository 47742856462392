<div class="background">
  <div class="box">
    <form [formGroup]="form">
      <div class="p-3">
        <h1>Reset Password</h1>
        <div class="mb-3">
          <label for="floatingInput">Inserisci la tua nuova password</label>
          <input type="email" class="form-control" formControlName="password" id="floatingInput" placeholder="Enter your new password" [ngClass]="{ 'is-invalid': form.controls.password.errors }">
          <div *ngIf="form.controls.password.errors" class="invalid-feedback" align="left">
            <span>Confirm Password is required</span>
          </div>
        </div>
        <div class="mb-3">
          <label for="floatingInput2">Conferma la tua nuova password</label>
          <input type="email" class="form-control" formControlName="confirm_password" id="floatingInput2" placeholder="Cofirm your new password" [ngClass]="{ 'is-invalid': form.controls.confirm_password.errors }">
          <div *ngIf="form.controls.confirm_password.errors" class="invalid-feedback" align="left">
            <span>Confirm Password is required</span>
          </div>
        </div>
        <button class="w-100 btn"(click)="send()">Reset Password</button>
    </div>
    </form>
  </div>
</div>
