import { GuiService } from 'src/app/core/services/gui.service';
import { Component, Input, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RestService } from 'src/app/core/services/rest.service';

@Component({
  selector: 'app-structure-detail',
  templateUrl: './structure-detail.component.html',
  styleUrls: ['./structure-detail.component.scss']
})
export class StructureDetailComponent {
  @Input() structure: any;
  form!: UntypedFormGroup
  showBtnNew: boolean = false;
  provinces: any;
  enabled: any;
  consulting: any;
  consultingTerminated: any;
  licensed: any;
  licenseTerminated: any;

  constructor(private fb: UntypedFormBuilder, public guiService: GuiService, private restService: RestService){

  }

  ngOnInit(): void {
    this.initForm()
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.initForm()
  }

  initForm(){
    console.debug(this.structure)
    this.provinces = []
    this.getProvinces()
    this.structure && Object.keys(this.structure).length > 0 ? (this.populateForm(),this.showBtnNew= false) : this.showBtnNew= true
   }

   populateForm(){
    console.debug(this.structure)
    this.enabled = this.structure.enabled
    this.consulting = this.structure.consulting
    this.consultingTerminated = this.structure.consultingTerminated
    this.licensed = this.structure.licensed
    this.licenseTerminated = this.structure.licenseTerminated
   }

   editStructure(){
    this.guiService.loaded = false;
    this.restService.putService('structure','/update',this.structure).subscribe((ris)=>{
      this.guiService.loaded = true;
      this.guiService.editedSuccesfully()
      this.guiService.refreshList.next(true);
    })
   }

   newStructure(){
    this.guiService.loaded = false;
    console.debug(this.structure)
    this.restService.postService('structure','/create',this.structure).subscribe((ris)=>{
      this.guiService.loaded = true;
      this.guiService.createdSuccessfully()
      this.form.reset()
      this.guiService.refreshList.next(true);
    })
   }

   getProvinces(){
    this.restService.getService('provinces','').subscribe((ris)=>{
      this.provinces = ris
    })
   }

   close(){
    this.guiService.closeDetail.next(true);
   }
}
