<div class="row align-items-start">
  <div class="col-12 box-main-form">
    <fieldset>
      <div *ngIf="structureService.selectedStructureConfig && structureService.selectedStructureConfig.setup_phase">

        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.CMX.BEDZZLE_HOTEL_ID" | translate }}</h3>
            <input class="form-control input-camere" type="text" [(ngModel)]="structureService.selectedStructureConfig.BEDZZLE_HOTEL_ID.value" />
            <small ngbTooltip="{{helptxt}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
              <i-feather name="help-circle"></i-feather>
            </small>
          </label>
        </div>

        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.CMX.BEDZZLE_API_KEY" | translate }}</h3>
            <input class="form-control input-camere" type="text" [(ngModel)]="structureService.selectedStructureConfig.BEDZZLE_API_KEY.value" />
            <small ngbTooltip="{{helptxt}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
              <i-feather name="help-circle"></i-feather>
            </small>
          </label>
        </div>

        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.CMX.BEDZZLE_STRATEGY_ID" | translate }}</h3>
            <input class="form-control input-camere" type="text" [(ngModel)]="structureService.selectedStructureConfig.BEDZZLE_STRATEGY_ID.value" />
            <small ngbTooltip="{{helptxt}}" placement="right" tooltipClass="custom-tooltip-class" triggers="click:blur">
              <i-feather name="help-circle"></i-feather>
            </small>
          </label>
        </div>

        <div class="col-12">
          <label class="config-item">
            <h3 class="d-inline-block">{{ "CFG.CMX.BEDZZLE_RATES_MAPPING" | translate }}</h3>
            <button (click)="openMealPlansSettings(content)" class="btn btn-primary mx-2">{{"CFG.CMX.BEDZZLE_SETTINGS_MEAL_PLANS" | translate}}</button>
            <button  class="btn btn-outline-primary">{{"CFG.CMX.BEDZZLE_COPY_MEAL_PLANS" | translate}}</button>
          </label>
        </div>

      </div>
    </fieldset>
  </div>

</div>


<!-- Modal per generare il JSON per Meal Plan -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h1 class="modal-title fs-5" id="exampleModalLabel">{{"CFG.CMX.BEDZZLE_SETTINGS_MEAL_PLANS" | translate}}</h1>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.close()"></button>
  </div>
  <div class="modal-body">
    <div class="accordion" id="mealplansBedzzle">
      <div class="accordion-item" *ngFor="let mealp of mealPlan; let i = index">
        <h2 class="accordion-header">
          <button class="accordion-button" type="button" (click)="this.mealp.accordionShow = !this.mealp.accordionShow">
            {{mealp.id}}
          </button>
        </h2>
        <div id="#collapse_{{i}}" class="accordion-collapse collapse" [class.show]="this.mealp.accordionShow" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <table>
              <thead>
               <tr>
                <th></th>
                <th>Id</th>
                <th>Code</th>
                <th>Base Price</th>
                <th>Delete Date</th>
                <th>Description</th>
                <th>Name</th>
                <th>Not Refundable</th>
                <th>Order</th>
                <th>Pax</th>
                <th>Property Id</th>
                <th>Rate Type</th>
                <th>Room Id</th>
                <th>Visibility CtxCHM</th>
                <th>Visibility CtxPMS</th>
                <th>Visibility CtxWEB</th>
              </tr>
              </thead>

              <tbody>
               <tr *ngFor="let item of mealp.items">
                <td><input type="checkbox" [(ngModel)]="item.enabled"></td>
                <td>{{item.id}}</td>
                <td>{{item.code}}</td>
                <td>{{item.basePrice}}</td>
                <td>{{item.deleteDate}}</td>
                <td>{{item.description}}</td>
                <td>{{item.name}}</td>
                <td>{{item.notRefundable}}</td>
                <td>{{item.order}}</td>
                <td>{{item.pax}}</td>
                <td>{{item.propertyId}}</td>
                <td>{{item.rateType}}</td>
                <td>{{item.roomId}}</td>
                <td>{{item.visibilityCtxCHM}}</td>
                <td>{{item.visibilityCtxPMS}}</td>
                <td>{{item.visibilityCtxWEB}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Close</button>
    <button type="button" class="btn btn-primary" (click)="setMealPlans()">{{ "FX.edit" | translate }}</button>
  </div>
</ng-template>
