<div class="col-xxl-12 col-xl-12">
  <div class="card card-h-100" *ngIf="clients">
    <div class="card-header justify-content-between d-flex align-items-center">
     <div class="col-sm-8 col-md-8">
      <div class="row">
        <div class="form-floating col-2">
          <input type="text" id="social" class="form-control" [(ngModel)]="socialCtrl">
          <label for="social">Ragione Sociale</label>
        </div>
        <div class="form-floating col-2">
          <input type="text" id="iva" class="form-control" [(ngModel)]="ivaCtrl">
          <label for="iva">P.Iva</label>
        </div>
        <div class="form-floating col-2">
          <input type="text" id="structure" class="form-control" [(ngModel)]="structureCtrl">
          <label for="structure">Structure</label>
        </div>
        <div class="form-floating col-2">
          <input type="text" id="nation" class="form-control" [(ngModel)]="nationCtrl">
          <label for="nation">Nazione</label>
        </div>
        <span class="icona cerca col-1">
          <i class="uil-search" (click)="getClients()"></i>
        </span>
      </div>
    </div>
    <div class="col-sm-4 col-md-4">
      <div class="d-flex align-items-start justify-content-around top-icons">
        <button class="btn btn-primary" (click)="newClientEnable()">
          <i class="uil-plus"></i>
        </button>
        <button class="btn btn-primary" (click)="guiService.chooseItemView('CARD')"
          [class.selected]="guiService.viewType == 'CARD'">
          <i class="uil-table"></i>
        </button>
        <button class="btn btn-primary" [class.selected]="guiService.viewType == 'TABLE'"
          (click)="guiService.chooseItemView('TABLE')">
          <i class="uil-list-ul"></i>
        </button>
        <ngb-pagination class="col-6"
        (pageChange)="loadPage($event)"
          [(page)]="page"
          [pageSize]="size"
          [collectionSize]="clients.recordsTotal"
          [ellipses]="true"
          [maxSize]="1"
          ></ngb-pagination>
      </div>
    </div>

    </div><!-- end card header -->
    <div class="card-body row">

      <div [ngClass]="showDetail ? 'col-sm-2 col-md-6' : 'col-sm-12 col-md-12'" >

        <!-- START TABLE VIEW -->
        <div class="table-responsive" *ngIf="guiService.viewType == 'TABLE'">
          <table class="table table-sm table-hover mb-0">
            <thead>
            <tr>
              <th class="text-center">#</th>
              <th>Ragione Sociale</th>
              <th class="text-center">P.Iva</th>
              <th>Codice Fiscale</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let client of clients.data">
              <th scope="row" [class.selected]="clientSelected && client.id == clientSelected.id">#{{client.id}}</th>
              <td [class.selected]="clientSelected && client.id == clientSelected.id" class="name-color"><a (click)="clientDetail(client.id)">{{client.name}}</a></td>
              <td [class.selected]="clientSelected && client.id == clientSelected.id" class="text-center">{{client.vatNumber}}</td>
              <td [class.selected]="clientSelected && client.id == clientSelected.id">{{client.cf}}</td>
              <td [class.selected]="clientSelected && client.id == clientSelected.id">
                <div class="text-end mb-1" ngbDropdown>
                  <i class="uil-cog m-0 standard" ngbDropdownToggle id="dropdownBasic0"></i>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic0">
                    <button ngbDropdownItem (click)="clientDetail(client.id)">Detail</button>
                    <button ngbDropdownItem (click)="clientConfig(client.id)">Settings</button>
                    <button ngbDropdownItem (click)="guiService.deleteConfirm('crm_base_new','/inv_customer/delete',client.id)">Delete</button>
                  </div>
                </div>
              </td>
            </tr>
            </tbody><!-- end tbody -->
          </table><!-- end table -->
        </div><!-- end table responsive -->


        <!-- START CARD VIEW -->
        <div class="card-group justify-content-center" *ngIf="guiService.viewType == 'CARD'">
          <div class="col-xl-3 col-xxl-2 col-sm-3 col-md-3 card-body-structure-wrapper" *ngFor="let client of clients.data">
            <div class="card-body-structure" [class.selected]="clientSelected && client.id == clientSelected.id">
              <div class="action-btn">
                <div class="text-end mb-1" ngbDropdown>
                  <i class="uil-cog m-0 standard" ngbDropdownToggle id="dropdownBasic2"></i>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                    <button ngbDropdownItem (click)="clientDetail(client.id)">Detail</button>
                    <button ngbDropdownItem (click)="clientConfig(client.id)">Settings</button>
                    <button ngbDropdownItem (click)="guiService.deleteConfirm('crm_base_new','/inv_customer/delete',client.id)">Delete</button>
                  </div>
                </div>
              </div>

              <h3 class="pid-area">#{{client.id}}</h3>

              <div class="titolo d-flex justify-content-between">
                <h1><a (click)="clientDetail(client.id)">{{client.name}}</a></h1>
              </div>
              <div class="body-card">
                <div class=""><h3>P.Iva:</h3>
                  <p>{{client.vatNumber}}</p></div>
                <div class=""><h3>Codice Fiscale:</h3>
                  <p>{{client.cf}}</p></div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-10 col-md-6" *ngIf="showDetail && !showConfig">
        <app-client-detail [client]="clientSelected" *ngIf="newClient == false"></app-client-detail>
        <app-client-detail [client]="{}" *ngIf="newClient"></app-client-detail>
      </div>
      <div class="col-sm-10 col-md-6" *ngIf="showDetail && showConfig">
        <app-client-config [client]="clientSelected"></app-client-config>
      </div>
    </div><!-- end card body -->

  </div><!-- end card -->
</div>
