<form>
    <div class="card mb-0">
      <div class="card-header">
          <h1 class="card-title text-center">{{structure.name}}</h1>
          <h1 *ngIf="!structure.name" class="text-center">Crea nuova struttura</h1>
          <i class="uil-times-square close-icon" (click)="close()"></i>
      </div><!-- end card header -->
      <form class="card-body">
          <div class="row">
              <div class="col-12">
                  <div class="mb-3 row">
                      <label for="name" class="col-md-2 col-form-label">Nome</label>
                      <div class="col-md-10">
                          <input class="form-control" type="text" name="name"[(ngModel)]="structure.name">
                      </div>
                  </div><!-- end row -->
                  <div class="mb-3 row">
                      <label for="address" class="col-md-2 col-form-label">Indirizzo</label>
                      <div class="col-md-10">
                          <input class="form-control" type="text"
                              name="address"[(ngModel)]="structure.address">
                      </div>
                  </div><!-- end row -->
                  <div class="mb-3 row">
                      <label for="cap" class="col-md-2 col-form-label">CAP/ZIP code</label>
                      <div class="col-md-10">
                          <input class="form-control" type="text"
                              name="cap"[(ngModel)]="structure.cap">
                      </div>
                  </div><!-- end row -->
                  <div class="mb-3 row">
                      <label for="city" class="col-md-2 col-form-label">Città</label>
                      <div class="col-md-10">
                          <input class="form-control" type="text"
                              name="city"[(ngModel)]="structure.city">
                      </div>
                  </div><!-- end row -->
                  <div class="mb-3 row">
                      <label for="province" class="col-md-2 col-form-label">Provincia</label>
                      <div class="col-md-10">
                          <select class="form-select" type="text" name="province"[(ngModel)]="structure.province" >
                            <option [value]="provincia.sigla" *ngFor="let provincia of provinces">{{provincia.name}}</option>
                          </select>
                      </div>
                  </div><!-- end row -->
                  <div class="mb-3 row">
                    <label for="country" class="col-md-2 col-form-label">Nazione</label>
                    <div class="col-md-10">
                        <input class="form-control" type="text"  name="country"[(ngModel)]="structure.country">
                    </div>
                </div><!-- end row -->
                <div class="mb-3 row" *ngIf="structure.id!=null">
                  <label for="latitudine" class="col-md-2 col-form-label">Latitudine</label>
                  <div class="col-md-10">
                      <input class="form-control" type="text" name="latitudine"[(ngModel)]="structure.latitude">
                  </div>
              </div><!-- end row -->
              <div class="mb-3 row" *ngIf="structure.id!=null">
                <label for="longitudine" class="col-md-2 col-form-label">Longitudine</label>
                <div class="col-md-10">
                    <input class="form-control" type="text" name="longitudine"[(ngModel)]="structure.longitude">
                </div>
            </div><!-- end row -->
            <div class="mb-3 row">
              <label for="rooms" class="col-md-2 col-form-label">Camere</label>
              <div class="col-md-10">
                  <input class="form-control" type="text" name="rooms"[(ngModel)]="structure.rooms">
              </div>
          </div><!-- end row -->
          <div class="mb-3 row">
            <label for="phoneNumber" class="col-md-2 col-form-label">Telefono</label>
            <div class="col-md-10">
                <input class="form-control" type="text" name="phoneNumber"[(ngModel)]="structure.phoneNumber">
            </div>
        </div><!-- end row -->
        <div class="mb-3 row">
          <label for="fax" class="col-md-2 col-form-label">Fax</label>
          <div class="col-md-10">
              <input class="form-control" type="number"  name="fax"[(ngModel)]="structure.fax">
          </div>
      </div><!-- end row -->
      <div class="mb-3 row">
        <label for="webSite" class="col-md-2 col-form-label">Sito web</label>
        <div class="col-md-10">
            <input class="form-control" type="text" name="webSite"[(ngModel)]="structure.webSite">
        </div>
    </div><!-- end row -->
    <div class="mb-3 row">
      <label for="responsible" class="col-md-2 col-form-label">Referente</label>
      <div class="col-md-10">
          <input class="form-control" type="text" name="responsible"[(ngModel)]="structure.responsible">
      </div>
  </div><!-- end row -->
  <div class="mb-3 row">
    <label for="communicationEmail" class="col-md-2 col-form-label">Comunicazioni (Email)</label>
    <div class="col-md-10">
        <input class="form-control" type="email" name="communicationEmail"[(ngModel)]="structure.communicationEmail">
    </div>
</div><!-- end row -->
<div class="mb-3 row">
  <label for="reservationEmail" class="col-md-2 col-form-label">Prenotazioni (Email)</label>
  <div class="col-md-10">
      <input class="form-control" type="email" name="reservationEmail"[(ngModel)]="structure.reservationEmail">
  </div>
</div><!-- end row -->
<div class="mb-3 row">
<label for="enabled" class="col-md-2 col-form-label">Abilitata</label>
<div class="col-md-10 d-flex align-items-center">
    <ui-switch defaultBoColor="#dfdfdf" color="#51d28c" [(ngModel)]="structure.enabled" [ngModelOptions]="{standalone: true}" checkedLabel="Si"uncheckedLabel="No"></ui-switch>
</div>
</div><!-- end row -->
<div class="mb-3 row">
<label for="consulting" class="col-md-2 col-form-label">In consulenza</label>
<div class="col-md-10 d-flex align-items-center">
  <ui-switch defaultBoColor="#dfdfdf" color="#51d28c" [(ngModel)]="structure.consulting" [ngModelOptions]="{standalone: true}" checkedLabel="Si"uncheckedLabel="No"></ui-switch>
</div>
</div><!-- end row -->
<div class="mb-3 row">
<label for="consultingTerminated" class="col-md-2 col-form-label">Consulenza terminata</label>
<div class="col-md-10 d-flex align-items-center">
<ui-switch defaultBoColor="#dfdfdf" color="#51d28c" [(ngModel)]="structure.consultingTerminated" [ngModelOptions]="{standalone: true}" checkedLabel="Si"uncheckedLabel="No"></ui-switch>
</div>
</div><!-- end row -->
<div class="mb-3 row">
<label for="licensed" class="col-md-2 col-form-label">Licenza</label>
<div class="col-md-10 d-flex align-items-center">
<ui-switch defaultBoColor="#dfdfdf" color="#51d28c" [(ngModel)]="structure.licensed" [ngModelOptions]="{standalone: true}" checkedLabel="Si"uncheckedLabel="No"></ui-switch>
</div>
</div><!-- end row -->
<div class="mb-3 row">
<label for="licenseTerminated" class="col-md-2 col-form-label">Licenza terminata</label>
<div class="col-md-10 d-flex align-items-center">
<ui-switch defaultBoColor="#dfdfdf" color="#51d28c" [(ngModel)]="structure.licenseTerminated" [ngModelOptions]="{standalone: true}" checkedLabel="Si"uncheckedLabel="No"></ui-switch>
</div>
</div><!-- end row -->
              </div><!-- end col -->
          </div><!-- end row -->
        </form><!-- end card body -->
  </div>
  <div class="d-flex justify-content-center m-2">
    <button (click)="editStructure()" class="btn-edit" *ngIf="showBtnNew == false">Modifica</button>
    <button (click)="newStructure()" class="btn-new" *ngIf="showBtnNew == true">Crea</button>
  </div>
  </form>
