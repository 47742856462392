import { Component } from '@angular/core';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';

@Component({
  selector: 'app-clienti',
  templateUrl: './clienti.component.html',
  styleUrls: ['./clienti.component.scss']
})
export class ClientiComponent {
  socialCtrl: any;
  ivaCtrl: any;
  structureCtrl: any;
  nationCtrl: any;
  categoryCtrl: any;
  loaded: boolean = false;
  clients: any = [];

  clientSelected: any = null;
  newClient: boolean = false;
  page = 0
  size = 10;
  showDetail: boolean = false;
  showConfig: boolean = false;

  constructor(private restService: RestService, public guiService: GuiService){
    this.guiService.closeDetail.subscribe(value => {
      if(value == true){
        this.clientSelected = null;
        this.showDetail = false;
      }
    });

    this.guiService.refreshList.subscribe( value => {
      if(value == true){
        this.page = 0
        this.getClients()
        this.showDetail = false
      }
     });
  }

  ngOnInit(): void {
    this.getClients();
  }

  loadPage(event:any){
    this.page = event
    console.debug(event)
    this.getClients()
   }

  getClients(){
    let tmpJson: any = {
      "draw":this.page,
      "start": this.page*10,
      "length": 10,
      "order[0][column]": "0",
      "order[0][dir]":"asc",
      "ragioneSociale": this.socialCtrl ? this.socialCtrl : '',
      "piva": this.ivaCtrl ? this.ivaCtrl : '',
      "structure": this.structureCtrl ? this.structureCtrl : '',
      "country": this.nationCtrl ? this.nationCtrl : ''
    };

    let json = new URLSearchParams(tmpJson).toString();
    this.loaded = false;
    this.restService.postService('crm_base_new','/inv_customer/list?'+json,{}).subscribe((ris)=>{
      console.debug(ris)
      this.clients = [];
      this.clients = ris;
      this.loaded = true;
    });
  }

  clientDetail(id: any){
    this.restService.getService('crm_base_new','/inv_customer/detail/'+id).subscribe((ris)=>{
      this.clientSelected = ris.model
      this.newClient = false;
      this.showDetail = true;
      this.showConfig = false;
    })
  }

  newClientEnable() {
    this.newClient = true;
    this.clientSelected = {};
    this.showDetail = true;
    this.showConfig = false;
  }

  clientConfig(id:any){
    this.restService.getService('crm_base_new','/inv_customer/customer_configuration/'+id).subscribe((ris)=>{
      this.clientSelected = ris.model
      this.clientSelected['id'] = id
      this.showDetail = true;
      this.showConfig = true;
    })
  }
}
