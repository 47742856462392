import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';
import { StructureService } from 'src/app/core/services/structure.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sales-channel-list',
  templateUrl: './sales-channel-list.component.html',
  styleUrls: ['./sales-channel-list.component.scss']
})
export class SalesChannelListComponent {
  structureView: boolean = false;
  channelSelected: any = null;
  newChannel: boolean = false;
  channelCtrl: any;
  page = 1
  size = 15;
  channels: any;
  showDetail: boolean = false;
  active = 1;
  tabCriteria: string | undefined;

  constructor(public guiService: GuiService, private restService: RestService, public structureService: StructureService,private router: Router){
    this.structureService.isStructureChanged.subscribe( value => {
      if(value == true){
        this.ngOnInit()
        this.channelSelected = null;
        this.showDetail = false;
      }
   });

    this.newChannel = false

    this.guiService.closeDetail.subscribe( value => {
      if(value == true){
        this.channelSelected = null;
        this.showDetail = false;
      }
  });

  this.guiService.refreshList.subscribe( value => {
    if(value == true){
      this.page = 1
      this.getChannels()
    }
   });

   this.structureService.selectedStructure ? null : Swal.fire({
    title: 'Nessuna struttura selezionata',
    icon: 'warning'
  }).then((result)=>{
    if(result.isConfirmed){
      this.router.navigate(['admin/dashboard']);
    }
  })

  }

  ngOnInit(): void {
    this.getChannels()
  }

  getChannels(){
    let params = {
      page: this.page-1,
      searchCriteria: this.channelCtrl ? [{
        "key": "CODE",
        "operation" : "CONTAINS",
        "value" : this.channelCtrl,
        "orPredicate" : false
      }] : [],
      size: this.size,
      structureId: this.structureService.selectedStructure.id
    }
    if(this.tabCriteria){
      params.searchCriteria.push({
        "key": 'ENABLED',
        "operation" : "EQUALITY",
        "value" : this.tabCriteria === 'ENABLED' ? true : false,
        "orPredicate" : false
      })
    }
    this.restService.postService('sales_channel','/v2/search',params).subscribe((ris)=>{
      this.channels = ris
    })
   }

   loadPage(event:any){
    this.page = event
    this.getChannels()
   }


   detailChannel(ch: number) {
      this.channelSelected = ch;
      this.newChannel = false
      this.showDetail = true;
   }

   newChannelEnable() {
     this.newChannel = true;
     this.channelSelected = {};
     this.showDetail = true;
   }

   activeTab(tabNum:any){
    if(tabNum === 1){
      this.tabCriteria = undefined;
    }if(tabNum === 2){
      this.tabCriteria = 'ENABLED';
    }else if(tabNum === 3){
      this.tabCriteria = 'DISABLED';
    }
    this.getChannels()
   }

   disableChannel(channel:any){

    Swal.fire({
      title: 'Sei sicuro?',
      text: "Vuoi disabilitare questo elemento?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, disabilita!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        let editChannel = {
          code: channel.code.replace(/\s/g, '_'),
          description: channel.description,
          enabled: false
        }

        this.restService.putService('sale_channels','/'+this.structureService.selectedStructure.id + '/'+ channel.id,editChannel).subscribe((ris)=>{
          this.guiService.editedSuccesfully()
          this.guiService.refreshList.next(true);
        })
      }
    })
   }

}
