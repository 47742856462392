import { Component } from '@angular/core';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';

@Component({
  selector: 'app-fatture-agenti',
  templateUrl: './fatture-agenti.component.html',
  styleUrls: ['./fatture-agenti.component.scss']
})
export class FattureAgentiComponent {
  socialCtrl: any;
  loaded: boolean = false;

  invoices: any;
  invoiceSelected: any = {};
  newInvoice: boolean = false;
  page = 0
  size = 10;
  showDetail: boolean = false;

  constructor(private restService: RestService, public guiService: GuiService){
    this.guiService.closeDetail.subscribe(value => {
      if(value == true){
        this.invoiceSelected = null;
        this.showDetail = false;
      }
    });

    this.guiService.refreshList.subscribe( value => {
      if(value == true){
        this.page = 0
        this.getInvoices()
        this.showDetail = false
      }
     });
  }

  ngOnInit(): void {
    this.getInvoices();
  }

  loadPage(event:any){
    this.page = event
    this.getInvoices()
  }


  getInvoices(){
    let tmpJson: any = {
      "draw":this.page,
      "start": this.page*10,
      "length": 10,
      "order[0][column]": "0",
      "order[0][dir]":"asc",
      "ragioneSociale": this.socialCtrl ? this.socialCtrl : ''
    };

    let json = new URLSearchParams(tmpJson).toString();
    this.loaded = false;
    this.restService.postService('crm_base_new','/inv_agent_invoice/search?'+json,{}).subscribe((ris)=>{
      console.debug(ris)
      this.invoices = [];
      this.invoices = ris;
      this.loaded = true;
    });
  }

  invoiceDetail(idInvoice: any,idAgent:any){
    this.getReconciled(idInvoice);
    this.getToBeReconciled(idAgent);
    this.invoiceSelected.id = idInvoice
    this.invoiceSelected.ToBeReconciledId = idAgent
  }

  getReconciled(id:any){
    this.restService.getService('crm_base_new','/inv_agent/report_compact/reconciled/'+id).subscribe((ris)=>{
      this.newInvoice = false;
      this.invoiceSelected.reconciled = ris.data
      this.showDetail = true;
    })
  }

  getToBeReconciled(id:any){
    this.restService.getService('crm_base_new','/inv_agent/report_compact/to_be_reconciled/'+id).subscribe((ris)=>{
      this.newInvoice = false;
      this.invoiceSelected.toBeReconciled = ris.data
      this.showDetail = true;
    })
  }

  openCloudInvoice(link:any){
    window.open(link,'_blank')
  }

}
