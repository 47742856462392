<div class="modal-header">
  <h4 class="modal-title">Modifica Periodo: {{date[0] | date: 'shortDate'}} - {{date[1] | date: 'shortDate'}}</h4>
</div>
<div class="modal-body">
  <div class="row mb-3">
    <h3 class="col-2 text-center">Room</h3>
    <h3 class="col-3 text-center">Minimum Stay</h3>
    <h3 class="col-3 text-center">Availability</h3>
    <h3 class="col-3 text-center">Status</h3>
  </div>
  <div class="row mb-1" *ngFor="let room of rooms; let i = index">
    <div class="col-2 text-center">
      <h1 [title]="room.title">{{room.title}}</h1>
    </div>
    <div class="col-3 text-center">
      <input class="form-control" type="number" [(ngModel)]="controls.min[room.title]">
    </div>
    <div class="col-3 text-center">
      <input class="form-control" type="number" [(ngModel)]="controls.ava[room.title]">
    </div>
    <div class="col-3 text-center">
      <select class="form-select" type="text" [(ngModel)]="controls.status[room.title]">
        <option value=""></option>
        <option [value]="0">Aperta</option>
        <option [value]="1">Chiusa</option>
      </select>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn" (click)="closeModal()">Chiudi</button>
  <button type="button" class="btn-edit" (click)="edit()">Modifica</button>
</div>
