import { Component } from '@angular/core';

@Component({
  selector: 'app-anagrafiche',
  templateUrl: './anagrafiche.component.html',
  styleUrls: ['./anagrafiche.component.scss']
})
export class AnagraficheComponent {
  activeTab: any = 1;

}
