<form>
  <div class="card mb-0">
    <div class="card-header">
        <h1 class="card-title" *ngIf="service && service != null">#{{service.id}}: {{service.typeCode}}</h1>
        <h1 *ngIf="!service || service == null" class="text-center">{{"FX.create" | translate}} {{"FX.extras" | translate}}</h1>
        <i class="uil-times-square close-icon" (click)="close()"></i>
    </div><!-- end card header -->
    <form class="card-body">
        <div class="row">
            <div class="col-12">

              <div class="mb-3 row">
                <label for="name" class="col-md-2 col-form-label">{{"FX.extras" | translate}}</label>
                <div class="col-md-10">
                  <ng-select [clearable]="true" placeholder="" bindLabel="code" bindValue="id" [items]="servicesList" [(ngModel)]="extraservice.typeId" class="p-0" 
                  [ngModelOptions]="{standalone: true}">
                  </ng-select>
                </div>
              </div><!-- end row -->

              <div class="mb-3 row">
                <label for="toDate" class="col-md-2 col-form-label">Da</label>
                <div class="col-md-10">
                  <input class="form-control" type="date" name="fromDate" [(ngModel)]="extraservice.fromDate" />
                </div>
              </div>

              <div class="mb-3 row">
                <label for="toDate" class="col-md-2 col-form-label">A</label>
                <div class="col-md-10">
                  <input class="form-control" type="date" name="toDate" [(ngModel)]="extraservice.toDate" />
                </div>
              </div>

              <div class="mb-3 row">
                <div class="col day-col">
                  <label class="day">{{ "DAYS.SHORT.1" | translate }}</label>
                  <input class="form-check-input" type="checkbox" name="day1" [(ngModel)]="extraservice.monday">
                </div>
                <div class="col day-col">
                  <label class="day">{{ "DAYS.SHORT.2" | translate }}</label>
                  <input class="form-check-input" type="checkbox" name="day2" [(ngModel)]="extraservice.tuesday">
                </div>
                <div class="col day-col">
                  <label class="day">{{ "DAYS.SHORT.3" | translate }}</label>
                  <input class="form-check-input" type="checkbox" name="day3" [(ngModel)]="extraservice.wednesday">
                </div>
                <div class="col day-col">
                  <label class="day">{{ "DAYS.SHORT.4" | translate }}</label>
                  <input class="form-check-input" type="checkbox" name="day4" [(ngModel)]="extraservice.thursday">
                </div>
                <div class="col day-col">
                  <label class="day">{{ "DAYS.SHORT.5" | translate }}</label>
                  <input class="form-check-input" type="checkbox" name="day5" [(ngModel)]="extraservice.friday">
                </div>
                <div class="col day-col">
                  <label class="day">{{ "DAYS.SHORT.6" | translate }}</label>
                  <input class="form-check-input" type="checkbox" name="day6" [(ngModel)]="extraservice.saturday">
                </div>
                <div class="col day-col">
                  <label class="day">{{ "DAYS.SHORT.7" | translate }}</label>
                  <input class="form-check-input" type="checkbox" name="day7" [(ngModel)]="extraservice.sunday">
                </div>
              </div>
                  
              <div class="mb-3 row">
                <label for="dailyPrice" class="col-md-2 col-form-label">Prezzo (Giorno)</label>
                <div class="col-md-4">
                    <input class="form-control" type="text" id="dailyPrice" name="dailyPrice" [(ngModel)]="extraservice.dailyPrice" />
                </div>
              </div>

              <div class="mb-3 row">
                <label for="toDate" class="col-md-2 col-form-label">Una tantum</label>
                <div class="col-md-4 d-flex align-items-center">
                  <ui-switch defaultBoColor="#dfdfdf" color="#51d28c" [(ngModel)]="extraservice.perPerson" [ngModelOptions]="{standalone: true}" 
                  checkedLabel="{{ 'STRUCTURE.yes' | translate }}" uncheckedLabel="{{ 'STRUCTURE.no' | translate }}"></ui-switch>
                </div>
              </div>
            </div><!-- end col -->
        </div><!-- end row -->
      </form><!-- end card body -->
</div>
<div class="d-flex justify-content-center m-2">
  <button (click)="editExtraService()" class="btn-edit" *ngIf="showBtnNew == false">{{"FX.edit" | translate}}</button>
  <button (click)="newExtraService()" class="btn-new" *ngIf="showBtnNew == true">{{"FX.create" | translate}}</button>
</div>
</form>
