import { Component, EventEmitter, Output } from '@angular/core';
import {StructureService} from "../../../../core/services/structure.service";
import {RestService} from "../../../../core/services/rest.service";
import {GuiService} from "../../../../core/services/gui.service";
import {CalendarService} from "../../../../core/services/calendar.service";

import * as moment from 'moment';

@Component({
  selector: 'app-top-calendar',
  templateUrl: './top-calendar.component.html',
  styleUrls: ['./top-calendar.component.scss']
})
export class TopCalendarComponent {
  @Output() reloadCalendar = new EventEmitter();
  months:any = null;
  days: any;
  now: any;
  currentMonth: any;
  currentDay: any;
  startMonth: any;
  selectedMonth: any;
  selectedMonthIndex: any;

  monthsPickup: any;
  monthsPickuploaded: boolean = false;
  daysPickup: any;
  firstInit: boolean = false;

  constructor(public structureService: StructureService, private restService: RestService, public guiService: GuiService, public calService: CalendarService){

  }

  ngOnInit(): void {

    this.now = moment();
    
    // nowView indica il giorno di riferimento visualizzato
    let nowView = this.calService.monthSelectedForCalendar && this.calService.yearSelectedForCalendar ? moment(this.calService.yearSelectedForCalendar+""+this.calService.monthSelectedForCalendar+"01","YYYYMMDD") : this.now;
    this.currentDay = nowView.day();
    this.currentMonth = nowView.month();
    this.startMonth = nowView.subtract(1,'month');
    this.generateMonths();
  }

  generateMonths(wayToMove?:any){
    this.months = [];


    if(wayToMove){
      this.startMonth = wayToMove == 'right' ? moment(this.startMonth).add(1,'month') : moment(this.startMonth).subtract(1,'month')
    }
    for(let k = 0; k < 12; k++){
      let tmpDate = moment(this.startMonth).add(k,'month')
      this.months.push(
        {
          month: moment(tmpDate).month(),
          year: moment(tmpDate).year(),
          id: tmpDate.format("YYYY")+"-"+tmpDate.format("MM")
        }
      )
    }

    let mnt = this.startMonth.format("YYYY")+'-'+this.startMonth.format("MM");
    this.calService.getCalendarPickUp(mnt,12).subscribe((ris)=> {
      this.monthsPickup = ris;
      this.daysPickup = ris[mnt] ? ris[mnt].days : {};
      this.monthsPickuploaded = true;

      let selectMonthParam: any = {};
        selectMonthParam.month = this.calService.monthSelectedForCalendar ? parseInt(this.calService.monthSelectedForCalendar)-1 : moment().month();
        selectMonthParam.year = this.calService.yearSelectedForCalendar ? this.calService.yearSelectedForCalendar : moment().year();
        selectMonthParam.id = selectMonthParam.year+"-"+moment().month(selectMonthParam.month).format("MM");

      this.firstInit === false ?  this.selectMonth(selectMonthParam) : null;
      this.calService.selectedDay = this.now.format('YYYYMMDD');
    });
  }

  selectMonth(monthObj?: any){
    this.firstInit = true;
    if(monthObj){

      console.debug("1-",monthObj);
      this.selectedMonthIndex = monthObj.id;
      this.selectedMonth = moment().year(monthObj.year).month(monthObj.month).date(1);
      this.calService.monthSelectedForCalendar = this.selectedMonth.format('MM');
      this.calService.yearSelectedForCalendar = this.selectedMonth.format('YYYY');

      let mnt = this.calService.yearSelectedForCalendar+'-'+this.calService.monthSelectedForCalendar;
      this.daysPickup = this.monthsPickup[mnt] ? this.monthsPickup[mnt].days : {};
    }else{

      this.selectedMonthIndex = moment().format("YYYY-MM");
      this.selectedMonth = moment().year(moment().year()).month(this.selectedMonthIndex).date(1);
      this.calService.monthSelectedForCalendar = this.selectedMonth.format('MM');
      this.calService.yearSelectedForCalendar = this.selectedMonth.format('YYYY');

      let mnt = this.calService.yearSelectedForCalendar+'-'+this.calService.monthSelectedForCalendar;
      this.daysPickup = this.monthsPickup[mnt] ? this.monthsPickup[mnt].days : {};
    }

    this.calService.prepareDays(this.calService.monthSelectedForCalendar);
  }

  selectDay(day:any,index:any){
      this.calService.selectedDay = day.y+''+day.m+''+day.d /*TEMPORANEO POI SPOSTARE = selectedDay (settare anche all oninit)*/
      if(index > 2) {
        this.scrollTo(index-3);
      } else {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        this.guiService.scrollY = false;
      }
    }

  scrollTo(element: any): void {
    (document.getElementById(element) as HTMLElement).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

}
