<form>
  <div class="card mb-0">
    <div class="card-header">
        <h1 class="card-title text-center">{{channel.code}}</h1>
        <h1 *ngIf="!channel.code" class="text-center">Crea Sales Channel</h1>
        <i class="uil-times-square close-icon" (click)="close()"></i>
    </div><!-- end card header -->
    <form class="card-body">
        <div class="row">
            <div class="col-12">
                <div class="mb-3 row">
                    <label for="title" class="col-md-2 col-form-label">Codice *</label>
                    <div class="col-md-10">
                        <input class="form-control" type="text" id="code" [(ngModel)]="channel.code" [ngModelOptions]="{standalone: true}">
                        <small id="code">Codice alfanumerico (se channel abilitato max 4 caratteri Es: XXXX)</small>
                    </div>
                </div><!-- end row -->
                <div class="mb-3 row">
                  <label for="name" class="col-md-2 col-form-label">Nome *</label>
                  <div class="col-md-10">
                      <input class="form-control" type="text" id="name" [(ngModel)]="channel.description" [ngModelOptions]="{standalone: true}">
                  </div>
              </div><!-- end row -->
              <div class="mb-3 row">
                <label for="baseBeds" class="col-md-2 col-form-label">{{ "GENERIC.str_enabled" | translate}}</label>
                <div class="col-md-10 d-flex align-items-end"><div dir="ltr" class="form-check form-switch mb-2">
                    <input type="checkbox" name="enabledroom" class="form-check-input" [(ngModel)]="channel.enabled" [ngModelOptions]="{standalone: true}">
                  </div>
                </div>
              </div>
            </div><!-- end col -->
        </div><!-- end row -->
      </form><!-- end card body -->
</div>
<div class="d-flex justify-content-center m-2">
  <button (click)="editChannel()" class="btn-edit" *ngIf="showBtnNew == false">Modifica</button>
  <button (click)="newChannel()" class="btn-new" *ngIf="showBtnNew == true">Crea</button>
</div>
</form>
