import { Injectable } from '@angular/core';
import {StructureService} from "./structure.service";
import {TranslateService} from "@ngx-translate/core";
import {GuiService} from "./gui.service";

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  AUTH_INFO: any = {
    username: "",
    password: "",
    otpCode: ""
  };

  menu_items: any = [];

  constructor(private structureService: StructureService, private translate: TranslateService) {
    this.menu_items = [
      {
        icon:'uil-home-alt',
        name: "FX.main",
        zones: [
          {
            label: "FX.dashboard",
            link: 'admin/dashboard',
            condition: false,
            icon: 'pie-chart',
            perm: 'DASHBOARD'
          },
          {
            label: "FX.structures",
            link: 'admin/structure-list',
            condition: false,
            icon: 'calendar',
            perm: 'STRUCTURE'
          },
          {
            label: "FX.structures_reliable",
            link: 'admin/reliable',
            condition: false,
            icon: 'calendar',
            perm: 'STRUCTURE'
          },
          {
            label: "FX.highlights",
            link: 'main/highlights',
            condition: true,
            icon: 'chart',
            perm: 'STRUCTURE'
          },
          {
            label: "FX.account",
            link:'admin/users-list',
            condition: false,
            icon: 'calendar',
            perm: 'DASHBOARD,ACCOUNT'
          },
          {
            label: "FX.profile",
            link: 'admin/profile',
            condition: false,
            icon: 'calendar',
            perm: 'DASHBOARD,ACCOUNT,VIEW'
          }
        ]
      },
      {
        icon:'uil-sliders-v-alt',
        name: "FX.setting",
        zones: [
          {
            label: "FX.strsettings",
            link: '/settings/strconfig',
            condition: true,
            icon: 'calendar',
            perm: "STRUCTURE,STRUCTURE,STRSETTINGS"
          },
          {
            label:"FX.pricelists",
            link: '/settings/pricingmodel',
            condition: true,
            icon: 'message-square',
            perm: "STRUCTURE,PRICELISTS,VIEW"
          },
          {
            label:"FX.extras",
            link: '/settings/extraservice',
            condition: true,
            icon: 'calendar',
            perm: "STRUCTURE,SERVICE,VIEW"
          },
          {
            label:"FX.planning",
            link: '/settings/planning',
            condition: true,
            icon: 'calendar',
            perm: "STATS,REPORT,PLANNING"
          },
          {
            label:"FX.calendar_event",
            link: '/settings/calendarevent',
            condition: true,
            icon: 'calendar',
            perm: "STATS,REPORT,PLANNING"
          }
        ]
      },
      {
        icon:'uil-calendar-alt',
        name: "FX.operation",
        zones: [
          {
            label: "FX.dataentry",
            link: '/operation/dataentry',
            condition: true,
            icon: 'calendar',
            perm: "REV,REVOLUTION,UPLOADFILE"
          },
          {
            label: "FX.calendar",
            link: '/operation/calendar',
            condition: true,
            icon: 'calendar',
            perm: "REV,REVOLUTION,CALENDAR"
          },
          {
            label: "FX.availability",
            link: '/operation/availability',
            condition: true,
            icon: 'calendar',
            perm: "CHMAN,CH_AVAILABILITY,CALENDAR"
          },
          {
            label: "FX.advancedhistory",
            link: '/operation/updatehistory',
            condition: true,
            icon: 'calendar',
            perm: "REV,REVOLUTION,UPDATEHISTORY"
          },
          {
            label: "FX.log",
            link: '/operation/updatemonitoring',
            condition: true,
            icon: 'calendar',
            perm: "STRUCTURE,STRUCTURE,LOG"
          }
        ]
      },
      {
        icon:'uil-chart-bar',
        name: "FX.stats",
        zones: [
          {
            label:"FX.general",
            link: 'stats/reportgeneral',
            condition: true,
            icon: 'calendar',
            perm: "STATS,REPORT,GENERAL"
          },
          {
            label:"FX.fileexport",
            link: 'stats/fileexport',
            condition: true,
            icon: 'calendar',
            perm: "STATS,REPORT,GENERAL"
          },
          {
            label:"FX.view_month",
            link: 'stats/reportviewmonth',
            condition: true,
            icon: 'calendar',
            perm: "STATS,REPORT,VIEW_MONTH"
          },
          {
            label:"FX.view_cluster",
            link: 'stats/reportviewcluster',
            condition: true,
            icon: 'calendar',
            perm: "STATS,REPORT,VIEW_CLUSTER"
          },
          {
            label:'FX.reportother',
            link: 'stats/report-bi',
            condition: true,
            icon: 'calendar',
            perm: "STATS_NO,REPORT,ZOHO-RMC_DASH"
          },
          {
            label:'FX.costpanel',
            link: 'stats/cost-panel',
            condition: true,
            icon: 'calendar',
            perm: "STATS,REPORT,VIEW_CLUSTER"
          }
        ]
      },
      {
        icon:'uil-invoice',
        name: "FX.accounting",
        zones: [
          {
            label: "FX.registry",
            link: 'invoices/anagrafiche',
            condition: false,
            icon: 'calendar',
            perm: "INVOICE_GENERAL,NAMES"
          },
          {
            label: "FX.contracts",
            link: 'invoices/contratti',
            condition: false,
            icon: 'calendar',
            perm: "INVOICE_MANAGEMENT,MANAGEMENT"
          },
          {
            label: "FX.advisor",
            link: 'invoices/agenti',
            condition: false,
            icon: 'calendar',
            perm: "STATS,REPORT,ZOHO-REPORT_7"
          },
          {
            label: "FX.customerscounters",
            link: 'invoices/conteggi',
            condition: false,
            icon: 'calendar',
            perm: "INVOICE_CYCLE,INVOICES,COUNTERS"
          },
        ]
      },
      {
        icon:'uil-comment-info-alt',
        name: "FX.support",
        zones: [
          {
            label: "FX.customercare",
            link: 'support/customercare',
            condition: false,
            icon: 'calendar',
            perm: "STATS,REPORT,ZOHO-REPORT_5"
          },
          {
            label: "FX.videoguide",
            link: 'support/videoguide',
            condition: false,
            icon: 'calendar',
            perm: "STATS,REPORT,ZOHO-REPORT_6"
          },
          {
            label: "FX.manuals",
            link: 'support/manuals',
            condition: false,
            icon: 'calendar',
            perm: "STATS,REPORT,ZOHO-REPORT_7"
          }
        ]
      }
    ];
  }
  getMenuItems(perms?: any) {
    let items = JSON.parse(JSON.stringify(this.menu_items));
    for(let i=items.length-1;i>=0;i--) {
      let item = items[i];
      let zones = item.zones;

      for(let k=0;k<zones.length;k++) {
        if(perms.indexOf(zones[k].perm) < 0) {
          item.zones.splice(k,1);
          k--;
        }
      }
      if(zones.length < 1) {
        items.splice(i,1);
      }
    }
    return items;
  }


}
