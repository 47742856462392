import { Component, Input, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';

@Component({
  selector: 'app-client-config',
  templateUrl: './client-config.component.html',
  styleUrls: ['./client-config.component.scss']
})
export class ClientConfigComponent {
  @Input() client: any;
  form!: UntypedFormGroup;
  ivaFlag: any;
  inpsFlag: any;
  esenteRit: any;
  blacklist: any;
  scorporoIva: any;

  ivaCodeDropdown = [
    {label: 'Seleziona IVA',value: -1},
    {label: 'IVA 22',value: 1},
    {label: 'IVA 4',value: 2},
    {label: 'NO IVA',value: 3},
    {label: 'IVA 20',value: 4},
    {label: 'IVA 21',value: 5}
  ]
  typeCodeDropdown = [
    {label: 'Seleziona tipo',value: -1},
    {label: 'SOCIETA',value: 0},
    {label: 'AGEVOLATA',value: 1},
    {label: 'FORFETTARIO',value: 2},
    {label: 'SPAGNA',value: 3},
    {label: 'INDIVIDUALE',value: 4},
    {label: 'OCCASIONALE',value: 6}
  ]

  currencyCodeDropdown = [
    {label: 'Seleziona divisa',value: -1},
    {label: 'Euro',value: 1},
    {label: 'Dollaro americano',value: 2},
  ]

  areaCodeDropdown = [
    {label: 'Seleziona area',value: -1},
    {label: 'Italia',value: 1},
    {label: 'Extra europea',value: 3},
    {label: 'Italia - zona extradoganale',value: 4}
  ]

  languageCodeDropdown = [
    {label: 'Seleziona lingua',value: -1},
    {label: 'Italiano',value: 1},
    {label: 'Inglese',value: 2},
    {label: 'Spagnolo',value: 3}
  ]


  constructor(public guiService: GuiService, private fb: UntypedFormBuilder, private restService: RestService){

  }

  ngOnInit(): void {
    this.initForm()
    console.debug(this.client)
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initForm()
  }

  initForm(){
    this.form = this.fb.group({
      areaCode: [null, Validators.required],
      currencyCode: [null, Validators.required],
      ficID: [null, Validators.required],
      ggScadenza: [null, Validators.required],
      iban: [null, Validators.required],
      ivaCode: [null, Validators.required],
      languageCode: [null, Validators.required],
      pagDefault: [null, Validators.required],
      swift: [null, Validators.required],
      typeCode: [null, Validators.required]
    })

    this.populateForm()
   }

   populateForm(){
      this.form.controls['areaCode'].setValue(this.client.areaCode)
      this.form.controls['currencyCode'].setValue(this.client.currencyCode)
      this.form.controls['ficID'].setValue(this.client.ficID)
      this.form.controls['ggScadenza'].setValue(this.client.ggScadenza)
      this.form.controls['iban'].setValue(this.client.iban)
      this.form.controls['ivaCode'].setValue(this.client.ivaCode)
      this.form.controls['languageCode'].setValue(this.client.languageCode)
      this.form.controls['pagDefault'].setValue(this.client.pagDefault)
      this.form.controls['swift'].setValue(this.client.swift)
      this.form.controls['typeCode'].setValue(this.client.typeCode)
      this.esenteRit = this.client.esenteRit
      this.blacklist = this.client.blacklist
      this.inpsFlag = this.client.inpsFlag
      this.scorporoIva = this.client.scorporoIva
      this.ivaFlag = this.client.ivaFlag
   }

   save(){
    console.debug(this.form.value)
    let newConfig = {
      areaCode: this.form.value.areaCode,
      blacklist: this.blacklist,
      currencyCode: this.form.value.currencyCode,
      esenteRit: this.esenteRit,
      ficID: this.form.value.ficID,
      ggScadenza: this.form.value.ggScadenza,
      iban: this.form.value.iban,
      inpsFlag: this.inpsFlag,
      ivaCode: this.form.value.ivaCode,
      ivaFlag: this.ivaFlag,
      languageCode: this.form.value.languageCode,
      pagDefault: this.form.value.pagDefault,
      scorporoIva: this.scorporoIva,
      swift: this.form.value.swift,
      typeCode: this.form.value.typeCode
    }
     this.restService.postService('crm_base_new','/inv_customer/save_configuration/'+this.client.id,newConfig).subscribe((ris)=>{
       this.guiService.editedSuccesfully()
     })
   }

  close(){
    this.guiService.closeDetail.next(true);
   }

}
