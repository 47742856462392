<div>
  <div class="card mb-0">
    <div class="card-header">
        <h1 class="card-title text-center">{{supplier.name}}</h1>
        <h1 *ngIf="!supplier.name" class="text-center">Crea nuova fornitore</h1>
        <i class="uil-times-square close-icon" (click)="close()"></i>
    </div><!-- end card header -->
    <form class="card-body" [formGroup]="form">
      <div class="row">
        <div class="col-12">
          <div class="mb-3 row form-floating">
            <input class="form-control" type="text" id="name" formControlName="name">
            <label for="name">Ragione sociale</label>
          </div>
          <div class="mb-3 row form-floating">
            <input class="form-control" type="text" id="vatNumber" formControlName="vatNumber">
            <label for="vatNumber">Partita IVA</label>
          </div>
          <div class="mb-3 row form-floating">
            <input class="form-control" type="text" id="cf" formControlName="cf">
            <label for="cf" class="col-md-2 col-form-label">Codice fiscale</label>
          </div>
          <div class="mb-3 row form-floating">
            <input class="form-control" type="text" id="legalAddressStreet" formControlName="legalAddressStreet">
            <label for="legalAddressStreet" class="col-md-2 col-form-label">Indirizzo</label>
          </div>
          <div class="mb-3 row form-floating">
            <input class="form-control" type="text" id="legalAddressCity" formControlName="legalAddressCity">
            <label for="legalAddressCity" class="col-md-2 col-form-label">Città</label>
          </div>
          <div class="mb-3 row form-floating">
            <input class="form-control" type="text" id="legalAddressCap" formControlName="legalAddressCap">
            <label for="legalAddressCap" class="col-md-2 col-form-label">CAP</label>
          </div>
          <div class="mb-3 row form-floating">
            <ng-select [clearable]="true" placeholder="Regione" [items]="regions" bindLabel="name" [(ngModel)]="regionSelected" class="p-0"
              (search)="getRegion($event)" [ngModelOptions]="{standalone: true}">
            </ng-select>
          </div>
          <div class="mb-3 row form-floating">
            <ng-select [clearable]="true" placeholder="Provincia" [items]="provinces" bindLabel="name"  [(ngModel)]="provinceSelected" class="p-0"
              (search)="getProvince($event)" [ngModelOptions]="{standalone: true}">
            </ng-select>
          </div>
          <div class="mb-3 row form-floating">
            <ng-select [clearable]="true" placeholder="Nazione" bindLabel="name" [items]="countrys" [(ngModel)]="countrySelected" class="p-0"
              (search)="getCountry($event)" [ngModelOptions]="{standalone: true}">
            </ng-select>
          </div>
          <div class="mb-3 row form-floating">
            <ng-select [clearable]="true" placeholder="Metodo pagamento" bindLabel="name" [(ngModel)]="methodSelected" class="p-0"
              (search)="getPaymentMethod($event)" [ngModelOptions]="{standalone: true}" [items]="methods">
            </ng-select>
          </div>
          <div class="mb-3 row form-floating">
            <textarea class="form-control" type="text" id="note" formControlName="note"></textarea>
            <label for="note" class="col-md-2 col-form-label">Note</label>
          </div>
        </div>
      </div>
    </form><!-- end card body -->
    <div class="card mb-0" *ngIf="supplier.name">
      <div class="card-header">
        <h3 class="card-title text-center">Contatti</h3>
      </div>
      <form class="card-body" [formGroup]="form">
        <div class="row new-contact-container">
          <div class="form-floating col-3">
            <input type="text" id="new-contact-name" class="form-control" formControlName="contact_name">
            <label for="new-contact-name">name</label>
          </div>
          <div class="form-floating col-4">
            <input type="text" id="new-contact-phone" class="form-control" formControlName="contact_phone">
            <label for="new-contact-phone">phone</label>
          </div>
          <div class="form-floating col-4">
            <input type="text" id="new-contact-email" class="form-control" formControlName="contact_email">
            <label for="new-contact-email">email</label>
          </div>
          <div class="col-1">
            <i class="uil-plus-square" (click)="createContact()"></i>
          </div>
        </div>
        <div *ngFor="let contact of supplier.contacts; let indice = index" class="contact-container">
          <div class="row"><h4 class="col-3">{{contact.name}}</h4> <h4 class="col-4">{{contact.phone}}</h4> <a [href]="'mailto:'+contact.email" class="col-4">{{contact.email}}</a> <div class="col-1"><i class="uil-trash" (click)="deleteContact(contact.id,indice)"></i></div></div>
        </div>
      </form>
    </div>
</div>
<div class="d-flex justify-content-center m-2">
  <button class="btn-edit" *ngIf="showBtnNew == false" (click)="editSupplier()">Modifica</button>
  <button class="btn-new" *ngIf="showBtnNew == true" (click)="newSupplier()">Crea</button>
</div>
</div>
