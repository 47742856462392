import {Component, Input, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {RateModel} from "../../../../core/model/rate-model";
import {GuiService} from "../../../../core/services/gui.service";
import {RestService} from "../../../../core/services/rest.service";
import {StructureService} from "../../../../core/services/structure.service";
import * as moment from "moment/moment";
import {CalendarEvent} from "../../../../core/model/calendar-event";

@Component({
  selector: 'app-calendar-event-detail',
  templateUrl: './calendar-event-detail.component.html',
  styleUrls: ['./calendar-event-detail.component.scss']
})
export class CalendarEventDetailComponent {
  @Input() event: any;
  @Input() eventlist: any;
  form!: UntypedFormGroup;
  showBtnNew: boolean = true;
  overlappedEventId: any;
  events: Array<CalendarEvent> = [];

  event_model: CalendarEvent;

  constructor(private fb: UntypedFormBuilder, public guiService: GuiService, private restService: RestService, public structureService: StructureService){

    this.event_model = {
      name: "",
      eventDay: "",
      eventDuration: 1,
      daysBefore: 0,
      daysAfter: 0
    };
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.overlappedEventId = null;
    this.event_model = JSON.parse(JSON.stringify(this.event));
    this.event_model.overlappedEvent ? this.overlappedEventId = this.event_model.overlappedEvent.id : null;
    this.showBtnNew = this.event_model.id ? false : true;
  }

  newEvent(){

    this.event_model.overlappedEventId = this.overlappedEventId != null ? this.overlappedEventId : null;

    console.debug("Create Event Model", this.event_model);
    this.restService.postService('calendar_events','/'+this.structureService.selectedStructure.id, this.event_model).subscribe((ris =>{
      this.guiService.createdSuccessfully()
      this.guiService.refreshList.next(true);
      this.close();
    }));
  }

  editEvent(){

    this.event_model.overlappedEventId = this.overlappedEventId != null ? this.overlappedEventId : null;

    this.restService.putService('calendar_events','/'+this.structureService.selectedStructure.id+'/'+this.event.id,this.event_model).subscribe((ris =>{
      this.guiService.editedSuccesfully()
      this.guiService.refreshList.next(true);
      this.close();
    }))
  }



  queryEvent(evento: any) {
    if(evento.term.length > 2){
      console.debug(evento.term, evento.term.length);
      this.restService.getService('calendar_events','/likeList/?query='+evento.term).subscribe((ris)=>{
        this.events = ris;

      })
    }
  }


  close(){
    this.guiService.closeDetail.next(true);
  }
}
