import { Component } from '@angular/core';
import Swal from "sweetalert2";
import {RestService} from "../../../../core/services/rest.service";
import {ActivatedRoute, Router} from "@angular/router";
import {StructureService} from "../../../../core/services/structure.service";
import {GuiService} from "../../../../core/services/gui.service";
import * as moment from "moment";

@Component({
  selector: 'app-structure-configuration-user',
  templateUrl: './structure-configuration-user.component.html',
  styleUrls: ['./structure-configuration-user.component.scss']
})
export class StructureConfigurationUserComponent {
  owners:any = [];
  users = [];
  userSelected: any;
  roles: any = [];
  roleSelected:any;

  constructor(private restService: RestService,private route: ActivatedRoute, public structureService: StructureService,private router: Router,public guiService: GuiService) {
    this.guiService.refreshList.subscribe( value => {
      if(value == true){
        this.getStructureOwners()
      }
     });
  }


  ngOnInit(): void {
    this.getStructureOwners();
    this.getRoles()
  }

  getRoles(){
    this.restService.getService('users','/profiles').subscribe((ris=>{
      this.roles = []
      this.roles = ris
      console.debug(this.roles)
    }))
  }

  getStructureOwners(){
    this.restService.getService('structure','/get_owners/'+this.structureService.selectedStructure.id).subscribe((ris)=>{
      this.owners = ris.data
    })
  }

  queryOwner(evento: any) {
    if(evento.term.length > 2){
      console.debug(evento.term, evento.term.length);
      this.restService.getService('users','/likelist/?query='+evento.term+"&_="+moment().format('x')).subscribe((ris)=>{
        this.users = ris;
      })
    }
  }

  assignOwner(){
    console.debug(this.roleSelected)
    console.debug(this.userSelected)
    // https://core-dot-revolution-plus-nuova-arch.oa.r.appspot.com/revo-easy-manager-webapp/structure/set_owner/24?ownerId=1&profileId=REVOLUTION%20FM
    this.restService.putService('structure','/set_owner/'+this.structureService.selectedStructure.id+'?ownerId='+this.userSelected.id+"&profileId="+this.roleSelected,null).subscribe((ris =>{
      this.guiService.editedSuccesfully();
      this.getStructureOwners();
    }))
  }

  deleteUserStructure(owner: any) {
    console.debug("OWNER to delete", owner);
    this.guiService.deleteConfirm('structure','/owner',this.structureService.selectedStructure.id+'?ownerId='+owner.username);
  }
}
