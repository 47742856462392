import { Component, Input } from '@angular/core';
import * as moment from "moment/moment";
import { CalendarService } from 'src/app/core/services/calendar.service';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';
import { StructureService } from 'src/app/core/services/structure.service';

@Component({
  selector: 'app-ai-rate',
  templateUrl: './ai-rate.component.html',
  styleUrls: ['./ai-rate.component.scss']
})
export class AiRateComponent  {
  @Input('startDate') dateStartReceived: any;
  @Input('endDate') dateEndReceived: any;
  rates: any;
  refRooms: any = {
  };
  daysApplied: any = []
  inProcessing: boolean = false;


  constructor(private restSrv: RestService, private structureSrv: StructureService,public guiService: GuiService,private calService: CalendarService){

  }

  ngOnInit(): void {
    this.callAi()
  }

  callAi(){
    setTimeout(() => {
      this.guiService.loaded = true;
    }, 100);

    this.restSrv.getService('ai_rate',this.structureSrv.selectedStructure.id +'/?from='+ this.dateStartReceived+ '&to='+this.dateEndReceived).subscribe((ris)=>{

        let tmp = []
        for(let key in ris.rates){
          ris.rates[key].date = key
          tmp.push(ris.rates[key])
        }
        this.rates = tmp.reverse();
        this.calcRefRooms()
        console.log('AI RATES___________',this.rates)
        this.guiService.loaded = false;
    })
  }

  calcRefRooms(){
    this.refRooms = {
    };
    let calendaraDataCopy:any = {}

    for(let date in this.calService.calendarDataDays){

      if(this.rates.find((obj: { date: string; }) => obj.date === date)){

        calendaraDataCopy[date] = this.calService.calendarDataDays[date]
      }
    }

    for(let day in calendaraDataCopy){
      this.refRooms[day] = {
      }
      this.refRooms[day]['rates'] = {}
      for(let roomKey in calendaraDataCopy[day].rates){
        let room = calendaraDataCopy[day].rates[roomKey]
        if(room.currentFormula === null){
          this.refRooms[day]['rates'][roomKey] = {
            rateValue: room.current.rate === '' ? 0 : parseInt(room.current.rate),
            forced: false
          }
        }
      }
    }
    console.debug(this.refRooms)
  }

  checkRatePositive(val: number){
    return Math.sign(val) === 1 ? 'green' : 'red';
  }

  applyAll(){
    for(let rate of this.rates){
      this.refRooms[rate.date]['rates'][this.structureSrv.selectedStructureConfig['AI_ROOM_REF_0'].value].rateValue = rate.aiRate;
    }
    console.debug('OBJ TO SEND FOR AI APICALL',this.refRooms)

    this.restSrv.putService('calendar','v4/'+this.structureSrv.selectedStructure.id+'/days/edit-rates',this.refRooms).subscribe((ris)=>{
      this.guiService.editedSuccesfully();
      console.debug(ris)
    })
  }

  apply(date: any,index: number){
    this.inProcessing = true;
    /* 0 = non applicato / 1 = in applicazione / 2 = applicato */
    this.rates[index].applyStatus = 1;

    this.refRooms[date]['rates'][this.structureSrv.selectedStructureConfig['AI_ROOM_REF_0'].value].rateValue = this.rates.find((x: { date: any; }) => x.date === date).aiRate
    let param:any = {}
    param[date] = this.refRooms[date]
    console.debug(param)

    this.restSrv.putService('calendar','v4/'+this.structureSrv.selectedStructure.id+'/days/edit-rates',param).subscribe((ris)=>{
      this.guiService.editedSuccesfully();
      this.inProcessing = false;
      this.rates[index].applyStatus = 2;
      console.debug(ris)
    })
  }
}
