<div class="card mb-0 section-container">
    <div class="card-body">


        <div class="item-list" *ngIf="loaded">
            <div class="row" *ngFor="let item of items">
                <div class="col-8">
                    <i class="uil uil-file-alt" *ngIf="!item.restriction"></i>
                    <i class="uil uil-file-block-alt" *ngIf="item.restriction"></i>
                    <h2>{{item.name}}</h2>
                </div>
                <div class="col-4 text-end">
                    <button class="btn btn-link me-2" (click)="selectPlan(item)">
                        <span>{{ "FX.view" | translate}}</span>
                    </button>
                    <!--button class="btn btn-sm btn-outline-danger">
                        <i-feather name="trash-2"></i-feather>
                    </button-->
                </div>
            </div>
            <div *ngIf="!loaded" class="main-page-loading">
                <div class="loading-bars-box">
                  <div class="loading-bars"></div>
                </div>
              </div>
            <div class="row text-center" *ngIf="!createUpdatePlan">
                <button class="btn btn-primary" (click)="createNewPlan()">
                    {{"AVAILABILITY.create_plan" | translate}}
                </button>
            </div>
        </div>

        <div class="card item-form" *ngIf="createUpdatePlan">
            <div class="card-header">
                <h5 *ngIf="rateplan.id">
                   {{"AVAILABILITY.edit_plan" | translate }}
                   <b>{{ rateplan.name }} </b>
                </h5>
                <h5 *ngIf="!rateplan.id">
                   {{"AVAILABILITY.create_plan" | translate }}
                </h5>
            </div>
            <div class="card-body">
                <div class="form-floating mb-3 row">
                    <div class="col-6 form-floating pad-left">
                        <input class="form-control" type="text" id="code" name="code" [(ngModel)]="rateplan.name"
                            placeholder="{{'AVAILABILITY.plan_insert_name' | translate}}">
                        <label for="code">{{"GENERIC.rate_plan_name" | translate}}</label>
                    </div>
                    <div class="form-floating col-6" *ngIf="strService.selectedStructureConfig['CHANNEL_SYSTEM'].value === 'WUBOOK'">
                        <span class="custom-label">
                            <label>{{"AVAILABILITY.restriction_wb" | translate}}</label>
                        </span>
                        <div dir="ltr" class="form-check form-switch custom-control custom-switch">
                            <input type="checkbox" id="customSwitchsizesm" [checked]="rateplan.restriction"
                                [(ngModel)]="rateplan.restriction" class="form-check-input">
                        </div>
                    </div>
                </div><!-- end row -->

                <div class="container room-container" *ngIf="rooms">
                    <div class="mb-3 row" *ngFor="let room of rooms; let i = index">
                        <div class="custom-label col-4">
                            <small>[{{room.title}}]</small>
                            <label class="">{{room.name}}</label>
                        </div>

                        <div class="form-floating col-4">
                            <input class="form-control input-camere" id="formula" placeholder="formula" type="text"
                                [(ngModel)]="roomIndex[room.id].value" />
                            <label for="formula">{{ "PRICING.formula" | translate }}</label>
                        </div>
                        <div class="form-floating col-4">
                            <input class="form-control input-camere" id="minValue" placeholder="minValue" type="text"
                                [(ngModel)]="roomIndex[room.id].minValue" />
                            <label for="minValue">{{ "PRICING.min" | translate }}</label>
                        </div>

                    </div><!-- end row -->

                    <div class="row text-center">
                        <button class="btn btn-primary" (click)="savePlan()">
                            {{"GENERIC.save_btn" | translate}}
                        </button>
                        <button class="btn btn-link" (click)="cancelSelectionPlan()">
                            {{"GENERIC.reset_btn_lower_case" | translate}}
                        </button>
                    </div>
                </div>
            </div>

        </div>

    </div><!-- end card body -->
</div>
