import { Component, Input, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';
import { StructureService } from 'src/app/core/services/structure.service';

@Component({
  selector: 'app-sales-channel-detail',
  templateUrl: './sales-channel-detail.component.html',
  styleUrls: ['./sales-channel-detail.component.scss']
})
export class SalesChannelDetailComponent {
  @Input() channel: any;
  showBtnNew: boolean = false;
  enabled: any;

  constructor(private fb: UntypedFormBuilder, public guiService: GuiService, public structureService: StructureService, private restService: RestService) {

  }

  ngOnInit(): void {
    this.init()
   }

   ngOnChanges(changes: SimpleChanges): void {
    this.init()
  }

  init(){
    console.debug(this.channel)
    this.channel && Object.keys(this.channel).length > 0 ? this.showBtnNew= false : this.showBtnNew= true
  }



   newChannel(){
    let newChannel = {
      code: this.channel.code.replace(/\s/g, '_'),
      description: this.channel.description,
      enabled: !this.channel.enabled ? false : this.channel.enabled
    }
    console.debug(newChannel)
     this.restService.postService('sale_channels','/'+this.structureService.selectedStructure.id, newChannel).subscribe({
      next: (ris)=>{
        this.guiService.createdSuccessfully()
        this.guiService.refreshList.next(true);
        this.close();
      },
      error: (err)=>{
        console.error(err);
      }
    })
   }

   editChannel(){
    let editChannel = {
      code: this.channel.code.replace(/\s/g, '_'),
      description: this.channel.description,
      enabled: !this.channel.enabled ? false : this.channel.enabled
  }

    this.restService.putService('sale_channels','/'+this.structureService.selectedStructure.id + '/'+ this.channel.id,editChannel).subscribe({
      next: (ris)=>{
        this.guiService.editedSuccesfully()
        this.guiService.refreshList.next(true);
        this.close();
      },
      error: (err)=>{
        console.error(err);
      }
    })
   }

   close(){
    this.guiService.closeDetail.next(true);
   }

}
