import { Component } from '@angular/core';
import { RestService } from '../../services/rest.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { GuiService } from '../../services/gui.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-link',
  templateUrl: './reset-link.component.html',
  styleUrls: ['./reset-link.component.scss']
})
export class ResetLinkComponent {
  isLoading: boolean = false;
  resetLink: any;

  newPassword: string = "";
  isVisibileNewPassword: boolean = false;
  confirmPassword: string = "";
  isVisibileConfirmPassword: boolean = false;

  constructor(private restService: RestService,private router: Router, public guiService: GuiService, public translate: TranslateService, private route: ActivatedRoute) {
    this.isLoading = false; 
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.resetLink = params.get('id');
    });

    console.debug("RESET LINK",this.resetLink);
  }

  send() {
    this.isLoading = true;
    let param = {
      "newChosenPassword": this.newPassword,
      "resetLink": this.resetLink
    }

    console.debug("PARAM", param);
    this.restService.extResetPsw("resetpsw", "/reset", param).subscribe({
      next: (res)=> {
        console.debug("RES", res);

        Swal.fire({
          title: 'Success!',
          text: this.translate.instant("AUTH.RESET_SUCCESS"),
          icon: 'success'
        });
        this.isLoading = false;
        this.router.navigate(["/auth/login"]);
      },
      error: (err)=> {
        console.debug("ERR", err);
        this.isLoading = false;
      }
    })
  }
}