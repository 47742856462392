import {Component, ElementRef, HostListener, Renderer2, ViewChild} from '@angular/core';
import * as moment from 'moment';
import { RestService } from 'src/app/core/services/rest.service';
import { StructureService } from 'src/app/core/services/structure.service';
import numeral, * as Numeral from 'numeral';
import { GuiService } from 'src/app/core/services/gui.service';
import Swal from 'sweetalert2';
import {CalendarService} from "../../../../core/services/calendar.service";
import {an} from "@fullcalendar/core/internal-common";

const sumTypeMap: any = {
    "1" : "roomtype",
    "2" : "saleschannel",
    "3" : "tot"
  }
@Component({
  selector: 'app-manual-mod',
  templateUrl: './manual-mod.component.html',
  styleUrls: ['./manual-mod.component.scss']
})
export class ManualModComponent {
  @ViewChild('colslimit')
  colslimit!: ElementRef;
  date: any = {
    from: moment().format("YYYY-MM-DD"),
    to: (moment().add(1,"month")).format("YYYY-MM-DD")
  }
  showTOT: boolean = true;
  showTable: boolean = false;
  sumType: any = '1';
  sumTypeFlag: boolean = false;
  tmpResult:any = []

  headerWidth: any;
  scrollX: boolean = false;
  scrollY: boolean = false;
  listener: any;

  salesChannel: any
  roomsType: any
  loaded: boolean = false;
  showGRP: boolean = false;

  constructor(public structureService: StructureService,private restService: RestService, private renderer2: Renderer2, public guiService: GuiService, public calService: CalendarService){
    this.listener = this.renderer2.listen('window', 'scroll', (e) => {
      // console.debug("SCROLLTOP",this.getScrollingElement().scrollTop);
      // console.debug("HEADER WIDTH",this.headerWidth);
      this.scrollY = this.guiService.getScrollingElement().scrollTop > 180 ? true : false;
    });
    this.listener = this.renderer2.listen('window', 'resize', (e) => {
      this.headerWidth = document.getElementById("colslimit")!.offsetWidth;
    });
  }

  ngOnDestroy(): void {
    this.listener();
  }
  onXScroll(idx:any) {
    let scrollL = document.getElementById(idx)!.scrollLeft;
    let elements =  document.getElementsByClassName("element-h-scroll");
    if(elements.length>0) {
      for(let k in elements) {
        elements[k] ? elements[k].scrollLeft = scrollL : null;
      }
    }
  }

  ngOnInit(): void {
    this.getRooms();
    this.getChannels();
  }

  loadData() {
    this.loaded = false;

    let from = moment(this.date.from)
    let to = moment(this.date.to)
    let result = [moment({...from})]

    this.calService.getCalendarDataEntry(moment(this.date.from).format("YYYY-MM-DD"),moment(this.date.to).format("YYYY-MM-DD")).subscribe( (ris)=> {
      this.printDays(ris);
    });

    /*while(from.isSame(to) == false){
      from.add(1, 'day');
      result.push(moment({ ...from }));
    }*/

    //this.print(result.map(x => x.format("DD-MM-YYYY")))
  }

  printDays(result:any){
    this.tmpResult = []
    for(let item in result){
      let tmpDate = moment(item,"YYYY-MM-DD");
      let dayName = tmpDate.day();
      let day = tmpDate.format('D');
      let monthName = tmpDate.format('M');
      let year = tmpDate.format('YYYY');
      let tmpData: any = {
        "roomtype":[],
        "saleschannel":[],
        "tot": {
          occ: result[item] && result[item].occupancies['WHOLE_OCCUPANCY'] && result[item] && result[item].occupancies['WHOLE_OCCUPANCY'].current ? result[item].occupancies['WHOLE_OCCUPANCY'].current.occupancy : '',
          prod: result[item] && result[item].occupancies['WHOLE_OCCUPANCY'] &&  result[item] && result[item].occupancies['WHOLE_OCCUPANCY'].current ? result[item].occupancies['WHOLE_OCCUPANCY'].current.revenue : ''
        },
        "grp": {
          occ:result[item] && result[item].occupancies['GROUP'] && result[item].occupancies['WHOLE_OCCUPANCY'].current ? result[item].occupancies['WHOLE_OCCUPANCY'].current.occupancy : '',
          prod: result[item] && result[item].occupancies['GROUP'] && result[item].occupancies['WHOLE_OCCUPANCY'].current ? result[item].occupancies['WHOLE_OCCUPANCY'].current.revenue : ''
        },
        "cap": {
          cap: result[item] && result[item].otherinfos.CAPACITY.current ? result[item].otherinfos.CAPACITY.current.value : null
        }
      };
      console.debug(result[item] && result[item].occupancies['WHOLE_OCCUPANCY'] &&  result[item] && result[item].occupancies['WHOLE_OCCUPANCY'].current ? result[item].occupancies['WHOLE_OCCUPANCY'].current.revenue : '')

      for(let k of this.roomsType){
        tmpData.roomtype.push({
          id: k.id,
          title: k.title,
          occ: result[item] && result[item].occupancies[k.title] && result[item].occupancies[k.title].current ? result[item].occupancies[k.title].current.occupancy : '',
          prod: result[item] && result[item].occupancies[k.title] && result[item].occupancies[k.title].current ? result[item].occupancies[k.title].current.revenue : ''
        }
        )

      }

      for(let k of this.salesChannel){
        tmpData.saleschannel.push({
          id: k.id,
          title: k.code,
          occ: result[item] && result[item].occupancies[k.code] && result[item].occupancies[k.code].current ? result[item].occupancies[k.code].current.occupancy : '',
          prod: result[item] && result[item].occupancies[k.code] && result[item].occupancies[k.code].current ? result[item].occupancies[k.code].current.revenue : ''
        })
      }

      this.tmpResult.push(
        {
          dt: item,
          day: day,
          number: dayName,
          month: monthName,
          year: year,
          data: tmpData
        }
      )

    }

    this.sumType != '3' ? this.sumTypeFlag = true : this.sumTypeFlag = false

    this.showTable = true;
    this.loaded = true;
    this.headerWidth = document.getElementById("colslimit")!.offsetWidth;
  }

  getRooms(){
    if(this.structureService.selectedStructure){
      let params = {
        page: 0,
        searchCriteria: [{
          "key": "ENABLED",
          "operation" : "EQUALITY",
          "value" : true,
          "orPredicate" : false
        }],
        size: 100,
        structureId: this.structureService.selectedStructure.id
      }
      this.restService.postService('room_type','/v2/search',params).subscribe((ris) =>{
        this.roomsType = ris.items;
        this.loaded = this.roomsType && this.salesChannel;
    })
    }
  }

  getChannels(){
    let params = {
      page: 0,
      searchCriteria: [{
        "key": "ENABLED",
        "operation" : "EQUALITY",
        "value" : true,
        "orPredicate" : false
      }],
      size: 100,
      structureId: this.structureService.selectedStructure.id
    }
    this.restService.postService('sales_channel','/v2/search',params).subscribe((ris)=>{
      this.salesChannel = ris.items;
      this.loaded = this.roomsType && this.salesChannel;
    })
   }


   onPaste(evento: any,header: any, type: any,indexLine: any,indexObj:any) {
    let tmpValue = evento.clipboardData.getData('Text');
    let tmpArray = tmpValue.split('\n').map((elem: string) => elem.trim());

    for(let k = 0; k < tmpArray.length; k++){
      //k == 0 ? this.tmpResult[indexLine+k].data[header][type] = '' : null
      if(this.tmpResult[indexLine+k]) {
        if(header=="tot" || header=="cap" ) {
          setTimeout(() => {
            this.tmpResult[indexLine+k].data[header][type] = tmpArray[k];
            this.tmpResult[indexLine+k].data[header][type+'_changed'] = true;
            this.tmpResult[indexLine+k].edited = true;
            //this.sumValues(indexLine+k)
          }, 100);
        } else {
          setTimeout(() => {
            let tmp = tmpArray[k].replace(/\./g,"");
            tmp = tmp.replace(",",".");

            this.tmpResult[indexLine+k].data[header][indexObj][type] = tmp;
            console.debug("tmpArray[k]",tmp);
            this.tmpResult[indexLine+k].data[header][indexObj][type+'_changed'] = true;
            this.tmpResult[indexLine+k].edited = true;
            this.sumValues(indexLine+k)
          }, 100);
        }
      }

    }
   }

   prepareSave(){
    let param: any = {};
    let data_update: number = 0;
    let param_cap: any = {};
    let cap_update: number = 0;

    for(let day of this.tmpResult) {
      if(day.edited) {

        param[day.dt] = {
          occupancies: {}
        };

        for (let room of day.data.roomtype) {
          if(room.occ_changed || room.prod_changed) {
            console.debug("OCC CHANGED", room.occ_changed);
            param[day.dt].occupancies[room.title] = {
              day: day.dt
            };
            room.occ != null && room.occ != '' || room.prod == 0 ? param[day.dt].occupancies[room.title].occupancy = parseInt(room.occ) : null;
            room.prod != null && room.prod != '' || room.prod == 0 ? param[day.dt].occupancies[room.title].revenue = room.prod : null;
          }
        }

        for (let sales_c of day.data.saleschannel) {
          if(sales_c.occ_changed || sales_c.prod_changed) {
            param[day.dt].occupancies[sales_c.title] = {
              day: day.dt
            }
            sales_c.occ != null && sales_c.occ != '' ? param[day.dt].occupancies[sales_c.title].occupancy = parseInt(sales_c.occ) : null;
            sales_c.prod != null && sales_c.prod != '' ? param[day.dt].occupancies[sales_c.title].revenue = sales_c.prod : null;
          }

        }

        param[day.dt].occupancies['WHOLE_OCCUPANCY'] = {
          day: day.dt
        }

        day.data.tot.occ != '' ? param[day.dt].occupancies['WHOLE_OCCUPANCY'].occupancy = day.data.tot.occ : param[day.dt].occupancies['WHOLE_OCCUPANCY'].occupancy = 0;
        day.data.tot.prod != '' ? param[day.dt].occupancies['WHOLE_OCCUPANCY'].revenue = parseFloat((''+day.data.tot.prod).replace(',','.')) : param[day.dt].occupancies['WHOLE_OCCUPANCY'].revenue = 0;
        day.data.grp.occ != '' ? param[day.dt].occupancies['WHOLE_OCCUPANCY'].occupancyFromGroups = parseInt(day.data.grp.occ) : param[day.dt].occupancies['WHOLE_OCCUPANCY'].occupancyFromGroups = 0;
        day.data.grp.prod != '' ? param[day.dt].occupancies['WHOLE_OCCUPANCY'].revenueFromGroups = parseFloat((''+day.data.grp.prod).replace(',','.')) : param[day.dt].occupancies['WHOLE_OCCUPANCY'].revenueFromGroups = 0;

        data_update++;
      }

      if(day.data.cap['cap_changed']) {
        cap_update++;
        param_cap[day.dt] = {
          CAPACITY: day.data.cap.cap
        };
      }

    }

    console.debug("PREPARED DATA",data_update,cap_update);
    this.update({
      param: param,
      data_update: data_update,
      param_cap: param_cap,
      cap_update: cap_update
    });

   }
   
   update(obj: any) {
    this.loaded = false;
    // controllo se ci sono dati di OCC o PROD da modificare
    if(obj.data_update>0) {
      this.calService.updateCalendarDataEntry(obj.param).subscribe( (ris)=> {
        console.debug("UPDATE DATA", ris);

        // controllo se ci sono dati di CAP da modificare
        if(obj.cap_update > 0) {
          this.updateCapacity(obj.param_cap);
        } else {
          this.guiService.editedSuccesfully();
          this.loaded = true;
        }
      });
    } else {
      // controllo se ci sono dati di CAP da modificare
      obj.cap_update > 0 ? this.updateCapacity(obj.param_cap) : null;
    }
   }
   updateCapacity(param: any) {
     this.restService.putService('calendar','v4/'+this.structureService.selectedStructure.id+'/days/edit-capacity',param).subscribe( (ris)=> {
       console.debug("UPDATE CAPACITY", ris);
       this.guiService.editedSuccesfully();
       this.loaded = true;
     });
   }

   checkChanges(header: any, type: any,indexLine: any,indexObj:any){
    this.tmpResult[indexLine].data[header][indexObj][type+'_changed'] = true;
    this.tmpResult[indexLine].edited = true;
    this.guiService.isConfigEdited = true;
    this.normalizeNum(this.tmpResult[indexLine].data[header][indexObj][type])
    this.sumValues(indexLine);
   }

   checkCapacityChanges(header: any, type: any,indexLine: any){
    this.tmpResult[indexLine].data[header][type+'_changed'] = true;
    this.tmpResult[indexLine].edited = true;
    this.guiService.isConfigEdited = true;
   }

   sumValues(index: any){
    let res = this.tmpResult[index]
      let prod = 0
      let occ = 0
      for(let d of res.data[sumTypeMap[this.sumType]]){

        let tmpProd: any = 0;

        if(d['prod']) {
          tmpProd = d['prod']*100
          /* COMMENTATO CON L'INTRODUZIONE DELLA FUNZIONE NORMALIZENUM */
          // tmpProd = (''+d['prod']).replace(/\./g,'');
          // tmpProd = tmpProd.replace(',','.');
        }
        d['occ'] && d['occ'] != '' ? occ += parseInt(d['occ']) : null
        d['prod'] && d['prod'] != '' ? prod += tmpProd : null
      }

      res.data['tot']['prod'] = prod/100
      res.data['tot']['occ'] = occ
   }

   reset(){
    if(this.guiService.isConfigEdited){
      Swal.fire({
      title: 'Hai salvato le tue modifiche?',
      text: 'Se non hai salvato perderai le tue modifiche',
      showCancelButton: true,
      confirmButtonText: 'Non voglio salvare',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.loadData()
        this.guiService.isConfigEdited = false
      }
    })
    }else{
      this.loadData()
      console.debug('ricaricati i dati')
    }

   }

   normalizeNum(inputNum: any){

    console.debug("INPUT", inputNum);

    if(inputNum && inputNum != '' && inputNum > 0){
      
    }
   }
}
