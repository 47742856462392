import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';
import { StructureService } from 'src/app/core/services/structure.service';

@Component({
  selector: 'app-room-mapping',
  templateUrl: './room-mapping.component.html',
  styleUrls: ['./room-mapping.component.scss']
})
export class RoomMappingComponent implements OnInit {
  rooms: any;
  roomIndex: any;
  channelRooms: Array<any> = [];
  channelMapping: Array<any> = [];
  loaded: boolean = true;
  createUpdatePlan: boolean = false;

  constructor(private restService: RestService, public strService: StructureService, private guiService: GuiService, public translate: TranslateService) {

  }

  ngOnInit(): void {
    this.loaded = false;
    this.roomIndex = null;
    this.getChannelRooms();
    this.getChannelMapping();
  }

  getChannelMapping() {
    this.loaded = false;
    this.restService.getService('channel', '_mapping/get/' + this.strService.selectedStructure.id).subscribe((ris) => {
      ris && ris.data ? this.channelMapping = ris.data : null;

      console.debug("Rooms MAPPING", this.channelMapping);

      this.getRooms();
    })
  }


  getRooms(){
    if(this.strService.selectedStructure){
      let params = {
        page: 0,
        searchCriteria: [{
          "key": "ENABLED",
          "operation" : "EQUALITY",
          "value" : true,
          "orPredicate" : false
        }],
        size: 100,
        structureId: this.strService.selectedStructure.id
      }
      this.restService.postService('room_type','/v2/search',params).subscribe((ris) =>{
        this.rooms = ris.items;

        this.loaded

        setTimeout(()=> {
          this.prepareRoomIndex();
        },2000)

    })
    }
  }
  getChannelRooms() {
    this.loaded = false;
    this.restService.getService('channel','/list/rooms/'+this.strService.selectedStructure.id).subscribe((ris)=>{
      ris && ris.data ? this.channelRooms = ris.data : null;

      console.debug("channelRooms",this.channelRooms);

    })
  }

  close() {
    this.guiService.closeDetail.next(true);
  }
  prepareRoomIndex() {
    this.roomIndex = {};

    for(let k in this.rooms) {
      console.debug("Room", this.rooms[k]);
      this.roomIndex[this.rooms[k].id] = this.rooms[k];
      this.roomIndex[this.rooms[k].id].data = null;
      this.roomIndex[this.rooms[k].id].channelRoomId = this.channelMapping.find(x => x.roomId === this.rooms[k].id) ? this.channelMapping.find(x => x.roomId === this.rooms[k].id).channelRoomCode : null;
      this.roomIndex[this.rooms[k].id].channelRoomCode = this.channelMapping.find(x => x.roomId === this.rooms[k].id) ? this.channelMapping.find(x => x.roomId === this.rooms[k].id).channelRoomCode : null;
    }
    this.loaded = true;
    console.debug("roomIndex",this.roomIndex);
  }

  savePlan() {
    this.loaded = false;

    let param: any = [];

   for(let k in this.rooms) {
      let room = this.rooms[k];

      param.push({
        "roomId": room.id,
        "channelRoomId": room.channelRoomId,
        "channelRoomCode": room.channelRoomCode
     });
   }

   console.debug("PARAM", param);
    this.restService.putService('channel','_mapping/edit/'+this.strService.selectedStructure.id, param).subscribe((ris)=>{
      console.debug("CREATE RATES",ris);

      this.guiService.genericSuccesfully();
      this.createUpdatePlan = false;
      this.loaded = true;
    })

  }
}
