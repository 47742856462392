import { GuiService } from 'src/app/core/services/gui.service';
import { Component, Input, SimpleChanges } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { RestService } from 'src/app/core/services/rest.service';
import { StructureService } from 'src/app/core/services/structure.service';
import {RateModel, RulesModel} from "../../../../core/model/rate-model";

@Component({
  selector: 'app-price-detail',
  templateUrl: './price-detail.component.html',
  styleUrls: ['./price-detail.component.scss']
})
export class PriceDetailComponent {
  @Input() price: any;
  @Input() priceDuplicate!: true;
  form!: UntypedFormGroup;
  showBtnNew: boolean = true;
  now: any;
  rooms: any;

  rate_model: RateModel;

  constructor(private fb: UntypedFormBuilder, public guiService: GuiService, private restService: RestService, public structureService: StructureService){

    this.rate_model = {
      "code": "",
      "description": "",
      "fromDate": "",
      "toDate": "",
      "monday": true,
      "tuesday": true,
      "wednesday": true,
      "thursday": true,
      "friday": true,
      "saturday": true,
      "sunday": true,
      "rules": {}
    };
  }

  ngOnInit(): void {
   }

   ngOnChanges(changes: SimpleChanges): void {
     this.getRooms();
     this.now = String(moment().unix());
  }



  initForm(){
    if(!this.guiService.isObjEmpty(this.price)) {
      console.debug("PRICE",this.price);
      this.populateForm();
    }
  }

  populateForm(){
    console.debug(this.priceDuplicate)
    this.showBtnNew = false;
    this.rate_model.code = this.priceDuplicate ? this.price.code +'_'+this.now : this.price.code;
    this.rate_model.description = this.price.description;
    this.rate_model.fromDate = this.price.fromDate;
    this.rate_model.toDate = this.price.toDate;
    this.rate_model.monday = this.price.monday;
    this.rate_model.tuesday = this.price.tuesday;
    this.rate_model.wednesday = this.price.wednesday;
    this.rate_model.thursday = this.price.thursday;
    this.rate_model.friday = this.price.friday;
    this.rate_model.saturday = this.price.saturday;
    this.rate_model.sunday = this.price.sunday;

    for(let room of this.rooms){
      if(!this.price.rules[room.title]){
        console.debug('NON ESISTE')
        this.price.rules[room.title] = {
          "minValue": "",
          "maxValue": "",
          "variable": null,
          "formula": null,
          "enabled": false
        }
      } else {
        this.price.rules[room.title].enabled = true;
      }
    }
    this.rate_model.rules = this.price.rules;
   }


   newPrice(){
    console.debug("Create Rate Model", this.rate_model);
    for(let k in this.rate_model.rules) {
      if(!this.rate_model.rules[k].enabled) {
        delete this.rate_model.rules[k];
      }
    }

    this.restService.postService('price_list','/'+this.structureService.selectedStructure.id, this.rate_model).subscribe((ris =>{
      this.guiService.createdSuccessfully();
      this.close();
      this.guiService.refreshList.next(true);

    }));
   }

   editPrice(){
     for(let k in this.rate_model.rules) {
       if(!this.rate_model.rules[k].enabled) {
         delete this.rate_model.rules[k];
       }
     }

     this.restService.putService('price_list','/'+this.structureService.selectedStructure.id+'/'+this.price.id,this.rate_model).subscribe((ris =>{
        this.guiService.editedSuccesfully();
        this.close();
        this.guiService.refreshList.next(true);
      }))
   }

  getRooms() {
    if(this.structureService.selectedStructure && this.structureService.selectedStructure.id){
      let params = {
      page: 0,
      searchCriteria: [{
        "key": "ENABLED",
        "operation" : "EQUALITY",
        "value" : true,
        "orPredicate" : false
      }],
      size: 100,
      structureId: this.structureService.selectedStructure.id
    }

      this.restService.postService('room_type', '/v2/search', params).subscribe((ris) => {
      this.rooms = ris.items
      let rules: any = {};
      for (let i in ris.items) {
        let room = ris.items[i];
        rules[room.title] = {
          "minValue": "",
          "maxValue": "",
          "variable": null,
          "formula": null,
          "enabled": false
        }
      }

      this.rate_model.rules = rules;
      console.debug("Rate model", this.rate_model);
      this.initForm();
    })
    }

  }

  changeRuleRef(idx: number) {
    this.rate_model.rules[idx].formula = this.rate_model.rules[idx].variable;
  }

   close(){
    this.guiService.closeDetail.next(true);
   }
}
