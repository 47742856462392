import {Component, ElementRef, Renderer2, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {GuiService} from 'src/app/core/services/gui.service';
import {RestService} from 'src/app/core/services/rest.service';
import {StructureService} from 'src/app/core/services/structure.service';
import Swal from 'sweetalert2';
import {CalendarService} from "../../../../core/services/calendar.service";
import * as math from 'mathjs';

@Component({
  selector: 'app-rates',
  templateUrl: './rates.component.html',
  styleUrls: ['./rates.component.scss']
})
export class RatesComponent {
  loaded: boolean = true;
  isCfgSaved: boolean = true;
  loadingCalandarData: boolean = true;
  @ViewChild('colslimit')
  colslimit!: ElementRef;
  scrollX: boolean = false;
  scrollY: boolean = false;
  listener: any;
  headerWidth: any;

  roomsType: any;
  roomsTypeIndex: any;
  tmpResult: any = [];
  rateResult: any = {};
  date: any = {
    from: moment().format("YYYY-MM-DD"),
    to: (moment().add(1, 'month')).format("YYYY-MM-DD")
  }
  showTable: boolean = false;

  constructor(public structureService: StructureService, private restService: RestService, private renderer2: Renderer2, public guiService: GuiService, public calService: CalendarService) {
    this.listener = this.renderer2.listen('window', 'scroll', (e) => {
      this.scrollY = this.guiService.getScrollingElement().scrollTop > 180 ? true : false;
    });
    this.listener = this.renderer2.listen('window', 'resize', (e) => {
      this.headerWidth = document.getElementById("colslimit")!.offsetWidth;
    });
  }

  ngOnDestroy(): void {
    this.listener();
  }

  onXScroll(idx: any) {
    let scrollL = document.getElementById(idx)!.scrollLeft;
    let elements = document.getElementsByClassName("element-h-scroll");
    if (elements.length > 0) {
      for (let k in elements) {
        elements[k] ? elements[k].scrollLeft = scrollL : null;
      }
    }
  }

  ngOnInit(): void {
    this.getRooms();
  }

  getRooms() {
    this.roomsTypeIndex = [];
    if (this.structureService.selectedStructure) {
      this.loaded = false;
      let params = {
        page: 0,
        searchCriteria: [{
          "key": "ENABLED",
          "operation" : "EQUALITY",
          "value" : true,
          "orPredicate" : false
        }],
        size: 100,
        structureId: this.structureService.selectedStructure.id
      }
      this.restService.postService('room_type', '/v2/search', params).subscribe((ris) => {
        this.roomsType = ris.items;

        for(let k in this.roomsType) {
          this.roomsTypeIndex.push(this.roomsType[k].title);
        }
        this.loaded = true;
      })
    }
  }

  loadCalendarData() {
    this.loaded = false;
    this.loadingCalandarData = true;
    this.rateResult = {};
    this.calService.calendarDataDays = null;

    this.calService.getCalendarData(this.date.from, this.date.to, '', '',true).subscribe((ris) => {

      for (let k in ris) {
        let item = ris[k].rates;
        this.rateResult[k] = item;
        for(let i in item) {
          if(item[i].current == null) {
            item[i].current = {
              forced:false,
              rate:''
            }
          }
        }
        item.edited = false;
      }

      console.debug("load Calendar Data");
      console.debug(ris);
      console.debug(this.rateResult);
      console.debug("-----------------------------");

      this.printDays();
    });
  }

  save() {
    let param: any = {};
    let errorParam: boolean = false;

    for(let k in this.rateResult) {
      let item = this.rateResult[k];

      console.debug("RATE RESULT", item);

      if(item.edited) {
        param[k] = {
          rates: {}
        }
        for(let i in item) {

          console.debug("ITEM", i, item[i]);
          if(item[i].currentFormula == null && item[i].currentMaxValue == null && item[i].currentMinValue == null) {
            continue;
          }

          if(this.roomsTypeIndex.indexOf(i) >= 0) {

            if(item[i].currentFormula == null || item[i].forced) {
              if(item[i].current != null) {
                delete item[i]['error'];
                param[k].rates[i] = {
                  rateValue: item[i].current.rate,
                  forced: item[i].current.forced ? item[i].current.forced : false
                }
              } else {
                if(i!= 'edited') {
                  delete param[k];
                  break;
                }
              }
            }
          }

        }
      }
    }

    console.debug("PARAM TO SAVE", param);
    this.restService.putService('calendar','v4/'+this.structureService.selectedStructure.id+'/days/edit-rates',param).subscribe((ris)=>{
      this.guiService.editedSuccesfully();
    });
  }

  printDays() {
    let from = moment(this.date.from)
    let to = moment(this.date.to)
    let result = [moment({...from})]

    while (from.isSame(to) == false) {
      from.add(1, 'day');
      result.push(moment({...from}));
    }

    this.print(result.map(x => x.format("DD-MM-YYYY")))
  }

  print(result: any) {
    this.structureService.selectedStructure.rooms
    this.tmpResult = []
    for (let item of result) {
      let tmpDate = moment(item, 'DD,MM,YYYY');

      this.tmpResult.push(
        {
          d: tmpDate.format('DD'),
          m: tmpDate.format('MM'),
          mInt: tmpDate.format('M'),
          y: tmpDate.format('YYYY'),
          DoW: tmpDate.day(),
          day: tmpDate.format('YYYY-MM-DD')
        }
      )
    }

    console.debug("tmpResult", this.tmpResult)
    setTimeout(() => {
      this.loaded = true;
      this.showTable = true;
      this.headerWidth = document.getElementById("colslimit")!.offsetWidth;
    }, 1000);

  }

  reset() {
    if (this.guiService.isConfigEdited) {
      Swal.fire({
        title: 'Hai salvato le tue modifiche?',
        text: 'Se non hai salvato perderai le tue modifiche',
        showCancelButton: true,
        confirmButtonText: 'Non voglio salvare',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.getRooms()
          this.guiService.isConfigEdited = false
        }
      })
    } else {
      this.getRooms()
      console.debug('ricaricati i dati')
    }

  }

  onPaste(evento: any, indexRow: any, rt: any) {
    let text = evento.clipboardData.getData('Text').split('\n').map((elem: string) => elem.trim());

    console.debug("TEXT", text);

    for(let i=1; i<text.length; i++){
      let cnt = indexRow + i;
      let day = this.tmpResult[cnt].day;
      this.rateResult[day][rt].current.rate = parseFloat(text[i].replace(',','.').replace(' ',''));
      if(this.rateResult[day][rt].currentFormula != null) {
        this.rateResult[day][rt].current.forced = true;
      } else {
        this.checkChanges(cnt,rt);
      }
    }

    let ref: any = this;
    setTimeout(function () {
      let day = ref.tmpResult[indexRow].day;
      ref.rateResult[day][rt].current.rate = parseFloat(text[0].replace(',','.').replace(' ',''));
      if(ref.rateResult[day][rt].currentFormula != null) {
        ref.rateResult[day][rt].current.forced = true;
      } else {
        ref.checkChanges(indexRow,rt);
      }
    }, 100);

  }

  checkChanges(indexRow: number, rt: string) {
    this.rateResult[this.tmpResult[indexRow].day].edited = true;
    this.rateResult[this.tmpResult[indexRow].day][rt].edited = true;

    if(this.rateResult[this.tmpResult[indexRow].day][rt].currentFormula==null) {
      this.refreshBaseFormula(indexRow, rt);
    } else {
      this.rateResult[this.tmpResult[indexRow].day][rt].current.forced = true;
      this.refreshBaseFormula(indexRow, rt);
    }
    this.guiService.isConfigEdited = true
  }

  refreshBaseFormula(indexRow: number, rt: string) {
    let dt = this.tmpResult[indexRow].day;
    let price_model = this.rateResult[dt];

      let scope: any = {};

      for(let k in price_model) {
        if(k!= 'edited' && price_model[k].currentFormula==null) {
          scope[k] = price_model[k].current != null ? price_model[k].current.rate : price_model[k].currentMinValue;
        }
      }
      for(let k in price_model) {
        if(k!= 'edited' && price_model[k].currentFormula!=null) {

          if(price_model[k].current.forced) {
            scope[k] = price_model[k].current.rate;
          }
          let tmp = math.parse(price_model[k].currentFormula);
          tmp.forEach(function (node, path, parent) {
            switch (node.type) {
              case 'SymbolNode':
                if(!price_model[k].current.forced) {
                  let t: any = node;
                  scope[k] = scope[t.name] ? math.evaluate(price_model[k].currentFormula, scope) : price_model[k].currentFormula;
                }
                break
              default:
              // nothing
            }
          })
        }
      }

      for(let k in price_model) {
        if(k!= 'edited' && !price_model[k].current.forced) {
          price_model[k].currentFormula ? price_model[k].current.rate = math.evaluate(price_model[k].currentFormula, scope) : null;
        }
      }

      // VERIFICO CHE I PREZZI SIANO TUTTI IMPOSTATI SULLA GIORNATA
      for(let k in price_model) {
        if(k!= 'edited' && !price_model[k].current) {
          price_model[k].error = true;
        }
      }

  }

  unlockPrice(indexRow: number, rt: string) {
    this.rateResult[this.tmpResult[indexRow].day][rt].current.forced = false;
    this.refreshBaseFormula(indexRow, rt);
  }

  configKeyChange(k:string) {
    this.isCfgSaved = false;
    this.structureService.selectedStructureConfig[k].value == null || this.structureService.selectedStructureConfig[k].value == "0" ? this.structureService.selectedStructureConfig[k].value = "1" : this.structureService.selectedStructureConfig[k].value = "0";

    this.guiService.loaded = false;
    let param = [];
    for(let c in this.structureService.selectedStructureConfig){
      param.push(this.structureService.selectedStructureConfig[c]);
    }

    console.debug("PARAM CFG",param);

    this.restService.putService('structure_configuration', '/v2/update/'+this.structureService.selectedStructure.id+'/CALENDAR',param).subscribe((ris)=>{
      this.guiService.editedSuccesfully();
      this.isCfgSaved = true;
    })
  }

}
