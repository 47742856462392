import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, type OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';
import { StructureService } from 'src/app/core/services/structure.service';

@Component({
  selector: 'app-structure-configuration-ai',
  templateUrl: './structure-configuration-ai.component.html',
  styleUrls: ['./structure-configuration-ai.component.scss'],
})
export class StructureConfigurationAiComponent {
  refTypologies: any = [null,null,null,null,null]
  rooms: any;

  constructor(
    public guiService: GuiService,
    public structureService: StructureService,
    private router: Router,
    private restService: RestService
  ) {}

  ngOnInit(): void {
    this.getRooms();
    this.refTypologies[0] = this.structureService.selectedStructureConfig && this.structureService.selectedStructureConfig.AI_ROOM_REF_0 ? this.structureService.selectedStructureConfig.AI_ROOM_REF_0.value : null
    this.refTypologies[1] = this.structureService.selectedStructureConfig && this.structureService.selectedStructureConfig.AI_ROOM_REF_1 ? this.structureService.selectedStructureConfig.AI_ROOM_REF_1.value : null
    this.refTypologies[2] = this.structureService.selectedStructureConfig && this.structureService.selectedStructureConfig.AI_ROOM_REF_2 ? this.structureService.selectedStructureConfig.AI_ROOM_REF_2.value : null
    this.refTypologies[3] = this.structureService.selectedStructureConfig && this.structureService.selectedStructureConfig.AI_ROOM_REF_3 ? this.structureService.selectedStructureConfig.AI_ROOM_REF_3.value : null
    this.refTypologies[4] = this.structureService.selectedStructureConfig && this.structureService.selectedStructureConfig.AI_ROOM_REF_4 ? this.structureService.selectedStructureConfig.AI_ROOM_REF_4.value : null
  }

  getRooms() {
    let params = {
      size: 100,
      page: 0,
      structureId: this.structureService.selectedStructure.id,
      searchCriteria: [],
    };
    this.restService.postService('room_type', '/v2/search', params).subscribe((ris) => {
        this.rooms = ris.items;
        console.debug('AI_ROOMS', ris);
      });
  }

   setRefs(){
      this.structureService.selectedStructureConfig.AI_ROOM_REF_0.value = this.refTypologies[0];
      this.structureService.selectedStructureConfig.AI_ROOM_REF_1.value = this.refTypologies[1];
      this.structureService.selectedStructureConfig.AI_ROOM_REF_2.value = this.refTypologies[2];
      this.structureService.selectedStructureConfig.AI_ROOM_REF_3.value = this.refTypologies[3];
      this.structureService.selectedStructureConfig.AI_ROOM_REF_4.value = this.refTypologies[4];
  }

  save() {
    this.setRefs()
    this.guiService.loaded = false;
    let param = [];
    for(let c in this.structureService.selectedStructureConfig){
      param.push(this.structureService.selectedStructureConfig[c]);
    }

    console.debug("PARAM CFG",param);

    this.restService.putService('structure_configuration', '/v2/update/'+this.structureService.selectedStructure.id+'/CALENDAR',param).subscribe((ris)=>{
      this.guiService.isConfigEdited = false;
      this.guiService.loaded = true;
      this.guiService.editedSuccesfully();
    })
  }

  reset(){
    this.refTypologies[0] = this.structureService.selectedStructureConfig && this.structureService.selectedStructureConfig.AI_ROOM_REF_0 ? this.structureService.selectedStructureConfig.AI_ROOM_REF_0.value : null
    this.refTypologies[1] = this.structureService.selectedStructureConfig && this.structureService.selectedStructureConfig.AI_ROOM_REF_1 ? this.structureService.selectedStructureConfig.AI_ROOM_REF_1.value : null
    this.refTypologies[2] = this.structureService.selectedStructureConfig && this.structureService.selectedStructureConfig.AI_ROOM_REF_2 ? this.structureService.selectedStructureConfig.AI_ROOM_REF_2.value : null
    this.refTypologies[3] = this.structureService.selectedStructureConfig && this.structureService.selectedStructureConfig.AI_ROOM_REF_3 ? this.structureService.selectedStructureConfig.AI_ROOM_REF_3.value : null
    this.refTypologies[4] = this.structureService.selectedStructureConfig && this.structureService.selectedStructureConfig.AI_ROOM_REF_4 ? this.structureService.selectedStructureConfig.AI_ROOM_REF_4.value : null
  }

  resetIfFirstRefEmpty(){
    this.refTypologies[1] = '';
    this.refTypologies[2] = '';
    this.refTypologies[3] = '';
    this.refTypologies[4] = '';
  }

}
