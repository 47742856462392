<div class="page-title-box d-flex align-items-center justify-content-between">
  <h4 class="mb-0">Report General</h4>
</div>
<div class="col-xxl-12 col-xl-12">
  <div class="card card-h-100">
    <div class="card-header justify-content-between d-flex align-items-center">
      <div class="col-sm-12 col-md-12">
        <div class="form-floating">
          <input class="form-control flatpickr-input" type="text" mwlFlatpickr mode="range" [(ngModel)]="defaultDate" [locale]="locale" [dateFormat]="dateformat">
          <label for="init">From / To</label>
        </div>
        <div class="form-floating">
          <ng-select [clearable]="false" placeholder="{{'CALENDAR.STA' | translate}}" [(ngModel)]="selectedTyp">
            <ng-option value="DAILY">{{"GENERIC.daily" | translate}}</ng-option>
            <ng-option value="MONTHLY">{{"GENERIC.monthly" | translate}}</ng-option>
          </ng-select>
        </div>
        <!--div class="form-floating">
          <ng-select [clearable]="false" placeholder="{{'CALENDAR.STA' | translate}}" [(ngModel)]="year.static">
            <ng-option *ngFor="let item of selectyears" [value]="item">{{item}}</ng-option>
          </ng-select>
        </div>
        <div class="form-floating">
          <ng-select [clearable]="false" placeholder="{{'CALENDAR.DYH' | translate}}" [(ngModel)]="year.dynamic">
            <ng-option *ngFor="let item of selectyears" [value]="item">{{item}}</ng-option>
          </ng-select>
        </div-->
        <div class="days-checkbox">
          <div dir="ltr" class="form-check form-switch mb-2" *ngFor="let fd of filterDays; let d = index">
            <input type="checkbox" id="customSwitchsizesm" class="form-check-input" [(ngModel)]="fd.value">
            <label for="customSwitchsizesm" class="form-check-label">{{ "DAYS.SHORT."+d | translate}}</label>
          </div>
        </div>
        <div class="form-floating">
          <button class="btn btn-primary" (click)="preloadData()"><i class="uil-search"></i></button>
          <button class="btn btn-primary mx-2" (click)="exportFile()"><i class="uil-export"></i></button>
        </div>
      </div>
      <div class="col-sm-3 col-md-3" class="d-flex align-items-center justify-content-end top-icons">

      </div>
    </div><!-- end card header -->


    <div class="contenitore" *ngIf="calService.calendarDataDays!=null">
      <div class="box-day"
           *ngFor="let item of days_data; let d = index"
           id="{{d}}"
           [class.selected-day]="calService.selectedDay && (item.y+''+item.m+''+item.d) == calService.selectedDay"
           [class.pre-post-month]="calService.selectedDay && item.m != calService.monthSelectedForCalendar"
           [class.past-days]="calService.selectedDay && !calService.nowForCalendar.isSameOrBefore(item.day,'day')"
           [class.edited]="calService.editedDays.indexOf(item.day) >= 0">

        <div class="row"
             *ngIf="calService.calendarDataDays[item.day] && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY']">

          <div class="col-10">


            <!-- Start ROOMS code -->

            <div class="days-col col-12" *ngIf="guiService.calendarTabSelected =='rooms'">


              <!-- HEADER TABLE -->
              <div class="row">
                <div class="col-day-label">
                  &nbsp;
                </div>
                <div class="col-day-tot-occ header-text">
                  <div class="main-data">
                  <span class="day-cell">
                    <span class="day-cell-occ">
                      OCC
                    </span>
                  </span>
                  </div>
                </div>
                <div class="col-day-data header-text" id="ref_{{d}}" (mouseup)="onXScroll(d)">
                  <div class="main-data-wrapper" [style.width.px]="((rooms.length + channels.length)*70)">
                    <div class="main-data" *ngIf="rooms">
                    <span *ngFor="let room of rooms; let i = index">
                      <span class="day-cell">{{room.title}}</span>
                    </span>
                    </div>

                    <!-- CHANNELS -->
                    <div class="main-data" *ngIf="channels">
                    <span *ngFor="let channel of channels; let i = index">
                      <span class="day-cell">{{channel.code}}</span>
                    </span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- CURRENT YEAR -->
              <div class="row current-year">
                <div class="col-day-label black">
                <span *ngIf="calService.calendarDataDays[item.day]">
                    <span class="event-area" *ngFor="let ev of calService.calendarDataDays[item.day].events.current">
                      <i class="bx" [ngClass]="{'bxs-star': !ev['preOrPostDay'], 'bx-star' : ev['preOrPostDay'] }"
                         ngbTooltip="{{ev.name}}" placement="top"
                         *ngIf="calService.calendarDataDays[item.day].events.current"></i>
                    </span>
                  {{'DAYS.SHORT.' + item.DoW | translate}} {{item.d}}-{{item.m}}-{{item.y}}
                </span>
                </div>
                <div class="col-day-tot-occ">
                  <div class="main-data">
                  <span class="day-cell">
                    <span class="day-cell-occ">
                      {{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current ? calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.occupancy : '/'}}

                    </span>
                  </span>
                  </div>
                </div>
                <div class="col-day-data">
                  <div class="main-data-wrapper" [style.width.px]="((rooms.length + channels.length)*70)">
                    <div class="main-data" *ngIf="rooms">
                  <span class="day-cell" *ngFor="let room of rooms; let i = index">
                    <span class="day-cell-occ" >
                      {{calService.calendarDataDays[item.day].occupancies[room.title] && calService.calendarDataDays[item.day].occupancies[room.title].current ? calService.calendarDataDays[item.day].occupancies[room.title].current.occupancy : ''}}

                    </span>
                  </span>
                    </div>

                    <!-- CHANNELS -->
                    <div class="main-data" *ngIf="channels">
                  <span class="day-cell" *ngFor="let channel of channels; let i = index">
                    <span class="day-cell-occ" >
                      <i>{{channels.occ ? channels.occ : '/'}}</i>
                      <i class="pk pick-up-less">2</i>
                    </span>
                    <span class="day-cell-pk">/</span>
                  </span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- DYNAMIC HISTORY -->
              <div class="row dynamic-h-year">
                <div class="col-day-label pink second-date">
                  <span *ngIf="calService.calendarDataDays[item.day]">
                    <span class="event-area" *ngFor="let ev of calService.calendarDataDays[item.day].events.dynHistoryc">
                      <i class="bx" [ngClass]="{'bxs-star': !ev['preOrPostDay'], 'bx-star' : ev['preOrPostDay'] }"
                         ngbTooltip="{{ev.name}}" placement="top"
                         *ngIf="calService.calendarDataDays[item.day].events.current"></i>
                    </span>
                    {{'DAYS.SHORT.' + item.DoW | translate}} {{item.dynday}}</span>
                </div>
                <div class="col-day-tot-occ">
                  <div class="main-data">
                  <span class="day-cell">
                    <span class="day-cell-occ">
                      <i>{{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric ? calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric.occupancy : '/'}}</i>
                      <i class="pk">

                        <span class="pk" *ngIf="calService.calendarDataDays != null && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric.occupancyPickup != null">
                          <span
                            [ngClass]="{
                            'pick-up-more': calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric.occupancyPickup>0,
                            'pick-up-less': calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric.occupancyPickup<0
                            }">
                            {{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric.occupancyPickup==0 ? '' : calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric.occupancyPickup}}
                          </span>
                        </span>
                      </i>
                    </span>
                  </span>
                  </div>
                </div>
                <div class="col-day-data">
                  <div class="main-data-wrapper" [style.width.px]="((rooms.length + channels.length)*70)">
                    <div class="main-data" *ngIf="rooms">
                    <span *ngFor="let room of rooms; let i = index" class="dynamic-h-data">
                      <span class="day-cell">
                        <span class="day-cell-occ">
                          <i>{{calService.calendarDataDays[item.day].occupancies[room.title] && calService.calendarDataDays[item.day].occupancies[room.title].dynHistoric ? calService.calendarDataDays[item.day].occupancies[room.title].dynHistoric.occupancy : '/'}}</i>
                          <i class="pk">

                            <span class="pk" *ngIf="calService.calendarDataDays != null && calService.calendarDataDays[item.day].occupancies[room.title] && calService.calendarDataDays[item.day].occupancies[room.title].dynHistoric && calService.calendarDataDays[item.day].occupancies[room.title].dynHistoric.occupancyPickup != null">
                              <span
                                [ngClass]="{
                                'pick-up-more': calService.calendarDataDays[item.day].occupancies[room.title].dynHistoric.occupancyPickup>0,
                                'pick-up-less': calService.calendarDataDays[item.day].occupancies[room.title].dynHistoric.occupancyPickup<0
                                }">
                                {{calService.calendarDataDays[item.day].occupancies[room.title].dynHistoric.occupancyPickup==0 ? '' : calService.calendarDataDays[item.day].occupancies[room.title].dynHistoric.occupancyPickup}}
                              </span>
                            </span>
                          </i>
                        </span>
                      </span>
                    </span>
                    </div>

                    <!-- CHANNELS -->
                    <div class="main-data" *ngIf="channels">
                    <span *ngFor="let channel of channels; let i = index">
                      <span class="day-cell">{{channel.occ}}</span>
                    </span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- LAST YEAR -->
              <div class="row last-year">
                <div class="col-day-label blue">
                  <span *ngIf="calService.calendarDataDays[item.day]">
                    <span class="event-area" *ngFor="let ev of calService.calendarDataDays[item.day].events.statHistoric">
                      <i class="bx" [ngClass]="{'bxs-star': !ev['preOrPostDay'], 'bx-star' : ev['preOrPostDay'] }"
                         ngbTooltip="{{ev.name}}" placement="top"
                         *ngIf="calService.calendarDataDays[item.day].events.current"></i>
                    </span>
                    {{'DAYS.SHORT.' + item.DoW | translate}} {{item.staday}}
                  </span>
                </div>
                <div class="col-day-tot-occ">
                  <div class="main-data">
                  <span class="day-cell">
                    <span class="day-cell-occ">
                      <i>{{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric ? calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric.occupancy : '/'}}</i>
                      <i class="pk">

                        <span class="pk" *ngIf="calService.calendarDataDays != null && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric.occupancyPickup != null">
                          <span
                            [ngClass]="{
                            'pick-up-more': calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric.occupancyPickup>0,
                            'pick-up-less': calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric.occupancyPickup<0
                            }">
                            {{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric.occupancyPickup==0 ? '' : calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric.occupancyPickup}}
                          </span>
                        </span>
                      </i>
                    </span>
                  </span>
                  </div>
                </div>
                <div class="col-day-data">
                  <div class="main-data-wrapper" [style.width.px]="((rooms.length + channels.length)*70)">
                    <div class="main-data" *ngIf="rooms">
                    <span *ngFor="let room of rooms; let i = index" class="last-y-data">
                      <span class="day-cell">
                        <span class="day-cell-occ">
                          <i>{{calService.calendarDataDays[item.day].occupancies[room.title] && calService.calendarDataDays[item.day].occupancies[room.title].statHistoric ? calService.calendarDataDays[item.day].occupancies[room.title].statHistoric.occupancy : '/'}}</i>
                          <i class="pk">

                            <span class="pk" *ngIf="calService.calendarDataDays != null && calService.calendarDataDays[item.day].occupancies[room.title] && calService.calendarDataDays[item.day].occupancies[room.title].statHistoric && calService.calendarDataDays[item.day].occupancies[room.title].statHistoric.occupancyPickup != null">
                              <span
                                [ngClass]="{
                                'pick-up-more': calService.calendarDataDays[item.day].occupancies[room.title].statHistoric.occupancyPickup>0,
                                'pick-up-less': calService.calendarDataDays[item.day].occupancies[room.title].statHistoric.occupancyPickup<0
                                }">
                                {{calService.calendarDataDays[item.day].occupancies[room.title].statHistoric.occupancyPickup==0 ? '' : calService.calendarDataDays[item.day].occupancies[room.title].statHistoric.occupancyPickup}}
                              </span>
                            </span>
                          </i>
                        </span>
                      </span>
                    </span>
                    </div>

                    <!-- CHANNELS -->
                    <div class="main-data" *ngIf="channels">
                    <span *ngFor="let channel of channels; let i = index">
                      <span class="day-cell">{{channel.occ}}</span>
                    </span>
                    </div>
                  </div>
                </div>
              </div>



            </div>


          </div>
          <div class="col-2 mid-box-container">
            <!-- <div class="col-4 d-inline-block text-center mid-box">
              <h1>OCC</h1>
              <h3>{{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current ? calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.occupancy : '/'}}</h3>
              <h4>
                <span class="pick-up-less"
                      *ngIf="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current!=null && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.vrminus>0">
                  {{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.vrminus}}
                </span>
                <span class="pick-up-more"
                      *ngIf="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current!=null && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.vrplus>0">
                  {{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.vrplus}}
                </span>
              </h4>
              <h3
                class="pink">{{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current!=null ? calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.occupancy : '/'}}
              </h3>
              <h3
                class="blue">{{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current!=null ? calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.occupancy : '/'}}
              </h3>

            </div> -->
            <div class="col-6 d-inline-block text-center mid-box">
              <h1>RMC</h1>
              <h3 *ngIf="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current!=null && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.occupancy>0">
                {{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.rmc | currency : structureService.selectedStructureConfig.currency.value:'symbol':'1.2-2'}}
              </h3>
              <h3 *ngIf="!calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current">
                {{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric.rmc ? calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric.rmc - calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric.rmc : '/'}}
              </h3>

              <h3 class="pink">
                {{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY']>0 ? calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric.rmc : '0' | currency :'EUR':'symbol':'1.2-2'}}</h3>
              <h3 class="blue">
                {{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY']>0 ? calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric.rmc : '0' | currency :'EUR':'symbol':'1.2-2'}}</h3>

            </div>
            <div class="col-6 d-inline-block text-center mid-box">
              <h1>RPar</h1>
              <h3>{{(capacity>0 && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current ? calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.revenue / capacity : '0') | currency :'EUR':'symbol':'1.2-2'}}</h3>

              <h3 class="pink">
                {{(capacity>0 && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current ? calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.revenue / capacity : '0') | currency :'EUR':'symbol':'1.2-2'}}</h3>
              <h3 class="blue">
                {{(capacity>0 && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current ? calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.revenue / capacity : '0') | currency :'EUR':'symbol':'1.2-2'}}</h3>
            </div>
          </div>

        </div>


        <!-- NO DATA -->
        <div class="row"
          *ngIf="!calService.calendarDataDays[item.day] || !calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY']">
          <div class="col-6">
            <div class="graphics">
              <div class="row current-year mt-2">
                <div class="col-3 black">
                  <span *ngIf="calService.calendarDataDays[item.day]">
                    <span class="event-area" *ngFor="let ev of calService.calendarDataDays[item.day].events.current">
                      <i class="bx" [ngClass]="{'bxs-star': !ev['preOrPostDay'], 'bx-star' : ev['preOrPostDay'] }"
                         ngbTooltip="{{ev.name}}" placement="top"
                         *ngIf="calService.calendarDataDays[item.day].events.current"></i>
                    </span>
                    {{'DAYS.SHORT.' + item.DoW | translate}} {{item.d}}-{{item.m}}-{{item.y}}
                  </span>
                </div>
              </div>
              <div class="row dynamic-h-year mt-1">
                <div class="col-3 pink second-date">
                  <span *ngIf="calService.calendarDataDays[item.day]">
                    <span class="event-area" *ngFor="let ev of calService.calendarDataDays[item.day].events.dynHistoryc">
                      <i class="bx" [ngClass]="{'bxs-star': !ev['preOrPostDay'], 'bx-star' : ev['preOrPostDay'] }"
                         ngbTooltip="{{ev.name}}" placement="top"
                         *ngIf="calService.calendarDataDays[item.day].events.current"></i>
                    </span>
                    {{'DAYS.SHORT.' + item.DoW | translate}} {{item.d}}-{{item.m}}-{{item.y}}
                  </span>
                </div>
              </div>
              <div class="row last-year mt-1">
                <div class="col-3 blue">
                  <span *ngIf="calService.calendarDataDays[item.day]">
                    <span class="event-area" *ngFor="let ev of calService.calendarDataDays[item.day].events.statHistoric">
                      <i class="bx" [ngClass]="{'bxs-star': !ev['preOrPostDay'], 'bx-star' : ev['preOrPostDay'] }"
                         ngbTooltip="{{ev.name}}" placement="top"
                         *ngIf="calService.calendarDataDays[item.day].events.current"></i>
                    </span>
                    {{'DAYS.SHORT.' + item.DoW | translate}} {{item.d}}-{{item.m}}-{{item.y}}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="cal-data-empty">
              <span>{{ "GENERIC.no_data" | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!loaded" class="main-page-loading">
    <div class="loading-bars-box" *ngIf="tmpResult.length > 0">
      <div class="loading-bars"></div>
    </div>
  </div>
