import {Component, Input, Renderer2} from '@angular/core'
import * as moment from "moment/moment";
import {StructureService} from "../../../../../core/services/structure.service";
import {RestService} from "../../../../../core/services/rest.service";
import {GuiService} from "../../../../../core/services/gui.service";
import {CalendarService} from "../../../../../core/services/calendar.service";
import * as math from "mathjs";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarRateHistoryComponent } from '../calendar-rate-history/calendar-rate-history.component';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-table-calendar',
  templateUrl: './table-calendar.component.html',
  styleUrls: ['./table-calendar.component.scss']
})
export class TableCalendarComponent {
  @Input('days_data') days_data: any;
  @Input('rooms') rooms: any;
  @Input('channels') channels: any;
  moment: any = moment;

  roomsIndex: Array<any> = [];

  daysprocessing: Array<any> = [];

  loadingCalandarData: boolean = true;
  scrollLeft: any = {
    idx: null,
    left: 0
  };

  listener: any;
  tmpResult:any = [];
  rateResult: any = {};
  capacity: number = this.structureService.selectedStructure.rooms;
  isAiBtnPress: any= null;

  constructor(public structureService: StructureService, private restService: RestService, public guiService: GuiService, private renderer2: Renderer2, public calService: CalendarService,
    private modalService: NgbModal, public translate: TranslateService) {
    this.listener = this.renderer2.listen('window', 'scroll', (e) => {
      // console.debug("SCROLLTOP",this.getScrollingElement().scrollTop);
      // console.debug("HEADER WIDTH",this.headerWidth);
      this.guiService.scrollY = this.guiService.getScrollingElement().scrollTop > 1 ? true : false;
    });
  }

  ngOnInit(): void {
    this.roomsIndex = [];
    for (let k = 0; k < this.rooms.length; k++) {
      this.roomsIndex.push(this.rooms[k].title);
    }
    this.printDays();
    this.calService.editedDays = [];
  }

  ngOnChanges() {
    console.debug('NG CHANGES __________', this.calService.reloadCalendarData)
    if(this.calService.reloadCalendarData === true){
      this.loadCalendarData();
      this.calService.reloadCalendarData = false;
    }

  }

  loadCalendarData() {
    this.loadingCalandarData = true;
    this.calService.calendarDataDays = null;

    console.debug("<>->",this.calService.monthSelectedForCalendar, this.calService.yearSelectedForCalendar);

    let from = moment().year(this.calService.yearSelectedForCalendar).month(parseInt(this.calService.monthSelectedForCalendar)-1).date(1).subtract(3,'days').format("YYYY-MM-DD");
    let to = moment().year(this.calService.yearSelectedForCalendar).month(parseInt(this.calService.monthSelectedForCalendar)-1).date(31).add(3,'days').format("YYYY-MM-DD");

    if(this.calService.dynamicHistoryYearRef==0) {
      this.calService.dynamicHistoryYear = moment(from,"YYYY-MM-DD").subtract(1,'year').format("YYYY");
    } else {
      this.calService.dynamicHistoryYear = this.calService.dynamicHistoryYearRef;
    }


    if(this.calService.staticHistoryYearRef==0) {
      this.calService.staticHistoryYear = moment(from,"YYYY-MM-DD").subtract(1,'year').format("YYYY");
    } else {
      this.calService.staticHistoryYear = this.calService.staticHistoryYearRef;
    }

    this.calService.getCalendarData(from,to,this.calService.dynamicHistoryYear,this.calService.staticHistoryYear).subscribe((ris)=> {
      this.rateResult = {};

      console.debug("load Calendar Data");
      for(let day in ris ) {
                let occs = ris[day].occupancies;

        for(let k in occs) {

          let tmpRoom = this.rooms.find((x: { title: string; }) => x.title === k);
          if(tmpRoom){
            let tmpCurrentOccupancy = ris[day].occupancies[k].current && ris[day].occupancies[k].current.occupancy ? ris[day].occupancies[k].current.occupancy : 0
            let tmpCurrentDynHistoric = ris[day].occupancies[k].dynHistoric && ris[day].occupancies[k].dynHistoric.occupancy ? ris[day].occupancies[k].dynHistoric.occupancy : 0
            let tmpCurrentStatHistoric = ris[day].occupancies[k].statHistoric && ris[day].occupancies[k].statHistoric.occupancy ? ris[day].occupancies[k].statHistoric.occupancy : 0

            occs[k].inv_occ_current = tmpRoom.defaultAvailability ? tmpRoom.defaultAvailability - tmpCurrentOccupancy : 'N/A';
            occs[k].inv_occ_dynHistoric = tmpRoom.defaultAvailability ? tmpRoom.defaultAvailability - tmpCurrentDynHistoric : 'N/A';
            occs[k].inv_occ_statHistoric = tmpRoom.defaultAvailability ? tmpRoom.defaultAvailability - tmpCurrentStatHistoric : 'N/A';
          }

          if(k=="WHOLE_OCCUPANCY") {
            let item = ris[day].occupancies.WHOLE_OCCUPANCY;

            // CURRENT
            if(item) {
              let tmp_cap = ris[day].otherinfos.CAPACITY.current != null ? ris[day].otherinfos.CAPACITY.current.value : this.structureService.selectedStructure.rooms;

              item.current==null ? item.current = {} : null;
              item.current.inv = item.current && item.current.occupancy>0 ? tmp_cap-item.current.occupancy : tmp_cap;
            }

            // DYN HISTORY
            if(item && item.dynHistoric!=null) {
              let tmp_cap = ris[day].otherinfos.CAPACITY.dynHistoric != null ? ris[day].otherinfos.CAPACITY.dynHistoric.value : this.structureService.selectedStructure.rooms;

              item.dynHistoric.inv = item.dynHistoric && item.dynHistoric.occupancy>0 ? tmp_cap-item.dynHistoric.occupancy : tmp_cap;
            }

            // STA HISTORY
            if(item && item.statHistoric!=null) {
              let tmp_cap = ris[day].otherinfos.CAPACITY.statHistoric != null ? ris[day].otherinfos.CAPACITY.statHistoric.value : this.structureService.selectedStructure.rooms;

              item.statHistoric.inv = item.statHistoric && item.statHistoric.occupancy>0 ? tmp_cap-item.statHistoric.occupancy : tmp_cap;
              ris[day].statHistoricDay = this.translate.instant('DAYS.SHORT.' + moment(ris[day].statHistoricDay,"YYYY-MM-DD").day() )+" "+moment(ris[day].statHistoricDay,"YYYY-MM-DD").format("DD-MM-YYYY");
            }
          } else {
            let item = ris[day].occupancies[k];
            if(item.current == null) {
              item.current = {
                "day": day,
                "occupancy": 0,
                "occupancyPickup": 0,
                "occupancyFromGroups": 0,
                "occupancyFromGroupsPickup": 0,
                "groupsVRplus": 0,
                "groupsVRminus": 0,
                "revenue": 0,
                "revenueFromGroups": 0,
                "vrminus": 0,
                "vrplus": 0
              }
            }
            if(item.dynHistoric == null) {
              item.dynHistoric = {
                "day": day,
                "occupancy": 0,
                "occupancyPickup": 0,
                "occupancyFromGroups": 0,
                "occupancyFromGroupsPickup": 0,
                "groupsVRplus": 0,
                "groupsVRminus": 0,
                "revenue": 0,
                "revenueFromGroups": 0,
                "vrminus": 0,
                "vrplus": 0
              }
            }
            if(item.statHistoric == null) {
              item.statHistoric = {
                "day": day,
                "occupancy": 0,
                "occupancyPickup": 0,
                "occupancyFromGroups": 0,
                "occupancyFromGroupsPickup": 0,
                "groupsVRplus": 0,
                "groupsVRminus": 0,
                "revenue": 0,
                "revenueFromGroups": 0,
                "vrminus": 0,
                "vrplus": 0
              }
            }
          }
        }

        let rates = ris[day].rates;
        this.rateResult[day] = rates;
        for(let i in rates) {
          if(rates[i].current == null) {
            rates[i].current = {
              forced:false,
              rate:''
            }
          }else if(rates[i].current && rates[i].current.rate){
            /* SHOW OR NOT AI BUTTON */
            if(i === this.structureService.selectedStructureConfig['AI_ROOM_REF_0']?.value && moment(day,'YYYY-MM-DD').isSameOrAfter(moment().add(10,'days'),'day')){
              rates[i].current.showAi = true;
            }else{
              rates[i].current.showAi = false;
            }
          }
        }
        rates.edited = false;
      }

      this.calService.calendarDataDays = ris;
      console.debug("CALENDAR DATA DAYS", this.calService.calendarDataDays);

      this.loadingCalandarData = false;
    });
  }

  // Passare m=mese e y=anno quando si cambia giorno. Nella prima chiamata viene preso il mese e anno corrente.
  printDays(){
    console.debug("SelectedDay",this.calService.selectedDay)

    let tmp = moment()

    //let from = moment(this.date.from)
    //let to = moment(this.date.to)
    //let result = [moment({...from})]

    /*while(from.isSame(to) == false){
      from.add(1, 'day');
      result.push(moment({ ...from }));
    }

    this.print(result.map(x => x.format("DD-MM-YYYY"))) */
  }
  print(result:any){
    this.tmpResult = []
    for(let item of result){
      let tmpDate = moment(item,'DD,MM,YYYY')
      let dayName = tmpDate.day()
      let day = tmpDate.format('D')
      let monthName = tmpDate.format('M')
      let year = tmpDate.format('YYYY')
      let tmpData: any = {
        "roomtype":[],
        "saleschannel":[],
        "tot":[
          {
            occ:'',
            prod: ''
          }
        ]
      }

      for(let k of this.rooms){
        tmpData.roomtype.push({
            id: k.id,
            title: k.title,
            occ:'',
            prod: ''
          }
        )
      }

      for(let k of this.channels){
        tmpData.saleschannel.push({
          id: k.id,
          title: k.code,
          occ:'',
          prod: ''
        })
      }

      this.tmpResult.push(
        {
          day: day,
          number: dayName,
          month: monthName,
          year: year,
          data: tmpData
        }
      )
    }

    console.debug(this.tmpResult)
  }

  onXScroll(idx:any) {
    let scrollL = document.getElementById('ref_'+idx)!.scrollLeft;
    console.debug(scrollL)
    let elements =  document.getElementsByClassName("col-day-data");
    if(elements.length>0) {
      for(let k in elements) {
        if(Number.isInteger(parseInt(k))) {
          elements[k] ? elements[k].scrollLeft = scrollL : null;
        }
      }
    }
  }

  evalDtOld(y:string,m:string,d:string) {
    return moment().isSameOrBefore(y+m+d, 'day');
  }


  checkChanges(indexRow: number, rt: string,day: string) {
    this.rateResult[day].edited = true;
    this.calService.editedDays.indexOf(day) < 0 ? this.calService.editedDays.push(day) : null;
    if(this.rateResult[day][rt].currentFormula==null) {
      this.refreshBaseFormula(indexRow, rt,day);
    } else {
      this.rateResult[day][rt].current.forced = true;
      this.refreshBaseFormula(indexRow, rt,day);
    }
    this.guiService.isConfigEdited = true
  }

  refreshBaseFormula(indexRow: number, rt: string,day: string) {
    let dt = day;
    let price_model = this.rateResult[dt];

    let scope: any = {};

    for(let k in price_model) {
      if(k!= 'edited' && price_model[k].currentFormula==null) {
        scope[k] = price_model[k].current != null ? price_model[k].current.rate : price_model[k].currentMinValue;
      }
    }

    for(let k in price_model) {
      if(k!= 'edited' && price_model[k].currentFormula!=null) {

        if(price_model[k].current.forced) {
          scope[k] = price_model[k].current.rate;
        }
        let tmp = math.parse(price_model[k].currentFormula);
        tmp.forEach(function (node, path, parent) {
          switch (node.type) {
            case 'SymbolNode':
              if(!price_model[k].current.forced) {
                let t: any = node;
                scope[k] = scope[t.name] ? math.evaluate(price_model[k].currentFormula, scope) : price_model[k].currentFormula;
              }
              break
            default:
            // nothing
          }
        })
      }
    }

    for(let k in price_model) {
      if(k!= 'edited' && !price_model[k].current.forced) {
        price_model[k].currentFormula ? price_model[k].current.rate = math.evaluate(price_model[k].currentFormula, scope) : null;
      }
    }

    // VERIFICO CHE I PREZZI SIANO TUTTI IMPOSTATI SULLA GIORNATA
    for(let k in price_model) {
      if(k!= 'edited' && !price_model[k].current) {
        price_model[k].error = true;
      }
    }

  }

  unlockPrice(indexRow: number, rt: string,day: string) {
    this.rateResult[day][rt].current.forced = false;
    this.refreshBaseFormula(indexRow, rt,day);
  }




  rateSave(day?: string) {
    this.daysprocessing.indexOf(day) < 0 ? this.daysprocessing.push(day) : null;

    let rateItems: any = {};
    if(day) {
      rateItems[day] = this.rateResult[day];
    } else {
      rateItems = this.rateResult;
    }
    let param: any = {};
    let errorParam: boolean = false;

    console.debug("rateItems", rateItems, this.rooms);
    for(let k in rateItems) {
      let item = rateItems[k];

      if(item.edited) {
        param[k] = {
          rates: {}
        }
        for(let i in item) {
          if(this.roomsIndex.indexOf(i)>=0) {
            if (item[i].currentFormula == null || item[i].current.forced) {
              if (item[i].current != null) {
                delete item[i]['error'];
                param[k].rates[i] = {
                  rateValue: item[i].current.rate,
                  forced: item[i].current.forced ? item[i].current.forced : false
                }
              } else {
                if (i != 'edited') {
                  delete param[k];
                  break;
                }
              }
            }
          }

        }
      }
    }

    console.debug("PARAM TO SAVE", param);
    this.restService.putService('calendar','v4/'+this.structureService.selectedStructure.id+'/days/edit-rates',param).subscribe((ris)=>{
      this.guiService.editedSuccesfully();

      if(day) {
        this.rateResult[day].edited = false;
        this.daysprocessing.splice(this.daysprocessing.indexOf(day), 1);
        this.calService.editedDays.splice(this.calService.editedDays.indexOf(day),1);
      } else {
        this.loadCalendarData();
      }
    });
  }

  calendarRateHistory(day: string) {
    const modalRef = this.modalService.open(CalendarRateHistoryComponent, {size: 'xl'});
    modalRef.componentInstance.day = day;
    modalRef.componentInstance.rooms = this.rooms;
  }

  loadAiRate(indexRow: number, day: string, code:string) {
    this.isAiBtnPress = indexRow;

    this.restService.getService('ai_rate',this.structureService.selectedStructure.id +'/?from='+ day+ '&to='+day).subscribe((ris)=>{

      let msg = day + "<br>" +
      code + "<br>";

      if(ris.rates[day].quality=="CLOSED") {
        msg += "<span class='badge bg-danger'>"+this.translate.instant("GENERIC.closed")+"</h6>";
      } else {
        msg += ris.rates[day].revolutionRate+" <i class='bx bx-right-arrow-alt'></i> <b>"+ris.rates[day].aiRate+"</b>";
      }

      Swal.fire({
        title: this.translate.instant("AI.ai_rate_proposed"),
        html: msg,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '"#3085d6',
        cancelButtonColor: '"#d33',
        cancelButtonText: this.translate.instant("GENERIC.logout_no"),
        confirmButtonText: this.translate.instant("AI.apply_btn"),
      }).then((result) => {
        this.isAiBtnPress = null;
        if (result.isConfirmed) {
          this.rateResult[day][code].current.rate = ris.rates[day].aiRate;
          this.checkChanges(indexRow,code,day);
        }
      });
    })
  }

  checkOverRevPar(item: any){
    const current = this.capacity> 0 && this.calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current ? this.calService.calendarDataDays[item.day]?.occupancies['WHOLE_OCCUPANCY']?.current && this.calService.calendarDataDays[item.day]?.occupancies['WHOLE_OCCUPANCY']?.current?.revenue / this.capacity : '0';
    const statHistoric = this.capacity> 0 && this.calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric ? this.calService.calendarDataDays[item.day]?.occupancies['WHOLE_OCCUPANCY']?.statHistoric?.revenue / this.capacity : '0';
    return current > statHistoric ? true : false;
  }

  inputStatus(day: any){
    if(this.calService.selectedDay && !this.calService.nowForCalendar.isSameOrBefore(day,'day')){
      if(this.guiService.userLogged.roles !== 'REVOLUTION USER'){
        return true
      }else{
        return true
      }
    }else{
      if(this.guiService.userLogged.roles !== 'REVOLUTION USER'){
        return false
      }else{
        return true
      }
    }
  }
}
