<div>
  <div class="card mb-0">
    <div class="card-header">
        <h1 class="card-title text-center" *ngIf="conteggio && conteggio.monthToChange">Inserimento conteggi</h1>
        <h1 class="card-title text-center" *ngIf="conteggio && !conteggio.monthToChange">Genera fattura mese in corso</h1>
        <i class="uil-times-square close-icon" (click)="close()"></i>
    </div><!-- end card header -->
    <form class="card-body" [formGroup]="form">
      <div class="row" *ngIf="showBtnNew == false">
        <div class="col-12">
          <div class="mb-3 row form-floating">
            <input class="form-control" type="text" id="importo" formControlName="importo" (change)="calculateCommissions()">
            <label for="importo">Importo</label>
          </div>
          <div class="mb-3 row form-floating">
            <input class="form-control" type="text" id="commissione" formControlName="commissione" (change)="calculateCommissions()">
            <label for="commissione">Commissione</label>
          </div>
          <div class="mb-3 row form-floating">
            <input class="form-control" type="text" id="totale" formControlName="totale">
            <label for="totale" class="col-md-2 col-form-label">Totale</label>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="showBtnNew">
        <div class="col-12">
          <div class="mb-3 row form-floating">
            <input class="form-control" type="number" id="scadenza" formControlName="scadenza">
            <label for="scadenza">Scadenza</label>
            <small>Di default 30gg</small>
          </div>
        </div>
      </div>
    </form><!-- end card body -->
</div>
<div class="d-flex justify-content-center m-2">
  <button class="btn-new" (click)="save()" *ngIf="showBtnNew == false">Salva</button>
  <button class="btn-new" (click)="generateInvoice()" *ngIf="showBtnNew">Crea</button>
</div>
</div>
