<div class="page-title-box d-flex align-items-center justify-content-between">
    <h4 class="mb-0">Structure List</h4>
</div>
<div class="col-xxl-12 col-xl-12" *ngIf="guiService.loaded">
    <div class="card card-h-100" *ngIf="structures">
        <div class="card-header row">
            <div class="col-sm-12 col-md-6 col-xl-8">
            </div>
        </div><!-- end card header -->
        <div class="card-body row">
            <div class="col-sm-12">
                <div class="custom-align-end">
                    <select [(ngModel)]="size" (change)="changeSize()">
                        <option *ngFor="let n of sizeOption" [value]="n">{{n}}</option>
                    </select>
                    <ngb-pagination class="" (pageChange)="loadPage($event)" [(page)]="page" [pageSize]="size"
                    [collectionSize]="structures.totalItems" [maxSize]="3"></ngb-pagination>
                </div>
            </div>
            <div class="col-sm-12 col-md-12">

                <!-- START TABLE VIEW -->
                <div class="table-responsive" *ngIf="guiService.viewType == 'TABLE'">
                    <table class="table table-sm table-hover mb-0">
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th class="text-center">{{ "GENERIC.city" | translate }}</th>
                                <th>{{ "RELIABLE.full" | translate }}</th>
                                <th>{{ "RELIABLE.only_occ" | translate }}</th>
                                <th>{{ "RELIABLE.occ_and_prod_past" | translate }}</th>
                                <th>
                                    <div class="action-btn">
                                        <button class="btn btn-warning btn-sm mx-2" (click)="getStructures()">
                                          {{ "GENERIC.reload_btn" | translate}}
                                        </button>
                                        <button class="btn btn-primary btn-sm mx-2" (click)="savAll()">
                                            {{ "GENERIC.save_all_btn" | translate}}
                                        </button>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let structure of structures.items">
                                <th scope="row">
                                    #{{structure.id}}</th>
                                <td class="name-color">{{structure.name}}</td>
                                <td class="text-center">
                                    {{structure.city}}</td>
                                <td>
                                    <div dir="ltr" class="form-check form-switch" >
                                        <input type="checkbox" id="customSwitchsizesm"
                                            [checked]="structure.reliable == true"
                                            [(ngModel)]="structure.reliable"
                                            class="form-check-input">
                                    </div>
                                </td>
                                <td>
                                    <div dir="ltr" class="form-check form-switch">
                                        <input type="checkbox" id="customSwitchsizesm"
                                            [checked]="structure.occReliable"
                                            [(ngModel)]="structure.occReliable"
                                            class="form-check-input">
                                    </div>
                                </td>
                                <td>
                                    <div dir="ltr" class="form-check form-switch">
                                        <input type="checkbox" id="customSwitchsizesm"
                                            [checked]="structure.clusterReliable"
                                            [(ngModel)]="structure.clusterReliable"
                                            class="form-check-input">
                                    </div>
                                </td>
                                <td>
                                    <div class="action-btn">
                                        <button class="btn btn-warning btn-sm mx-2" (click)="reloadStructureInfo(structure.id)">
                                        {{ "GENERIC.reload_btn" | translate}}
                                        </button>
                                        <button class="btn btn-primary btn-sm mx-2" (click)="saveSingleStructure(structure.id)">
                                            {{ "GENERIC.save_btn" | translate}}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody><!-- end tbody -->
                    </table><!-- end table -->
                </div><!-- end table responsive -->

            </div>


        </div><!-- end card body -->

    </div><!-- end card -->
</div>

<div *ngIf="!guiService.loaded" class="main-page-loading">
    <div class="loading-bars-box">
        <div class="loading-bars"></div>
    </div>
</div>