import { Component, Input, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';

@Component({
  selector: 'app-fornitori-detail',
  templateUrl: './fornitori-detail.component.html',
  styleUrls: ['./fornitori-detail.component.scss']
})
export class FornitoriDetailComponent {
  @Input() supplier: any;
  form!: UntypedFormGroup
  showBtnNew: boolean = false;
  provinces: any = [];
  countrys: any = [];
  regions: any = [];
  methods: any = [];
  provinceSelected: any;
  countrySelected: any;
  regionSelected: any;
  methodSelected: any;

  constructor(private fb: UntypedFormBuilder, public guiService: GuiService, private restService: RestService){

  }

  ngOnInit(): void {
    console.debug(this.supplier)
    this.initForm()
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.initForm()
  }

  initForm(){
    this.form = this.fb.group({
      name: [null, Validators.required],
      vatNumber: [null, Validators.required],
      cf: [null, Validators.required],
      legalAddressStreet: [null, Validators.required],
      legalAddressCity: [null, Validators.required],
      legalAddressCap: [null, Validators.required],
      note: [null],
      contact_email: [null],
      contact_name: [null],
      contact_phone: [null]
    })

    this.supplier && Object.keys(this.supplier).length > 0 ? (this.populateForm(),this.showBtnNew= false) : this.showBtnNew= true
   }

   populateForm(){
    this.form.controls['name'].setValue(this.supplier.name)
    this.form.controls['vatNumber'].setValue(this.supplier.vatNumber)
    this.form.controls['cf'].setValue(this.supplier.cf)
    this.form.controls['legalAddressStreet'].setValue(this.supplier.legalAddressStreet)
    this.form.controls['legalAddressCity'].setValue(this.supplier.legalAddressCity)
    this.form.controls['legalAddressCap'].setValue(this.supplier.legalAddressCap)
    this.form.controls['note'].setValue(this.supplier.note),
    this.provinceSelected = this.supplier.legalAddressProvince.id ? this.supplier.legalAddressProvince : null,
    this.countrySelected = this.supplier.legalAddressCountry.id ? this.supplier.legalAddressCountry : null,
    this.regionSelected = this.supplier.legalAddressRegion.id ? this.supplier.legalAddressRegion : null,
    this.methodSelected = this.supplier.pagDefault.id ? this.supplier.pagDefault : null
   }

   getProvince(evento:any){
    if(evento.term.length > 2){
    this.restService.getService('core_base_new','/common/provinces_like?query='+evento.term).subscribe((ris)=>{
      this.provinces = ris
    })
    }
  }

  getCountry(evento:any){
    if(evento.term.length > 2){
    this.restService.getService('core_base_new','/common/countries_like?query='+evento.term).subscribe((ris)=>{
      this.countrys = ris
    })
    }
  }

  getRegion(evento:any){
    if(evento.term.length > 2){
    this.restService.getService('core_base_new','/common/regions_like?query='+evento.term).subscribe((ris)=>{
      this.regions = ris
    })
    }
  }

  getPaymentMethod(evento:any){
    if(evento.term.length > 2){
    this.restService.getService('crm_base_new','/inv_payment_method/actives?query='+evento.term).subscribe((ris)=>{
      this.methods = ris
    })
    }
  }
  editSupplier(){
    let editedSupplier = {
      id: this.supplier.id,
      name: this.form.value.name,
      vatNumber: this.form.value.vatNumber,
      cf: this.form.value.cf,
      legalAddressStreet: this.form.value.legalAddressStreet,
      legalAddressCity: this.form.value.legalAddressCity,
      legalAddressCap: this.form.value.legalAddressCap,
      legalAddressProvince: this.provinceSelected ? this.provinceSelected : this.supplier.legalAddressProvince,
      legalAddressCountry: this.countrySelected ? this.countrySelected : this.supplier.legalAddressCountry,
      legalAddressRegion: this.regionSelected ? this.regionSelected : this.supplier.legalAddressRegion,
      pagDefault: this.methodSelected ? this.methodSelected : this.supplier.pagDefault,
      note: this.form.value.note
    }

      this.restService.putService('crm_base_new','/inv_supplier',editedSupplier).subscribe((ris)=>{
        this.guiService.editedSuccesfully()
        this.guiService.refreshList.next(true);
      })
  }

  newSupplier(){
    let newSupplier = {
      name: this.form.value.name,
      vatNumber: this.form.value.vatNumber,
      cf: this.form.value.cf,
      legalAddressStreet: this.form.value.legalAddressStreet,
      legalAddressCity: this.form.value.legalAddressCity,
      legalAddressCap: this.form.value.legalAddressCap,
      legalAddressProvince: this.provinceSelected ? this.provinceSelected : this.supplier.legalAddressProvince,
      legalAddressCountry: this.countrySelected ? this.countrySelected : this.supplier.legalAddressCountry,
      legalAddressRegion: this.regionSelected ? this.regionSelected : this.supplier.legalAddressRegion,
      pagDefault: this.methodSelected ? this.methodSelected : this.supplier.pagDefault,
      note: this.form.value.note
    }

    this.restService.postService('crm_base_new','/inv_supplier/create',newSupplier).subscribe((ris)=>{
      this.guiService.createdSuccessfully()
      this.guiService.refreshList.next(true);
    })
  }
  close(){
    this.guiService.closeDetail.next(true);
  }

  createContact(){
    let newContact = {
      email: this.form.value.contact_email,
      name: this.form.value.contact_name,
      phone: this.form.value.contact_phone
    }
    this.restService.postService('crm_base_new','/inv_supplier/'+this.supplier.id+'/contact/',newContact).subscribe((ris)=>{
      this.supplier.contacts = ris.data
    })
  }

  deleteContact(idContact: any, indexContact: any){
    this.restService.deleteService('crm_base_new','/inv_supplier/'+this.supplier.id+'/contact/'+idContact,{}).subscribe((ris)=>{
      this.supplier.contacts.splice(indexContact, 1)
    })
  }

}
