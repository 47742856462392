<div class="card mb-0">

    <div class="card-body">

        <div class="card item-form">

            <div class="card-body">

                <div class="container room-container" *ngIf="rooms && roomIndex!=null">
                    <div class="mb-3 row" *ngFor="let room of rooms; let i = index">
                        <div class="custom-label col-5">
                            <label class="">{{room.name}}</label>
                            <small>{{room.title}}</small>
                        </div>
                        <!-- SELECT SOLO SE DIVERSE DA VERTICAL -->
                        <div class="form-floating col-6" *ngIf="strService.selectedStructureConfig['CHANNEL_SYSTEM'].value !== 'VERTICAL'">
                            <ng-select [clearable]="false" placeholder="{{'AVAILABILITY.channel_room' | translate}}" [(ngModel)]="roomIndex[room.id].channelRoomCode" class="room-mapping-select">
                                <ng-option *ngFor="let item of channelRooms" [value]="item.id+''">
                                    {{item.code}}
                                    <small>[{{item.id}}]</small>
                                </ng-option>
                            </ng-select>
                        </div>
                        <!-- SELECT SOLO SE DIVERSE DA VERTICAL -->

                        <!-- INPUT SOLO SE VERTICAL -->
                        <div class="form-floating col-6" *ngIf="strService.selectedStructureConfig['CHANNEL_SYSTEM'].value === 'VERTICAL'">
                          <input class="form-control custom-padding" type="text" [(ngModel)]="roomIndex[room.id].channelRoomCode" >
                      </div>
                      <!-- INPUT SOLO SE VERTICAL -->


                    </div><!-- end row -->

                    <div class="row text-center">
                        <button class="btn btn-primary" (click)="savePlan()">
                            {{"GENERIC.save_btn" | translate}}
                        </button>
                    </div>
                </div>


                <div *ngIf="!loaded" class="main-page-loading">
                    <div class="loading-bars-box">
                      <div class="loading-bars"></div>
                    </div>
                  </div>



            </div>

        </div>

    </div><!-- end card body -->
</div>
