import { Component, OnChanges, OnInit, SimpleChanges, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';
import { StructureService } from 'src/app/core/services/structure.service';

@Component({
  selector: 'app-rate-channel',
  templateUrl: './rate-channel.component.html',
  styleUrls: ['./rate-channel.component.scss']
})
export class RateChannelComponent implements OnInit {
  rooms: Array<any> = [];
  roomIndex: any;
  loaded: boolean = true;
  loadedRules: Array<boolean> = [false,false,false];
  items: Array<any> = [];
  createUpdatePlan: boolean = false;

  rateplan: any;

  constructor(private restService: RestService, public strService: StructureService, private guiService: GuiService, public translate: TranslateService) {

  }

  ngOnInit(): void {

    this.getRooms();
    this.getChannelRatePlan();
  }


  getRooms(){
    if(this.strService.selectedStructure){
      let params = {
        page: 0,
        searchCriteria: [{
          "key": "ENABLED",
          "operation" : "EQUALITY",
          "value" : true,
          "orPredicate" : false
        }],
        size: 100,
        structureId: this.strService.selectedStructure.id
      }
      this.restService.postService('room_type','/v2/search',params).subscribe((ris) =>{
        this.rooms = ris.items;

        this.loadedRules[0] = true;
        this.evaluateLoading();

        setTimeout(()=> {
          this.prepareRoomIndex();
        }, 500);
      })
    }
  }

  getChannelRatePlan() {
    this.loaded = false;
    this.restService.getService('cm_rate', '/list/' + this.strService.selectedStructure.id).subscribe((ris) => {
      console.debug("RATES", ris);
      ris && ris.data ? this.items = ris.data : null;

      this.loadedRules[1] = true;
      this.evaluateLoading();
    })
  }

  close() {
    this.guiService.closeDetail.next(true);
  }

  createNewPlan() {
    this.rateplan = {
      "name": "",
      "parity": false,
      "restriction": false,
      "rateDetails": []
    }

    for (let k in this.rooms) {
      let id = this.rooms[k].id;
      this.roomIndex[id].value = "";
      this.roomIndex[id].minValue = "";
    }
    this.createUpdatePlan = true;
  }

  selectPlan(item: any) {
    this.rateplan = item;
    let tmp = item.rateDetails;

    for (let k in tmp) {
      let id = tmp[k].roomId;
      this.roomIndex[id].data = tmp[k];
      this.roomIndex[id].value = tmp[k].value;
      this.roomIndex[id].minValue = tmp[k].minValue;
    }
    this.createUpdatePlan = true;
  }

  prepareRoomIndex() {
    this.roomIndex = {};
    for (let k in this.rooms) {
      this.roomIndex[this.rooms[k].id] = this.rooms[k];
    }

    this.loadedRules[2] = true;
    this.evaluateLoading();
  }

  savePlan() {
    // create/26
    let param: any = {
      "name": this.rateplan.name,
      "restriction": this.rateplan.restriction,
      "rateDetails": [
      ]
    };


    if (this.rateplan.id && this.rateplan.id != null) {
      param.id = this.rateplan.id;

      for (let k in this.rooms) {
        let id = this.rooms[k].id;

        console.debug(this.roomIndex[id]);
        param.rateDetails.push({
          "id": this.roomIndex[id].data.id,
          "roomId": id,
          "value": this.roomIndex[id].value == null ? '' : this.roomIndex[id].value,
          "minValue": this.roomIndex[id].minValue == null ? '' : this.roomIndex[id].minValue,
        });
      }

      this.restService.putService('cm_rate', '/update/' + this.strService.selectedStructure.id, param).subscribe((ris) => {
        console.debug("UPDATE RATES", ris);

        this.rateplan = null;
        this.createUpdatePlan = false;
      });
    } else {

      for (let k in this.rooms) {
        let id = this.rooms[k].id;

        console.debug(this.roomIndex[id]);
        param.rateDetails.push({
          "roomId": id,
          "value": this.roomIndex[id].value == null ? '' : this.roomIndex[id].value,
          "minValue": this.roomIndex[id].minValue == null ? '' : this.roomIndex[id].minValue,
        });
      }
      this.restService.postService('cm_rate', '/create/' + this.strService.selectedStructure.id, param).subscribe((ris) => {

        this.rateplan = null;
        this.createUpdatePlan = false;

        this.getChannelRatePlan();
      });
    }

  }

  cancelSelectionPlan() {
    this.createUpdatePlan = false;
    this.rateplan = null;
  }


  evaluateLoading() {
    let tmp = true;
    for(let k in this.loadedRules) {
      tmp = tmp && this.loadedRules[k];
    }

    this.loaded = tmp;
  }
}
