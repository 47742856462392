import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, type OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';
import { StructureService } from 'src/app/core/services/structure.service';
import * as moment from "moment/moment";

@Component({
  selector: 'app-info-structure',
  templateUrl: './info-structure.component.html',
  styleUrls: ['./info-structure.component.scss']
})
export class InfoStructureComponent implements OnInit {
  loaded: Array<boolean> = [true,true,true];
  provinces: any;
  tag_geo:any = [];
  tag_geoCtrl:any;
  tag_strType:any = [];
  tag_strTypeCtrl:any;
  tag_type: any = [];
  tag_typeCtrl:any;
  structure: any;
  notes: any = [];
  noteCtrl:any;
  expandFlags: any = {
    str: true,
    tag: false,
    note: false
  }

  constructor(public guiService: GuiService, public structureService: StructureService, public modalService: NgbModal, private restService: RestService){
    this.guiService.refreshList.subscribe( value => {
      if(value == true){
        this.getNotes()
      }
     });
  }

  ngOnInit(): void {
    this.getProvinces();
    this.getTagsSelects();
    this.structure = JSON.parse(JSON.stringify(this.structureService.selectedStructure));
    this.getNotes();
   }


   getProvinces(){
    this.loaded[0] = false;
    this.restService.getService('provinces','').subscribe((ris)=>{
      this.provinces = ris
      this.loaded[0] = true;
    })
   }

   getTagsInfo(){
    this.restService.getService('tag','/list/0/'+this.structureService.selectedStructure.id).subscribe((ris)=>{
      this.tag_geoCtrl = ris.find((x: { type: string; }) => x.type === 'GEO').id
      this.tag_strTypeCtrl = ris.find((x: { type: string; }) => x.type === 'STRUCTURE').id
      this.tag_typeCtrl = ris.find((x: { type: string; }) => x.type === 'TYPE').id
    })
   }

   editTags(){
    let param = [this.tag_geoCtrl,this.tag_strTypeCtrl,this.tag_typeCtrl]
    console.debug('param edit tags', param)
    this.restService.putService('tag','/edit/'+this.structureService.selectedStructure.id,param).subscribe((ris)=>{
      this.guiService.editedSuccesfully()
    })
   }

   getTagsSelects(){
    this.loaded[1] = false;
    this.restService.getService('tag','/list/0').subscribe((ris)=>{
      for(let tag of ris){
        if(tag.type === 'STRUCTURE'){
          this.tag_strType.push(tag)
        }else if(tag.type === 'TYPE'){
          this.tag_type.push(tag)
        }else if(tag.type === 'GEO'){
          this.tag_geo.push(tag)
        }
      }
      console.debug(this.tag_geo)
      this.loaded[1] = true;
    })
    this.getTagsInfo()
   }

   editInfoStr(){
     this.restService.putService('structure','/update',this.structure).subscribe((ris)=>{
      this.structureService.selectedStructure = this.structure;
       this.guiService.editedSuccesfully();
    })
   }

   getNotes(){
    this.loaded[2] = false;
    this.restService.getService('note','/list/STRUCTURE/'+this.structureService.selectedStructure.id).subscribe((ris)=>{
      this.notes = ris
      this.loaded[2] = true;
    })
  }

  addNote(){
    let param = [{value:this.noteCtrl,type:'STRUCTURE',date:String(moment().format('yyyy-MM-DD'))}]
    this.restService.postService('note','/create/'+this.structureService.selectedStructure.id,param).subscribe((ris)=>{
      this.getNotes()
      this.noteCtrl = ''
      this.guiService.createdSuccessfully()
    })
  }

}
