import {Component, ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {GuiService} from "../../../core/services/gui.service";
import {StructureService} from "../../../core/services/structure.service";
import * as moment from "moment/moment";
import {CalendarService} from "../../../core/services/calendar.service";
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexXAxis
} from "ng-apexcharts";
import {RestService} from "../../../core/services/rest.service";
import Swal from "sweetalert2";


export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  colors: string[];
};

@Component({
  selector: 'app-highlights',
  templateUrl: './highlights.component.html',
  styleUrls: ['./highlights.component.scss']
})
export class HighlightsComponent {
  pickups: any = [];
  activities: any = [];
  reportDateSelected: any = null;

  yearCtrl: any;
  years: any = [];

  startMonth: any;
  selectedMonth: any;
  selectedMonthIndex: any;

  monthsPickup: any;
  monthsPickuploaded: boolean = false;
  daysPickup: any;

  action_logs: Array<any> = [];

  @ViewChild("chart") chart: any;
  public chartOptions1: ChartOptions = {
    series: [
      {
        name: "Month Pickup (+)",
        data: []
      },
      {
        name: "Month Pickup (-)",
        data: []
      }
    ],
    chart: {
      type: "bar",
      height: 300
    },
    colors: [
      "#5ae264",
      "#fc2b2b",
      "#e8e8e7"
    ],
    plotOptions: {
      bar: {
        colors: {},
        columnWidth: "25%"
      }
    },
    dataLabels: {
      enabled: false
    },
    yaxis: {
      title: {
        text: "Pickups"
      },
      labels: {
        formatter: function(y: any) {
          return y.toFixed(0) + "";
        }
      }
    },
    xaxis: {
      categories: [
        this.translate.instant("MONTHS.LONG.1"),
        this.translate.instant("MONTHS.LONG.2"),
        this.translate.instant("MONTHS.LONG.3"),
        this.translate.instant("MONTHS.LONG.4"),
        this.translate.instant("MONTHS.LONG.5"),
        this.translate.instant("MONTHS.LONG.6"),
        this.translate.instant("MONTHS.LONG.7"),
        this.translate.instant("MONTHS.LONG.8"),
        this.translate.instant("MONTHS.LONG.9"),
        this.translate.instant("MONTHS.LONG.10"),
        this.translate.instant("MONTHS.LONG.11"),
        this.translate.instant("MONTHS.LONG.12")
      ],
      labels: {
        rotate: -90
      }
    }
  };

  constructor(public translate: TranslateService,private router: Router, public guiService: GuiService,
              public structureService: StructureService, public calService: CalendarService, public restService: RestService) {

  }

  ngOnInit(): void {
    this.guiService.changeArea("FX.main");
    this.guiService.selectMenuItems("FX.main");
    
    setTimeout(()=>{
      this.chartOptions1.xaxis.categories = [
        this.translate.instant("MONTHS.LONG.1"),
        this.translate.instant("MONTHS.LONG.2"),
        this.translate.instant("MONTHS.LONG.3"),
        this.translate.instant("MONTHS.LONG.4"),
        this.translate.instant("MONTHS.LONG.5"),
        this.translate.instant("MONTHS.LONG.6"),
        this.translate.instant("MONTHS.LONG.7"),
        this.translate.instant("MONTHS.LONG.8"),
        this.translate.instant("MONTHS.LONG.9"),
        this.translate.instant("MONTHS.LONG.10"),
        this.translate.instant("MONTHS.LONG.11"),
        this.translate.instant("MONTHS.LONG.12")
      ];
      
      this.startMonth = moment().subtract(1,'month');
      this.selectedMonth = moment().format("MM");
      this.selectedMonthIndex = parseInt(this.selectedMonth);;
      this.getYears();
      let ref = this;
      this.chartOptions1.chart.events = {
        click: function(event, chartContext, config) {
  
          ref.selectMonth(config.dataPointIndex);
        }
      }

    },1000);

  }


  getUpdateLog(){
    console.debug("this.selectedMonth",this.yearCtrl);
    let from = moment(this.yearCtrl+"-"+this.selectedMonth+"-"+"01","YYYY-MM-DD").format("YYYY-MM-DD");
    let to = moment().format("YYYY-MM-DD");

    let typ = '';
    let selecteduserid = '';


    let param: any = {
      page: 0,
      size: 10,
      searchCriteria: {
        fromDay: from,
        toDay: to
      }
    }

    this.restService.postService('trace','v4/'+this.structureService.selectedStructure.id+'/search',param).subscribe({
      next: (res) => {
        console.debug("LOG -> ",res);
        this.action_logs = res.items;
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  getYears(){
    this.years.push(moment().add(1, 'year').format('YYYY'));
    this.years.push(moment().format('YYYY'));
    for(let i = 1; i <= 4; i++){
      this.years.push(moment().subtract(i, 'year').format('YYYY'));
    }
    this.yearCtrl = moment().format('YYYY');

    this.generateMonths();
    this.getUpdateLog();
  }

  generateMonths(){
    if(!this.reportDateSelected) {
      this.calService.yearSelectedForCalendar = this.yearCtrl;
      this.calService.monthSelectedForCalendar = this.selectedMonth;
      this.reportDateSelected = this.yearCtrl+'-'+this.selectedMonth;
    }
    else {
      let tmp = this.reportDateSelected.split("-");
      this.calService.yearSelectedForCalendar = this.yearCtrl = tmp[0];
      this.calService.monthSelectedForCalendar = this.selectedMonth = tmp[1];
      this.selectedMonthIndex = parseInt(tmp[1]);
    }
    this.calService.getMonthlyIndicator();

    let from = this.yearCtrl && this.yearCtrl != null ? this.yearCtrl+'-01' : this.startMonth.format("YYYY")+'-01';

    this.calService.getCalendarPickUp(from,12).subscribe((ris)=> {
      this.monthsPickup = ris;
      this.daysPickup = ris[from] ? ris[from].days : {};
      console.debug("monthsPickup", this.monthsPickup,from,this.daysPickup);

      this.loadChart();

    });
  }

  selectMonth(monthIdx?: any){
    this.selectedMonth = moment((monthIdx+1),"M").format("MM");
    this.selectedMonthIndex = monthIdx+1;

    this.calService.yearSelectedForCalendar = this.yearCtrl;
    this.calService.monthSelectedForCalendar = this.selectedMonth;

    this.reportDateSelected = this.yearCtrl+'-'+this.selectedMonth;

    this.loadDays();
  }

  private loadChart() {
    let valplus: Array<any> = new Array(12).fill(0);
    let valminus: Array<any> = new Array(12).fill(0);
    let selM = moment(this.yearCtrl+"-"+this.selectedMonth,"YYYY-MM");
    this.pickups = new Array(selM.daysInMonth());

    for(let k in this.monthsPickup) {
      let tmp: any = moment(k,"YYYY-MM").month();
      valplus[moment(k,"YYYY-MM").month()] = this.monthsPickup[k].pickupPlus;
      valminus[moment(k,"YYYY-MM").month()] = this.monthsPickup[k].pickupMinus * (-1);
    }

    for(let i=1;i<=this.pickups.length;i++) {
      let tmp = moment(this.yearCtrl+"-"+this.selectedMonth+"-"+i,"YYYY-MM-D").format("YYYY-MM-DD");
      this.pickups[i-1] = {
        dt: tmp,
        DoW: this.translate.instant("DAYS.LONG."+moment(tmp,"YYYY-MM-DD").day()),
        pk: this.monthsPickup[selM.format("YYYY-MM")] && this.monthsPickup[selM.format("YYYY-MM")].days[tmp] ? this.monthsPickup[selM.format("YYYY-MM")].days[tmp].pickup : 0
      };
    }

    this.chartOptions1.series[0].data = valplus;
    this.chartOptions1.series[1].data = valminus;
    this.monthsPickuploaded = true;
  }

  private loadDays() {
    let vals: Array<any> = new Array(12).fill(0);
    let selM = moment(this.yearCtrl+"-"+this.selectedMonth,"YYYY-MM");
    this.pickups = new Array(selM.daysInMonth());
    for(let i=1;i<=this.pickups.length;i++) {
      let tmp = moment(this.yearCtrl+"-"+this.selectedMonth+"-"+i,"YYYY-MM-D").format("YYYY-MM-DD");
      console.debug("#############",tmp)
      this.pickups[i-1] = {
        dt: tmp,
        DoW: this.translate.instant("DAYS.LONG."+moment(tmp,"YYYY-MM-DD").day()),
        pk: this.monthsPickup[selM.format("YYYY-MM")].days[tmp] ? this.monthsPickup[selM.format("YYYY-MM")].days[tmp].pickup : 0
      };
    }
  }

  selectYearChange() {
    console.debug("-----------#################", this.reportDateSelected)
    this.generateMonths();
    this.getUpdateLog();
  }

}
