<div class="page-title-box d-flex align-items-center justify-content-between">
  <h4 class="mb-0">{{ "FX.calendar_event" | translate }}</h4>
</div>
<div class="col-xxl-12 col-xl-12">
  <div class="card card-h-100" *ngIf="events && loaded">
    <div class="card-header row">
      <div class="col-sm-12 col-md-6 col-xl-8">
        <!-- <div class="row">
          <div class="form-floating col-3">
          <input type="text" class="form-control" [(ngModel)]="searchCtrl">
          <label>Search</label>
        </div>
        <div class="form-floating col-1">
          <button class="btn btn-primary" (click)="getPrices()">
            <i class="uil-search"></i>
          </button>
        </div>
        </div> -->
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4">
        <div class="row">
          <div class="col-4 d-flex justify-content-between align-items-start">
            <button class="btn btn-primary" (click)="newEventEnable()">
              <i class="uil-plus"></i>
            </button>
            <button class="btn btn-primary" (click)="guiService.chooseItemView('CARD')"
              [class.selected]="guiService.viewType == 'CARD'">
              <i class="uil-table"></i>
            </button>
            <button class="btn btn-primary" [class.selected]="guiService.viewType == 'TABLE'"
              (click)="guiService.chooseItemView('TABLE')">
              <i class="uil-list-ul"></i>
            </button>
          </div>
          <ngb-pagination class="col-8 d-flex justify-content-end" (pageChange)="loadPage($event)" [(page)]="page" [pageSize]="size"
            [collectionSize]="events.length"></ngb-pagination>
        </div>
      </div>
    </div><!-- end card header -->
    <div class="card-body row">

      <div [ngClass]="showDetail ? 'col-sm-2 col-md-6' : 'col-sm-12 col-md-12'">

        <!-- START TABLE VIEW -->
        <div class="table-responsive" *ngIf="guiService.viewType == 'TABLE'">
          <table class="table table-sm table-hover mb-0">
            <thead>
            <tr>
              <th></th>
              <th>{{ "EVENTS.name" | translate}}</th>
              <th class="text-center">{{ "EVENTS.start_event" | translate}}</th>
              <th>{{ "EVENTS.dur_event" | translate}}</th>
              <th>{{ "EVENTS.pre_event_day" | translate}}</th>
              <th>{{ "EVENTS.post_event_day" | translate}}</th>
              <th>{{ "EVENTS.ref" | translate}}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of events">
              <th scope="row" [class.selected]="eventSelected && item.id == eventSelected.id">#{{item.id}}</th>
              <td [class.selected]="eventSelected && item.id == eventSelected.id" class="name-color"><a (click)="editEvent(item)">{{item.name}}</a></td>
              <td [class.selected]="eventSelected && item.id == eventSelected.id" class="text-center">{{item.eventDay | date: 'dd/MM/YYYY'}}</td>
              <td [class.selected]="eventSelected && item.id == eventSelected.id">{{item.eventDuration}}</td>
              <td [class.selected]="eventSelected && item.id == eventSelected.id">{{item.daysBefore}}</td>
              <td [class.selected]="eventSelected && item.id == eventSelected.id">{{item.daysAfter}}</td>
              <td [class.selected]="eventSelected && item.id == eventSelected.id">{{item.overlappedEvent ? item.overlappedEvent.name : ''}}</td>
              <td [class.selected]="eventSelected && item.id == eventSelected.id">
                <div class="text-end mb-1" ngbDropdown>
                  <i class="uil-cog m-0" ngbDropdownToggle id="dropdownBasic0"></i>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic0">
                    <button ngbDropdownItem (click)="editEvent(item)">{{ "EVENTS.edit" | translate}}</button>
                    <button ngbDropdownItem (click)="deleteEvent(item)">{{ "EVENTS.cancel" | translate}}</button>
                  </div>
                </div>
              </td>
            </tr>
            </tbody><!-- end tbody -->
          </table><!-- end table -->
        </div><!-- end table responsive -->


        <!-- START CARD VIEW -->
        <div class="card-group justify-content-center" *ngIf="guiService.viewType == 'CARD'">
          <div class="col-xl-3 col-xxl-2 col-sm-3 col-md-3 card-body-structure-wrapper" *ngFor="let item of events">
            <div class="card-body-structure" [class.selected]="eventSelected && item.id == eventSelected.id">
              <div class="action-btn">
                <div class="text-end mb-1" ngbDropdown>
                  <i class="uil-cog m-0" ngbDropdownToggle id="dropdownBasic2"></i>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                    <button ngbDropdownItem (click)="editEvent(item)">{{ "EVENTS.edit" | translate}}</button>
                    <button ngbDropdownItem (click)="deleteEvent(item)">{{ "EVENTS.cancel" | translate}}</button>
                  </div>
                </div>
              </div>

              <h3 class="pid-area">#{{item.id}}</h3>

              <div class="titolo d-flex justify-content-between">
                <h1><a (click)="editEvent(item)">{{item.name}}</a></h1>
              </div>
              <div class="body-card">
                <div class=""><h3>{{ "EVENTS.start_event" | translate}}:</h3>
                  <p>{{item.eventDay}}</p>
                </div>
                <div class=""><h3>{{ "EVENTS.dur_event" | translate}}</h3>
                  <p>{{item.eventDay | date: 'dd/MM/YYYY'}}</p>
                </div>
                <div class=""><h3>{{ "EVENTS.pre_event_day" | translate}}</h3>
                  <p>{{item.daysBefore}}</p>
                </div>
                <div class=""><h3>{{ "EVENTS.post_event_day" | translate}}</h3>
                  <p>{{item.daysAfter}}</p>
                </div>
                <div class=""><h3>{{ "EVENTS.ref" | translate}}</h3>
                  <p>{{item.overlappedEvent ? item.overlappedEvent.name : ''}}</p>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-10 col-md-6" *ngIf="showDetail">
        <app-calendar-event-detail [eventlist]="events" [event]="eventSelected" *ngIf="newEvent == false"></app-calendar-event-detail>
        <app-calendar-event-detail [eventlist]="events" [event]="{}" *ngIf="newEvent"></app-calendar-event-detail>
      </div>

    </div><!-- end card body -->

  </div><!-- end card -->


  <div *ngIf="!loaded" class="main-page-loading">
    <div class="loading-bars-box">
      <div class="loading-bars"></div>
    </div>
  </div>
</div>
