<div class="contenitore">
  <div class="months-container" *ngIf="months && monthsPickuploaded">
    <i class="mdi mdi-chevron-left icona-arrow" (click)="generateMonths('left')"></i>
    <div *ngFor="let month of months; let i = index"
         class="month-box"
         [class.selected-month]="month.id == selectedMonthIndex"
         (click)="selectMonth(month)">
      <span>{{month.year}}</span>
      <h4 class="month-name">{{"MONTHS.SHORT." + (month.month + 1) | translate}}</h4>
      <h4 class="month-edited_marker" [class.month-edited]="monthsPickup[month.id]"></h4>
    </div>
    <i class="mdi mdi-chevron-right icona-arrow" (click)="generateMonths('right')"></i>
  </div>
  <div class="days-container" *ngIf="months && monthsPickuploaded">
    <div class="day-box"
         *ngFor="let day of calService.daysForCalendar; let i = index"
         (click)="selectDay(day,i)"
         [class.day-box-previous-month]="selectedMonth.format('MM')!=day.m"
         [class.selected-day]="day.y+''+day.m+''+day.d == calService.selectedDay">
      <h4 class="day" *ngIf="day.DoW != 0">{{"DAYS.SHORT." + (day.DoW) | translate}}</h4>
      <h4 class="day" *ngIf="day.DoW == 0">{{"DAYS.SHORT.7" | translate}}</h4>
      <h3 class="day-number">{{day.d}}</h3>
      <div class="day-edited-marker" [class.day-edited]="daysPickup[day.day]"></div>
    </div>
  </div>
</div>
