import { Component, ElementRef, ViewChild } from '@angular/core';
import { RestService } from '../../services/rest.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GuiService } from '../../services/gui.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { PermissionService } from '../../services/permission.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent {
  @ViewChild('otp-0') otp_0: ElementRef | undefined;
  @ViewChild('otp-1') otp_1: ElementRef | undefined;
  @ViewChild('otp-2') otp_2: ElementRef | undefined;
  @ViewChild('otp-3') otp_3: ElementRef | undefined;
  @ViewChild('otp-4') otp_4: ElementRef | undefined;
  @ViewChild('otp-5') otp_5: ElementRef | undefined;

  isLoading: boolean = false;
  codeStep: number = 0;
  otpCode: Array<any> = [null, null, null, null, null, null];


  constructor(private route: ActivatedRoute, private restService: RestService, private router: Router, public guiService: GuiService, 
    public translate: TranslateService, private perms: PermissionService) {

  }

  ngOnInit(): void {
    
  }

  validateOTP() {
    this.isLoading = true;
    let otp = "";
    for(let k in this.otpCode) {
      otp += this.otpCode[k]+"";
    }
    this.perms.AUTH_INFO.otpCode = otp;

    console.debug(this.perms.AUTH_INFO)
    this.restService.doLogin('',this.perms.AUTH_INFO).subscribe({
      next: (ris:any)=>{
        this.perms.AUTH_INFO = {
          username: "",
          password: "",
          otpCode: ""
        };

        this.restService.tknSubj.next(ris["token"])
        let tkn = ris["token"]
        sessionStorage.setItem("tkn", tkn);

        this.restService.REMEMBER_ME ? this.setRememberMe() : this.userInfo();
      },error: (e)=>{

        this.isLoading = false;
        Swal.fire({
          title: 'Ops!',
          text: "Le credenziali sono errate",
          icon: 'error'
        });

      }

    });
  }

  userInfo(){
    this.restService.getService('users','/current').subscribe({
      next: (ris:any)=>{
        if(this.guiService.decodeToken()){
          this.guiService.userLogged = this.guiService.decodeToken();
            let role = this.guiService.userLogged.roles;
            this.checkRole(role);
        }
        sessionStorage.setItem('userId',ris.id)
      },error: (e)=>{
        console.debug('error',e)
      }
    })
  }

  checkRole(role: string){
    this.guiService.loadMenuItems();
    if(role == 'SUPERADMIN,'){
      this.router.navigate(['admin/dashboard']);
    } else {
      this.router.navigate(['admin/dashboard']);
    }
  }

  setRememberMe() {
    this.restService.postService("remember_me","/generate",{}).subscribe({
      next: (ris) => {
        console.debug("RIS",ris);
        this.restService.REMEMBER_ME.appkey = ris.token;

        localStorage.setItem("appremre",btoa(JSON.stringify(this.restService.REMEMBER_ME)));
      },
      error: (err) => {
        console.debug("ERR",err);
      }
    })

    this.userInfo();
  }

  sendNewOTP() {

    this.isLoading = true;
    let login = {
      username: this.perms.AUTH_INFO.username,
      password: this.perms.AUTH_INFO.password
    }
    console.debug(login)
    this.restService.doLogin('',login).subscribe({
      next: (ris:any)=>{
        // NOTHING
      },error: (e)=>{

        // GESTIONE AUTENTICAZIONE OTP
        if(e.status == 404 && e.error && e.error.error) {
          let emsg = e.error.error;

          if(emsg.message == "otp required") {
            this.isLoading = false;
            Swal.fire({
              title: 'OK',
              text: this.translate.instant("AUTH.NEW_CODE_SENT"),
              icon: 'success'
            });
          }
        } else {

          this.isLoading = false;
          Swal.fire({
            title: 'Ops!',
            text: "Le credenziali sono errate",
            icon: 'error'
          });

        }
      }

    })

  }

  autoFocus (step: number) { 
    setTimeout(()=>{

      if(this.otpCode[step]!=null) {
        this.codeStep = step+1;

        this.codeStep > 5 ? this.codeStep = 0 : null; 

        console.debug("CODE STEP",this.codeStep, document.getElementById("otp-"+this.codeStep));
        switch(this.codeStep) {
          case 0:
            setTimeout(()=>{
              document.getElementById("otp-0")?.focus();
            },200);
            break;
          case 1:
            setTimeout(()=>{
              document.getElementById("otp-1")?.focus();
            },200);
            break;
          case 2:
            setTimeout(()=>{
              document.getElementById("otp-2")?.focus();
            },200);
            break;
          case 3:
            setTimeout(()=>{
              document.getElementById("otp-3")?.focus();
            },200);
            break;
          case 4:
            setTimeout(()=>{
              document.getElementById("otp-4")?.focus();
            },200);
            break;
          case 5:
            setTimeout(()=>{
              document.getElementById("otp-5")?.focus();
            },200);
            break;
          default:
            setTimeout(()=>{
              document.getElementById("otp-0")?.focus();
            },500);
        }
      }
    }, 100);
  }

  pasteCode(e: ClipboardEvent) {
    let text = e.clipboardData?.getData('text');
    let ary: any = text?.split("");
    for(let i=1; i<6; i++) {
      this.otpCode[i] = ary[i]+"";
    }

    setTimeout(()=>{
      this.otpCode[0] = ary[0];
    },10);
  }

}
