import { LanguageService } from './../../../../core/services/language.service';
import {Component, SimpleChanges} from '@angular/core';
import {RestService} from "../../../../core/services/rest.service";
import {ActivatedRoute, Router} from "@angular/router";
import {StructureService} from "../../../../core/services/structure.service";
import {GuiService} from "../../../../core/services/gui.service";
import {TranslateService} from "@ngx-translate/core";
import { CurrencyListConst } from '../../../../core/model/currency-list.const';

@Component({
  selector: 'app-structure-configuration-main',
  templateUrl: './structure-configuration-main.component.html',
  styleUrls: ['./structure-configuration-main.component.scss']
})
export class StructureConfigurationMainComponent {
  currencies: any = CurrencyListConst.currencylist;
  currency_list: Array<any> = [];

  constructor(private restService: RestService,private route: ActivatedRoute, public structureService: StructureService,private router: Router,public guiService: GuiService, public translate: TranslateService, public language: LanguageService) {
  }
  ngOnInit(): void {
    !this.structureService.selectedStructureConfig.closure_hour.value ? this.structureService.selectedStructureConfig.closure_hour.value = "22" : null;
    !this.structureService.selectedStructureConfig.auto_canc.value ? this.structureService.selectedStructureConfig.auto_canc.value = "1" : null;
    !this.structureService.selectedStructureConfig.vo.value ? this.structureService.selectedStructureConfig.vo.value = "1" : null;
    !this.structureService.selectedStructureConfig.TIME_MACHINE_ACTIVE.value ? this.structureService.selectedStructureConfig.TIME_MACHINE_ACTIVE.value="1" : null
    this.prepareCurrencyList();
  }

  prepareCurrencyList() {
    this.currency_list = [];
    for(let k in this.currencies) {
      this.currency_list.push(this.currencies[k]);
    }
  }

  save() {
    this.guiService.loaded = false;
    let param = [];
    for(let c in this.structureService.selectedStructureConfig){
      param.push(this.structureService.selectedStructureConfig[c]);
    }

    console.debug("PARAM CFG",param);

    this.restService.putService('structure_configuration', '/v2/update/'+this.structureService.selectedStructure.id+'/CALENDAR',param).subscribe((ris)=>{
      this.guiService.isConfigEdited = false;
      this.guiService.loaded = true;
      this.guiService.editedSuccesfully();
    })
  }
  reset() {}

  configKeyChange(k:string) {
    this.structureService.selectedStructureConfig[k].value == "0" ? this.structureService.selectedStructureConfig[k].value = "1" : this.structureService.selectedStructureConfig[k].value = "0";
  }
}
