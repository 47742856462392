<div class="page-title-box d-flex align-items-center justify-content-between">
  <h4 class="mb-0">Sales Channel List</h4>
</div>
<div class="col-xxl-12 col-xl-12">
  <div class="card card-h-100" *ngIf="channels">
    <div class="card-header row">
      <div class="col-sm-12 col-md-6 col-xl-8">
        <div class="row">
          <div class="form-floating col-3">
          <input type="text" class="form-control" [(ngModel)]="channelCtrl">
          <label>Search</label>
        </div>
        <div class="form-floating col-1">
          <button class="btn btn-primary" (click)="getChannels()">
            <i class="uil-search"></i>
          </button>
        </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4">
        <div class="row">
          <div class="col-6 d-flex justify-content-between align-items-start">
            <button class="btn btn-primary" (click)="newChannelEnable()">
              <i class="uil-plus"></i>
            </button>
            <button class="btn btn-primary" (click)="guiService.chooseItemView('CARD')"
              [class.selected]="guiService.viewType == 'CARD'">
              <i class="uil-table"></i>
            </button>
            <button class="btn btn-primary" [class.selected]="guiService.viewType == 'TABLE'"
              (click)="guiService.chooseItemView('TABLE')">
              <i class="uil-list-ul"></i>
            </button>
          </div>
          <ngb-pagination class="col-6 d-flex justify-content-end" (pageChange)="loadPage($event)" [(page)]="page" [pageSize]="size"
            [collectionSize]="channels.totalItems"></ngb-pagination>
        </div>
      </div>
      <div class="col-12">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs custom-tab" (click)="activeTab(active)">
          <li [ngbNavItem]="1">
            <button ngbNavLink>Tutte</button>
            <ng-template ngbNavContent>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <button ngbNavLink>Attive</button>
            <ng-template ngbNavContent>
            </ng-template>
          </li>
          <li [ngbNavItem]="3">
            <button ngbNavLink>Disattive</button>
            <ng-template ngbNavContent>
            </ng-template>
          </li>
        </ul>
      </div>
    </div><!-- end card header -->
    <div class="card-body row" *ngIf="channels">

      <div [ngClass]="showDetail ? 'col-sm-2 col-md-6' : 'col-sm-12 col-md-12'" >

        <!-- START TABLE VIEW -->
        <div class="table-responsive" *ngIf="guiService.viewType == 'TABLE'">
          <table class="table table-sm table-hover mb-0">
            <thead>
            <tr>
              <th></th>
              <th>Code</th>
              <th class="text-center">Nome</th>
              <th>Status</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let channel of channels.items">
              <th scope="row" [class.selected]="channelSelected && channel.id == channelSelected.id">#{{channel.id}}</th>
              <td [class.selected]="channelSelected && channel.id == channelSelected.id" class="name-color"><a (click)="detailChannel(channel)">{{channel.code}}</a></td>
              <td [class.selected]="channelSelected && channel.id == channelSelected.id" class="text-center">{{channel.description}}</td>
              <td [class.selected]="channelSelected && channel.id == channelSelected.id" class="text-center">
                <i class="uil-ban text-danger" *ngIf="!channel.enabled"></i>
                <i class="uil-thumbs-up text-success" *ngIf="channel.enabled"></i>
              </td>
              <td [class.selected]="channelSelected && channel.id == channelSelected.id">
                <div class="text-end mb-1" ngbDropdown>
                  <i class="uil-cog m-0" ngbDropdownToggle id="dropdownBasic0"></i>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic0">
                    <button ngbDropdownItem (click)="detailChannel(channel)">{{ "FX.edit" | translate }}</button>
                    <button ngbDropdownItem (click)="disableChannel(channel)">{{ "FX.disable" | translate }}</button>
                  </div>
                </div>
              </td>
            </tr>
            </tbody><!-- end tbody -->
          </table><!-- end table -->
        </div><!-- end table responsive -->


        <!-- START CARD VIEW -->
        <div class="card-group justify-content-center" *ngIf="guiService.viewType == 'CARD'">
          <div class="col-xl-3 col-xxl-2 col-sm-3 col-md-3 card-body-structure-wrapper" *ngFor="let channel of channels.items">
            <div class="card-body-structure" [class.selected]="channelSelected && channel.id == channelSelected.id">
              <div class="action-btn">
                <div class="text-end mb-1" ngbDropdown>
                  <i class="uil-cog m-0" ngbDropdownToggle id="dropdownBasic2"></i>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                    <button ngbDropdownItem (click)="detailChannel(channel)">{{ "FX.edit" | translate }}</button>
                    <button ngbDropdownItem (click)="disableChannel(channel)">{{ "FX.disable" | translate }}</button>
                  </div>
                </div>
              </div>

              <h3 class="pid-area">#{{channel.id}}</h3>

              <div class="titolo d-flex justify-content-between">
                <h1><a (click)="detailChannel(channel)">{{channel.code}}</a></h1>
              </div>
              <div class="body-card">
                <div class=""><h3>Code:</h3>
                  <p>{{channel.code}}</p></div>
                <div class=""><h3>Nome:</h3>
                  <p>{{channel.description}}</p></div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-10 col-md-6" *ngIf="showDetail">
        <app-sales-channel-detail [channel]="channelSelected" *ngIf="newChannel == false"></app-sales-channel-detail>
        <app-sales-channel-detail  [channel]="{}" *ngIf="newChannel"></app-sales-channel-detail>
      </div>
    </div><!-- end card body -->

  </div><!-- end card -->
</div>
