import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RestService } from './rest.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  baseURL = environment.baseURL;
  tkn: any = "";
  lang = "";
  baseOPT = {};

  constructor(private restService: RestService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.tkn = this.restService.getTkn()
    this.tkn && this.tkn!="" ? null : this.tkn = sessionStorage.getItem("tkn");
    req = req.clone({
      setHeaders: {
        'Access-Control-Allow-Origin':'*',
        'Accept': 'application/json'
      },
    });

    let pathname: any = [];
    req.url ? pathname = req.url.split("/") : null;

    if(pathname.indexOf("reportv4")>0){
      req = req.clone({
        setHeaders: {
          'Access-Control-Allow-Origin':'*',
          'Accept': 'application/octet-stream',
          'Content-Type': 'application/octet-stream'
        },
      });
    }

    if(pathname.indexOf("jwt")>0 || pathname.indexOf("rev_push")>0){
      req = req.clone({
        headers: req.headers.delete('Authorization')
      })
    }else{
      req = req.clone({
      headers: req.headers.set('Authorization', 'Bearer '+this.tkn)
    });
    }



    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.debug('event--->>>', event);
        }
        return event;
      })
  );
  }
}
