<div class="row align-items-start">
    <div class="col-11 box-main-form">

      <div *ngIf="structureService.selectedStructureConfig && structureService.selectedStructureConfig.setup_phase">

        <div class="row mb-4">
            <div class="col-12">
              <label class="config-item">
                <h3 class="d-inline-block">{{"CFG.pms" | translate}}</h3>
                <ng-select [clearable]="false" placeholder="{{'CFG.pms' | translate}}" [(ngModel)]="pmsSelected" class="d-inline-block">
                  <ng-option value="">{{ "CFG.no_valid_pms" | translate }}</ng-option>
                  <ng-option *ngFor="let item of pms" [value]="item.value">{{item.name}}</ng-option>
                </ng-select>
              </label>
            </div>
        </div>
        
        
        <div class="row mb-4" *ngIf="pmsSelected=='ZAK'">
          <div class="col-2">
            <label class="config-item">
              <h3 class="d-inline-block">{{"CFG.ZAK_API_KEY" | translate}}</h3>
            </label>
          </div>
          <div class="col-10 form-floating">
            <input class="form-control" type="text" [(ngModel)]="structureService.selectedStructureConfig.ZAK_API_KEY.value" />
          </div>
        </div>

        <span *ngIf="structureService.selectedStructureConfig.pms && structureService.selectedStructureConfig.pms.value !== '' && structureService.selectedStructureConfig.pms.value !== null">
        <div class="row mb-4">
          <div class="col-2">
            <label class="config-item">
              <h3 class="d-inline-block">{{"CFG.PMS_TIME_SLOT" | translate}}</h3>
            </label>
          </div>
          <div class="col-2 form-floating">
            <input class="form-control" type="time" [(ngModel)]="timeToAdd" (keyup.enter)="addChipTimeSlot()">
            <small>Insert text and press "ENTER"</small>
          </div>
          <div class="col-1">
            <button class="btn btn-outline-secondary" (click)="addChipTimeSlot()">
              <i class="uil-plus col-1" ></i>
            </button>
          </div>
          <div class="chip col-7" *ngFor="let resource of timeSlots; let k = index">
            <h2>{{resource}}</h2>
            <i class="uil-times-square chip-icon" (click)="removeChipTimeSlot(k)"></i>
          </div>
        </div>




        <div class="row mb-4">
          <div class="col-2">
            <label class="config-item">
              <h3 class="d-inline-block">{{"CFG.PMS_CREDENTIAL" | translate}}</h3>
            </label>
          </div>
          <div class="col-2 form-floating">
            <input class="form-control" type="text" [(ngModel)]="PMS_CREDENTIAL.username" />
            <small>{{"CFG.PMS_CREDENTIAL_USER" | translate}}</small>
          </div>
          <div class="col-2 form-floating">
            <input class="form-control" type="password" [(ngModel)]="PMS_CREDENTIAL.password" />
            <small>{{"CFG.PMS_CREDENTIAL_PSW" | translate}}</small>
          </div>
          <div class="col-6">
            <button class="btn btn-primary" (click)="savePMSCredential()">
              <i class="uil-plus" >{{ "GENERIC.save_btn" | translate }}</i>
            </button>
            <button class="btn btn-danger" (click)="deletePMSCredential()">
              <i class="uil-trash-alt" >{{ "GENERIC.delete_btn_lowercase" | translate }}</i>
            </button>
          </div>
        </div>


        <div class="row mb-4">
            <div class="col-12">
              <label class="config-item">
                <h3 class="d-inline-block">{{"CFG.IMPORT_TOT_OCC_CALC_MODE" | translate}}</h3>
                <ng-select [clearable]="false" placeholder="{{'CFG.IMPORT_TOT_OCC_CALC_MODE' | translate}}" [(ngModel)]="modeSelected" class="d-inline-block">
                  <ng-option *ngFor="let item of calc_mode" [value]="item.value">{{item.name}}</ng-option>
                </ng-select>
              </label>
            </div>
        </div>

        <div class="row mb-4" *ngIf="modeSelected=='CUSTOMIZZATA'">
          <div class="col-12">
            <label class="config-item">
              <h3 class="d-inline-block">
                {{"CFG.tot_occ_rooms" | translate}}<br>
                <small>Insert text and press "ENTER"</small>
              </h3>
              <div class="d-inline-block chip-insert">
                <input class="form-control" type="text" id="occ_tot_rt" [(ngModel)]="stringWrote['_occ_tot_rt']" (keyup.enter)="addChipRoomInOccTot('occ_tot_rt')">
              </div>
              <div class="d-inline-block btn-add" (click)="addChipRoomInOccTot('occ_tot_rt')">
                <i class="uil-plus" ></i>
              </div>
              <div class="chip d-inline-block" *ngFor="let resource of occ_resources_full; let k = index">
                <h2>{{resource}}</h2>
                <i class="uil-times-square chip-icon" (click)="removeChipRoomInOccTot('occ_tot_rt')"></i>
              </div>
            </label>
          </div>
        </div>
        <ul ngbNav #customNav="ngbNav" [activeId]="activeTab" class="nav nav-tabs nav-tabs-custom">
          <li [ngbNavItem]="1">
              <a ngbNavLink>
                  <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                  <span class="d-none d-sm-block">Tipologie</span>
              </a>
              <ng-template ngbNavContent>
                  <div class="content">
                    <div class="row mb-4" *ngFor="let room of rooms; let i = index">
                      <h4 class="col-1">{{room.title}}</h4>
                      <div class="col-2 form-floating">
                        <input class="form-control" type="text" id="rt_code" [(ngModel)]="stringWrote['_'+room.id]" (keyup.enter)="addChipRoom(room.id,i)">
                        <label for="rt_code">Type</label>
                        <small>Insert text and press "ENTER"</small>
                      </div>
                      <div class="col-1">
                        <button class="btn btn-outline-secondary" (click)="addChipRoom(room.id,i)">
                          <i class="uil-plus col-1" ></i>
                        </button>
                      </div>
                      <div class="chip col-5" *ngFor="let resource of room.resources_full; let k = index">
                        <h2>{{resource}}</h2>
                        <i class="uil-times-square chip-icon" (click)="removeChipRoom(k,i)"></i>
                      </div>
                    </div>
                  </div>
              </ng-template>
          </li>
          <li [ngbNavItem]="2">
              <a ngbNavLink>
                  <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                  <span class="d-none d-sm-block">Canali</span>
              </a>
              <ng-template ngbNavContent>
                <div class="content">
                  <div class="row mb-4" *ngFor="let channel of channels; let i = index">
                    <h4 class="col-1">{{channel.code}}</h4>
                    <div class="col-2 form-floating">
                      <input class="form-control" type="text" id="sc_code" [(ngModel)]="stringWrote2['_'+channel.id]" (keyup.enter)="addChipChannel(channel.id,i)">
                      <label for="sc_code">Type</label>
                      <small>Insert text and press "ENTER"</small>
                    </div>
                    <div class="col-1">
                        <button class="btn btn-outline-secondary" (click)="addChipChannel(channel.id,i)">
                          <i class="uil-plus col-1" ></i>
                        </button>
                      </div>
                    <div class="chip col-5" *ngFor="let resource of channel.resources_full; let k = index">
                      <h2>{{resource}}</h2>
                      <i class="uil-times-square chip-icon" (click)="removeChipChannel(k,i)"></i>
                    </div>
                  </div>
                </div>
              </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="customNav"></div>
        </span>
      </div>

    </div>
  <div class="col-1 btn-group-vertical" role="group">

    <button class="btn-send" (click)="sendPms()">
      <span class="uil-folder-check"></span>
      <h1>save</h1>
    </button>
    <button class="btn-delete" (click)="clear()">
      <span class="uil-trash"></span>
      <h1>Clear</h1>
    </button>
  </div>
</div>

