import { RestService } from './../../core/services/rest.service';
import { SettingsComponent } from '../../pages/left-side/settings.component';
import { StructureService } from './../../core/services/structure.service';
import { Component, OnInit, EventEmitter, Output, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';

import { LanguageService } from '../../core/services/language.service';
import { environment } from '../../../environments/environment';
import { GuiService } from 'src/app/core/services/gui.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from "sweetalert2";
import {CalendarService} from "../../core/services/calendar.service";
import { InfoStructureComponent } from 'src/app/pages/shared/info-structure/info-structure.component';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar Component
 */
export class TopbarComponent implements OnInit {
  @ViewChild('searchSelect', {static: false}) searchSelect: any;
  mode: string | undefined;
  element: any;
  flagvalue: any;
  cookieValue: any;
  countryName: any;
  valueset: any;

  /*AREA VARS*/
  selectedArea: any = 'principale';

  /* LOADING SEARCH */
  loadingSearch: boolean = false;
  searchStructureEnable: boolean = false;

  @Output() mobileMenuButtonClicked = new EventEmitter();

  constructor(
    private router: Router,
    public languageService: LanguageService,
    public _cookiesService: CookieService,
    public translate: TranslateService,
    public guiService: GuiService,
    public structureService: StructureService,
    private modalService: NgbModal,
    private restService: RestService,
    public calService: CalendarService
    ) { }

  /***
   * Language Listing
  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'French', flag: 'assets/images/flags/french.jpg', lang: 'fr' },
  ];
   */
  listLang = [
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' }
  ];

  @Output() settingsButtonClicked = new EventEmitter();

  ngOnInit(): void {
    // Cookies wise Language set
    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/italy.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }

    if(sessionStorage.getItem('selected-structure')) {
      this.structureService.structures = [JSON.parse(sessionStorage.getItem('selected-structure')+"")];
    }

    this.structureService.isStructureChanged.subscribe( value => {
      if(value == true){
      this.structureService.structures = [JSON.parse(sessionStorage.getItem('selected-structure')|| '{}')];
      }
  });

    this.guiService.selectMenuItems(this.selectedArea)
    this.guiService.selectedArea.subscribe(area => this.selectedArea = area);
  }

  /***
   * Language Value Set
   */
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    console.debug(lang)
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    let msg = "";
    console.debug("REMEMBER-ME", "1 "+this.restService.REMEMBER_ME.selected, "2 "+this.restService.REMEMBER_ME.appkey);

    if(this.restService.REMEMBER_ME.selected && this.restService.REMEMBER_ME.appkey!="") {
      msg += this.translate.instant("GENERIC.logout_remember_me") + " " + this.translate.instant("GENERIC.logout");
    } else {
      msg += this.translate.instant("GENERIC.logout");
    }

    Swal.fire({
      title: this.translate.instant("ALERTS.warning"),
      text: msg,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '"#3085d6',
      cancelButtonColor: '"#d33',
      cancelButtonText: this.translate.instant("GENERIC.logout_no"),
      confirmButtonText: this.translate.instant("GENERIC.logout_yes"),
    }).then((result) => {
      console.debug("---->",result.isConfirmed);
      if (result.isConfirmed) {
        this.restService.doLogout();
      }
    });
  }

  enableSearchStructure() {
    this.searchStructureEnable = true;
    this.structureService.selectedStructure = null;

    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      this.searchSelect.focus();
    },100);
  }

  selectStructure(selection:any){

    if(selection){
      this.structureService.selectedStructure = selection
      sessionStorage.setItem('selected-structure',JSON.stringify(selection))
      this.structureService.getStructurebyId(selection.id)
      this.structureService.isStructureChanged.next(true);

      this.router.navigate(['main/highlights'])
      .then(() => {
        window.location.reload();
      });
      
    }
  }

  resetSelectedStructure(){
    this.structureService.selectedStructure = JSON.parse(sessionStorage.getItem('selected-structure') || '');
    this.searchStructureEnable = false;

  }

  openSettings(){
    this.modalService.open(SettingsComponent,{ centered: true })
  }

  selectArea(link:any, area: any){
    if(!this.structureService.selectedStructure && link.condition) {
      Swal.fire({
        title: this.translate.instant("ALERTS.no_structure_selected"),
        icon: 'warning'
      })
      return false;
    }
    console.debug("AREA",area);
    this.guiService.changeArea(area);
    this.guiService.selectMenuItems(area);
    this.guiService.isSideBarChanged.next(true);

    this.router.navigate([link]);
  }

  search(event:any){

    if(event.term && event.term.length > 2){
      this.loadingSearch = true;
      this.restService.getService('structure',`/likeList/?query=${event.term}`).subscribe(ris =>{
        this.loadingSearch = false;
        this.structureService.structures = ris
        console.debug(this.structureService.structures);
      })
    }
  }

  setupModeChange() {
    this.structureService.updateStructureConfig("CALENDAR",this.structureService.selectedStructureConfig.setup_phase);
  }

  openInfoStr(){
    this.modalService.open(InfoStructureComponent, {size: 'xl'});
  }
}
