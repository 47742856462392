<div class="card mb-0">
  <div class="card-header">
    <h1 class="card-title text-center" *ngIf="this.selectedroom && this.selectedroom.title">{{this.selectedroom.title}}</h1>
    <h1 *ngIf="!this.selectedroom" class="text-center">{{"FX.room_type_create" | translate}}</h1>
    <i class="uil-times-square close-icon" (click)="close()"></i>
  </div><!-- end card header -->
  <form class="card-body">
    <div class="row">
      <div class="col-12">
        <div class="mb-3 row">
          <label for="title" class="col-md-2 col-form-label">Codice *</label>
          <div class="col-md-10">
            <input class="form-control" type="text" name="title" [disabled]="selectedroom" [(ngModel)]="room.code">
            <small id="title">Codice alfanumerico (se channel abilitato max 4 caratteri Es: XXXX)</small>
          </div>
        </div><!-- end row -->
        <div class="mb-3 row">
          <label for="name" class="col-md-2 col-form-label">Nome *</label>
          <div class="col-md-10">
            <input class="form-control" type="text" name="name" [(ngModel)]="room.name">
          </div>
        </div><!-- end row -->
        <div class="mb-3 row">
          <label for="parent" class="col-md-2 col-form-label">Principale</label>
          <div class="col-md-10">
            <ng-select [clearable]="false" [(ngModel)]="room.idParentRoomType" class="select-control" name="idParentRoomType">
              <ng-option value=""></ng-option>
              <ng-option *ngFor="let item of rooms" [value]="item.id">{{item.name}}</ng-option>
            </ng-select>
            <small id="parent">Selezionare una camera principale se la tipologia che si sta creando/modificando è una
              camera figlia</small>
          </div>
        </div><!-- end row -->
        <div class="mb-3 row">
          <label for="baseBeds" class="col-md-2 col-form-label">{{ "GENERIC.str_enabled" | translate}}</label>
          <div class="col-md-10"><div dir="ltr" class="form-check form-switch mb-2">
              <input type="checkbox" name="enabledroom" class="form-check-input" [(ngModel)]="room.enabled">
            </div>
          </div>
        </div><!-- end row -->
        <div class="mb-3 row">
          <label for="baseBeds" class="col-md-2 col-form-label">Posti letto base</label>
          <div class="col-md-10">
            <input class="form-control" type="text" name="baseBeds" [(ngModel)]="room.baseBeds">
            <small id="baseBeds">Necessario per collegamento con channel</small>
          </div>
        </div><!-- end row -->
        <div class="mb-3 row">
          <label for="defaultAvailability" class="col-md-2 col-form-label">Disp. base</label>
          <div class="col-md-10">
            <input class="form-control" type="text" name="defaultAvailability" [(ngModel)]="room.defaultAvailability">
            <small id="defaultAvailability">Necessario per collegamento con channel</small>
          </div>
        </div><!-- end row -->
        <div class="mb-3 row">
          <label for="defaultPrice" class="col-md-2 col-form-label">Prezzo base</label>
          <div class="col-md-10">
            <input class="form-control" type="text" name="defaultPrice" [(ngModel)]="room.defaultPrice">
            <small id="defaultPrice">Necessario per collegamento con channel</small>
          </div>
        </div><!-- end row -->
        <div class="mb-3 row">
          <label for="description" class="col-md-2 col-form-label" required>Descrizione</label>
          <div class="col-md-10">
            <textarea class="form-control" type="text" name="description" [(ngModel)]="room.description"></textarea>
          </div>
        </div><!-- end row -->
      </div><!-- end col -->
    </div><!-- end row -->
  </form><!-- end card body -->
</div>
<div class="d-flex justify-content-center m-2">
  <button (click)="editRoom()" class="btn-edit" *ngIf="showBtnNew == false">Modifica</button>
  <button (click)="newRoom()" class="btn-new" *ngIf="showBtnNew == true">Crea</button>
</div>
