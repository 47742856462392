<div class="contenitore" *ngIf="calService.calendarDataDays!=null">
  <div class="box-day"
       *ngFor="let item of days_data; let d = index"
       id="{{d}}"
       [class.selected-day]="calService.selectedDay && (item.y+''+item.m+''+item.d) == calService.selectedDay"
       [class.pre-post-month]="calService.selectedDay && item.m != calService.monthSelectedForCalendar"
       [class.past-days]="calService.selectedDay && !calService.nowForCalendar.isSameOrBefore(item.day,'day')"
       [class.edited]="calService.editedDays.indexOf(item.day) >= 0">

    <div class="row"
         *ngIf="calService.calendarDataDays[item.day] && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY']">

      <div class="col-6">
        <div class="row row-custom">
          <div class="col-4 text-center day-tab" [class.tab-selected]="guiService.calendarTabSelected == 'rooms'"
               (click)="selectTab('rooms')">
            <span>{{ "PRICING.rooms" | translate }}</span>
          </div>
          <div class="col-4 text-center day-tab" [class.tab-selected]="guiService.calendarTabSelected == 'channels'"
               (click)="selectTab('channels')">
            <span>{{ "FX.saleschannels" | translate }}</span>
          </div>
          <div class="col-4">

          </div>
        </div>


        <!-- Start ROOMS code -->

        <div class="graphics" *ngIf="guiService.calendarTabSelected =='rooms'">
          <div class="row current-year mt-2">
            <div class="col-3 black">
              <span class='day' *ngIf="calService.calendarDataDays[item.day]">
                <span class="event-area" *ngFor="let ev of calService.calendarDataDays[item.day].events.current">
                  <i class="bx"
                     [ngClass]="{'bxs-star': !ev['preOrPostDay'], 'bx-star' : ev['preOrPostDay'] }"
                     ngbTooltip="{{ev.name}}" placement="top" triggers="click"
                     *ngIf="calService.calendarDataDays[item.day].events.current"></i>
                </span>
                {{'DAYS.SHORT.' + item.DoW | translate}} {{item.d}}-{{item.m}}-{{item.y}}
              </span>
            </div>
            <div class="col-9">
              <div class="main-data" *ngIf="rooms && !loadingCalandarData">
                <div *ngIf="!calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current"><span class="no-data-day">{{ "GENERIC.no_data" | translate }} </span></div>
                <div *ngIf="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current"><span class="no-data-day"></span>

                <div class="main-data-bg">
                  <!-- OCC LINE CURRENT YEAR -->
                  <span *ngFor="let room of rooms; let i = index">
                    <span class="main-data-inside"
                          ngbTooltip="{{room.name}}: {{calService.calendarDataDays[item.day].occupancies[room.title].current ? calService.calendarDataDays[item.day].occupancies[room.title].current.occupancy : ''}}"
                          placement="top"
                          *ngIf="calService.calendarDataDays[item.day].occupancies[room.title] && calService.calendarDataDays != null && calService.calendarDataDays[item.day].occupancies[room.title].current"
                          [style.width]="(100 / (capacity+(10*rooms.length)))*(calService.calendarDataDays[item.day].occupancies[room.title].current.occupancy+8)+'%'"
                          [style.background-color]="colorMap[i].color">
                          <a (click)="guiService.showSegmentInfo(item.day,room,calService.calendarDataDays[item.day].occupancies[room.title].current)">{{calService.calendarDataDays[item.day].occupancies[room.title].current ? calService.calendarDataDays[item.day].occupancies[room.title].current.occupancy : ''}}</a>
                    </span>
                    <span class="main-data-inside"
                          ngbTooltip="{{room.name}}: 0"
                          placement="bottom"
                          *ngIf="!calService.calendarDataDays[item.day].occupancies[room.title] || !calService.calendarDataDays[item.day].occupancies[room.title].current"
                          [style.width]="'calc('+(100*(2) /(capacity+(2*rooms.length)))+'% - 2px)'"
                          [style.background-color]="colorMap[i].color">0
                    </span>
                  </span>
                  <span class="inv-block"
                        *ngIf="calService.calendarDataDays != null &&
                        calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] &&
                        calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current &&
                        calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.inv">
                    <span
                     [class.overbooking]="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.inv<0">
                      {{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.inv}}
                    </span>
                  </span>
                </div>
              </div>

                <!-- PICKUP CURRENT YEAR -->
                <div class="pickup-row">
                  <span *ngFor="let room of rooms; let i = index">
                    <span class="main-data-inside"
                          *ngIf="calService.calendarDataDays != null && calService.calendarDataDays[item.day].occupancies[room.title] && calService.calendarDataDays[item.day].occupancies[room.title].current"
                          [style.width]="(100 / (capacity+(10*rooms.length)))*(calService.calendarDataDays[item.day].occupancies[room.title].current.occupancy+8)+'%'">
                      <span class="pick-up-less"
                        *ngIf="calService.calendarDataDays[item.day].occupancies[room.title].current && calService.calendarDataDays[item.day].occupancies[room.title].current.occupancyPickup != null && calService.calendarDataDays[item.day].occupancies[room.title].current.occupancyPickup<0">
                        {{calService.calendarDataDays[item.day].occupancies[room.title].current.occupancyPickup}}</span>
                      <span class="pick-up-more"
                        *ngIf="calService.calendarDataDays[item.day].occupancies[room.title].current && calService.calendarDataDays[item.day].occupancies[room.title].current.occupancyPickup != null && calService.calendarDataDays[item.day].occupancies[room.title].current.occupancyPickup>0">
                        {{calService.calendarDataDays[item.day].occupancies[room.title].current.occupancyPickup}}</span>
                    </span>
                    <span class="main-data-inside"
                          *ngIf="!calService.calendarDataDays[item.day].occupancies[room.title] || !calService.calendarDataDays[item.day].occupancies[room.title].current"
                          [style.width]="'calc('+(100*(2) /(capacity+(2*rooms.length)))+'% - 2px)'">&nbsp;
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row dynamic-h-year mt-1" *ngIf="calService.showDynamicHistory">
            <div class="col-3 pink second-date">
              <span class='day' *ngIf="calService.calendarDataDays[item.day]">
                <span class="event-area" *ngFor="let ev of calService.calendarDataDays[item.day].events.dynHistoryc">
                  <i class="bx" [ngClass]="{'bxs-star': !ev['preOrPostDay'], 'bx-star' : ev['preOrPostDay'] }"
                     ngbTooltip="{{ev.name}}" placement="top" triggers="click"
                     *ngIf="calService.calendarDataDays[item.day].events.current"></i>
                </span>
                {{'DAYS.SHORT.' + item.dynDoW | translate}} {{item.dynday}}
              </span>
            </div>
            <div class="col-9">
              <div class="main-data" *ngIf="rooms && !loadingCalandarData">
                <div *ngIf="!calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric"><span class="no-data-day">{{ "GENERIC.no_data" | translate }} </span></div>
                <div *ngIf="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric" class="main-data-bg">

                  <!-- OCC LINE DYNAMIC HISTORY YEAR -->
                  <span *ngFor="let room of rooms; let i = index">
                    <span class="main-data-inside"
                          ngbTooltip="{{room.name}}: {{calService.calendarDataDays[item.day].occupancies[room.title].current.occupancy}}"
                          placement="bottom"
                          *ngIf="calService.calendarDataDays != null && calService.calendarDataDays[item.day].occupancies[room.title] && calService.calendarDataDays[item.day].occupancies[room.title].dynHistoric"
                          [style.width]="(100 / (capacity+(10*rooms.length)))*(calService.calendarDataDays[item.day].occupancies[room.title].current.occupancy+8)+'%'"
                          [style.background-color]="colorMap[i].color">
                          {{calService.calendarDataDays[item.day].occupancies[room.title].current.occupancy}}
                    </span>
                    <span class="main-data-inside"
                          ngbTooltip="{{room.name}}: 0"
                          placement="bottom"
                          *ngIf="!calService.calendarDataDays[item.day].occupancies[room.title] || !calService.calendarDataDays[item.day].occupancies[room.title].dynHistoric"
                          [style.width]="'calc('+(100*(2) /(capacity+(2*rooms.length)))+'% - 2px)'"
                          [style.background-color]="colorMap[i].color">0
                    </span>
                  </span>
                  <span class="inv-block"
                        *ngIf="calService.calendarDataDays != null && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY']">
                    <span [class.overbooking]="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.inv<0">
                      {{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.inv}}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row last-year mt-1">
            <div class="col-3 blue">
              <span class='day' *ngIf="calService.calendarDataDays[item.day]">
                <span class="event-area" *ngFor="let ev of calService.calendarDataDays[item.day].events.statHistoric">
                  <i class="bx" [ngClass]="{'bxs-star': !ev['preOrPostDay'], 'bx-star' : ev['preOrPostDay'] }"
                     ngbTooltip="{{ev.name}}" placement="top" triggers="click"
                     *ngIf="calService.calendarDataDays[item.day].events.current"></i>
                </span>
                {{'DAYS.SHORT.' + item.staDoW | translate}} {{item.staday}}
              </span>
            </div>
            <div class="col-9">
              <div class="main-data" *ngIf="rooms && !loadingCalandarData">
                <div *ngIf="!calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric"><span class="no-data-day">{{ "GENERIC.no_data" | translate }} </span></div>
                <div *ngIf="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric" class="main-data-bg">
                  <!-- OCC LINE STATIC HISTORY YEAR -->
                  <span *ngFor="let room of rooms; let i = index">
                    <span class="main-data-inside"
                          ngbTooltip="{{room.name}}: {{calService.calendarDataDays[item.day].occupancies[room.title].statHistoric.occupancy}}"
                          placement="bottom"
                          *ngIf="calService.calendarDataDays != null && calService.calendarDataDays[item.day].occupancies[room.title] && calService.calendarDataDays[item.day].occupancies[room.title].statHistoric"
                          [style.width]="(100 / (capacity+(10*rooms.length)))*(calService.calendarDataDays[item.day].occupancies[room.title].statHistoric.occupancy+8)+'%'"
                          [style.background-color]="colorMap[i].color">
                          {{calService.calendarDataDays[item.day].occupancies[room.title].statHistoric.occupancy}}
                    </span>
                    <span class="main-data-inside"
                          ngbTooltip="{{room.name}}: 0"
                          placement="bottom"
                          *ngIf="!calService.calendarDataDays[item.day].occupancies[room.title] || !calService.calendarDataDays[item.day].occupancies[room.title].statHistoric"
                          [style.width]="'calc('+(100*(2) /(capacity+(2*rooms.length)))+'% - 2px)'"
                          [style.background-color]="colorMap[i].color">0
                    </span>
                  </span>
                  <span class="inv-block"
                        *ngIf="calService.calendarDataDays != null && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY']">
                    <span>{{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric.inv}}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Start SALES CHANNEL code -->

        <div class="graphics" *ngIf="guiService.calendarTabSelected =='channels'">
          <div class="row current-year mt-2">
            <div class="col-3 black">
              <span class='day' *ngIf="calService.calendarDataDays[item.day]">
                <span class="event-area" *ngFor="let ev of calService.calendarDataDays[item.day].events.current">
                  <i class="bx" [ngClass]="{'bxs-star': !ev['preOrPostDay'], 'bx-star' : ev['preOrPostDay'] }"
                     ngbTooltip="{{ev.name}}" placement="top"
                     *ngIf="calService.calendarDataDays[item.day].events.current"></i>
                </span>
                {{'DAYS.SHORT.' + item.DoW | translate}} {{item.d}}-{{item.m}}-{{item.y}}
              </span>
            </div>
            <div class="col-9">
              <div class="main-data" *ngIf="channels && !loadingCalandarData">
                <div class="main-data-bg">
                  <!-- OCC LINE CURRENT YEAR -->
                  <span *ngFor="let channel of channels; let i = index">
                    <span class="main-data-inside"
                          ngbTooltip="{{channel.code}}: {{calService.calendarDataDays[item.day].occupancies[channel.code].current ? calService.calendarDataDays[item.day].occupancies[channel.code].current.occupancy : ''}}"
                          placement="top"
                          *ngIf="calService.calendarDataDays[item.day].occupancies[channel.code] && calService.calendarDataDays[item.day].occupancies[channel.code].current"
                          [style.width]="'calc('+(100*(calService.calendarDataDays[item.day].occupancies[channel.code].current.occupancy+2) / (capacity+(2*channels.length)))+'% - 2px)'"
                          [style.background-color]="colorMap[i].color">
                          {{calService.calendarDataDays[item.day].occupancies[channel.code].current ? calService.calendarDataDays[item.day].occupancies[channel.code].current.occupancy : ''}}
                    </span>
                    <span class="main-data-inside"
                          ngbTooltip="{{channel.name}}: 0"
                          placement="bottom"
                          *ngIf="!calService.calendarDataDays[item.day].occupancies[channel.code] || !calService.calendarDataDays[item.day].occupancies[channel.code].current"
                          [style.width]="'calc('+(100*(2) /(capacity+(2*channel.length)))+'% - 2px)'"
                          [style.background-color]="colorMap[i].color">0
                    </span>
                  </span>
                  <span class="inv-block"
                        *ngIf="calService.calendarDataDays != null && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY']">
                    <span>{{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current ? calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.inv : ''}}</span>
                  </span>
                </div>

                <!-- PICKUP CURRENT YEAR -->
                <div class="pickup-row">
                  <span *ngFor="let channel of channels; let i = index">
                    <span class="main-data-inside"
                      *ngIf="calService.calendarDataDays != null && calService.calendarDataDays[item.day].occupancies[channel.code] && calService.calendarDataDays[item.day].occupancies[channel.code].current"
                      [style.width]="'calc('+(100*(calService.calendarDataDays[item.day].occupancies[channel.code].current.occupancy+2) /(capacity+(2*channels.length)))+'% - 2px)'">
                      <span class="pick-up-less"
                            *ngIf="calService.calendarDataDays[item.day].occupancies[channel.code].current.vrminus != null && calService.calendarDataDays[item.day].occupancies[channel.code].current.vrminus>0">
                        {{calService.calendarDataDays[item.day].occupancies[channel.code].current.vrminus}}</span>
                      <span class="pick-up-more"
                            *ngIf="calService.calendarDataDays[item.day].occupancies[channel.code].current.vrplus != null && calService.calendarDataDays[item.day].occupancies[channel.code].current.vrplus>0">
                        {{calService.calendarDataDays[item.day].occupancies[channel.code].current.vrplus}}</span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row dynamic-h-year mt-1">
            <div class="col-3 pink second-date">
              <span class='day' *ngIf="calService.calendarDataDays[item.day]">
                <span class="event-area" *ngFor="let ev of calService.calendarDataDays[item.day].events.dynHistoryc">
                  <i class="bx" [ngClass]="{'bxs-star': !ev['preOrPostDay'], 'bx-star' : ev['preOrPostDay'] }"
                     ngbTooltip="{{ev.name}}" placement="top"
                     *ngIf="calService.calendarDataDays[item.day].events.current"></i>
                </span>
                {{'DAYS.SHORT.' + item.dynDoW | translate}} {{item.dynday}}
              </span>
            </div>
            <div class="col-9">
              <div class="main-data" *ngIf="channels && !loadingCalandarData">
                <div *ngIf="!calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric"><span class="no-data-day">{{ "GENERIC.no_data" | translate }} </span></div>
                <div *ngIf="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric" class="main-data-bg">

                  <!-- OCC LINE DYNAMIC HISTORY YEAR -->
                  <span *ngFor="let channel of channels; let i = index">
                    <span class="main-data-inside"
                          ngbTooltip="{{channel.name}}: {{calService.calendarDataDays[item.day].occupancies[channel.code].dynHistoric.occupancy}}"
                          placement="bottom"
                          *ngIf="calService.calendarDataDays[item.day].occupancies[channel.code] && calService.calendarDataDays[item.day].occupancies[channel.code].dynHistoric"
                          [style.width]="'calc('+(100*(calService.calendarDataDays[item.day].occupancies[channel.code].dynHistoric.occupancy+2) /(capacity+(2*channels.length)))+'% - 2px)'"
                          [style.background-color]="colorMap[i].color">
                          {{calService.calendarDataDays[item.day].occupancies[channel.code].dynHistoric.occupancy}}
                    </span>
                    <span class="main-data-inside"
                          ngbTooltip="{{channel.name}}: 0"
                          placement="bottom"
                          *ngIf="!calService.calendarDataDays[item.day].occupancies[channel.code] || !calService.calendarDataDays[item.day].occupancies[channel.code].dynHistoric"
                          [style.width]="'calc('+(100*(2) /(capacity+(2*channel.length)))+'% - 2px)'"
                          [style.background-color]="colorMap[i].color">0
                    </span>
                  </span>
                  <span class="inv-block"
                        *ngIf="calService.calendarDataDays != null && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY']">
                    <span>{{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric.inv}}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row last-year mt-1">
            <div class="col-3 blue">
              <span class='day' *ngIf="calService.calendarDataDays[item.day]">
                <span class="event-area" *ngFor="let ev of calService.calendarDataDays[item.day].events.statHistoric">
                  <i class="bx" [ngClass]="{'bxs-star': !ev['preOrPostDay'], 'bx-star' : ev['preOrPostDay'] }"
                     ngbTooltip="{{ev.name}}" placement="top"
                     *ngIf="calService.calendarDataDays[item.day].events.current"></i>
                </span>
                {{'DAYS.SHORT.' + item.staDoW | translate}} {{item.staday}}
              </span>
            </div>
            <div class="col-9">
              <div class="main-data" *ngIf="rooms && !loadingCalandarData">
                <div *ngIf="!calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric">
                  <span class="no-data-day">{{ "GENERIC.no_data" | translate }} </span>
                </div>
                <div *ngIf="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric" class="main-data-bg">
                  <!-- OCC LINE STATIC HISTORY YEAR -->
                  <span *ngFor="let channel of channels; let i = index">
                    <span class="main-data-inside"
                          ngbTooltip="{{channel.name}}: {{calService.calendarDataDays[item.day].occupancies[channel.code].statHistoric.occupancy}}"
                          placement="bottom"
                          *ngIf="calService.calendarDataDays[item.day].occupancies[channel.code] && calService.calendarDataDays[item.day].occupancies[channel.code].statHistoric"
                          [style.width]="'calc('+(100*((calService.calendarDataDays[item.day].occupancies[channel.code].statHistoric.occupancy)+2) /(capacity+(2*channels.length)))+'% - 2px)'"
                          [style.background-color]="colorMap[i].color">
                          {{calService.calendarDataDays[item.day].occupancies[channel.code].statHistoric.occupancy}}
                    </span>
                    <span class="main-data-inside"
                          ngbTooltip="{{channel.name}}: 0"
                          placement="bottom"
                          *ngIf="!calService.calendarDataDays[item.day].occupancies[channel.code] || !calService.calendarDataDays[item.day].occupancies[channel.code].statHistoric"
                          [style.width]="'calc('+(100*(2) /(capacity+(2*channel.length)))+'% - 2px)'"
                          [style.background-color]="colorMap[i].color">0
                    </span>
                  </span>
                  <span class="inv-block"
                        *ngIf="calService.calendarDataDays != null && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY']">
                    <span>{{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric.inv}}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
      <div class="col-2 mid-box-container">
        <div class="col-4 d-inline-block text-center mid-box" [class.mid-box-no-dynamic-history]="!calService.showDynamicHistory">
          <h1> <a>OCC</a></h1>
          <h3>{{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current ? calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.occupancy : '/'}}</h3>
          <h4>
            <span class="pick-up-less"
                  *ngIf="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.occupancyPickup<0">
              {{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.occupancyPickup}}
            </span>
            <span class="pick-up-more"
                  *ngIf="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.occupancyPickup>0">
              {{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.occupancyPickup}}
            </span>
          </h4>
          <h3 class="pink" *ngIf="calService.showDynamicHistory">
            <span *ngIf="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric">
              {{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] ? calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric.occupancy : '/'}}
            </span>
          </h3>
          <h3 class="blue">
            <span *ngIf="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric">
              {{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] ? calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric.occupancy : '/'}}</span>
          </h3>

        </div>
        <div class="col-4 d-inline-block text-center mid-box" [class.mid-box-no-dynamic-history]="!calService.showDynamicHistory">
          <h1><a (click)="guiService.showDetailDayInfo(item.day,'WHOLE_OCCUPANCY',calService.calendarDataDays[item.day].occupancies)">RMC</a></h1>
          <h3 *ngIf="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.occupancy>0">
            {{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.revenue / calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.occupancy | currency :'EUR':'symbol':'1.2-2'}}
          </h3>
          <h3 *ngIf="!calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] || calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.occupancy<=0">0</h3>
          <h4>&nbsp;</h4>
          <h3 class="pink" *ngIf="calService.showDynamicHistory">
            <span *ngIf="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric.occupancy>0">
              {{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric.revenue / calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric.occupancy | currency :'EUR':'symbol':'1.2-2'}}
            </span>
          </h3>
          <h3 class="blue">
            <span *ngIf="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'] && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric.occupancy>0">
            {{calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric.revenue / calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric.occupancy | currency :'EUR':'symbol':'1.2-2'}}
            </span>
          </h3>
        </div>
        <div class="col-4 d-inline-block text-center mid-box" [class.mid-box-no-dynamic-history]="!calService.showDynamicHistory" [class.over-revpar]="checkOverRevPar(item)">
          <h1>RPar</h1>
          <h3 >{{(capacity>0 ? calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current && calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].current.revenue / capacity : '0') | currency :'EUR':'symbol':'1.2-2'}}</h3>
          <h4>&nbsp;</h4>
          <h3 class="pink" *ngIf="calService.showDynamicHistory">
            <span *ngIf="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric">
              {{(capacity>0 ?
              calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].dynHistoric.revenue / capacity : '0') | currency :
              'EUR':'symbol':'1.2-2'}}
            </span>
          </h3>
          <h3 class="blue">
            <span *ngIf="calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric">
            {{(capacity>0 ?
              calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY'].statHistoric.revenue / capacity : '0') | currency :
              'EUR':'symbol':'1.2-2'}}
            </span>
          </h3>
        </div>
      </div>
      <div class="input-box" [class.input-box-no-dynamic-history]="!calService.showDynamicHistory" [class.expand]="calService.expandArea == d">
        <div class="row">
          <div class="col-2"></div>
          <div class="col-1 black">VR(+/-)</div>
          <div class="col-3 black">Price</div>
          <div class="col-2 black" *ngIf="structureService.selectedStructureConfig.length && structureService.selectedStructureConfig['WUBOOK_SYNC_ACTIVE'].value=='1'">Avail</div>
          <div class="col-2 black" *ngIf="structureService.selectedStructureConfig.length && structureService.selectedStructureConfig['WUBOOK_SYNC_ACTIVE'].value=='1'">MS</div>
          <div class="col-2 black" *ngIf="structureService.selectedStructureConfig.length && structureService.selectedStructureConfig['WUBOOK_SYNC_ACTIVE'].value=='1'">Open</div>
        </div>
        <div class="row mb-1" *ngFor="let room of rooms; let i = index">
          <div class="col-2">
            <h1 [title]="room.title">{{room.title}}</h1>
            <h2 *ngIf="calService.calendarDataDays[item.day].rates[room.title].currentFormula">{{calService.calendarDataDays[item.day].rates[room.title].currentFormula}}</h2>
            <button class="btn btn-link btn-link-ai" [disabled]="isAiBtnPress===d"
              *ngIf="rateResult[item.day][room.title].current.showAi"
              (click)="loadAiRate(d, item.day,room.title)">
              <i class="bx bx-right-arrow-alt"></i> AI
            </button>
          </div>
          <div class="col-1">
            <h2 class="pick-up-more" *ngIf="calService.calendarDataDays[item.day].occupancies[room.title] && calService.calendarDataDays[item.day].occupancies[room.title].current">{{calService.calendarDataDays[item.day].occupancies[room.title].current.vrplus}}</h2>
            <h2 class="pick-up-less" *ngIf="calService.calendarDataDays[item.day].occupancies[room.title] && calService.calendarDataDays[item.day].occupancies[room.title].current">{{calService.calendarDataDays[item.day].occupancies[room.title].current.vrminus}}</h2>
          </div>
          <div class="col-3 position-relative">
            <input class="form-control" type="text"
             [disabled]="inputStatus(item.day)"
             [(ngModel)]="rateResult[item.day][room.title].current.rate"
             (change)="checkChanges(d,room.title,item.day)">
            <i class="alert-icon"
              [ngClass]="{
                'uil-exclamation-triangle error': !calService.calendarDataDays[item.day].rates[room.title],
                'uil-info-circle info': calService.calendarDataDays[item.day].rates[room.title].current && calService.calendarDataDays[item.day].rates[room.title].current.forced
              }" ></i>
          </div>
          <div class="col-2">
            <input *ngIf="structureService.selectedStructureConfig && structureService.selectedStructureConfig['WUBOOK_SYNC_ACTIVE'].value=='1'" class="form-control" type="text" [disabled]="inputStatus(item.day)">
          </div>
          <div class="col-2">
            <input *ngIf="structureService.selectedStructureConfig && structureService.selectedStructureConfig['WUBOOK_SYNC_ACTIVE'].value=='1'" class="form-control" type="text" [disabled]="inputStatus(item.day)">
          </div>
          <div class="col-2">
            <ui-switch *ngIf="structureService.selectedStructureConfig.length && structureService.selectedStructureConfig['WUBOOK_SYNC_ACTIVE'].value=='1'" defaultBoColor="#dfdfdf" color="#51d28c" class="ui-switch-custom"
               [disabled]="inputStatus(item.day)">
            </ui-switch>
          </div>
        </div>

      </div>

      <div ngbDropdown class="btn btn-action-day">
          <span class="table-icon" ngbDropdownToggle>
            <i *ngIf="daysprocessing.indexOf(item.day) < 0" class="uil-ellipsis-v"></i>
            <i *ngIf="daysprocessing.indexOf(item.day) >= 0" class="fas fa-spinner fa-spin"></i>
          </span>
        <div ngbDropdownMenu>
          <button ngbDropdownItem (click)="rateSave(item.day)" *ngIf="!(inputStatus(item.day))">
            {{ "GENERIC.save_btn" | translate }}
          </button>
          <button ngbDropdownItem (click)="calendarRateHistory(item.day)">{{ "PRICING.pricing_history" | translate }}</button>
          <button ngbDropdownItem (click)="guiService.showDetailDayRateInfo(item.day,calService.calendarDataDays[item.day].rates)">{{ "PRICING.pricing_compare" | translate }}</button>
        </div>
      </div>

      <a class="btn-expand-collapse" (click)="calService.expandCollapse(d)">
        {{ calService.expandArea == d ? 'RIDUCI' : 'ESPANDI'}}
        <i *ngIf="calService.expandArea == d" class="uil-minus-square"></i>
        <i *ngIf="calService.expandArea != d" class="uil-plus-square"></i>
      </a>
    </div>


    <!-- NO DATA -->
    <div class="row"
         *ngIf="!calService.calendarDataDays[item.day] || !calService.calendarDataDays[item.day].occupancies['WHOLE_OCCUPANCY']">
      <div class="col-6">
        <div class="graphics">
          <div class="row current-year mt-2">
            <div class="col-3 black">
              <span>
                {{'DAYS.SHORT.' + item.DoW | translate}} {{item.d}}-{{item.m}}-{{item.y}}
              </span>
            </div>
          </div>
          <div class="row dynamic-h-year mt-1" *ngIf="calService.showDynamicHistory">
            <div class="col-3 pink second-date">
              <span>
                {{'DAYS.SHORT.' + item.DoW | translate}} {{item.d}}-{{item.m}}-{{item.y}}
              </span>
            </div>
          </div>
          <div class="row last-year mt-1">
            <div class="col-3 blue">
              <span>
                {{'DAYS.SHORT.' + item.DoW | translate}} {{item.d}}-{{item.m}}-{{item.y}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="cal-data-empty">
          <span>{{ "GENERIC.no_data" | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!calService.calendarDataDays" class="main-page-loading">
  <div class="loading-bars-box">
    <div class="loading-bars"></div>
  </div>
</div>

