<div class="page-title-box d-flex align-items-center justify-content-between">
  <h4 class="mb-0">{{"FX.advancedhistory" | translate}}</h4>
</div>
<div class="col-xxl-12 col-xl-12" >
  <div class="card card-h-100" *ngIf="loaded">
    <div class="card-header justify-content-between d-flex align-items-center p-0">
      <div class="col-sm-12 col-md-12 text-center">
        <ngb-datepicker #dp 
        (dateSelect)="onDateSelection($event)" 
        [displayMonths]="manyMonths" 
        navigation="arrows"
        [maxDate]="now"
        [startDate]="startDate"
        [dayTemplate]="t" outsideDays="collapsed" />

        <ng-template #t let-date let-focused="focused">
          <span
            class="custom-day"
            [class.focused]="focused"
            [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)"
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null"
          >
            {{ date.day }}
          </span>
        </ng-template>
        <div class="p-2">
          <button type="button" class="btn btn-primary" (click)="getForecastHistory()" [disabled]="!fromDate || !toDate">
            <i class="uil uil-search me-2"></i> {{ "FX.view" | translate}}
          </button>
        </div>
      </div>
    </div><!-- end card header -->
    
    <div class="card-body">
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <ul id="events-list">
            <li *ngFor="let item of forecasts; let i = index">
              <h6 (click)="forecastDetail(i)">
                {{ item.forecastDatetime | date: "YYYY-MM-dd HH:mm:ss"}}
              </h6>
              <small>{{item.forecastEmailAuthor}}</small>

              <button type="button" class="btn btn-sm" [ngClass]="selectedForecast && selectedForecast.id==i ? 'btn-warning' : 'btn-link'" (click)="forecastDetail(i)">
                <i *ngIf="!selectedForecast || selectedForecast.id!=i" class="uil uil-circle"></i>
                <i *ngIf="selectedForecast && selectedForecast.id==i" class="uil uil-angle-right"></i>
              </button>
            </li>
          </ul>
        </div>
        <div class="col-sm-12 col-md-8">
          <ul id="events-detail" *ngIf="selectedForecast">
            <li *ngFor="let item of selectedForecast.data; let i = index">
              <h6>
                {{ item.dt | date: "YYYY-MM-dd"}}
              </h6>
              <div class="detail-badge">
                <span  *ngFor="let rate of item.rates; let i = index" class="badge badge-dark-subtle not-changed">
                  <div class="room-title">{{rate.roomCode}}</div>
                  <div class="rate-info">{{rate.rate}} <i class="uil uil-info-circle info" *ngIf="rate.forced"></i></div>
                </span>
              </div>
            </li>
            <li *ngIf="selectedForecast.data.length==0">
              <h6>
                {{ "GENERIC.no_data" | translate }}
              </h6>
            </li>
          </ul>
        </div>
      </div>


    </div>
    
    
    
    
    
    
    
    <!--div class="row justify-content-center">
      <div class="col-xl-12">
        <div class="verti-timeline">

          <span class="timeline-box-req" *ngFor="let item of timelineData">
            <small><h6 class="from-class-dt">{{item.to}}</h6></small>
              <span *ngFor="let data of item.data; let i = index">
                <div class="timeline-item" [ngClass]="(i % 2 == 0) ? 'left' : 'right'">
                  <div class="timeline-block">
                    <div class="timeline-box card">
                      <div class="card-body">
                        <div class="timeline-date">{{data.date | date: "dd/MM/YYYY HH:mm"}}</div>
                        <h5 class="title-update-tag">{{ "UPDATE_HISTORY."+data.type | translate }}</h5>
                        <div class="text-muted">
                          <div [innerHTML]="data.value"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </span>
            <small><h6 class="to-class-dt">{{item.from}}</h6></small>
          </span>

          <div class="timeline-item left">
            <div class="timeline-block">
              <div class="time-show-btn mt-0">
                <button (click)="loadMore()" class="btn-new">{{ "FX.load_more" | translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div-->
  </div>
  <div *ngIf="!loaded" class="main-page-loading">
    <div class="loading-bars-box">
      <div class="loading-bars"></div>
    </div>
  </div>
</div>
