import { Component } from '@angular/core';
import {GuiService} from "../../../core/services/gui.service";
import {RestService} from "../../../core/services/rest.service";
import {StructureService} from "../../../core/services/structure.service";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {CalendarEvent} from "../../../core/model/calendar-event";

@Component({
  selector: 'app-calendar-event',
  templateUrl: './calendar-event.component.html',
  styleUrls: ['./calendar-event.component.scss']
})
export class CalendarEventComponent {

  eventView: boolean = false;
  eventSelected: any = null;
  newEvent: boolean = false;
  page = 1
  size = 15;
  events: Array<CalendarEvent> = [];
  showDetail: boolean = false;
  loaded: boolean = false;

  constructor(public guiService: GuiService, private restService: RestService, public structureService: StructureService,private router: Router, public translate: TranslateService){
    this.newEvent = false

    this.guiService.closeDetail.subscribe( value => {
      if(value == true){
        this.eventSelected = null;
        this.showDetail = false;
      }
    });

    this.guiService.refreshList.subscribe( value => {
      if(value == true){
        this.page = 1
        this.getEvents()
      }
    });
  }

  ngOnInit(): void {
    this.getEvents()
  }

  getEvents(){
    this.restService.getService('calendar_events','/'+this.structureService.selectedStructure.id).subscribe(ris =>{
      this.events = ris;
      this.loaded = true;
    })
  }

  loadPage(event:any){
    this.page = event
    this.getEvents()
  }

  editEvent(item: CalendarEvent){
      this.eventSelected = item;
      this.newEvent = false;
      this.showDetail = true;
  }

  newEventEnable() {
    this.newEvent = true;
    this.eventSelected = {};
    this.showDetail = true;
  }

  deleteEvent(item: CalendarEvent) {
    this.guiService.deleteConfirm('calendar_events','/'+this.structureService.selectedStructure.id,item.id);
  }
}
