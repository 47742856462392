import { Component } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { RestService } from '../../services/rest.service';


@Component({
  selector: 'app-confirm-reset-password',
  templateUrl: './confirm-reset-password.component.html',
  styleUrls: ['./confirm-reset-password.component.scss']
})
export class ConfirmResetPasswordComponent {
  form!: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private restService: RestService,private router: Router){

  }

  ngOnInit(): void {
    this.form = this.fb.group({
      password: [null, Validators.required],
      confirm_password: [null, Validators.required]
    });
  }

  send(){
    if(this.form.valid){
        Swal.fire({
        title: 'Success!',
        text: "Abbiamo modificato la tua password con successo",
        icon: 'success'
        });
    }


  }
}
