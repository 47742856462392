import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { RestService } from 'src/app/core/services/rest.service';
import { StructureService } from 'src/app/core/services/structure.service';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-file-export',
  templateUrl: './file-export.component.html',
  styleUrls: ['./file-export.component.scss']
})
export class FileExportComponent implements OnInit{
  date: any = {
    from: moment().format("YYYY-MM-DD"),
    to: (moment().add(1,"month")).format("YYYY-MM-DD")
  };
  loaded: boolean = false;

  constructor(private restService: RestService, public structureService: StructureService){

  }
  ngOnInit(): void {
    this.loaded = true;
  }

  exportFile(){
    console.debug(this.date.from, this.date.to)
    this.loaded = false;
    this.restService.getReport('export_file', this.structureService.selectedStructure.id+`/export-by-days?from=${this.date.from}&to=${this.date.to}`).subscribe({
      next: (ris:any)=>{
        this.loaded = true;
        console.debug(ris);
        const data: Blob = new Blob([ris], {
          type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, 'report' + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      },
      error: (e)=>{
        this.loaded = true;
        console.debug("ERROR",e);
      }

    })
  }

}
