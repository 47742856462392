import { Component, Renderer2, ViewChild } from '@angular/core';
import { GuiService } from 'src/app/core/services/gui.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexPlotOptions,
  ApexXAxis,
  ApexFill,
  ApexDataLabels,
  ApexYAxis,
  ApexGrid
} from "ng-apexcharts";
import * as moment from 'moment';
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  fill: ApexFill;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  plotOptions: ApexPlotOptions;
};

import { RestService } from 'src/app/core/services/rest.service';
import { StructureService } from 'src/app/core/services/structure.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent {
  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;
  file: any;
  chartpage: number = 0;
  chartTotPage: number = 0;
  chartsize: number = 30;
  renderFlag: boolean = false;
  renderTable: boolean = false;
  fileList: any = []
  progressValue: any = null;

  autoReloadInterval: any = null;
  progressBar: number = 0;

  fileSelected: any;
  a: any
  date: any = {
    from: '',
    to: ''
  }
  laFenosa: any;
  tmpResult: any;

  tooltipOption = {
    enabled: false
  }
  headerWidth: any;

  scrollX: boolean = false;
  scrollY: boolean = false;
  listener: any;
  roomsType: any;
  salesChannel: any;

  constructor(public guiService: GuiService,private modalService: NgbModal,private renderer2: Renderer2, private restService: RestService, private strService: StructureService){
    this.listener = this.renderer2.listen('window', 'scroll', (e) => {
      // console.debug("SCROLLTOP",this.getScrollingElement().scrollTop);
      // console.debug("HEADER WIDTH",this.headerWidth);
      this.scrollY = this.getScrollingElement().scrollTop > 180 ? true : false;
    });
    this.listener = this.renderer2.listen('window', 'resize', (e) => {
      // console.debug("SCROLLTOP",this.getScrollingElement().scrollTop);
      this.headerWidth = document.getElementById("colslimit")!.offsetWidth;
      // console.debug("HEADER WIDTH",this.headerWidth);
    });
  }

  ngOnInit(): void {
    this.getUploadedFileList();
  }

  ngOnDestroy(): void {
    this.listener();
    this.progressBar = 0;
    clearInterval(this.autoReloadInterval);
  }
  getScrollingElement(): Element {
    return document.scrollingElement || document.documentElement;
  }

  onYScroll(idx:any){
    let scrollT = document.getElementById(idx)!.scrollTop;
    this.scrollY = scrollT > 180 ? true : false;
  }
  onXScroll(idx:any) {
    let scrollL = document.getElementById(idx)!.scrollLeft;
    let elements =  document.getElementsByClassName("element-h-scroll");
    if(elements.length>0) {
      for(let k in elements) {
        elements[k] ? elements[k].scrollLeft = scrollL : null;
      }
    }
  }

  getUploadedFileList() {
    this.progressBar = 0;
    clearInterval(this.autoReloadInterval);

    this.restService.getService('file_upload', '/list/'+this.strService.selectedStructure.id+'?size=12&order=uploadDate&direction=DESC').subscribe((ris)=>{
      console.debug("FILE LIST",ris.data);
      this.fileList = ris.data;
      for(let item of this.fileList) {
        if(item.status == "PROCESSING" || item.status == "UPLOADED") {
          this.startAutoReload();
          break;
        }
      }
    })
  }
  startAutoReload() {
    let cnt: number = 0;
    this.autoReloadInterval = setInterval(()=>{
      cnt++
      if(cnt>20) {
        cnt = 0;
        this.getUploadedFileList();

        // DA RIMUOVERE IN PRODUZIONE
        this.restService.postService("pmsx","/get_token",{"u":"EXTTEST000","p":"12345678","pId":"77"}).subscribe(() => {
          console.debug("wake up PMSX");
        });
      } else {
        this.progressBar = cnt*5;
      }
    }, 1000)
  }

  getFile(event:any){
    this.file = event.target.files[0]

    console.debug('file', this.file)
  }

  sendFile(){
    let fileName: any = "-";
      
    if (this.file) {
        this.progressValue = {};
        fileName = this.file.name;
        const formData = new FormData();

        formData.append("uploadType", this.strService.selectedStructureConfig.pms.value.toUpperCase());
        formData.append("thumbnail", this.file);

        this.restService.uploadFile('file_upload', '/upload/'+this.strService.selectedStructure.id, formData).subscribe((ris)=>{
          console.debug("UPLOAD",ris);

          this.progressValue = ris;
          if(ris.loaded) {
            this.progressValue = ris.total ? (ris.total/100)*ris.loaded : 100;
          } 

          if(ris.loaded && !ris.total) {
            setTimeout(()=>{
              this.guiService.genericSuccesfully();
              this.getUploadedFileList();
              this.progressValue = null;

            },1000);
          }
        })
    }


  }

  clear(){
    this.file = undefined
  }

  detail(fileId: any,modalContent:any){
    this.fileSelected =  this.fileList.find((x: { id: any; }) => x.id == fileId)
    console.debug(this.fileSelected)
    this.fullModal(modalContent)
  }

  fullModal(dataModal: any) {
    this.modalService.open(dataModal, { size: 'fullscreen', windowClass: 'modal-holder'})
  }

  initChart(){
    this.laFenosa.sort(this.guiService.sortByProperty("in"));


    let len = this.laFenosa.length;
    this.chartTotPage = Math.ceil(len/this.chartsize);

    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    this.chartOptions = {
      series: [
        {
          data: []
        }
      ],
      chart: {
        height: 750,
        type: "rangeBar"
      },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true,
          dataLabels: {
            hideOverflowingLabels: false
          }
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function(val:any, opts:any) {
          var label = opts.w.globals.labels[opts.dataPointIndex];
          var a = moment(val[0]);
          var b = moment(val[1]);
          var diff = b.diff(a, "days");
          return label + ": " + diff + (diff > 1 ? " days" : " day");
        },
        style: {
          colors: ["#f3f4f5", "#fff"]
        }
      },
      xaxis: {
        type: "datetime"
      },
      yaxis: {
        show: false
      },
      grid: {
          xaxis: {
            lines: {
              show: true
            }
          },
        column: {
          colors: ["#f3f4f5"],
          opacity: 1
        },
        row: {
          colors: ["#f3f4f5", "#ffffff00"],
          opacity: 0.5
        }
      },
      tooltip: {
        custom: ({series, seriesIndex, dataPointIndex, w}: any) => {
          var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

          return '<ul>' +
            '<li><b>Adults</b>: ' + data.info.adults + '</li>' +
            '<li><b>Amount</b>: ' + data.info.amount + '</li>' +
            '<li><b>Amount Details</b>: ' + data.info.amountDetails + '</li>' +
            '<li><b>Channel Code</b>: ' + data.info.channelCode + '</li>' +
            '<li><b>Children</b>: ' + data.info.children + '</li>' +
            '<li><b>Dt</b>: ' + data.info.dt + '</li>' +
            '<li><b>Id</b>: ' + data.info.id + '</li>' +
            '<li><b>In</b>: ' + data.info.in + '</li>' +
            '<li><b>Out</b>: ' + data.info.out + '</li>' +
            '<li><b>Qt</b>: ' + data.info.qt + '</li>' +
            '<li><b>Room Code</b>: ' + data.info.roomCode + '</li>' +
            '<li><b>Room Code Assigned</b>: ' + data.info.roomCodeAssigned + '</li>' +
            '<li><b>Source</b>: ' + data.info.source + '</li>' +
            '<li><b>Status</b>: ' + data.info.status + '</li>' +
            '</ul>';
        }
      }
    };
    this.renderChart();
  }


  renderChart(){
    this.chartOptions.series[0].data = [];
    console.debug(this.laFenosa)
    let block = []
    if(this.chartpage == 0){
      block = this.laFenosa.slice(this.chartpage,this.chartsize)

    }else{
      block = this.laFenosa.slice((this.chartpage*this.chartsize),(this.chartpage*this.chartsize)+this.chartsize)
    }

    console.debug("BLOCK",block);
    let cnt = 0;
    for (let d of block) {
      this.chartOptions.series[0].data.push({
        x: ""+(cnt++),
        y: [
          parseInt(moment(d.in,'YYYYMMDD').format('x')),
          parseInt(moment(d.out,'YYYYMMDD').format('x'))
        ],
        info: d,
        fillColor: "#008FFB"
      })
    }
    setTimeout(() => {
      this.renderFlag = true
    }, 1000);
  }

  nextData() {
    this.chartpage++;
    this.renderFlag = false
    this.renderChart();
  }
  prevData() {
    if(this.chartpage > 0){
      this.chartpage--;
      this.renderFlag = false
      this.renderChart();
    }
  }

  downloadFile(item: any) {
    this.restService.getService('file_upload', '/get_file/'+this.strService.selectedStructure.id+'?size=12&order=uploadDate&direction=DESC').subscribe((ris)=>{
      console.debug("FILE LIST",ris.data);
      this.fileList = ris.data;
    })
  }

  /*modalSearch(){
    let on = moment(this.date.from).format('YYYYMMDD')
    let out = moment(this.date.to).format('YYYYMMDD')

    this.laFenosa = []
    laFenosaBasic.data.find(item => {
      if(item.in >= on && item.out <= out){
        this.laFenosa.push(item)
      }
    })
    console.debug(this.laFenosa)
    this.initChart()
  }

  modalSearchTwo(){
    let on = moment(this.date.from)
    let out = moment(this.date.to)

    let tmpArr: any = []
    this.tmpResult = []
    this.roomsType = []
    this.salesChannel = []
    sanSanoBasic.days.find(item => {
      let dateDay = moment(item.day,'DDMMYYYY')
      if(moment(dateDay).isSameOrAfter(on) && moment(dateDay).isSameOrBefore(out)){
        tmpArr.push(item)
      }
    })
    for(let x in tmpArr){

      let tmpData: any = {
        "roomtype":{},
        "saleschannel":{},
        "tot":[
          {
            occ:0,
            prod: 0
          }
        ]
      }

      for(let y of tmpArr[x].data){
        if(!tmpData.saleschannel[y.c.replace(/ /g,'')]){
          tmpData.saleschannel[y.c.replace(/ /g,'')] = {
          title: y.c,
          ind:{
            'occ': 0,
            'prod': 0
          },
          grp: {
            'occ': 0,
            'prod': 0
          }
        }
        }
        if(!tmpData.roomtype[y.t.replace(/ /g,'')]){
          tmpData.roomtype[y.t.replace(/ /g,'')] = {
          title: y.t,
          ind:{
            'occ': 0,
            'prod': 0
          },
          grp: {
            'occ': 0,
            'prod': 0
          }
        }
        }

        if(y.g == '0'){
          tmpData.saleschannel[y.c.replace(/ /g,'')].ind.occ += parseInt(y.o)
          tmpData.saleschannel[y.c.replace(/ /g,'')].ind.prod += parseInt(y.r)

          tmpData.roomtype[y.t.replace(/ /g,'')].ind.occ += parseInt(y.o)
          tmpData.roomtype[y.t.replace(/ /g,'')].ind.prod += parseInt(y.r)
        }else{
          tmpData.saleschannel[y.c.replace(/ /g,'')].grp.occ += parseInt(y.o)
          tmpData.saleschannel[y.c.replace(/ /g,'')].grp.prod += parseInt(y.r)

          tmpData.roomtype[y.t.replace(/ /g,'')].grp.occ += parseInt(y.o)
          tmpData.roomtype[y.t.replace(/ /g,'')].grp.prod += parseInt(y.r)
        }
        tmpData.tot[0].occ += parseInt(y.o)
        tmpData.tot[0].prod += parseInt(y.r)

        if(!this.roomsType.includes(y.t)){
          this.roomsType.push(y.t)
        }
        if(!this.salesChannel.includes(y.c)){
          this.salesChannel.push(y.c)
        }
      }

        let tmpDate = moment(tmpArr[x].day,'DDMMYYYY')
        let dayName = tmpDate.day()
        let day = tmpDate.format('D')
        let monthName = tmpDate.format('M')
        let year = tmpDate.format('YYYY')
      this.tmpResult.push(
        {
          day: {
            day: day,
            number: dayName,
            month: monthName,
            year: year
          },
          data: tmpData
        }
      )
    }

    console.debug(this.tmpResult)
    this.renderTable = true
  }

  transformString(val:any){
   return val.replace(/ /g,'')
  }
  transformDate(date:any){
    return moment(date,'DDMMYYYY').format('DD/MM/YYYY')
  }*/
}
