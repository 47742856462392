<div class="page-title-box d-flex align-items-center justify-content-between">
  <h4 class="mb-0">{{"FX.ch_availability" | translate}}</h4>
</div>
<div class="col-xxl-12 col-xl-12">
  <div class="card card-h-100 p-3">

    <div class="row card-header">
      <div class="col-8">
        <div class="row">
          <div class="form-floating range-input">
            <input class="form-control flatpickr-input" type="text" mwlFlatpickr [locale]="locale" mode="range"
              [(ngModel)]="defaultDate" [dateFormat]="dateformat">
            <label for="init">{{"GENERIC.interval" | translate}}</label>
          </div>
          <div class="form-floating select-floating" *ngIf="restriction_plans.length>0">
            <ng-select [items]="restriction_plans" bindLabel="name" bindValue="id" (change)="selectRestriction()"
              [(ngModel)]="selected_restriction_plan"
              placeholder="{{ 'AVAILABILITY.availability_restriction_plan' | translate }}"></ng-select>
          </div>

          <span class="icona cerca"><i class="uil-search" (click)="getAvailabilityPeriod()"></i></span>
        </div>
      </div>
      <div class="col-4 d-flex justify-content-end action-buttons">
        <div class="btn-group mx-3" role="group" *ngIf="contentAreaLoaded">
          <button type="button" class="btn btn-light" (click)="prevPeriod()">
            <i-feather name="chevron-left"></i-feather>
          </button>
          <button type="button" class="btn btn-light" (click)="nextPeriod()">
            <i-feather name="chevron-right"></i-feather>
          </button>
        </div>
        <button class="btn btn-primary mx-3" (click)="savePeriod()" *ngIf="contentAreaLoaded">
          <i-feather name="save"></i-feather>
        </button>
        <div ngbDropdown class="btn-dropdown">
          <button class="btn btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle>
            <i-feather name="settings"></i-feather>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button ngbDropdownItem (click)="inventoryCalendarSync()">{{ "AVAILABILITY.sync" | translate }}</button>
            <button ngbDropdownItem (click)="editPeriod(content)">{{ "GENERIC.edit_period_btn" | translate }}</button>
          </div>
        </div>
      </div>
    </div>




    <div class="row justify-content-center" *ngIf="contentAreaLoaded">
      <div class="col-xl-12 pt-5" *ngIf="calendarData">

        <!-- INTESTAZIONE -->
        <div class="row">
          <!-- INTESTAZIONE SINISTRA (VUOTA) -->
          <div class="header-left">
            &nbsp;
          </div>


          <!-- INTESTAZIONE SINISTRA (VUOTA) -->
          <div class="legend-left">
            &nbsp;
          </div>


          <!-- INTESTAZIONE IN ALTO -->
          <div class="header-calendar">
            <div class="calendar-row" [style.width]="(52 * calendarData.length)+'px'">
              <div class="calendar-day" *ngFor="let day of calendarData; let i = index">
                <span class="day-of-week">{{"DAYS.SHORT."+day.dow | translate}}</span>
                <span class="day-of-month">{{day.day}}</span>
                <span class="month">{{"MONTHS.SHORT."+(day.month+1) | translate}}</span>
                <span class="year">{{day.year}}</span>
              </div>
            </div>
          </div>
        </div>




        <div class="row">
          <div class="row calendar-data-row" *ngFor="let room of rooms">
            <!-- INTESTAZIONE SINISTRA -->
            <div class="header-left">
              <h6>{{room.name}}</h6>
              <small>{{room.title}}</small>
              <span>
                <div dir="ltr" class="form-check form-switch custom-control custom-switch" >
                  <input type="checkbox" id="customSwitchsizesm"
                        [checked]="roomsPeriodStatus[room.title]"
                        [(ngModel)]="roomsPeriodStatus[room.title]"
                        (change)="changePeriodRoom(room.title)"
                        class="form-check-input">
                </div>
              </span>
            </div>


            <!-- INTESTAZIONE SINISTRA -->
            <div class="legend-left">
              <span>{{"AVAILABILITY.availability_short" | translate}}</span>
              <span>{{"AVAILABILITY.ms_short" | translate}}</span>
              <span>{{"AVAILABILITY.status_short" | translate}}</span>
            </div>




            <!-- GIORNI CALENDARIO -->
            <div class="calendar">
              <div class="calendar-row" [style.width]="(52 * calendarData.length)+'px'">
                <div class="calendar-day" *ngFor="let day of calendarData">
                  <div *ngIf="day && day.data!=null && day.data[room.title]">
                    <span>
                      <input type="number" (change)="changeVal(day.dayId,room.title,'avl')" [(ngModel)]="day.data[room.title].avl"
                      [class.warning]="day.data[room.title].avl==0"
                      [class.edited]="day.data[room.title].avl_edited" min="0"
                      [disabled]="day.old" />
                    </span>
                    <span>
                      <input type="number" [(ngModel)]="day.data[room.title].ms" (change)="changeVal(day.dayId,room.title,'ms')"
                      [class.edited]="day.data[room.title].ms_edited" min="0"
                      [disabled]="day.old" />
                    </span>
                    <span>
                      <div dir="ltr" class="form-check form-switch custom-control custom-switch" >
                        <input type="checkbox" id="customSwitchsizesm"
                              [checked]="day.data[room.title].status == '0'"
                              (change)="changeRoomDayCheck(room.title,day.dayId)"
                              class="form-check-input"
                              [class.edited]="day.data[room.title].status_edited"
                              [disabled]="day.old">
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



      </div>
    </div>

    <ng-template #content let-modal>
      <app-edit-period-modal [items]="calendarData" [date]="[date.from,date.to]" [rooms]="rooms" (onCloseHandler)="closeModal()"></app-edit-period-modal>
    </ng-template>


    <div *ngIf="!loaded" class="main-page-loading">
      <div class="loading-bars-box">
        <div class="loading-bars"></div>
      </div>
    </div>
  </div>

</div>
