<header id="page-topbar" [class.fullscreen-toolbar]="guiService.SideBarFlag == true">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <!--div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
                    <span class="logo-sm">
                        <img src="assets/images/revolution_p_logo.svg" alt="" height="22">
                    </span>
          <span class="logo-lg">
                        <img src="assets/images/revolution_p_logo.svg" alt="" height="22">
                    </span>
        </a>

        <a routerLink="/" class="logo logo-light">
                    <span class="logo-sm">
                        <img src="assets/images/revolution_p_logo.svg" alt="" height="22">
                    </span>
          <span class="logo-lg">
                        <img src="assets/images/revolution_p_logo.svg" alt="" height="22">
                    </span>
        </a>
      </div-->

      <div class="logo-box">

        <span class="logo logo-dark">
                    <span class="logo-sm">
                        <img src="assets/images/Logotipo-negativo.svg" alt="" height="40">
                    </span>
          <span class="logo-lg">
                        <img src="assets/images/Logotipo-negativo.svg" alt="" height="40">
                    </span>
        </span>

        <span class="logo logo-light">
                    <span class="logo-sm">
                        <img src="assets/images/Logotipo-negativo.svg" alt="" height="40">
                    </span>
          <span class="logo-lg">
                        <img src="assets/images/Logotipo-negativo.svg" alt="" height="40">
                    </span>
        </span>
      </div>

      <!--button type="button" class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
              [class.d-none]="guiService.showBtnSidebar == false"
              (click)="guiService.showSideBar(guiService.SideBarFlag == true ? false : true)">
        <i class="fa fa-fw fa-bars"></i>
      </button-->

    </div>
    <!-- <div class="info-icon">
      <i class="uil-info-circle" *ngIf="structureService.selectedStructure && structureService.getStructurebyId(structureService.selectedStructure)" placement="bottom" [ngbTooltip]="'città: '+structureService.getStructurebyId(structureService.selectedStructure).city +' '+ 'via: '+structureService.getStructurebyId(structureService.selectedStructure).address"></i>
      <i class="uil-info-circle" *ngIf="!structureService.selectedStructure"></i>
    </div> -->
    <div class="">

      <div class="structure-selected-head" *ngIf="structureService.selectedStructure && !searchStructureEnable">
        <button class="btn btn-link btn-sm ms-2 d-inline-block" (click)="enableSearchStructure()">
          <i-feather name="search"></i-feather>
        </button>
        <div class="selected-structure-view d-inline-block">
          <h5>{{structureService.selectedStructure.name}}</h5>
          <small>{{structureService.selectedStructure.city}} ({{structureService.selectedStructure.province}})</small>
        </div>
        <button class="btn btn-sm ms-2 mb-1 d-inline-block" (click)="openInfoStr()">
          <i-feather name="info"></i-feather>
        </button>
      </div>

      <ng-select *ngIf="!structureService.selectedStructure || searchStructureEnable" (change)="selectStructure($event)"
                 [(ngModel)]="structureService.selectedStructure" (search)="search($event)"
                 placeholder="Seleziona Struttura" id="top-search" (focusout)="resetSelectedStructure()" #searchSelect >
                <ng-option *ngIf="loadingSearch">
                  <div class="loading-bars-box">
                    <div class="loading-bars"></div>
                  </div>
                </ng-option>
                <ng-option *ngFor="let str of structureService.structures" [value]="str">
                  <h5>{{str.name}}</h5>
                  <small>{{str.city}} ({{str.province}})</small>
                </ng-option>
      </ng-select>
    </div>
    <div class="dropdown-mega d-lg-block dropdown w-100" ngbDropdown>
      <button type="button" class="btn header-item" data-bs-toggle="dropdown" aria-haspopup="false"
              aria-expanded="false" ngbDropdownToggle>
        <span class="text-uppercase">{{selectedArea | translate}}</span>
        <i class="mdi mdi-chevron-down"></i>
      </button>
      <div class="dropdown-menu p-2 dropdown-megamenu" ngbDropdownMenu>
        <div class="row">
          <div class="col-lg-2 col-sm-4" *ngFor="let areaMenu of guiService.fullMenuItems">
            <div class="area-box">
              <label>
                <i class="area-box-icon" [class]="areaMenu.icon"></i>
                {{areaMenu.name | translate}}
              </label>
              <li *ngFor="let item of areaMenu.zones" class="dropdown-item">
                <a href="javascript:void(0);" [class.no-structure-selected]="!structureService.selectedStructure && item.condition" (click)="selectArea(item.link,areaMenu.name)">{{item.label | translate}}</a>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex">


      <div class="dropdown d-inline-block language-switch" ngbDropdown placement="bottom-right">
        <button type="button" class="btn header-item" data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" ngbDropdownToggle>
          <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16">
          <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
             (click)="setLanguage(item.text, item.lang, item.flag)"
             [ngClass]="{'active': cookieValue === item.lang}">
            <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span
            class="align-middle">{{item.text}}</span>
          </a>
        </div>
      </div>

      <!--div class="dropdown d-inline-block" ngbDropdown placement="bottom-right">
        <button type="button" class="btn header-item" data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" ngbDropdownToggle>
          <i class="uil-wrench"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end" ngbDropdownMenu>
          <div class="dropdown-item">
            <div dir="ltr" class="form-check form-switch mb-2" *ngIf="structureService.selectedStructureConfig && structureService.selectedStructureConfig.setup_phase">
              <input type="checkbox" id="customSwitchsizesm"
                     [checked]="structureService.selectedStructureConfig.setup_phase.value == '1'" class="form-check-input" (change)="setupModeChange()">
              <label for="customSwitchsizesm" class="form-check-label">{{ "CFG.setup_phase" | translate }}</label>
            </div>
          </div>
        </div>
      </div-->

      <div class="dropdown d-inline-block" ngbDropdown placement="bottom-right">
        <!--button type="button" class="btn header-item" data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" ngbDropdownToggle>
          <i class="uil-postcard"></i>
        </button-->
        <div class="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end" ngbDropdownMenu>

        </div>
      </div>

      <!--div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"
                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
          <i class="uil-bell"></i>
          <span class="noti-dot bg-danger"></span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
             aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h5 class="m-0 font-size-15"> {{ 'HEADER.NOTIFICATIONS.TITLE' | translate}} </h5>
              </div>
              <div class="col-auto">
                <a href="javascript:void(0);" class="small"> {{
                  'HEADER.NOTIFICATIONS.VIEW_ALL' | translate}}</a>
              </div>
            </div>
          </div>
          <ngx-simplebar style="max-height: 250px;">
            <h6 class="dropdown-header bg-light">{{
              'HEADER.NOTIFICATIONS.NEW' | translate}}</h6>
            <a href="" class="text-reset notification-item">
              <div class="d-flex border-bottom align-items-start">
                <div class="flex-shrink-0">
                  <img src="assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-sm"
                       alt="user-pic">
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TITLE' | translate}}</h6>
                  <div class="text-muted">
                    <p class="mb-1 font-size-13">{{ 'HEADER.NOTIFICATIONS.FIRST.TEXT' | translate}}
                    </p>
                    <p class="mb-0 font-size-10 text-uppercase fw-bold"><i
                      class="mdi mdi-clock-outline"></i> {{
                      'HEADER.NOTIFICATIONS.FIRST.TIME' | translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="" class="text-reset notification-item">
              <div class="d-flex border-bottom align-items-start">
                <div class="flex-shrink-0">
                  <div class="avatar-sm me-3">
                                        <span class="avatar-title bg-primary rounded-circle font-size-16">
                                            <i class="uil-shopping-basket"></i>
                                        </span>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TITLE' | translate}}</h6>
                  <div class="text-muted">
                    <p class="mb-1 font-size-13">{{ 'HEADER.NOTIFICATIONS.SECOND.TEXT' | translate}}</p>
                    <p class="mb-0 font-size-10 text-uppercase fw-bold"><i
                      class="mdi mdi-clock-outline"></i> {{
                      'HEADER.NOTIFICATIONS.SECOND.TIME' | translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <h6 class="dropdown-header bg-light">{{
              'HEADER.NOTIFICATIONS.EARLIER' | translate}}</h6>
            <a href="" class="text-reset notification-item">
              <div class="d-flex border-bottom align-items-start">
                <div class="flex-shrink-0">
                  <div class="avatar-sm me-3">
                                        <span
                                          class="avatar-title bg-soft-success text-success rounded-circle font-size-16">
                                            <i class="uil-truck"></i>
                                        </span>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TITLE' | translate}}</h6>
                  <div class="text-muted">
                    <p class="mb-1 font-size-13">{{ 'HEADER.NOTIFICATIONS.THIRD.TEXT' | translate}}
                    </p>
                    <p class="mb-0 font-size-10 text-uppercase fw-bold"><i
                      class="mdi mdi-clock-outline"></i> {{
                      'HEADER.NOTIFICATIONS.THIRD.TIME' |
                        translate}}</p>
                  </div>
                </div>
              </div>
            </a>

            <a href="" class="text-reset notification-item">
              <div class="d-flex border-bottom align-items-start">
                <div class="flex-shrink-0">
                  <img src="assets/images/users/avatar-4.jpg" class="me-3 rounded-circle avatar-sm"
                       alt="user-pic">
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TITLE' | translate}}</h6>
                  <div class="text-muted">
                    <p class="mb-1 font-size-13">{{ 'HEADER.NOTIFICATIONS.FOUR.TEXT' | translate}}</p>
                    <p class="mb-0 font-size-10 text-uppercase fw-bold"><i
                      class="mdi mdi-clock-outline"></i>{{
                      'HEADER.NOTIFICATIONS.FOUR.TIME' |
                        translate}}</p>
                  </div>
                </div>
              </div>
            </a>
          </ngx-simplebar>
          <div class="p-2 border-top d-grid">
            <a class="btn btn-sm btn-link font-size-14 btn-block text-center" href="javascript:void(0)">
              <i class="uil-arrow-circle-right me-1"></i> <span>{{
              'HEADER.NOTIFICATIONS.LOAD_MORE' | translate}}</span>
            </a>
          </div>
        </div>
      </div-->

      <div class="dropdown d-inline-block">
          <button type="button" class="btn header-item noti-icon right-bar-toggle" id="right-bar-toggle"
              (click)="toggleRightSidebar()">
              <i class="mdi mdi-calculator"></i>
          </button>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item user text-start d-flex align-items-center"
                id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                ngbDropdownToggle>
          <span class="rounded-circle header-profile-user">
            <b *ngIf="guiService.userLogged">{{guiService.userLogged.sub.charAt(0).toUpperCase()}}{{guiService.userLogged.sub.charAt(1).toUpperCase()}}</b>
          </span>
          <span class="ms-2 d-none d-sm-block user-item-desc">
                        <span class="user-name" >{{guiService.userLogged.sub}}</span>
                        <span class="user-sub-title"
                              *ngIf="guiService.userLogged">{{guiService.userLogged.roles}}</span>
                    </span>
        </button>
        <div class="dropdown-menu dropdown-menu-end pt-0" ngbDropdownMenu>
          <a class="dropdown-item" routerLink="profile" (click)="guiService.goTo('/admin/profile', translate.instant('FX.main'))">
            <span class="align-middle">
              <i class="mdi mdi-chevron-right"></i> Profile
            </span>
          </a>
          <!--a class="dropdown-item" routerLink="/chat" (click)="guiService.goTo('/invoices/anagrafiche', translate.instant('FX.accounting'))">
            <span class="align-middle">
              <i class="mdi mdi-chevron-right"></i> Invoice
            </span>
          </a-->
          <a class="dropdown-item" routerLink="/kanban-board" (click)="guiService.goTo('/support/videoguide', translate.instant('FX.support'))">
            <span class="align-middle">
              <i class="mdi mdi-chevron-right"></i> Guide
            </span>
          </a>
          <div class="text-center mt-2">
            <button (click)="logout()" class="logout">Log-Out</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
