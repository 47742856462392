// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseURL:{
    base: "https://core-dot-revolution-dev-4.oa.r.appspot.com",
    login: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/jwt/authenticate",
    auth: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/jwt",
    resetpsw: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/jwt",
    remember_me: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/remember-me",
    info: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/account/info",
    structure: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/structure",
    structure_detail: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/main/selectStructure",
    structure_configuration: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/structure_configuration",
    provinces: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/common/provinces",
    users: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/accountv4",
    account: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/account",
    room_type: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/roomType",
    roomtypes: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/room-types",
    price_list: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/structure_configuration/rates-computing-models",
    sales_channel: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/sales_channel",
    sale_channels: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/sale-channels",
    calendar_events: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/events",
    availability: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/calendar_availability",
    planning: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/rev_calendar",
    rev_calendar: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/rev_calendar",
    note: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/note",
    trace: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/trace",
    calendar: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/calendar",
    email: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/email",
    cm_rate: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/rate",
    channel: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/channel",
    file_upload: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/file_uploader",
    cm_availability: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/roomStatus",
    cmx_base: "https://cmx-dot-revolution-dev-4.oa.r.appspot.com",
    crmx_base: "https://crm-dot-silken-airfoil-374316.oa.r.appspot.com/revo-easy-manager-crm",
    crm_base_new: "https://crm-dot-revolution-plus-nuova-arch.oa.r.appspot.com/revo-easy-manager-crm",
    core_base_new: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp",
    extra_service: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/extraservices",
    tag: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/tag",
    forecasts: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/forecasts",
    report: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/report",
    pmsx: "https://pmsx-dot-silken-airfoil-374316.oa.r.appspot.com/rev_push",
    ai_rate: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/ai/",
    export_file: "https://core-dot-revolution-dev-4.oa.r.appspot.com/revo-easy-manager-webapp/reportv4/"
  }
};
