<div class="contenitore">
  <div class="row">
    <div class="col-8">
      <div class="row">
        <div class="form-floating">
          <input class="form-control" type="date" id="init" [(ngModel)]="date.from">
          <label for="init">DA</label>
        </div>
        <div class="form-floating">
          <input class="form-control" type="date" id="expire" [(ngModel)]="date.to">
          <label for="expire">A</label>
        </div>
        <button class="btn icona cerca" [class.btn-light]="!roomsType">
          <div class="loading-bars-box" *ngIf="!roomsType">
            <div class="loading-bars"></div>
          </div>
          <i class="uil-search" *ngIf="roomsType" (click)="loadCalendarData()"></i>
        </button>
      </div>
    </div>
    <div class="col-4 text-end">
      <label class="config-item" *ngIf="showTable">
        <h3 class="d-inline-block">{{ "CFG.edit_past" | translate }}</h3>
        <div dir="ltr" class="form-check form-switch" *ngIf="structureService.selectedStructureConfig.setup_phase && isCfgSaved">
          <input type="checkbox" id="customSwitchsizesm" [checked]="structureService.selectedStructureConfig.setup_phase.value == '1'" class="form-check-input" (change)="configKeyChange('setup_phase')">
        </div>
        <div class="loading-bars-box" *ngIf="!isCfgSaved">
          <div class="loading-bars"></div>
        </div>

      </label>
      <button class="btn icona annulla" *ngIf="showTable" (click)="reset()"><i class="uil-cancel"></i></button>
      <button class="btn icona cerca" *ngIf="showTable" (click)="save()"><i-feather name="save"></i-feather></button>
    </div>
  </div>
  <div class="tabella row" *ngIf="showTable && loaded">
    <div class="vuoto"></div>
    <div id="colslimit" class="col-10 col-sm-custom">
      <div id="main-h-scroll" [class.fixed-header]="scrollY" [style.width]="headerWidth+'px'"
           (scroll)="onXScroll('main-h-scroll')">
        <div class="row h-header" [style.width]="(84*(roomsType.length)) + 'px'">
          <div class="intestazione-orizzontale" *ngFor="let item of roomsType">
            <h2>{{item.title}}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- TABLE START -->
  <div class="row days-area" *ngIf="showTable && loaded">
    <div *ngFor="let item of tmpResult; let i = index">
      <div class="vuoto">
        <div class="colonna-verticale" [class.fixed-first-col]="scrollX">
          <div class="intestazione-verticale">
            <h4 class="day">{{"DAYS.SHORT." + (item.DoW) | translate}}</h4>
            <h3 class="day-number">{{item.d}}</h3>
            <h4>{{"MONTHS.SHORT." + (item.mInt) | translate}}</h4>
            <h4>{{item.y}}</h4>
          </div>
          <div class="header-vertical">
            <div>Tariffa</div>
            <div>Formula</div>
          </div>
        </div>
      </div>
      <div class="col-10 col-sm-custom colonna-verticale element-h-scroll" [style.width]="headerWidth+'px'" id="row_{{i}}">
        <div [style.width]="(84*(roomsType.length)) + 'px'">
          <div *ngFor="let rt of roomsType; let indice = index">
            <span *ngIf="rateResult[item.day][rt.title]">
              <input id="{{rt.title}}_{{item.day}}" type="number"
                     [(ngModel)]="rateResult[item.day][rt.title].current.rate"
                     [class.danger]="rateResult[item.day][rt.title].error"
                     [class.edited]="rateResult[item.day][rt.title].edited"
                     (paste)="onPaste($event,i,rt.title)"
                     (change)="checkChanges(i,rt.title)"
                     [disabled]="!rateResult[item.day][rt.title].currentFormula && !rateResult[item.day][rt.title].currentMaxValue && !rateResult[item.day][rt.title].currentMinValue">
              <small class="ref-formula" [class.no-formula]="rateResult[item.day][rt.title].currentFormula==null">
                <i class="bx bx-caret-up" *ngIf="rateResult[item.day][rt.title].currentFormula==null"></i>
                <i *ngIf="rateResult[item.day][rt.title].currentFormula!=null">{{rateResult[item.day][rt.title].currentFormula}}</i>
                <a class="btn-forced" *ngIf="rateResult[item.day][rt.title].current.forced" (click)="unlockPrice(i,rt.title)">
                  <i class="uil-lock"></i>
                </a>
              </small>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- TABLE END -->



  <div *ngIf="loaded == false" class="main-page-loading">
    <div class="loading-bars-box" *ngIf="!showTable">
      <div class="loading-bars"></div>
    </div>
  </div>
</div>
