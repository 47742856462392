import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GuiService } from 'src/app/core/services/gui.service';
import { RestService } from 'src/app/core/services/rest.service';
import { StructureService } from 'src/app/core/services/structure.service';
import moment from "moment/moment";
import Swal from "sweetalert2";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss']
})
export class AvailabilityComponent implements OnInit {
  loaded: boolean = false;
  contentAreaLoaded: boolean = false;
  showDetail: boolean= false;
  subArea: string = "";
  now: any = moment();

  locale: any = {
    weekdays: {
      shorthand: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
      longhand: [
        "Domenica",
        "Lunedì",
        "Martedì",
        "Mercoledì",
        "Giovedì",
        "Venerdì",
        "Sabato",
      ],
    },
    months: {
      shorthand: [
        "Gen",
        "Feb",
        "Mar",
        "Apr",
        "Mag",
        "Giu",
        "Lug",
        "Ago",
        "Set",
        "Ott",
        "Nov",
        "Dic",
      ],
      longhand: [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre",
      ],
    },
    firstDayOfWeek: 1,
    ordinal: () => "°",
    rangeSeparator: " al ",
    weekAbbreviation: "Se",
    scrollTitle: "Scrolla per aumentare",
    toggleTitle: "Clicca per cambiare",
    time_24hr: true,
    defaultDate: [moment() , moment().add(30,"days")]
  };
  daterange: any = null;
  dateformat: any = "d-m-Y";
  defaultDate: any = null;

  rooms: Array<any> = [];

  restriction_plans: Array<any> = [];
  selected_restriction_plan: any = 0;
  date: any = [moment().subtract(1,"day").toDate(),moment().toDate()];
  calendarDays: Array<any> = [];
  resultCalendarData: any = {};
  calendarData: Array<any> = [];

  roomsPeriodStatus: any = {};

  constructor(private restService: RestService, private strService: StructureService, private guiService: GuiService, public translate: TranslateService,private modalService: NgbModal) {
    this.guiService.closeDetail.subscribe( value => {
      if(value == true){
        this.showDetail = false;
      }
    })
  }

  ngOnInit() {
    this.guiService.changeArea("FX.operation");
    this.guiService.selectMenuItems("FX.operation");
    this.loaded = false;
    this.getRates();
    this.getRooms();

    this.defaultDate = [moment().subtract(1,"day").toDate(),moment().add(29,"days").toDate()];
    console.debug("DATE",this.defaultDate);
  }


  getRooms(){
    if(this.strService.selectedStructure){
      let params = {
        page: 0,
        searchCriteria: [{
          "key": "ENABLED",
          "operation" : "EQUALITY",
          "value" : true,
          "orPredicate" : false
        }],
        size: 100,
        structureId: this.strService.selectedStructure.id
      }
      this.restService.postService('room_type','/v2/search',params).subscribe((ris) =>{
        this.rooms = ris.items;

        this.roomsPeriodStatus = {};
        for(let k in this.rooms) {
          this.roomsPeriodStatus[this.rooms[k].title] = true;
        }
    })
    }
  }

  selectRestriction() {

  }

  getRates() {
    this.restService.getService('cm_rate','/list/'+this.strService.selectedStructure.id).subscribe((ris)=>{
      console.debug("RATES",ris);
      ris && ris.data ? this.restriction_plans = ris.data : null;

      this.loaded = true;
    });
  }

  getAvailabilityPeriod() {

    typeof this.defaultDate != "string" ? this.defaultDate = moment(this.defaultDate[0]).format("DD-MM-YYYY")+this.locale.rangeSeparator+moment(this.defaultDate[1]).format("DD-MM-YYYY") : null;

    let dtRange = this.defaultDate.split(this.locale.rangeSeparator);
    this.date.from = moment(dtRange[0],"DD-MM-YYYY").format("YYYY-MM-DD");
    this.date.to = moment(dtRange[1],"DD-MM-YYYY").add(1,"day").format("YYYY-MM-DD");

    console.debug("PERIOD", this.date.from,this.date.to);
    if(this.date.from && this.date.to) {
      this.loaded = false;
      this.contentAreaLoaded = false;

      this.restService.getService("cm_availability","/listByPeriod/"+this.strService.selectedStructure.id+"/"+this.selected_restriction_plan+"?from="+this.date.from+"&to="+this.date.to).subscribe( (ris)=> {
        let items = ris.data;

        this.resultCalendarData = {};
        for(let item of items) {
          let tmp: any = {};

          for(let r in item.roomsData) {
            tmp[item.roomsData[r].roomCode] = item.roomsData[r];
            this.roomsPeriodStatus[item.roomsData[r].roomCode] = this.roomsPeriodStatus[item.roomsData[r].roomCode] && item.roomsData[r].status == 0;
          }

          this.resultCalendarData[moment(item.dt,"DDMMYYYY").format("YYYYMMDD")] = tmp;
        }

        console.debug("AVAILABILITY PERIOD", this.resultCalendarData);
        this.prepareCalendarAvailability();
      })
    }

  }

  prepareCalendarAvailability() {
    let items: Array<any> = [];
    let from = moment(this.date.from,"YYYY-MM-DD");
    let to = moment(this.date.to,"YYYY-MM-DD");

    let count: number = to.diff(from,"days");
    this.calendarDays = new Array(count);

    for(let i=0; i<count; i++) {
      let dt = moment(from).add(i,"days");
      let param = {
        dayId: dt.format("YYYYMMDD"),
        dow: dt.day(),
        day: dt.format("DD"),
        month: dt.month(),
        year: dt.format("YYYY"),
        old: dt.isBefore(this.now, 'day'),
        data: this.resultCalendarData[dt.format("YYYYMMDD")]
      };
      items.push(param);
    }

    this.calendarData = items;
    this.loaded = true;
    this.contentAreaLoaded = true;

  }

  changeRoomDayCheck(roomCode: string, dayId: string) {
    let tmpStatus = true;


    for(let k in this.calendarData) {
      let item = this.calendarData[k];
      if(item.dayId == dayId) {
        item.data[roomCode].status = item.data[roomCode].status == 1 ? 0 : 1;
        this.changeVal(dayId, roomCode, 'status');
      }

      tmpStatus = tmpStatus && item.data[roomCode].status == 1;

    }

    this.roomsPeriodStatus[roomCode] = tmpStatus;

  }

  changePeriodRoom(roomCode: string) {
    let tmpStatus = this.roomsPeriodStatus[roomCode] ? 0 : 1;


    for(let k in this.calendarData) {
      let item = this.calendarData[k];

      item.data[roomCode].status = tmpStatus;
      item.data[roomCode].status_edited = true;

    }

  }

  changeVal(dayID: any, room: any, typ?: string) {
    !typ ? typ = "" : null;

    for(let k in this.calendarData) {
      if(this.calendarData[k].dayId == dayID) {
        this.calendarData[k].data[room][typ+"_edited"] = true;
      }
    }
    console.debug("CHANGE VALUE", this.calendarData);
  }

  nextPeriod() {
    let diff = moment(this.date.to,"YYYY-MM-DD").diff(moment(this.date.from,"YYYY-MM-DD"),"days");

    this.defaultDate = [moment(this.date.to,"YYYY-MM-DD").toDate(),moment(this.date.to,"YYYY-MM-DD").add((diff-1),"day").toDate()];

    this.date.from = moment(this.date.to,"YYYY-MM-DD").format("YYYY-MM-DD");
    this.date.to = moment(this.date.to,"YYYY-MM-DD").add((diff-1),"day").format("YYYY-MM-DD");

    this.getAvailabilityPeriod();
  }

  prevPeriod() {
    let diff = moment(this.date.to,"YYYY-MM-DD").diff(moment(this.date.from,"YYYY-MM-DD"),"days");

    this.defaultDate = [moment(this.date.from,"YYYY-MM-DD").subtract((diff),"day").toDate(),moment(this.date.from,"YYYY-MM-DD").subtract(1,"day").toDate()];

    this.date.to = moment(this.date.from,"YYYY-MM-DD").subtract((diff),"day").format("YYYY-MM-DD");
    this.date.from = moment(this.date.from,"YYYY-MM-DD").subtract(1,"day").format("YYYY-MM-DD");

    this.getAvailabilityPeriod();
  }


  savePeriod() {
    let param: any = {
      "restrictionId": this.selected_restriction_plan,
      "changes": []
    };

   for(let item of this.calendarData) {

    for(let k in item.data) {
      if(item.data[k].avl_edited || item.data[k].ml_edited || item.data[k].status_edited) {
        param.changes.push({
          "dt": moment(item.dayId,"YYYYMMDD").format("DDMMYYYY"),
          "roomCode":k,
          "closed": item.data[k].status,
          "availability": item.data[k].avl,
          "ms": item.data[k].ms
       })
      }
    }
   }

   console.debug("PARAM", param);

   this.restService.postService('availability','/edit/'+this.strService.selectedStructure.id,param).subscribe( (ris) => {
    console.debug("UPDATE CALENDAR AVAILABILITY", ris);

    this.guiService.editedSuccesfully();
   });

  }

  inventoryCalendarSync() {
    this.restService.postService('channel','/push_availability_activation/'+this.strService.selectedStructure.id,{}).subscribe( (ris) => {
      console.debug("SYNC CALENDAR AVAILABILITY", ris);

      this.guiService.genericSuccesfully();
     });

  }

  changeRangeDate() {
    console.debug("---------------->",this.defaultDate)
    let tmp: any = this.defaultDate.split(this.locale.rangeSeparator);
    this.defaultDate =  [moment(tmp[0],this.dateformat),moment(tmp[1],this.dateformat)];
  }

  editPeriod(content:any) {
    // this.subArea = "RATE_MAN";
    // this.showDetail = true;
    this.modalService.open(content, {size: 'xl'});
  }


  onXScroll(idx: any) {
    let scrollL = document.getElementById(idx)!.scrollLeft;
    let elements = document.getElementsByClassName("element-h-scroll");
    if (elements.length > 0) {
      for (let k in elements) {
        elements[k] ? elements[k].scrollLeft = scrollL : null;
      }
    }
  }

  closeModal(){
    this.modalService.dismissAll()
  }

}
