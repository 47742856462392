<div class="container">
  <div class="row">
    <div class="col-md-5">
      <ng-select [clearable]="false" placeholder="Utente" [(ngModel)]="userSelected" class=""
                 (search)="queryOwner($event)">
        <ng-option *ngFor="let user of users" [value]="user">{{user['username']}}</ng-option>
      </ng-select>
    </div>
    <div class="col-md-5">
      <ng-select [clearable]="false" placeholder="Ruolo" [(ngModel)]="roleSelected" id="role" class="">
        <ng-option *ngFor="let role of roles" [value]="role.name">{{role.name}}</ng-option>
      </ng-select>
    </div>
    <div class="col-md-2">
      <button class="btn btn-primary" (click)="assignOwner()">add</button>
    </div>

    <div class="row box-owner" *ngFor="let owner of owners">
      <h1 class="col-3">{{owner.username}}</h1>
      <h3 class="col-3">{{owner.role.name}}</h3>
      <h3 class="col-2">{{owner.expireDate}}</h3>
      <h2 class="col-2">{{owner.id}}</h2>
      <div class="col-2 text-end mb-1" ngbDropdown>
        <i class="uil-cog m-0" ngbDropdownToggle id="dropdownBasic0"></i>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic0">
          <button ngbDropdownItem>Edit</button>
          <button ngbDropdownItem
                  (click)="deleteUserStructure(owner)">
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
