<div class="page-title-box d-flex align-items-center justify-content-between">
</div>
<div class="col-xxl-12 col-xl-12 main-card-box">
  <div class="card card-h-100">
    <div class="row">
      <div class="col-sm-6 col-md-6 col-xl-2 col-xxl-2" *ngFor="let item of guiService.items">
        <aside class="profile-card">

          <header>
            <a>
              <i [class]="item.icon"></i>
            </a>
            <h1>{{item.name | translate}}</h1>
          </header>
          <div class="profile-bio" *ngFor="let zone of item.zones; let i = index">

            <h3 (click)="guiService.goTo(zone.link,item.name)"
                [class.no-structure-selected]="!structureService.selectedStructure && zone.condition">{{zone.label | translate}}</h3>
          </div>

        </aside>
      </div>
    </div>
  </div>
</div>
