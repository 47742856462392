import { RestService } from './../../services/rest.service';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { GuiService } from '../../services/gui.service';
import { PermissionService } from '../../services/permission.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  form!: UntypedFormGroup;
  isLoading: boolean = false;
  isVisibile: boolean = false;
  isRememberMe: boolean = false;

  constructor(private fb: UntypedFormBuilder, private restService: RestService,private router: Router, public guiService: GuiService,
    private perms: PermissionService, public translate: TranslateService){

  }

  ngOnInit(): void {
    this.isLoading = false;

    this.form = this.fb.group({
      username: [null, Validators.required],
      password: [null, Validators.required],
      remember: null
    });

    sessionStorage.getItem("tkn") && sessionStorage.getItem("tkn")!='' ? this.userInfo() : null;

    if(localStorage.getItem("appremre") !== null && this.guiService.isJsonString(atob(localStorage.getItem("appremre")+""))) {

      let rem_me = atob(localStorage.getItem("appremre")+"");
      this.restService.REMEMBER_ME = JSON.parse(rem_me);
      this.form.controls['remember'].setValue(this.restService.REMEMBER_ME.selected);

      this.restService.REMEMBER_ME.selected ? this.rememberLogin() : localStorage.removeItem("appremre");

    }

  }

  send(){
    this.isLoading = true;
    let login = {
      username: this.form.value.username,
      password: this.form.value.password
    }
    console.debug(login,this.form.value.remember);
    this.restService.doLogin('',login).subscribe({
      next: (ris:any)=>{
        this.restService.tknSubj.next(ris["token"])
        let tkn = ris["token"]
        sessionStorage.setItem("tkn", tkn);
        this.userInfo();
      },error: (e)=>{

        // GESTIONE AUTENTICAZIONE OTP
        if(e.status == 404 && e.error && e.error.error) {
          let emsg = e.error.error;

          if(emsg.message == "otp required") {
            this.perms.AUTH_INFO.username = login.username;
            this.perms.AUTH_INFO.password = login.password;
            this.restService.REMEMBER_ME.selected = this.form.value.remember;

            this.router.navigate(['auth/confirm-otp']);
          } else {

            this.isLoading = false;
            Swal.fire({
              title: 'Ops!',
              text: this.translate.instant("AUTH.CREDENTIALS_ERROR"),
              icon: 'error'
            });
          }
        } else {

          this.isLoading = false;
          Swal.fire({
            title: 'Ops!',
            text: this.translate.instant("AUTH.CREDENTIALS_ERROR"),
            icon: 'error'
          });

        }
      }

    })
  }

  rememberLogin() {
    this.isLoading = true;
    let param = {
      token: this.restService.REMEMBER_ME.appkey
    }

    this.restService.rememberLogin('',param).subscribe({
      next: (ris:any)=>{
        this.restService.tknSubj.next(ris["token"])
        let tkn = ris["token"]
        sessionStorage.setItem("tkn", tkn);

        console.debug("REM TKN",tkn);

        this.userInfo();
      },
      error: (e)=>{
        this.isLoading = false;
        console.debug("ERROR",e);
      }

    });
  }

  userInfo(){
    this.restService.getService('users','/current').subscribe({
      next: (ris:any)=>{
        if(this.guiService.decodeToken()){
          this.guiService.userLogged = this.guiService.decodeToken();
            let role = this.guiService.userLogged.roles;
            this.checkRole(role);
        }
        sessionStorage.setItem('userId',ris.id)
      },error: (e)=>{
        this.isLoading = false;
        console.debug('error',e)
      }
    })
  }

  checkRole(role: string){
    this.guiService.loadMenuItems();
    if(role == 'SUPERADMIN'){
      this.router.navigate(['admin/dashboard']);
    } else {
      this.router.navigate(['admin/dashboard']);
    }
  }



  resetPsw(){
    this.router.navigate(['reset-password']);
  }

  passwordToggle(){
    this.isVisibile ? this.isVisibile = false : this.isVisibile = true
  }

}
