<div class="row" *ngIf="user">
    <div class="col-xxl-3 col-lg-4">
        <div class="card">
            <div class="card-body card-bg left-color">

                <div class="text-center mt-4">
                    <div class="position-relative d-inline-block mx-auto mt-n5 mb-4">
                        <div class="circle">

                            <div class="initials">
                                {{user.firstName.charAt(0)}}{{user.lastName.charAt(0)}}
                            </div>
                        </div>
                    </div>

                    <h5 class="font-size-12 mb-1">{{user.firstName+ ' '+user.lastName }}</h5>
                    <p class="mb-0">{{user.mainProfileName}}</p>
                </div>
                <hr class="my-4">
                <div>
                    <h5 class="heading-structure-list font-size-10">Strutture Assegnate: <span>{{strCount}}</span></h5>
                    <div class="search-results" infiniteScroll [infiniteScrollDistance]="2"
                        [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false">
                        <div *ngFor="let structure of structures" class="structure-border">
                            <h1>{{structure.name}}</h1>
                            <h3>{{structure.address}} {{structure.city}}</h3>
                        </div>
                    </div>


                </div>
            </div>
        </div>
        <!-- end card -->

    </div>
    <!-- end col -->

    <div class="col-xxl-9 col-lg-8">
        <div class="card">
            <div class="card-body card-bg">
                <form>
                    <div class="card border shadow-none mb-5">
                        <div class="card-header d-flex align-items-center">
                            <div class="flex-grow-1">
                                <h5 class="card-title">{{ "TITLE.user_info" | translate}}</h5>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label class="form-label" for="firstName">{{"USERS.firstname" | translate}}</label>
                                        <input type="text" class="form-control" id="name" name="firstName"
                                            [(ngModel)]="user.firstName" />
                                    </div>

                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label class="form-label" for="lastName">{{"USERS.lastname" | translate}}</label>
                                        <input type="text" class="form-control" id="lastName" name="lastname"
                                            [(ngModel)]="user.lastName" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label class="form-label" for="email">{{"USERS.email" | translate}}</label>
                                        <input type="text" class="form-control" id="name" name="email"
                                            [(ngModel)]="user.email" />
                                    </div>

                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label class="form-label" for="language">{{"USERS.lang" | translate}}</label>
                                        <div class="col-md-12">
                                          <select [(ngModel)]="user.language" name="language">
                                            <option *ngFor="let lang of langs" [value]="lang.value">{{lang.label}}</option>
                                          </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="text-end">
                              <button type="submit" class="btn btn-edit" (click)="saveInfos()">Salva</button>
                          </div>
                        </div>
                    </div>
                    <!-- end card -->

                    <div class="card border shadow-none mb-5">
                        <div class="card-header d-flex align-items-center">
                            <div class="flex-grow-1">
                                <h5 class="card-title">Cambio Password</h5>
                                <small>{{ "USERS.password-info" | translate}}</small>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="password" class="form-label">Password</label>
                                        <div class="custom-password-field">
                                            <input [type]="isVisibileNewPassword ? 'text' : 'password'" class="form-control" name="newPassword" 
                                            [class.no_valid]="newPassword !='' && (confirmPassword != newPassword)" [(ngModel)]="newPassword">
                                            <i class="uil-eye" *ngIf="!isVisibileNewPassword" (click)="isVisibileNewPassword = true"></i>
                                            <i class="uil-eye-slash" *ngIf="isVisibileNewPassword" (click)="isVisibileNewPassword = false"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="confirmPassword" class="form-label">Conferma Password</label>
                                        <div class="custom-password-field">
                                            <input [type]="isVisibileConfirmPassword ? 'text' : 'password'" class="form-control" name="confirmPassword" 
                                            [class.no_valid]="confirmPassword !='' && (confirmPassword != newPassword)" [(ngModel)]="confirmPassword">
                                            <i class="uil-eye" *ngIf="!isVisibileConfirmPassword" (click)="isVisibileConfirmPassword = true"></i>
                                            <i class="uil-eye-slash" *ngIf="isVisibileConfirmPassword" (click)="isVisibileConfirmPassword = false"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-end">
                                <button type="submit" class="btn btn-edit"
                                    [disabled]="newPassword !='' && (confirmPassword != newPassword)"
                                    (click)="changePsw()">Salva</button>
                            </div>
                        </div>
                    </div>
                    <!-- end card -->
                </form>
                <!-- end form -->
            </div>
            <!-- end card body -->
        </div>
        <!-- end card -->
    </div>
    <!-- end col -->
</div>
<!-- end row -->